import { authHeader } from '../../shared/helpers/authHeader';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { influencerEndpoints } from './influencer.endpoints';
import { IInfluencer, IInfluencerFeed, IInfluencerForm, IInfluencersResponse } from './influencer.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getInfluencers = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IInfluencersResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.GET_INFLUENCERS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: pagination.orderBy,
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getInfluencerById = async (ambassadorId: number): Promise<IInfluencer> => {
    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.GET_INFLUENCER_BY_ID.url + ambassadorId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addInfluencer = async (ambassadorData: IInfluencerForm): Promise<AxiosResponse> => {
    const formData = new FormData();

    for (const key in ambassadorData) {
        if (!ambassadorData.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, ambassadorData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.ADD_INFLUENCER.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteInfluencer = async (ambassadorId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.DELETE_INFLUENCER.url + ambassadorId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateInfluencer = async (ambassador: IInfluencerForm, ambassadorId: number): Promise<AxiosResponse> => {
    const formData = new FormData();

    for (const key in ambassador) {
        if (!ambassador.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, ambassador[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.UPDATE_INFLUENCER.url + ambassadorId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

async function getInfluencerFeed(influencerId: number) {
    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.GET_INFLUNECER_FEED.url(influencerId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data as IInfluencerFeed[];
}

function createFeedFormData(feedEntry: IInfluencerFeed) {
    const formData = new FormData();

    for (const key in feedEntry) {
        if (!feedEntry.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, feedEntry[key]);
    }

    return formData;
}

async function addInfluencerFeedEntry(influencerId: number, feedEntry: IInfluencerFeed) {
    const formData = createFeedFormData(feedEntry);

    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.ADD_INFLUNECER_FEED_ENTRY.url(influencerId),
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data.data as IInfluencerFeed;
}

async function updateInfluencerFeedEntry(entryId: number, feedEntry: IInfluencerFeed) {
    const formData = createFeedFormData(feedEntry);

    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.UPDATE_INFLUNECER_FEED_ENTRY.url(entryId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data.data as IInfluencerFeed;
}

async function deleteInfluencerFeedEntry(entryId: number) {
    const requestOptions: AxiosRequestConfig = {
        url: influencerEndpoints.DELETE_INFLUNECER_FEED_ENTRY.url(entryId),
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return null;
}

export const influencerService = {
    getInfluencers,
    getInfluencerById,
    addInfluencer,
    deleteInfluencer,
    updateInfluencer,
    getInfluencerFeed,
    addInfluencerFeedEntry,
    updateInfluencerFeedEntry,
    deleteInfluencerFeedEntry,
};
