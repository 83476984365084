import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { AxiosError, AxiosResponse } from 'axios';
import { IFilters, IPagination, ISort } from '../../shared/components/interfaces';
import { authActions } from '../../shared/actions/auth.actions';
import {
    IFilter,
    IFilterActions,
    IFilterCategoryTagsForm,
    IFilterCategoryTagsResponse,
    IFilterForm,
    IFilterResponse,
} from './filter.interfaces';
import { filterService } from './filter.service';
import { filterConstants } from './filter.constants';
import en from '../../../assets/language/en.json';

function getFilters(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IFilterResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return filterService.getFilters(pagination, searchText, filters).then(
            (response: IFilterResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_REQUEST };
    }

    function success(response: IFilterResponse): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_SUCCESS,
            filterData: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_FAILURE, error };
    }
}

function getFilterById(filterId: number): (dispatch: Dispatch) => Promise<IFilter | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return filterService.getFilterById(filterId).then(
            (response: IFilter) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_REQUEST };
    }

    function success(response: IFilter): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_SUCCESS,
            filter: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_FAILURE, error };
    }
}

const addFilter = (filterData: IFilterForm): ((dispatch: Dispatch) => Promise<IFilter | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.addFilter(filterData).then(
            (response: IFilter) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.filter_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_REQUEST };
    }

    function success(response: IFilter): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_SUCCESS,
            filter: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_FAILURE, error };
    }
};

const updateFilter = (
    filterData: IFilterForm,
    filterId: number,
): ((dispatch: Dispatch) => Promise<IFilter | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.updateFilter(filterData, filterId).then(
            (response: IFilter) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.filter_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_REQUEST };
    }

    function success(response: IFilter): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_SUCCESS,
            filter: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_REQUEST, error };
    }
};

const deleteFilter = (
    filterId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.deleteFilter(filterId).then(
            (response: AxiosResponse) => {
                dispatch(success());
                completed && dispatch(alertActions.success(en.filter_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_REQUEST };
    }

    function success(): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_SUCCESS,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_FAILURE, error };
    }
};

const getFilterCategoryTags = (
    filterId: number,
): ((dispatch: Dispatch) => Promise<IFilterCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.getFilterCategoryTags(filterId).then(
            (response: IFilterCategoryTagsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IFilterCategoryTagsResponse): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_SUCCESS,
            filterCategoryTagsData: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_FAILURE, error };
    }
};

const addFilterCategoryTags = (
    filterCategoryTags: IFilterCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<IFilterCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.addFilterCategoryTags(filterCategoryTags).then(
            (response: IFilterCategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.filter_category_tags_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IFilterCategoryTagsResponse): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_SUCCESS,
            filterCategoryTagsData: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_FAILURE, error };
    }
};

const updateFilterCategoryTags = (
    filterCategoryTags: IFilterCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<IFilterCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return filterService.updateFilterCategoryTags(filterCategoryTags).then(
            (response: IFilterCategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.filter_category_tags_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IFilterCategoryTagsResponse): IFilterActions {
        return {
            type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_SUCCESS,
            filterCategoryTagsData: response,
        };
    }

    function failure(error: string): IFilterActions {
        return { type: filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_FAILURE, error };
    }
};

export const filterAction = {
    getFilters,
    getFilterById,
    addFilter,
    updateFilter,
    getFilterCategoryTags,
    addFilterCategoryTags,
    updateFilterCategoryTags,
    deleteFilter,
};
