import * as Yup from 'yup';
import formModels from './action.name.form.model';
import { ObjectSchema } from 'yup';
import { IActionNameForm } from '../../../action.names.interfaces';

const {
    formField: { nameEn, nameDe },
} = formModels.actionNameFormModel;

const actionNameValidationSchema: ObjectSchema<IActionNameForm> = Yup.object()
    .defined()
    .shape({
        nameEn: Yup.string().required(`${nameEn.requiredErrorMsg}`).defined(),
        nameDe: Yup.string().required(`${nameDe.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    actionNameValidationSchema,
};

export default validationSchemas;
