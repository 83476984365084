import React, { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import formModels from './formModel/form.model';
import SelectionStepper from './stepper';
import { IAddOrEditSelection } from '../../selection.interfaces';
import { useParams } from 'react-router-dom';
import { selectionActions } from '../../selection.actions';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type SelectionProps = typeof formModels.selectionFormModel & IAddOrEditSelection;

const AddOrEditSelection = (props: PropsWithChildren<SelectionProps>): JSX.Element => {
    const id = useIdParam();

    const [selectionToUpdate, setSelectionToUpdate] = useState<any>({ selection: null });

    useEffect(() => {
        async function getData() {
            let selection = null;

            if (id) {
                const selectionDisplaysResponse = await props.getSelectionDisplays(id);
                const selectionResponse = await props.getSelectionById(id);
                const pinnedSelectionRecipesResponse = await props.getPinnedSelectionRecipes(id);

                selection = {
                    ...selectionResponse,
                    displays: selectionDisplaysResponse.recipeSelectionDisplays.map((selection) => selection.display),
                    pinnedRecipes: pinnedSelectionRecipesResponse.pinnedSelectionRecipes,
                    blacklistedRecipeIds: pinnedSelectionRecipesResponse.blacklistedRecipeIds,
                };
            }

            setSelectionToUpdate({
                selection,
            });
        }

        getData();
    }, []);

    return <SelectionStepper key={selectionToUpdate?.selection?.id} fullSelection={selectionToUpdate} />;
};

const mapDispatchToProps = {
    getSelectionById: selectionActions.getSelectionById,
    getSelectionDisplays: selectionActions.getSelectionDisplays,
    getPinnedSelectionRecipes: selectionActions.getPinnedSelectionRecipes,
    getValueTags: selectionActions.getValueTag,
};

export default connect(null, mapDispatchToProps)(AddOrEditSelection);
