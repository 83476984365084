import { IUserReportActions, IUserReportState } from './user.report.interfaces';
import { userReportConstants } from './user.report.constants';

const initialState: IUserReportState = {
    loading: false,
};

const userReportsReducer = (state = initialState, action: IUserReportActions): IUserReportState => {
    switch (action.type) {
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_REQUEST:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_REQUEST:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_REQUEST:
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_REQUEST:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_SUCCESS:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_SUCCESS:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_SUCCESS:
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_SUCCESS:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_FAILURE:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_FAILURE:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_FAILURE:
        case userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_FAILURE:
        case userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_FAILURE:
            return {
                loading: false,
            };
        default:
            return state;
    }
};

export { userReportsReducer };
