import * as Yup from 'yup';
import formModels from './units.form.model';
import { ObjectSchema } from 'yup';
import { IUnit } from '../../../unit.interfaces';

const {
    formField: { name, type },
} = formModels.unitFormModel;

const unitValidationSchema: ObjectSchema<IUnit> = Yup.object()
    .defined()
    .shape({
        name: Yup.string().required(`${name.requiredErrorMsg}`).defined(),
        nameDe: Yup.string().required(`${name.requiredErrorMsg}`).defined(),
        type: Yup.string().required(`${name.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    unitValidationSchema,
};

export default validationSchemas;
