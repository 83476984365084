import React from 'react';

import { Button } from '@material-ui/core';
import styles from './index.module.scss';

type OwnProps = {
    onContinue: () => any;
    onAddDescription: () => any;
};

export default function NotCompleteDialog(props: OwnProps) {
    return (
        <div className={styles.descIncompleteDialog}>
            <p>The description is not completed.</p>
            <p>Do you want to continue anyway?</p>
            <div>
                <Button
                    classes={{
                        root: styles.buttonWhite,
                    }}
                    onClick={props.onContinue}
                >
                    CONTINUE
                </Button>
                <Button
                    classes={{
                        root: styles.buttonRed,
                    }}
                    onClick={props.onAddDescription}
                >
                    ADD DESCRIPTION
                </Button>
            </div>
        </div>
    );
}
