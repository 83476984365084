import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Paper,
    TextField,
} from '@material-ui/core';
import styles from '../index.module.scss';
import { recipeConstants } from '../../../recipe.constants';
import { Form, Formik, FormikProps } from 'formik';
import recipeFormModel from '../FormModel/RecipeFormModel';
import { styleConstants } from '../../../../../shared/constants/style.constants';
import formInitialValues from '../FormModel/FormInitialValues';
import validationSchemas from '../FormModel/ValidationSchema';
import { IGrillModel, IRecipeForm, IRecipeFormProps, RecipeDifficulty } from '../../../recipe.interfaces';
import { connect } from 'react-redux';
import { recipeAction } from '../../../recipe.actions';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import en from '../../../../../../assets/language/en.json';
import { cookingMethodActions } from '../../../../cookingMethods/cooking.method.actions';
import { ICookingMethodData, ICookingMethodResponse } from '../../../../cookingMethods/cooking.methods.interfaces';
import ImportFile from '../../../../import/pages/import.file';
import { IInfluencer } from '../../../../influencers/influencer.interfaces';
import { AccessTime } from '@material-ui/icons';
import { tutorialActions } from '../../../../tutorials/tutorial.actions';
import clsx from 'clsx';
import LanguageFlag from '../../../../../shared/components/generics/languageFlag/LanguageFlag';
import { AppState, useAppDispatch, useAppSelector } from '../../../../../shared/store';
import { ITutorialRecipe } from '../../../../tutorials/tutorial.interfaces';
import { influencerThunks } from '../../../../influencers/influencer.thunks';
interface PopoverOrigin {
    anchorOrigin: { vertical: 'top' | 'center' | 'bottom' | number; horizontal: 'left' | 'center' | 'right' | number };
    transformOrigin: {
        vertical: 'top' | 'center' | 'bottom' | number;
        horizontal: 'left' | 'center' | 'right' | number;
    };
    getContentAnchorEl: any;
    style: any;
}

type Props = typeof recipeFormModel.recipeFormModel & IRecipeFormProps & ReturnType<typeof mapStateToProps>;
const AddNewRecipe = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const hiddenThumbnailInput = React.useRef(null);
    const hiddenFullPicInput = React.useRef(null);
    let formValues = formInitialValues.recipeInitialValues;
    const componentIsMounted = useRef(true);
    const {
        formField: {
            name_en,
            name_de,
            cookingMethod,
            difficulty,
            restingTime,
            preparationTime,
            cookingTime,
            overnightTime,
            servings,
            minimumServing,
            servingSteps,
            thumbnailPicture,
            fullPicture,
            ambassador,
            grillModels,
            shouldTimeScale,
            proRecipe,
            adminRecipe,
            hintTitleDe,
            hintTitleEn,
            hintDescriptionDe,
            hintDescriptionEn,
            recipeTutorials,
        },
        recipeToUpdate,
    } = props;
    const [updatingRecipe, setUpdatingRecipe] = useState<IRecipeForm>(props.recipeToUpdate);
    const [cookingMethods, setCookingMethods] = useState<Array<ICookingMethodData>>([]);
    const [grills, setGrills] = useState<Array<IGrillModel>>([]);
    const [tutorials, setTutorials] = useState<Array<ITutorialRecipe>>([]);
    const ambassadors = useAppSelector((state) => state.influencers.influencers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (componentIsMounted) {
            if (updatingRecipe) {
                formValues = updatingRecipe;
            }
            getCookingMethods();
            getAmbassadors();
            getGrills();
            getTutorials();
        }
    }, []);

    const getCookingMethods = (): void => {
        props.getCookingMethods(globalConstants.PAGINATION_NO_LIMIT).then((response: ICookingMethodResponse) => {
            setCookingMethods(response.cookingMethods);
        });
    };
    const getAmbassadors = (): void => {
        dispatch(influencerThunks.fetchInfluencers({ pagination: globalConstants.PAGINATION_NO_LIMIT }));
    };
    const getGrills = (): void => {
        props.getGrills().then((response) => {
            setGrills(response.grillModels);
        });
    };
    const getTutorials = (): void => {
        props.getTutorials(globalConstants.PAGINATION_NO_LIMIT).then((response) => {
            setTutorials(response.tutorials);
        });
    };

    const handleSubmit = (values: IRecipeForm): void => {
        recipeToUpdate ? updateRecipe(values) : addRecipe(values);
    };

    const addRecipe = (values: IRecipeForm): void => {
        const newRecipe = values;
        newRecipe.difficulty_de = values.difficulty_en;

        props.addRecipe(newRecipe).then((response) => {
            setUpdatingRecipe(response.data);
            props.handleCompleteStep(response.data);
        });
        componentIsMounted.current = true;
    };

    const updateRecipe = (values: IRecipeForm): void => {
        const recipe = values;
        recipe.difficulty_de = values.difficulty_en;

        props.updateRecipe(recipe, recipeToUpdate.id).then((response) => {
            props.handleCompleteStep(response.data);
        });
    };

    const nextStep = (): void => {
        props.nextStep(updatingRecipe);
    };

    const handleImportClick = (type: number): void => {
        if (type) {
            hiddenFullPicInput.current.click();
        } else {
            hiddenThumbnailInput.current.click();
        }
    };

    const dropDownMenuProps: PopoverOrigin = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
        style: {
            height: '22rem',
        },
    };

    return (
        <>
            <Paper className={clsx(styles.addRecipePaper, 'mt8')} variant={'outlined'}>
                <Formik
                    enableReinitialize={true}
                    initialValues={updatingRecipe ? updatingRecipe : formValues}
                    onSubmit={(values: IRecipeForm, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.recipeSchema}
                >
                    {(props: FormikProps<IRecipeForm>) => (
                        <Form className={clsx(styles.addRecipeForm, 'mt8')}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.checkBox, 'mb8')}>
                                            <FormControlLabel
                                                name={overnightTime.name}
                                                value={props.values.overnightTime}
                                                control={<Checkbox color="primary" />}
                                                label={overnightTime.label}
                                                labelPlacement="end"
                                                onChange={props.handleChange}
                                                checked={props.values.overnightTime}
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={preparationTime.name}
                                                label={preparationTime.label}
                                                type="number"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.preparation_time}
                                                helperText={
                                                    props.errors.preparation_time && props.touched.preparation_time
                                                        ? props.errors.preparation_time
                                                        : ''
                                                }
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AccessTime />
                                                            <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={cookingTime.name}
                                                label={cookingTime.label}
                                                type="number"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.cooking_time}
                                                helperText={
                                                    props.errors.cooking_time && props.touched.cooking_time
                                                        ? props.errors.cooking_time
                                                        : ''
                                                }
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AccessTime />
                                                            <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={restingTime.name}
                                                label={restingTime.label}
                                                type="number"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.resting_time}
                                                helperText={
                                                    props.errors.resting_time && props.touched.resting_time
                                                        ? props.errors.resting_time
                                                        : ''
                                                }
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AccessTime />
                                                            <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={servings.label}
                                                name={servings.name}
                                                type="number"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.serving || ''}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                helperText={
                                                    props.errors.serving && props.touched.serving
                                                        ? props.errors.serving
                                                        : ''
                                                }
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={minimumServing.label}
                                                name={minimumServing.name}
                                                type="number"
                                                InputProps={{ inputProps: { min: 0, max: props.values.serving } }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.minimumServing || ''}
                                                helperText={
                                                    props.errors.minimumServing && props.touched.minimumServing
                                                        ? props.errors.minimumServing
                                                        : ''
                                                }
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={servingSteps.label}
                                                name={servingSteps.name}
                                                type="number"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.servingSteps || ''}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                helperText={
                                                    props.errors.servingSteps && props.touched.servingSteps
                                                        ? props.errors.servingSteps
                                                        : ''
                                                }
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={clsx(styles.checkBox, 'mt8')}>
                                            <FormControlLabel
                                                name={proRecipe.name}
                                                value={props.values.proRecipe}
                                                control={<Checkbox color="primary" />}
                                                label={proRecipe.label}
                                                labelPlacement="end"
                                                onChange={props.handleChange}
                                                checked={props.values.proRecipe}
                                            />
                                        </FormControl>
                                        <FormControl className={clsx(styles.checkBox, 'mt8')}>
                                            <FormControlLabel
                                                name={adminRecipe.name}
                                                value={props.values.adminRecipe}
                                                control={<Checkbox color="primary" />}
                                                label={adminRecipe.label}
                                                labelPlacement="end"
                                                onChange={props.handleChange}
                                                checked={props.values.adminRecipe}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item sm={12} xs={12} md={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={name_en.name}
                                                label={name_en.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_en}
                                                inputProps={{
                                                    maxLength: styleConstants.TEXT_FIELD_LIMIT,
                                                }}
                                                helperText={`${props.values.name_en.length}/${styleConstants.TEXT_FIELD_LIMIT}`}
                                                variant="outlined"
                                                multiline
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_en && props.touched.name_en
                                                    ? props.errors.name_en
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={name_de.name}
                                                label={name_de.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_de}
                                                inputProps={{
                                                    maxLength: styleConstants.TEXT_FIELD_LIMIT,
                                                }}
                                                helperText={`${props.values.name_de.length}/${styleConstants.TEXT_FIELD_LIMIT}`}
                                                variant="outlined"
                                                multiline
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_de && props.touched.name_de
                                                    ? props.errors.name_de
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={cookingMethod.label}
                                                select
                                                name={cookingMethod.name_en}
                                                value={props.values.cooking_method_ids}
                                                helperText={
                                                    props.errors.cooking_method_ids && props.touched.cooking_method_ids
                                                        ? props.errors.cooking_method_ids
                                                        : ''
                                                }
                                                onChange={props.handleChange(cookingMethod.name_en)}
                                                onBlur={props.handleBlur(cookingMethod.name_en)}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                                SelectProps={{
                                                    multiple: true,
                                                    MenuProps: { style: dropDownMenuProps.style },
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{cookingMethod.label}</em>
                                                </MenuItem>
                                                {cookingMethods
                                                    ? cookingMethods.map((cookingMethod, index) => (
                                                          <MenuItem key={index} value={cookingMethod.id}>
                                                              {cookingMethod.nameEn}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={difficulty.label}
                                                select
                                                name={difficulty.name_en}
                                                value={props.values.difficulty_en}
                                                helperText={
                                                    props.errors.difficulty_en && props.touched.difficulty_en
                                                        ? props.errors.difficulty_en
                                                        : ''
                                                }
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                variant={'outlined'}
                                                required
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{difficulty.label}</em>
                                                </MenuItem>
                                                {Object.keys(RecipeDifficulty).map((key) => (
                                                    <MenuItem key={key} value={RecipeDifficulty[key]}>
                                                        {RecipeDifficulty[key]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={ambassador.label}
                                                select
                                                name={ambassador.name}
                                                value={props.values.ambassador_id}
                                                helperText={
                                                    props.errors.ambassador_id && props.touched.ambassador_id
                                                        ? props.errors.ambassador_id
                                                        : ''
                                                }
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                onChange={props.handleChange(ambassador.name)}
                                                onBlur={props.handleBlur(ambassador.name)}
                                                variant={'outlined'}
                                                SelectProps={{
                                                    MenuProps: dropDownMenuProps,
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{ambassador.label}</em>
                                                </MenuItem>
                                                <MenuItem key="113052990262056400933888728" value={undefined}>
                                                    <em>none</em>
                                                </MenuItem>
                                                {ambassadors
                                                    ? ambassadors.map((ambassador, index) => (
                                                          <MenuItem key={index} value={ambassador.id}>
                                                              {ambassador.blogger_name}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={grillModels.label}
                                                select
                                                name={grillModels.name}
                                                value={props.values.grillModelIds}
                                                helperText={
                                                    props.errors.grillModelIds && props.touched.grillModelIds
                                                        ? props.errors.grillModelIds
                                                        : ''
                                                }
                                                onChange={props.handleChange(grillModels.name)}
                                                onBlur={props.handleBlur(grillModels.name)}
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                                required
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{grillModels.label}</em>
                                                </MenuItem>
                                                <MenuItem value={recipeConstants.GRILL_CONSTANTS.NO_GRILL_VALUE}>
                                                    {recipeConstants.GRILL_CONSTANTS.NO_GRILL}
                                                </MenuItem>
                                                {grills
                                                    ? grills.map((grill, index) => (
                                                          <MenuItem key={index} value={grill.id}>
                                                              {grill.name}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                label={recipeTutorials.label}
                                                select
                                                name={recipeTutorials.name}
                                                value={props.values.tutorialIds}
                                                helperText={
                                                    props.errors.tutorialIds && props.touched.tutorialIds
                                                        ? props.errors.tutorialIds
                                                        : ''
                                                }
                                                onChange={props.handleChange(recipeTutorials.name)}
                                                onBlur={props.handleBlur(recipeTutorials.name)}
                                                SelectProps={{
                                                    multiple: true,
                                                    MenuProps: dropDownMenuProps,
                                                }}
                                                FormHelperTextProps={{
                                                    className: clsx(styles.error_span, 'mt2'),
                                                }}
                                                variant={'outlined'}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{recipeTutorials.label}</em>
                                                </MenuItem>
                                                {tutorials
                                                    ? tutorials.map((tutorial, index) => (
                                                          <MenuItem key={index} value={tutorial.id}>
                                                              {tutorial.titleEn}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item sm={12} xs={12} md={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={hintTitleEn.name}
                                                label={hintTitleEn.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.hintTitleEn}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.hintTitleEn && props.touched.hintTitleEn
                                                    ? props.errors.hintTitleEn
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={hintTitleDe.name}
                                                label={hintTitleDe.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.hintTitleDe}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.hintTitleDe && props.touched.hintTitleDe
                                                    ? props.errors.hintTitleDe
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={hintDescriptionEn.name}
                                                label={hintDescriptionEn.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.hintDescriptionEn}
                                                variant="outlined"
                                                multiline
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.hintDescriptionEn && props.touched.hintDescriptionEn
                                                    ? props.errors.hintDescriptionEn
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <TextField
                                                name={hintDescriptionDe.name}
                                                label={hintDescriptionDe.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.hintDescriptionDe}
                                                variant="outlined"
                                                multiline
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.hintDescriptionDe && props.touched.hintDescriptionDe
                                                    ? props.errors.hintDescriptionDe
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item sm={12} xs={12} md={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={styles.formControl}>
                                            <Card className={clsx(styles.uploadCard, 'mb8')}>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={props.values.thumbnail_picture}
                                                        title={thumbnailPicture.label}
                                                        style={styles.imgCard}
                                                        remove={() => props.setFieldValue('thumbnail_picture', null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id="importFile"
                                                        type="file"
                                                        className={styles.fileInput}
                                                        ref={hiddenThumbnailInput}
                                                        onChange={(event) => {
                                                            props.setFieldValue(
                                                                thumbnailPicture.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() =>
                                                            handleImportClick(recipeConstants.RECIPE_PICTURES.THUMBNAIL)
                                                        }
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_thumbnail_img}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <FormControl className={styles.formControl}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={props.values.full_picture}
                                                        title={fullPicture.label}
                                                        style={styles.imgCard}
                                                        remove={() => props.setFieldValue('full_picture', null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id="importFile"
                                                        type="file"
                                                        className={styles.fileInput}
                                                        ref={hiddenFullPicInput}
                                                        onChange={(event) => {
                                                            props.setFieldValue(
                                                                fullPicture.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() =>
                                                            handleImportClick(recipeConstants.RECIPE_PICTURES.FULL)
                                                        }
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_full_img}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <FormControl className={clsx(styles.checkBox, 'mt8')}>
                                            <FormControlLabel
                                                name={shouldTimeScale.name}
                                                value={props.values.shouldTimeScale}
                                                control={<Checkbox color="primary" />}
                                                label={shouldTimeScale.label}
                                                labelPlacement="end"
                                                onChange={props.handleChange}
                                                checked={props.values.shouldTimeScale}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <div className={styles.bottomNavigation}>
                                <Button
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    onClick={nextStep}
                                    variant={'outlined'}
                                    disabled={!recipeToUpdate}
                                >
                                    {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                                </Button>
                                <Button
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    type="submit"
                                    variant={'outlined'}
                                    color={'inherit'}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        tutorialsTotal: state.tutorials.total,
        cookingMethodTotal: state.cookingMethods.total,
    };
};

const mapDispatchToProps = {
    getRecipeById: recipeAction.getRecipeById,
    addRecipe: recipeAction.addRecipe,
    updateRecipe: recipeAction.updateRecipe,
    getCookingMethods: cookingMethodActions.getCookingMethods,
    getRecipeCookingMethods: recipeAction.getRecipeCookingMethods,
    getGrills: recipeAction.getGrillModels,
    getTutorials: tutorialActions.getTutorials,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewRecipe);
