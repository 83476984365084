import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Step, StepButton, Stepper, WithStyles } from '@material-ui/core';
import styles from './index.module.scss';
import formModels from './formModel/form.model';
import { History } from 'history';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { stepperStyles } from '../../../recipe/pages/addRecipe/styles';
import en from '../../../../../assets/language/en.json';
import { IFilter, IFilterForm } from '../../filter.interfaces';
import FilterForm from './forms/Filter';
import FilterCategoryTags from './forms/FilterCategoryTags';
import { Link, useParams } from 'react-router-dom';
import { filterConstants } from '../../../../modules/filters/filter.constants';
import { AppState } from '../../../../shared/store';
import { withRouter } from 'react-router-dom';

interface IStepper extends WithStyles<typeof stepperStyles> {
    history: History;
    filterData: IFilter;
}

type Props = IFilterForm & IStepper & ReturnType<typeof mapStateToProps>;

const { formId, formField } = formModels.filterFormModel;
const steps: string[] = [en.filter_label, en.filter_category_tags_label];

const FilterStepper = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const { classes, filter, history } = props;
    const { id, step } = useParams() as any;

    const totalSteps: number = steps.length;
    const subRoutes = filterConstants.FILTERS_SUB_ROUTES;
    const defaultActiveStep = step ? Object.values(subRoutes).indexOf(step) : 0;

    const [activeStep, setActiveStep] = useState<number | null>(defaultActiveStep);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [filterToUpdate, setFilterToUpdate] = useState<IFilter | null>(id ? filter : null);

    const isLastStep = () => {
        return activeStep === totalSteps - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps;
    };

    const nextStep = (filter?: IFilter): void => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;

        setFilterToUpdate(filter);
        setActiveStep(newActiveStep);
        id && pushToHistory(newActiveStep);
    };

    const prevStep = (filter?: IFilter): void => {
        setFilterToUpdate(filter);
        setActiveStep((prevStep: number) => prevStep - 1);
        id && pushToHistory(activeStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = (filter?: IFilter) => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        nextStep(filter);
    };

    const pushToHistory = (pushToHistory) => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        history.push(`${filterConstants.FILTERS_ROUTES.EDIT_FILTER}/${formatSubRoutes[pushToHistory]}/${id}`);
    };

    const renderSteps = (step: number): JSX.Element => {
        switch (step) {
            case 0:
                return (
                    <FilterForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        filter={filterToUpdate}
                    />
                );
            case 1:
                return (
                    <FilterCategoryTags
                        formId={formModels.filterFormModel.formId}
                        formField={formModels.filterFormModel.formField}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        filter={filterToUpdate}
                    />
                );
            default:
                return (
                    <FilterForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        filter={filterToUpdate}
                    />
                );
        }
    };

    return (
        <div className={styles.addFilterStepper}>
            <Stepper activeStep={activeStep} className={classes.stepper} variant={'outlined'} nonLinear>
                {steps.map((label: string, index: number) => {
                    const subRoute = Object.values(subRoutes)[index];
                    return (
                        <Step key={label}>
                            <StepButton
                                component={Link}
                                to={`${filterConstants.FILTERS_ROUTES.EDIT_FILTER}/${subRoute}/${id}`}
                                onClick={handleStep(index)}
                                completed={completed[index]}
                                disabled={!filterToUpdate}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {renderSteps(activeStep)}
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        filter: state.filters.filter,
    };
};

export default compose(withRouter, withStyles(stepperStyles), connect(mapStateToProps, null))(FilterStepper);
