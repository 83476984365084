import React, { FunctionComponent, PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnState } from 'mui-datatables';
import { compose } from 'recompose';
import { Paper, withStyles } from '@material-ui/core';
import { muiStyles } from '../../../styles/mui.styles';
import { muiDataTablesConstants } from '../../../constants/mui.datatables.constants';
import clsx from 'clsx';
import { IFilters, IPagination } from '../../interfaces';
import { globalConstants } from '../../../constants/global.constants';
import { generateRequestFilters, getColumnNameAndIndex } from '../../../helpers/mui-table.helpers';

interface OwnProps {
    title: string;
    options: MUIDataTableOptions;
    columns: MUIDataTableColumnState[];
    data: any;
    total: number;
    loading: boolean;
    refreshData: (pagination: IPagination, searchText: string, filters?: Array<IFilters>) => Promise<void>;
    classes: {
        NameCell: string;
    };
}

const DynamicTable = (props: PropsWithChildren<OwnProps>): ReactElement<FunctionComponent<OwnProps>> => {
    const [filters, setFilters] = useState<Array<IFilters> | null>([]);
    const [pagination, setPagination] = useState<IPagination | null>(muiDataTablesConstants.TABLE_PAGINATION);
    const [searchText, setSearchText] = useState<string | null>(null);
    const { title, options, columns, data, classes, total, loading } = props;

    const _refresh = useCallback(async () => {
        await props.refreshData(pagination, searchText, filters);
    }, [pagination, searchText, filters]);

    useEffect(() => {
        (async () => {
            await props.refreshData(pagination, searchText, filters);
        })();
    }, [_refresh]);

    const tableOptions: MUIDataTableOptions = {
        filterType: muiDataTablesConstants.FILTER_TYPE.MULTISELECT,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        serverSide: true,
        rowsPerPage: pagination.size,
        count: total,
        searchText,
        onFilterChange: (columnChanged: string, filterList): void => {
            const filterState = getColumnNameAndIndex(columnChanged, columns);

            setFilters(generateRequestFilters(filterState, filterList, filters));
        },
        setRowProps: (): { className: string } => {
            return {
                className: clsx({ [classes.NameCell]: true }),
            };
        },
        ...options,
        onTableChange: (action, tableState) => {
            switch (action) {
                case muiDataTablesConstants.TABLE_ACTIONS.CHANGE_PAGE_ACTION:
                    setPagination({
                        ...pagination,
                        page: tableState.page,
                    });
                    break;
                case muiDataTablesConstants.TABLE_ACTIONS.CHANGE_ROWS_ACTION:
                    setPagination({
                        ...pagination,
                        size: tableState.rowsPerPage,
                    });
                    break;
                case muiDataTablesConstants.TABLE_ACTIONS.SEARCH_ACTION:
                    setSearchText(tableState.searchText);
                    setPagination({
                        ...pagination,
                        page: globalConstants.DEFAULT_PAGINATION.page,
                    });
                    break;
                case muiDataTablesConstants.TABLE_ACTIONS.SORT_ACTION:
                    setPagination({
                        ...pagination,
                        orderBy: tableState.sortOrder.name,
                        orderDirection: tableState.sortOrder.direction.toUpperCase() as any,
                    });
                    break;
                default:
                    break;
            }
        },
    };

    return (
        <Paper>
            <MUIDataTable title={title} options={tableOptions} columns={columns} data={data} />
        </Paper>
    );
};

export default compose(withStyles(muiStyles.customStyles))(DynamicTable);
