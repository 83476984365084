import { categoryTagsConstants } from './category.tags.constants';
import { ICategoryTagsState, ICategoryTagsActions } from './category.tags.interfaces';

const initialState: ICategoryTagsState = { categoryTags: [], categoryTag: null, loading: false };

const categoryTagsReducer = (state = initialState, action: ICategoryTagsActions): ICategoryTagsState => {
    switch (action.type) {
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_SUCCESS:
            return {
                ...state,
                categoryTags: action.categoryTagsData.categoryTags,
                total: action.categoryTagsData.total,
                loading: false,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_FAILURE:
            return {
                loading: false,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_SUCCESS:
            return {
                ...state,
                categoryTag: action.categoryTag,
                loading: false,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_SUCCESS:
            return {
                ...state,
                categoryTag: action.categoryTag,
                loading: false,
            };
        case categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { categoryTagsReducer };
