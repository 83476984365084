import { actionNamesConstants } from './action.names.constants';

const { ACTION_NAMES, ACTION_NAMES_WITH_PARAMS } = actionNamesConstants.ACTION_NAMES_ROUTES;

export const actionNamesEndpoints = {
    GET_ACTION_NAMES: {
        url: process.env.REACT_APP_SERVER_URL + ACTION_NAMES,
    },
    ADD_ACTION_NAME: {
        url: process.env.REACT_APP_SERVER_URL + ACTION_NAMES,
    },
    DELETE_ACTION_NAME: {
        url: process.env.REACT_APP_SERVER_URL + ACTION_NAMES_WITH_PARAMS,
    },
    UPDATE_ACTION_NAME: {
        url: process.env.REACT_APP_SERVER_URL + ACTION_NAMES_WITH_PARAMS,
    },
};
