import { CardMedia, Modal } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import styles from './index.module.scss';

type Props = {
    title: string;
    image: string;
    alt: string;
    baseStyle: React.CSSProperties;
};

export default function ImageModal(props: Props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <CardMedia
                onClick={handleOpen}
                component="img"
                style={{
                    ...props.baseStyle,
                    cursor: 'pointer',
                }}
                title={props.title}
                image={props.image}
                alt={props.alt}
            />
            <Modal open={open} onClose={handleClose} className={styles.modal}>
                <CardMedia
                    onClick={handleOpen}
                    component="img"
                    className={styles.modalImage}
                    title={props.title}
                    image={props.image}
                    alt={props.alt}
                />
            </Modal>
        </div>
    );
}
