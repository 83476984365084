import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const selectionFormModel = {
    formId: uniqueId('form-'),
    formField: {
        name_en: {
            name: 'name_en',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        name_de: {
            name: 'name_de',
            label: de.name,
            requiredErrorMsg: de.name_required_error_msg,
        },
        displays: {
            name: 'displays',
            label: en.display_label,
            requiredErrorMsg: en.display_required_error_msg,
        },
        seasonalStart: {
            name: 'seasonalStart',
            label: en.from,
        },
        seasonalEnd: {
            name: 'seasonalEnd',
            label: en.to,
        },
        recipe: {
            name: 'pinnedRecipes',
            label: en.recipes_label,
            requiredErrorMsg: en.recipes_required_error_msg,
        },
    },
};

const valueTagFormModel = {
    formId: uniqueId('form-'),
    formField: {
        preperationTime: {
            name: 'preparationTime',
            label: en.preparation_time_label,
        },
        cookingMethod: {
            name: 'cookingMethodIds',
            label: en.cooking_methods,
        },
        restingTime: {
            name: 'restingTime',
            label: en.resting_time_label,
        },
        ambassador: {
            name: 'ambassadorId',
            label: en.ambassador_label,
        },
        totalTime: {
            name: 'totalTime',
            label: en.total_time,
        },
        grillModel: {
            name: 'grillModelIds',
            label: en.grill_models_label,
        },
        cookingTimeFrom: {
            name: 'cookingTimeFrom',
            label: 'From',
            placeholder: en.cooking_time,
        },
        cookingTimeTo: {
            name: 'cookingTimeTo',
            label: 'To',
            placeholder: en.cooking_time,
        },
        rating: {
            name: 'rating',
            label: en.rating_label,
        },
        servings: {
            name: 'servings',
            label: en.servings_label,
        },
        zoneTemperatureFrom: {
            name: 'zoneTemperatureFrom',
            label: 'From',
            placeholder: en.zone_temperature,
        },
        zoneTemperatureTo: {
            name: 'zoneTemperatureTo',
            label: 'To',
            placeholder: en.zone_temperature,
        },
        difficulty: {
            name: 'difficulty',
            label: en.difficulty_label,
        },
        ingredientId: {
            name: 'ingredientIds',
            label: en.ingredient_label,
        },
    },
};

const recipeFormModel = {
    formId: uniqueId('form-'),
    formField: {
        recipe: {
            name: 'pinnedRecipes',
            label: en.recipes_label,
            requiredErrorMsg: en.recipes_required_error_msg,
        },
    },
};

const formModels = {
    selectionFormModel,
    recipeFormModel,
    valueTagFormModel,
};

export default formModels;
