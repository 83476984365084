import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import { commonOptions } from './common.datatable.options';

const cookingMethodColumns = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'nameDe',
        label: de.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    ...commonOptions.objectTimestamps,
];

export const cookingMethodDataTables = {
    cookingMethodColumns,
};
