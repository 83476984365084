import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import {
    ITutorialForm,
    ITutorialActions,
    ITutorialResponse,
    ITutorialData,
    ITutorialStepForm,
    ITutorialStepsResponse,
    ITutorialProductsResponse,
    ITutorialProductsForm,
} from './tutorial.interfaces';
import en from '../../../assets/language/en.json';
import { tutorialService } from './tutorial.service';
import { tutorialConstants } from './tutorial.constants';
import { IFilters, IPagination } from '../../shared/components/interfaces';

function getTutorials(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<ITutorialResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.getTutorials(pagination, searchText, filters).then(
            (response: ITutorialResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_REQUEST };
    }

    function success(response: ITutorialResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_SUCCESS,
            tutorialsData: response,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_FAILURE, error };
    }
}

const addTutorial = (
    TutorialsData: ITutorialForm,
): ((dispatch: Dispatch) => Promise<ITutorialResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.addTutorial(TutorialsData).then(
            (response: ITutorialResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_REQUEST };
    }

    function success(response: ITutorialResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_SUCCESS,
            tutorial: response.data,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_FAILURE, error };
    }
};

const updateTutorial = (
    TutorialData: ITutorialForm,
    TutorialId: number,
): ((dispatch: Dispatch) => Promise<ITutorialResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.updateTutorial(TutorialData, TutorialId).then(
            (response: ITutorialResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_REQUEST };
    }

    function success(response: ITutorialResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_SUCCESS,
            tutorial: response.data,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_FAILURE, error };
    }
};

const deleteTutorial = (
    tutorialId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.deleteTutorial(tutorialId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.tutorial_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_REQUEST };
    }

    function success(response: AxiosResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_SUCCESS,
        };
    }

    function failure(error: string): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_FAILURE,
            error,
        };
    }
};

function getTutorialById(tutorialId: number): (dispatch: Dispatch) => Promise<ITutorialData | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.getTutorialById(tutorialId).then(
            (response: ITutorialData) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_BY_ID_REQUEST };
    }

    function success(response: ITutorialData): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_BY_ID_SUCCESS,
            tutorial: response,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_BY_ID_FAILURE, error };
    }
}

const addTutorialStep = (
    tutorialStep: ITutorialStepForm,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.addTutorialStep(tutorialStep).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_step_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_REQUEST };
    }

    function success(response: AxiosResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_SUCCESS,
            tutorialStep: response.data,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_FAILURE, error };
    }
};

const updateTutorialStep = (
    tutorialStep: ITutorialStepForm,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.updateTutorialSteps(tutorialStep).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_step_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_REQUEST };
    }

    function success(response: AxiosResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_SUCCESS,
            tutorialStep: response.data,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_FAILURE, error };
    }
};

const getTutorialSteps = (
    tutorialId: number,
): ((dispatch: Dispatch) => Promise<ITutorialStepsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.getTutorialSteps(tutorialId).then(
            (response: ITutorialStepsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_REQUEST };
    }

    function success(response: ITutorialStepsResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_SUCCESS,
            tutorialStep: response.tutorialStep,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_FAILURE, error };
    }
};

const deleteTutorialStep = (tutorialStepId: number): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.deleteTutorialStep(tutorialStepId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_step_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_REQUEST };
    }

    function success(response: AxiosResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_SUCCESS,
        };
    }

    function failure(error: string): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_FAILURE,
            error,
        };
    }
};

const getTutorialProducts = (
    tutorialId: number,
): ((dispatch: Dispatch) => Promise<ITutorialProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.getTutorialProducts(tutorialId).then(
            (response: ITutorialProductsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_REQUEST };
    }

    function success(response: ITutorialProductsResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_SUCCESS,
            tutorialProducts: response.tutorialProducts,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_FAILURE, error };
    }
};

const addTutorialProducts = (
    tutorialProducts: ITutorialProductsForm,
): ((dispatch: Dispatch) => Promise<ITutorialProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.addTutorialProducts(tutorialProducts).then(
            (response: ITutorialProductsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_products_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_REQUEST };
    }

    function success(response: ITutorialProductsResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_SUCCESS,
            tutorialProducts: response.tutorialProducts,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_FAILURE, error };
    }
};

const updateRecipeProducts = (
    tutorialProducts: ITutorialProductsForm,
): ((dispatch: Dispatch) => Promise<ITutorialProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return tutorialService.updateTutorialProducts(tutorialProducts).then(
            (response: ITutorialProductsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.tutorial_products_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_REQUEST };
    }

    function success(response: ITutorialProductsResponse): ITutorialActions {
        return {
            type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_SUCCESS,
            tutorialProducts: response.tutorialProducts,
        };
    }

    function failure(error: string): ITutorialActions {
        return { type: tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_FAILURE, error };
    }
};

export const tutorialActions = {
    getTutorials,
    addTutorial,
    updateTutorial,
    deleteTutorial,
    getTutorialById,
    addTutorialStep,
    updateTutorialStep,
    getTutorialSteps,
    deleteTutorialStep,
    getTutorialProducts,
    addTutorialProducts,
    updateRecipeProducts,
};
