import { memo, useCallback, useState } from 'react';
import { useAppDispatch } from '../../../shared/store';
import { QueryOptions } from '../analytics.interfaces';
import { SetFilterPayload, analyticsActions } from '../analytics.slice';
import { AnalyticsFilterSection } from './filter.section';
import AnalyticsQueryResult from './query.results';
import styles from '../analyticsOverview/index.module.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';

type Props = {
    queryKey: string;
    queryOption: QueryOptions[string];
};

export const AnalyticsQuerySection = memo(AnalyticsQuerySectionComponent);

function AnalyticsQuerySectionComponent({ queryKey, queryOption }: Props) {
    const dispatch = useAppDispatch();
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

    const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionExpanded(isExpanded);
    };

    const setFilterOptions = useCallback(
        (filterPayload: SetFilterPayload) =>
            void dispatch(analyticsActions.setQuerySpecificFilters({ ...filterPayload, queryKey })),
        [dispatch],
    );

    return (
        <>
            <Accordion className={styles.accordion} expanded={accordionExpanded} onChange={handleAccordionChange}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <div className={styles.accordionSummary}>
                        <h2 className={styles.queryHeader}>{queryOption.name}</h2>
                        {!!queryOption.description && (
                            <p className={styles.queryDescription}>{queryOption.description}</p>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails className={styles.resultArea}>
                    <AnalyticsFilterSection
                        key={queryKey}
                        filterOptions={queryOption.filters}
                        setFilterOption={setFilterOptions}
                    />
                    <AnalyticsQueryResult queryKey={queryKey} displayValueSum={queryOption.displayValueSum} />
                </AccordionDetails>
            </Accordion>
        </>
    );
}
