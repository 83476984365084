import React from 'react';
import { Button, FormControl, FormGroup, Input, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { loginActions } from '../../login.actions';
import { loginConstants } from '../../login.constants';
import styles from './index.module.scss';

type OwnProps = DispatchPropsReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ChangePassword = (props: PropsWithChildren<OwnProps>) => {
    const initialValues = { newPassword: '' };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { token } = useParams<{ token: string }>();

    const validationSchema = loginConstants.changePasswordValidationSchema;

    const history = useHistory();
    const handleSubmit = async (values: typeof initialValues) => {
        setFormSubmitted(true);
        const result = await props.changePassword({
            newPassword: values.newPassword,
            forgotPasswordToken: token,
        });
        if (result === true) {
            history.push(loginConstants.ROUTES.LOGIN);
        }
    };

    return (
        <div className={styles.login}>
            <div className={styles.loginContainer}>
                <h1 className={styles.title}>Change Password for Otto Wilde CMS</h1>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<typeof initialValues>) => (
                        <Form className={styles.loginForm}>
                            <FormGroup>
                                <FormControl className={clsx(styles.formControl, 'mb8')}>
                                    <InputLabel id="passwordLabel" className={styles.formLabel}>
                                        Enter new password
                                    </InputLabel>
                                    <Input
                                        className={styles.formInput}
                                        name="newPassword"
                                        type="password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.newPassword}
                                    />
                                    <span className={clsx(styles.error_span, 'mt2')}>
                                        {props.errors.newPassword && props.touched.newPassword
                                            ? props.errors.newPassword
                                            : ''}
                                    </span>
                                </FormControl>
                                <Button
                                    className={styles.submitBtn}
                                    type="submit"
                                    variant={'outlined'}
                                    color={'inherit'}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty) || formSubmitted}
                                >
                                    Submit
                                </Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
                <Link className="mt-2" to={loginConstants.ROUTES.LOGIN}>
                    Login here
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
});

const mapDispatchToProps = {
    changePassword: loginActions.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
