import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { selectionEndpoints } from './selection.endpoints';
import {
    IAddRecipeForm,
    IPinnedSelectionRecipesResponse,
    IRecipeSelectionCategoryTagsForm,
    IRecipeSelectionCategoryTagsResponse,
    IRecipeSelectionDisplaysResponse,
    ISelection,
    ISelectionForm,
    ISelectionResponse,
    ISelectionsDisplaysResponse,
    IValueTagsForm,
    IValueTagsData,
    IRecipeSelectionProductsResponse,
    IRecipeSelectionProductsForm,
} from './selection.interfaces';
import { IRecipeResponse } from '../recipe/recipe.interfaces';
import { selectionConstants } from './selection.constants';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { headers } from '../../shared/helpers/headers';
import { genericHelpers } from '../../shared/helpers/generics';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getSelections = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<ISelectionResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_SELECTIONS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: genericHelpers.mapOrderBy(pagination.orderBy),
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getSelectionById = async (selectionId: number): Promise<ISelection | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_SELECTION_BY_ID.url + selectionId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addSelection = async (selectionData: ISelectionForm): Promise<ISelection | AxiosError> => {
    const selection = {
        name_en: selectionData.name_en,
        name_de: selectionData.name_de,
        seasonalStart: selectionData.seasonalStart,
        seasonalEnd: selectionData.seasonalEnd,
    };

    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.ADD_SELECTION.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: selection,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteSelection = async (selectionId: number): Promise<ISelection | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.DELETE_SELECTION.url + selectionId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateSelection = async (
    selectionData: ISelectionForm,
    selectionId: number,
): Promise<ISelection | AxiosError> => {
    const selection = {
        name_en: selectionData.name_en,
        name_de: selectionData.name_de,
        seasonalStart: selectionData.seasonalStart,
        seasonalEnd: selectionData.seasonalEnd,
    };

    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.UPDATE_SELECTION.url + selectionId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: selection,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getSelectionDisplays = async (selectionId: number): Promise<ISelectionsDisplaysResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_SELECTION_DISPLAYS.url + selectionId + selectionConstants.SELECTION_ROUTES.DISPLAYS,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getPinnedSelectionRecipes = async (selectionId: number): Promise<IPinnedSelectionRecipesResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.GET_SELECTION_RECIPES.url +
            selectionId +
            selectionConstants.SELECTION_ROUTES.PINNED_RECIPES,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeSelectionCategoryTags = async (
    recipeSelectionId: number,
): Promise<IRecipeSelectionCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_RECIPE_SELECTION_CATEGORY_TAGS.url + recipeSelectionId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addRecipeSelectionCategoryTags = async (
    recipeSelectionCategoryTags: IRecipeSelectionCategoryTagsForm,
): Promise<IRecipeSelectionCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.ADD_RECIPE_SELECTION_CATEGORY_TAGS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeSelectionCategoryTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipeSelectionCategoryTags = async (
    recipeSelectionCategoryTags: IRecipeSelectionCategoryTagsForm,
): Promise<IRecipeSelectionCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.UPDATE_RECIPE_SELECTION_CATEGORY_TAGS.url +
            recipeSelectionCategoryTags.recipe_selection_id,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: recipeSelectionCategoryTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateSelectionDisplays = async (
    selectionData: ISelectionForm,
): Promise<IRecipeSelectionDisplaysResponse | AxiosError> => {
    const selectionDisplays = {
        displays: selectionData.displays,
    };

    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.UPDATE_RECIPE_SELECTION_DISPLAYS.url +
            selectionData.id +
            selectionConstants.SELECTION_ROUTES.DISPLAYS,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: selectionDisplays,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addSelectionDisplays = async (
    selectionData: ISelectionForm,
): Promise<IRecipeSelectionDisplaysResponse | AxiosError> => {
    const selectionDisplays = {
        recipe_selection_id: selectionData.id,
        displays: selectionData.displays,
    };

    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.ADD_RECIPE_SELECTION_DISPLAYS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: selectionDisplays,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updatePinnedSelectionRecipes = async (
    selectionData: IAddRecipeForm,
    blackListedRecipeIds: number[],
): Promise<IPinnedSelectionRecipesResponse> => {
    const pinnedSelectionRecipes = {
        recipeIds: selectionData.pinnedRecipes.map((pinnedRecipe) => pinnedRecipe.recipe_id),
    };
    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.UPDATE_RECIPE_SELECTION_PINNED.url +
            selectionData.id +
            selectionConstants.SELECTION_ROUTES.PINNED,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: { ...pinnedSelectionRecipes, blackListedRecipeIds },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipesForSelection = async (
    pagination?: IPagination,
    searchText?: string,
    recipeSelectionId?: number,
): Promise<IRecipeResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.GET_RECIPE_BY_CATEGORY_TAGS.url +
            recipeSelectionId +
            selectionConstants.SELECTION_ROUTES.RECIPE,
        method: routeConstants.METHODS.GET,
        headers: headers.routeHeaders(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: pagination.orderBy,
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeSelectionProducts = async (recipeSelectionId: number): Promise<IRecipeSelectionProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_RECIPE_SELECTION_PRODUCTS.url + recipeSelectionId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);
    return response.data;
};

const addRecipeSelectionProducts = async (
    recipeSelectionProducts: IRecipeSelectionProductsForm,
): Promise<IRecipeSelectionProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.ADD_RECIPE_SELECTION_PRODUCTS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeSelectionProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipeSelectionProducts = async (
    recipeSelectionProducts: IRecipeSelectionProductsForm,
): Promise<IRecipeSelectionProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.UPDATE_RECIPE_SELECTION_PRODUCTS.url + recipeSelectionProducts.recipeSelectionId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: recipeSelectionProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addSelectionValueTag = async (valueTags: IValueTagsData): Promise<IValueTagsData | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.ADD_SELECTION_VALUE_TAG.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: valueTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateSelectionValueTag = async (valueTags: IValueTagsData): Promise<IValueTagsData | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.UPDATE_SELECTION_VALUE_TAG.url + valueTags.recipeSelectionId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: valueTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getSelectionValueTag = async (selectionId: number): Promise<IValueTagsForm | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_SELECTION_VALUE_TAG.url + selectionId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getRecipesByValueTag = async (selectionId: number): Promise<IRecipeResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url:
            selectionEndpoints.GET_RECIPES_BY_VALUE_TAG.pre_url +
            selectionId +
            selectionEndpoints.GET_RECIPES_BY_VALUE_TAG.post_id_url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipesByAllTags = async (selectionId: number): Promise<IRecipeResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: selectionEndpoints.GET_RECIPES_BY_ALL_TAGS.url + selectionId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const selectionService = {
    getSelections,
    getSelectionById,
    addSelection,
    deleteSelection,
    updateSelection,
    getSelectionDisplays,
    getPinnedSelectionRecipes,
    getRecipeSelectionCategoryTags,
    addRecipeSelectionCategoryTags,
    updateRecipeSelectionCategoryTags,
    updateSelectionDisplays,
    addSelectionDisplays,
    updatePinnedSelectionRecipes,
    getRecipesForSelection,
    addSelectionValueTag,
    updateSelectionValueTag,
    getSelectionValueTag,
    getRecipesByValueTag,
    getRecipeSelectionProducts,
    addRecipeSelectionProducts,
    updateRecipeSelectionProducts,
    getRecipesByAllTags,
};
