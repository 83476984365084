import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import ImageModal from '../../shared/components/generics/imageModal/ImageModal';
import { commonDatatableHelpers } from './common.datatable.options';

const troubleshootColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'internalName',
        label: 'Question',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'thumbnailEn',
        label: 'Thumbnail (EN)',
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string) {
                if (pictureUrl) {
                    return (
                        <ImageModal
                            baseStyle={{
                                height: '96px',
                                borderRadius: '8px',
                            }}
                            title={'Thumbnail (EN)'}
                            image={pictureUrl}
                            alt={'Thumbnail (EN)'}
                        />
                    );
                }
                return <></>;
            },
        },
    },
    {
        name: 'thumbnailDe',
        label: 'Thumbnail (DE)',
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string) {
                if (pictureUrl) {
                    return (
                        <ImageModal
                            baseStyle={{
                                height: '96px',
                                borderRadius: '8px',
                            }}
                            title={'Thumbnail (DE)'}
                            image={pictureUrl}
                            alt={'Thumbnail (DE)'}
                        />
                    );
                }
                return <></>;
            },
        },
    },
];

const troubleshootPrimaryStepsColumns: MUIDataTableColumn[] = [
    {
        name: 'titleEn',
        label: 'Title (EN)',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'titleDe',
        label: 'Title (DE)',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'thumbnailEn',
        label: 'Icon/Thumbnail (EN)',
        options: {
            filter: false,
            sort: false,
            customBodyRender: commonDatatableHelpers.imageModalRenderer,
        },
    },
    {
        name: 'thumbnailDe',
        label: 'Icon/Thumbnail (DE)',
        options: {
            filter: false,
            sort: false,
            customBodyRender: commonDatatableHelpers.imageModalRenderer,
        },
    },
];

const troubleshootStepsColumns: MUIDataTableColumn[] = [
    {
        name: 'defaultDescription',
        label: 'Default Description',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender: commonDatatableHelpers.localizationBodyRenderer('enEnTranslation', 100),
        },
    },
    {
        name: 'oldFirmwareIOS',
        label: 'Old Firmware iOS',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender: commonDatatableHelpers.localizationBodyRenderer('enEnTranslation', 100),
        },
    },
    {
        name: 'oldFirmwareAndroid',
        label: 'Old Firmware Android',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender: commonDatatableHelpers.localizationBodyRenderer('enEnTranslation', 100),
        },
    },
    {
        name: 'semanticFirmwareIOS',
        label: 'Semantic Firmware iOS',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender: commonDatatableHelpers.localizationBodyRenderer('enEnTranslation', 100),
        },
    },
    {
        name: 'semanticFirmwareAndroid',
        label: 'Semantic Firmware Android',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender: commonDatatableHelpers.localizationBodyRenderer('enEnTranslation', 100),
        },
    },
];

export const troubleshootTables = {
    troubleshootColumns,
    troubleshootPrimaryStepsColumns,
    troubleshootStepsColumns,
};
