export const ingredientsConstants = {
    INGREDIENTS_ACTION_TYPES: {
        GET_INGREDIENTS_REQUEST: 'GET_INGREDIENTS_REQUEST',
        GET_INGREDIENTS_SUCCESS: 'GET_INGREDIENTS_SUCCESS',
        GET_INGREDIENTS_FAILURE: 'GET_INGREDIENTS_FAILURE',
        GET_INGREDIENT_BY_ID_REQUEST: 'GET_INGREDIENT_BY_ID_REQUEST',
        GET_INGREDIENT_BY_ID_SUCCESS: 'GET_INGREDIENT_BY_ID_SUCCESS',
        GET_INGREDIENT_BY_ID_FAILURE: 'GET_INGREDIENT_BY_ID_FAILURE',
        ADD_INGREDIENT_REQUEST: 'ADD_INGREDIENT_REQUEST',
        ADD_INGREDIENT_SUCCESS: 'ADD_INGREDIENT_SUCCESS',
        ADD_INGREDIENT_FAILURE: 'ADD_INGREDIENT_FAILURE',
        UPDATE_INGREDIENT_REQUEST: 'UPDATE_INGREDIENT_REQUEST',
        UPDATE_INGREDIENT_SUCCESS: 'UPDATE_INGREDIENT_SUCCESS',
        UPDATE_INGREDIENT_FAILURE: 'UPDATE_INGREDIENT_FAILURE',
        DELETE_INGREDIENT: 'DELETE_INGREDIENT',
        UPDATE_INGREDIENT: 'UPDATE_INGREDIENT',
        DELETE_INGREDIENT_REQUEST: 'DELETE_INGREDIENT_REQUEST',
        DELETE_INGREDIENT_SUCCESS: 'DELETE_INGREDIENT_SUCCESS',
        DELETE_INGREDIENT_FAILURE: 'DELETE_INGREDIENT_FAILURE',
    },
    INGREDIENT_UNITS: {
        GRAM: 'g',
        MILLILITER: 'ml',
    },
    INGREDIENT_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
        IMPERIAL_UNIT: 4,
        METRIC_UNIT: 5,
        CONVERSION: 8,
    },
    INGREDIENT_LABELS: {
        INGREDIENT: 'Ingredient',
        INGREDIENTS: 'Ingredients',
        ADD_INGREDIENTS: 'Add ingredients',
    },
};
