import { IFilterActions, IFilterState } from './filter.interfaces';
import { filterConstants } from './filter.constants';

const initialState: IFilterState = {
    filters: [],
    filter: null,
    filterCategoryTags: [],
    total: 0,
    loading: false,
};

const filterReducer = (state = initialState, action: IFilterActions): IFilterState => {
    switch (action.type) {
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_REQUEST:
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_SUCCESS:
            return {
                ...state,
                filters: action.filterData.filters,
                total: action.filterData.total,
                loading: false,
            };
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTERS_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_FAILURE:
            return {
                loading: false,
            };
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_SUCCESS:
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_BY_ID_SUCCESS:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_SUCCESS:
            return {
                ...state,
                filter: action.filter,
                loading: false,
            };
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_FAILURE:
        case filterConstants.FILTER_ACTION_TYPES.DELETE_FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case filterConstants.FILTER_ACTION_TYPES.GET_FILTER_CATEGORY_TAGS_SUCCESS:
        case filterConstants.FILTER_ACTION_TYPES.ADD_FILTER_CATEGORY_TAGS_SUCCESS:
        case filterConstants.FILTER_ACTION_TYPES.UPDATE_FILTER_CATEGORY_TAGS_SUCCESS:
            return {
                ...state,
                filterCategoryTags: action.filterCategoryTagsData.filterCategoryTags,
                loading: false,
            };
        default:
            return state;
    }
};

export { filterReducer };
