import { IGlobalSearchPageItem } from '../../../global.search.interfaces';

const appPageItem: IGlobalSearchPageItem = {
    id: null,
    nameEn: '',
    nameDe: '',
    tagsEn: '',
    tagsDe: '',
    link: '',
    isGrillRelated: false,
    parentId: null,
    children: null,
};

export const formInitialValues = {
    appPageItem,
};
