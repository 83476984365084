import { FormControl, MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import CustomDatePicker from '../../../shared/components/generics/datePicker/CustomDatePicker';
import { useAppSelector } from '../../../shared/store';
import { FilterOptions } from '../analytics.interfaces';
import { SetFilterPayload } from '../analytics.slice';
import styles from '../analyticsOverview/index.module.scss';

type Props = {
    filterOptions: FilterOptions;
    setFilterOption: (filterPayload: SetFilterPayload) => void;
    label?: string;
};

export const AnalyticsFilterSection = memo(AnalyticsFilterSectionComponent);

function AnalyticsFilterSectionComponent(props: Props) {
    function renderFilterOption(filterKey: string, filterValue: FilterOptions[keyof FilterOptions]): any {
        switch (filterValue.type) {
            case 'SELECT':
                return (
                    <RenderSelectFilterOption
                        key={filterKey}
                        filterKey={filterKey}
                        value={filterValue}
                        setFilterOption={props.setFilterOption}
                    />
                );
            case 'STRING_SEARCH':
                return (
                    <RenderStringSearchFilterOption
                        key={filterKey}
                        filterKey={filterKey}
                        value={filterValue}
                        setFilterOption={props.setFilterOption}
                    />
                );
            case 'DATE':
                return (
                    <RenderDateFilterOption
                        key={filterKey}
                        filterKey={filterKey}
                        value={filterValue}
                        setFilterOption={props.setFilterOption}
                    />
                );
        }
    }

    if (!props.filterOptions || Object.keys(props.filterOptions).length === 0) {
        return null;
    }

    return (
        <>
            {props.label ? <h2>{props.label}</h2> : null}
            <div className={styles.filterOption}>
                {Object.entries(props.filterOptions).map(([filterKey, value], index) => (
                    <>{renderFilterOption(filterKey, value)}</>
                ))}
            </div>
        </>
    );
}

type FilterOptionProps<T extends FilterOptions[string]['type']> = {
    filterKey: string;
    setFilterOption: (filterPayload: SetFilterPayload) => void;
    value: FilterOptions[keyof FilterOptions] & {
        type: T;
    };
};

function RenderStringSearchFilterOption(props: FilterOptionProps<'STRING_SEARCH'>) {
    const initialGlobalFilters = useAppSelector((state) => state.analytics.globalSelectedFilters);

    const [selectedFilter, setSelectedFilter] = useState<string>(() => {
        const globalFilterOption = initialGlobalFilters[props.filterKey];
        if (globalFilterOption?.type === 'STRING_SEARCH') {
            return globalFilterOption?.searchString ?? '';
        }
        return '';
    });

    useEffect(() => {
        props.setFilterOption({
            filterKey: props.filterKey,
            filterValue:
                selectedFilter.length === 0
                    ? null
                    : {
                          type: 'STRING_SEARCH',
                          searchString: selectedFilter,
                      },
        });
    }, [selectedFilter]);

    return (
        <FormControl className={clsx(styles.formControl)} required>
            <TextField
                className={styles.formInput}
                label={props.value.name}
                name={props.filterKey}
                type="text"
                onChange={(event) => {
                    setSelectedFilter(event.target.value);
                }}
                value={selectedFilter}
                variant="outlined"
            />
        </FormControl>
    );
}

function RenderSelectFilterOption(props: FilterOptionProps<'SELECT'>) {
    const initialGlobalFilters = useAppSelector((state) => state.analytics.globalSelectedFilters);
    const [selectedFilters, setSelectedFilters] = useState<string[]>(() => {
        const globalFilterOption = initialGlobalFilters[props.filterKey];
        if (globalFilterOption?.type === 'SELECT') {
            return (globalFilterOption?.options as unknown as string[]) ?? [];
        }
        return [];
    });

    useEffect(() => {
        props.setFilterOption({
            filterKey: props.filterKey,
            filterValue:
                selectedFilters.length === 0
                    ? null
                    : {
                          type: 'SELECT',
                          options: selectedFilters,
                      },
        });
    }, [selectedFilters]);

    return (
        <FormControl className={clsx(styles.formControl)} required>
            <TextField
                className={styles.formInput}
                label={props.value.name}
                name={props.filterKey}
                type="text"
                required
                select
                SelectProps={{
                    multiple: true,
                }}
                onChange={(event) => {
                    setSelectedFilters(event.target.value as unknown as string[]);
                }}
                value={selectedFilters}
                variant="outlined"
            >
                {props.value.options.map((value) => (
                    <MenuItem key={`${value}`} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
}

function RenderDateFilterOption(props: FilterOptionProps<'DATE'>) {
    const initialGlobalFilters = useAppSelector((state) => state.analytics.globalSelectedFilters);

    const [startDate, setStartDate] = useState<Date | null>(() => {
        const globalFilterOption = initialGlobalFilters[props.filterKey];
        if (globalFilterOption?.type === 'DATE') {
            return globalFilterOption?.startDate ?? null;
        }
        return null;
    });
    const [endDate, setEndDate] = useState<Date | null>(() => {
        const globalFilterOption = initialGlobalFilters[props.filterKey];
        if (globalFilterOption?.type === 'DATE') {
            return globalFilterOption?.endDate ?? null;
        }
        return null;
    });

    useEffect(() => {
        props.setFilterOption({
            filterKey: props.filterKey,
            filterValue:
                !startDate && !endDate
                    ? null
                    : {
                          type: 'DATE',
                          startDate,
                          endDate,
                      },
        });
    }, [startDate, endDate]);

    return (
        <div className={styles.dateContainer}>
            <span>{props.value.name}</span>
            <div className={styles.date}>
                <FormControl className={clsx(styles.formControl)} required>
                    <CustomDatePicker
                        label={'Start Date'}
                        name={'startDate'}
                        value={startDate}
                        onChange={(newDate) => {
                            newDate?.setHours(0, 0, 0, 0);
                            setStartDate(newDate);
                        }}
                        placeholder="DD/MM/YY"
                        dateFormat="dd/MM/yy"
                    />
                </FormControl>
                <FormControl className={clsx(styles.formControl)} required>
                    <CustomDatePicker
                        label={'End Date'}
                        name={'endDate'}
                        value={endDate}
                        onChange={(newDate) => {
                            (newDate as Date)?.setHours(23, 59, 59, 999);
                            setEndDate(newDate);
                        }}
                        placeholder="DD/MM/YY"
                        dateFormat="dd/MM/yy"
                    />
                </FormControl>
            </div>
        </div>
    );
}
