export const categoryTagTypesEndpoints = {
    CATEGORY_TAG_TYPES_ROUTES: {
        CATEGORY_TAG_TYPES: '/category-tag-types',
    },
    GET_CATEGORY_TAG_TYPES: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tag-types',
    },
    ADD_CATEGORY_TAG_TYPE: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tag-types',
    },
    DELETE_CATEGORY_TAG_TYPE: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tag-types/',
    },
    UPDATE_CATEGORY_TAG_TYPE: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tag-types/',
    },
};
