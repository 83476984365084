import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import styles from './index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import { IPromotionCampaignProps } from '../../promotion.campaign.interfaces';
import { promotionCampaignDataTables } from '../../../helpers/promotion.campaign.datatables';
import moment from 'moment';
import { promotionCampaignActions } from '../../promotion.campaign.actions';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { promotionCampaignEndpoints } from '../../promotion.campaign.endpoints';

const PromotionCampaign = (
    props: PropsWithChildren<IPromotionCampaignProps>,
): ReactElement<IPromotionCampaignProps> => {
    const { promotionCampaigns, total, loading, getPromotionCampaigns, deletePromotionCampaign } = props;
    const tableColumns = [...promotionCampaignDataTables.promotionCampaignColumns];

    tableColumns.splice(2, 0, {
        name: 'active',
        label: en.campaign_status,
        options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
                return moment(new Date()).utc().diff(promotionCampaigns[tableMeta.rowIndex].startDate) >= 0 &&
                    moment(new Date()).utc().diff(promotionCampaigns[tableMeta.rowIndex].endDate) < 0
                    ? 'Active'
                    : 'Off';
            },
        },
    });

    tableColumns.splice(8, 0, {
        name: 'runningDate',
        label: en.campaign_running_date,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                const row = promotionCampaigns[tableMeta.rowIndex];
                return <>{`${moment(row.startDate).utc().calendar()} - ${moment(row.endDate).utc().calendar()}`}</>;
            },
        },
    });

    tableColumns.push({
        name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                return (
                    <Button
                        component={Link}
                        to={`${promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.EDIT_PROMOTION_CAMPAIGN}/${
                            promotionCampaigns[tableMeta.rowIndex].id
                        }`}
                        className={styles.editCampaign}
                    >
                        {en.edit_button_label}
                    </Button>
                );
            },
        },
    });

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const promotionCampaignsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const promotionCampaignsToRemoveLength = promotionCampaignsToRemoveIndex.length;

            promotionCampaignsToRemoveIndex.map(async (promotionCampaignIndex, index) => {
                const promotionCampaignId = promotionCampaigns[promotionCampaignIndex].id;
                const completed = promotionCampaignsToRemoveLength === index + 1;
                await deletePromotionCampaign(promotionCampaignId, completed);
                completed && (await _refreshPromotionCampaigns(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    useEffect(() => {
        getPromotionCampaigns(globalConstants.DEFAULT_PAGINATION);
    }, []);

    const _refreshPromotionCampaigns = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await getPromotionCampaigns(pagination, searchText, filters);
    };

    return (
        <div className={clsx(styles.campaigns, 'mt16')}>
            <Button
                className={clsx(styles.addCampaign, 'mb4')}
                component={Link}
                to={promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.ADD_PROMOTION_CAMPAIGN}
            >
                {en.add_new_campaign}
            </Button>
            <DynamicTable
                title={en.campaign_label}
                options={options}
                columns={tableColumns}
                data={promotionCampaigns}
                total={total}
                loading={loading}
                refreshData={_refreshPromotionCampaigns}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    promotionCampaigns: state.promotionCampaigns.promotionCampaigns,
    total: state.promotionCampaigns.total,
    loading: state.promotionCampaigns.loading,
});

const mapDispatchToProps = {
    getPromotionCampaigns: promotionCampaignActions.getPromotionCampaigns,
    deletePromotionCampaign: promotionCampaignActions.deletePromotionCampaign,
};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(PromotionCampaign);
