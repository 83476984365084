export interface IReferralOrderData {
    id: number;
    email: string;
    shopifyCustomerId: string;
    referralCode: string;
    referralCodeUser: {
        id: number;
        name: string;
    };
    orderCreatedAt: Date;
    shopifyPaymentId: string;
    orderStatus: ReferralOrderStatus;
    albersVoucherReceived: boolean;
}

export enum ReferralOrderStatus {
    FULFILLED = 'FULFILLED',
    CANCELLED = 'CANCELLED',
    RETURNED = 'RETURNED',
}
export interface IReferralOrderResponse {
    referralOrders: IReferralOrderData[];
    total: number;
}

export interface IReferralOrderState {
    referralOrders?: IReferralOrderData[];
    referralOrder?: IReferralOrderData;
    total?: number;
    loading: boolean;
    unusedVouchersCount?: number;
}

export interface IReferralOrderActions {
    type: string;
    referralOrders?: IReferralOrderResponse;
    referralOrder?: IReferralOrderData;
    unusedVouchersCount?: number;
    error?: string;
}

export interface IUploadReport {
    successfulInsertions: number;
    skippedVoucherPairs: {
        voucherCodeReferrer: string;
        voucherCodeReferee: string;
    }[];
}
