import * as Yup from 'yup';
import formModels from './blacklist.form.model';
import { ObjectSchema } from 'yup';
import { IBlacklist } from '../../../../reviews.interfaces';

const {
    formField: { wordEn, wordDe },
} = formModels.blacklistFormModel;

const blacklistValidationSchema: ObjectSchema<IBlacklist> = Yup.object()
    .defined()
    .shape({
        wordEn: Yup.string().required(`${wordEn.requiredErrorMsg}`).defined(),
        wordDe: Yup.string().required(`${wordDe.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    blacklistValidationSchema: blacklistValidationSchema,
};

export default validationSchemas;
