export function authHeader() {
    // return authorization header with jwt token
    const token = localStorage.getItem('token');
    if (token) {
        return {
            Authorization: token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    } else {
        return {};
    }
}
