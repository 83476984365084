import { importConstants } from './import.constants';
import { modifyFiles } from '../helpers/import.helpers';
import { IImportActions, IImportState } from './import.interfaces';

const initialState: IImportState = {
    fileProgress: {
        id: 0,
        status: 0,
        file: null,
        progress: 0,
    },
};

const importReducer = (state: IImportState = initialState, action: IImportActions): IImportState => {
    switch (action.type) {
        case importConstants.IMPORT_RECIPES_REQUEST:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    ...modifyFiles(state.fileProgress, action.file),
                },
            };
        case importConstants.IMPORT_RECIPES_SUCCESS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.fileId]: {
                        ...state.fileProgress[action.fileId],
                        status: 1,
                    },
                },
            };
        case importConstants.IMPORT_RECIPES_FAILURE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.fileId]: {
                        ...state.fileProgress[action.fileId],
                        status: 0,
                        progress: 0,
                    },
                },
            };
        default:
            return state;
    }
};

export { importReducer };
