import { tutorialConstants } from './tutorial.constants';

export const tutorialEndpoints = {
    TUTORIALS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    GET_TUTORIALS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS,
    },
    GET_TUTORIAL_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    ADD_TUTORIAL: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS,
    },
    DELETE_TUTORIAL: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    UPDATE_TUTORIAL: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    GET_TUTORIAL_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    ADD_TUTORIAL_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIAL_STEPS,
    },
    UPDATE_TUTORIAL_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIAL_STEPS,
    },
    DELETE_TUTORIAL_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIAL_STEPS,
    },
    GET_TUTORIAL_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
    ADD_TUTORIAL_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + '/tutorial-products/add',
    },
    UPDATE_TUTORIAL_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS_WITH_PARAMS,
    },
};
