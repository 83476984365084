import { recipeConstants } from './recipe.constants';
import { IRecipeState, RecipeActions } from './recipe.interfaces';

const initialState: IRecipeState = {
    recipes: [],
    recipeById: null,
    recipeCategoryTags: [],
    recipeIngredients: [],
    recipeProducts: [],
    recipeEquipments: [],
    total: 0,
    loading: false,
};

const recipeReducer = (state = initialState, action: RecipeActions): IRecipeState => {
    switch (action.type) {
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_REQUEST:
            return {
                ...state,
                recipeById: null,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_SUCCESS:
            return {
                ...state,
                recipes: action.recipeData.recipes,
                total: action.recipeData.total,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_FAILURE:
            return {
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_SUCCESS:
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_SUCCESS:
            return {
                ...state,
                recipeById: action.recipeById,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_SUCCESS:
            return {
                ...state,
                recipeById: action.recipeById,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_FAILURE:
        case recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_FAILURE:
        case recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_SUCCESS:
        case recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_SUCCESS:
            return {
                ...state,
                recipeCategoryTags: state.recipeCategoryTags.concat(action.recipeCategoryTagsData.recipeCategoryTags),
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                recipeEquipments: state.recipeEquipments.concat(action.recipeEquipmentsData.recipeEquipments),
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_REQUEST:
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_REQUEST:
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_PRODUCTS_REQUEST:
        case recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_REQUEST:
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                recipeIngredients: action.recipeIngredientsData.recipeIngredients,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_FAILURE:
        case recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_SUCCESS:
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_PRODUCTS_SUCCESS:
            return {
                ...state,
                recipeProducts: action.recipeProductsData.recipeProducts,
                loading: false,
            };
        default:
            return state;
    }
};

export { recipeReducer };
