export const referralOrderEndpoints = {
    GET_REFERRAL_ORDERS: {
        url: process.env.REACT_APP_SERVER_URL + '/referral-orders',
    },
    PATCH_REFERRAL_ORDER: {
        url: (id: number): string => process.env.REACT_APP_SERVER_URL + `/referral-orders/order-status/${id}`,
    },
    POST_VOUCHERS_FILE: {
        url: process.env.REACT_APP_SERVER_URL + '/albers-vouchers-file',
    },
    GET_UNUSED_VOUCHERS_COUNT: {
        url: process.env.REACT_APP_SERVER_URL + '/albers-vouchers/unused-count',
    },
};
