import { genericHelpers } from './generics';
import { convertToRaw, EditorState } from 'draft-js';

const parseRichText = (text: string): string => {
    const parsedData = JSON.parse(text);
    return parsedData.blocks[0].text;
};

const checkEditorState = (value: string): boolean => {
    if (genericHelpers.validString(value)) {
        const parsedValue = parseRichText(value);
        return parsedValue.length > 0;
    }
    return false;
};

const getEmptyEditorState = (): string => {
    return JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
};

export const richTextEditorHelpers = {
    parseRichText,
    checkEditorState,
    getEmptyEditorState,
};
