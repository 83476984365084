import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import {
    IFilter,
    IFilterCategoryTagsForm,
    IFilterCategoryTagsResponse,
    IFilterForm,
    IFilterResponse,
} from './filter.interfaces';
import { filterEndpoints } from './filter.endpoints';
import { IFilters, IPagination, ISort } from '../../shared/components/interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getFilters = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IFilterResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.GET_FILTERS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: pagination.orderBy,
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addFilter = async (filterData: IFilterForm): Promise<IFilter> => {
    const formData = new FormData();

    for (const key in filterData) {
        if (!filterData.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, filterData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.ADD_FILTER.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const deleteFilter = async (filterId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.DELETE_FILTER.url + filterId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateFilter = async (filterData: IFilterForm, filterId: number): Promise<IFilter> => {
    const formData = new FormData();

    for (const key in filterData) {
        if (!filterData.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, filterData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.UPDATE_FILTER.url + filterId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getFilterById = async (filterId: number): Promise<IFilter | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.GET_FILTER_BY_ID.url + filterId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getFilterCategoryTags = async (filterId: number): Promise<IFilterCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.GET_FILTER_CATEGORY_TAGS.url + filterId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addFilterCategoryTags = async (
    filterCategoryTags: IFilterCategoryTagsForm,
): Promise<IFilterCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.ADD_FILTER_CATEGORY_TAGS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: filterCategoryTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateFilterCategoryTags = async (
    filterCategoryTags: IFilterCategoryTagsForm,
): Promise<IFilterCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: filterEndpoints.UPDATE_FILTER_CATEGORY_TAGS.url + filterCategoryTags.filter_id,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: filterCategoryTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const filterService = {
    getFilters,
    addFilter,
    deleteFilter,
    updateFilter,
    getFilterById,
    getFilterCategoryTags,
    addFilterCategoryTags,
    updateFilterCategoryTags,
};
