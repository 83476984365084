import axios, { AxiosRequestConfig } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { referralOrderEndpoints } from './referral.order.endpoints';
import {
    IReferralOrderData,
    IReferralOrderResponse,
    IUploadReport,
    ReferralOrderStatus,
} from './referral.order.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

async function getReferralOrders(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IReferralOrderResponse> {
    const requestOptions: AxiosRequestConfig = {
        url: referralOrderEndpoints.GET_REFERRAL_ORDERS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
}

async function updateReferralOrderStatus(
    referralOrderId: number,
    orderStatus: ReferralOrderStatus,
): Promise<IReferralOrderData> {
    const requestOptions: AxiosRequestConfig = {
        url: referralOrderEndpoints.PATCH_REFERRAL_ORDER.url(referralOrderId),
        method: routeConstants.METHODS.PATCH,
        headers: authHeader(),
        data: {
            orderStatus,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
}

const convertToFormData = (entity: Record<string, unknown>) => {
    const formData = new FormData();

    for (const key in entity) {
        if (!entity.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, entity[key] as string | Blob);
    }
    return formData;
};

const uploadAlbersVouchersFile = async (voucherFile: File): Promise<IUploadReport> => {
    const requestOptions: AxiosRequestConfig = {
        url: referralOrderEndpoints.POST_VOUCHERS_FILE.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: convertToFormData({
            vouchers: voucherFile,
        }),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

async function getUnusedVouchersCount(): Promise<number> {
    const requestOptions: AxiosRequestConfig = {
        url: referralOrderEndpoints.GET_UNUSED_VOUCHERS_COUNT.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
}

export const referralOrderService = {
    getReferralOrders,
    updateReferralOrderStatus,
    uploadAlbersVouchersFile,
    getUnusedVouchersCount,
};
