import { globalConstants } from '../constants/global.constants';
import { GlobalActions } from './interfaces';

export const globalActions = {
    fetchStateFromStorage,
};

function fetchStateFromStorage(): GlobalActions {
    const token: string = localStorage.getItem('token');
    const refreshToken: string = localStorage.getItem('refreshToken');
    const user: string = localStorage.getItem('user');

    if (token && user) {
        return {
            type: globalConstants.FETCH_STATE_STORAGE_SUCCESS,
            token: token,
            refreshToken: refreshToken,
            user: JSON.parse(user),
        };
    } else {
        return { type: globalConstants.FETCH_STATE_STORAGE_ERROR };
    }
}
