export const promotionCampaignEndpoints = {
    PROMOTION_CAMPAIGN_ROUTES: {
        PROMOTION_CAMPAIGN: '/remote-settings/promotion-campaign',
        ADD_PROMOTION_CAMPAIGN: '/remote-settings/promotion-campaign/add',
        EDIT_PROMOTION_CAMPAIGN: '/remote-settings/promotion-campaign',
    },
    GET_PROMOTION_CAMPAIGNS: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign',
    },
    GET_SEGMENT_AUDIENCES: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/segment-audiences',
    },
    GET_PROMOTION_CAMPAIGN: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign/',
    },
    ADD_PROMOTION_CAMPAIGN: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign',
    },
    DELETE_PROMOTION_CAMPAIGN: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign/',
    },
    UPDATE_PROMOTION_CAMPAIGN: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign/',
    },
    GET_AVAILABLE_COUPON_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/promotion-campaign-coupon-products',
    },
    TEST_PROMOTION_CAMPAIGN_COUPON: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/validate-promotion-campaign-coupon',
    },
};
