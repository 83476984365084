import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { corsHeader } from '../../shared/helpers/corsHeader';
import {
    ITutorialData,
    ITutorialForm,
    ITutorialResponse,
    ITutorialStepsResponse,
    ITutorialStepForm,
    ITutorialProductsForm,
    ITutorialProductsResponse,
} from './tutorial.interfaces';
import { tutorialEndpoints } from './tutorial.endpoints';
import { tutorialConstants } from './tutorial.constants';
import { productConstants } from '../upselling/upselling.constants';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import store from '../../../app/shared/store';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';

type Progres = {
    loaded: number;
    total: number;
};

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getTutorials = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<ITutorialResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.GET_TUTORIALS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: camelToSnakeCase(pagination.orderBy),
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addTutorial = async (tutorialData: ITutorialForm): Promise<ITutorialResponse> => {
    const formData = new FormData();

    for (const key in tutorialData) {
        if (!tutorialData.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, tutorialData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.ADD_TUTORIAL.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteTutorial = async (tutorialId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.DELETE_TUTORIAL.url + tutorialId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateTutorial = async (tutorialData: ITutorialForm, tutorialId: number): Promise<ITutorialResponse> => {
    const formData = new FormData();

    for (const key in tutorialData) {
        if (!tutorialData.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, tutorialData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.UPDATE_TUTORIAL.url + tutorialId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getTutorialById = async (tutorialId: number): Promise<ITutorialData | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.GET_TUTORIAL_BY_ID.url + tutorialId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getTutorialSteps = async (tutorialId: number): Promise<ITutorialStepsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.GET_TUTORIAL_STEPS.url + tutorialId + tutorialConstants.TUTORIALS_ROUTES.STEPS,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addTutorialStep = async (tutorialSteps: ITutorialStepForm): Promise<AxiosResponse> => {
    const formData = new FormData();

    for (const key in tutorialSteps) {
        if (!tutorialSteps.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, tutorialSteps[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.TUTORIALS.url + tutorialSteps.tutorial_id + tutorialConstants.TUTORIALS_ROUTES.STEPS,
        method: routeConstants.METHODS.POST,
        headers: { ...authHeader(), ...corsHeader },
        data: formData,
        onUploadProgress: (progress: Progres): void => {
            const { loaded, total } = progress;

            const percentageProgress = Math.floor((loaded / total) * 100);

            store.dispatch({
                type: tutorialConstants.TUTORIAL_ACTION_TYPES.IMPORT_FILE_TUTORIAL_STEP,
                progressUpload: percentageProgress,
            });
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateTutorialSteps = async (tutorialSteps: ITutorialStepForm): Promise<AxiosResponse> => {
    const formData = new FormData();

    for (const key in tutorialSteps) {
        if (!tutorialSteps.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, tutorialSteps[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.UPDATE_TUTORIAL_STEPS.url + tutorialSteps.id,
        method: routeConstants.METHODS.PUT,
        headers: { ...authHeader(), ...corsHeader },
        data: formData,
        onUploadProgress: (progress: Progres): void => {
            const { loaded, total } = progress;

            const percentageProgress = Math.floor((loaded / total) * 100);

            store.dispatch({
                type: tutorialConstants.TUTORIAL_ACTION_TYPES.IMPORT_FILE_TUTORIAL_STEP,
                progressUpload: percentageProgress,
            });
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteTutorialStep = async (tutorialStepId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.DELETE_TUTORIAL_STEPS.url + tutorialStepId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getTutorialProducts = async (tutorialId: number): Promise<ITutorialProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.GET_TUTORIAL_PRODUCTS.url + tutorialId + productConstants.PRODUCT_ROUTES.PRODUCTS,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addTutorialProducts = async (tutorialProducts: ITutorialProductsForm): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: tutorialEndpoints.ADD_TUTORIAL_PRODUCTS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: tutorialProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateTutorialProducts = async (tutorialProducts: ITutorialProductsForm): Promise<ITutorialProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url:
            tutorialEndpoints.UPDATE_TUTORIAL_PRODUCTS.url +
            tutorialProducts.tutorialId +
            productConstants.PRODUCT_ROUTES.PRODUCTS,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: tutorialProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const tutorialService = {
    getTutorials,
    addTutorial,
    deleteTutorial,
    updateTutorial,
    getTutorialById,
    getTutorialSteps,
    addTutorialStep,
    updateTutorialSteps,
    deleteTutorialStep,
    getTutorialProducts,
    addTutorialProducts,
    updateTutorialProducts,
};
