import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { tutorialActions } from '../../../tutorial.actions';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import { upsellingActions } from '../../../../upselling/upselling.actions';
import { IProduct } from '../../../../upselling/upselling.interfaces';
import { withRouter } from 'react-router-dom';
import { ITutorialProducts, ITutorialProductsForm, ITutorialProductsFormComponent } from '../../../tutorial.interfaces';
import { tutorialConstants } from '../../../tutorial.constants';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { styleConstants } from '../../../../../shared/constants/style.constants';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';

type Props = ITutorialProductsFormComponent & ReturnType<typeof mapStateToProps>;

const AddProducts = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [tutorialProducts, setTutorialProducts] = useState<Array<ITutorialProducts>>([]);
    const [changedProducts, setChangedProducts] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const { tutorialData, history, productsTotal } = props;
    const id = useIdParam();

    useEffect(() => {
        const isMounted = true;
        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            productsTotal,
            products.length + tutorialProducts.length,
            page,
        );
        const isFirstMount = products.length + tutorialProducts.length === 0;

        (hasPagination || isFirstMount) &&
            props.getProducts({ ...globalConstants.PAGINATION_NO_LIMIT, page: nextPage }).then((response) => {
                const productsResponse = response.products.map((item) => {
                    return {
                        ...item,
                        nameEn: item.titleEn,
                        nameDe: item.titleDe,
                    };
                });
                if (isMounted) {
                    props.getTutorialProducts(id ? id : tutorialData.id).then((response) => {
                        const tutorialProductsResponse: Array<ITutorialProducts> = response.tutorialProducts;

                        if (Array.isArray(tutorialProductsResponse)) {
                            const newtutorialProductsResponse = tutorialProductsResponse.filter(
                                (tutorialProductResponse) =>
                                    !tutorialProducts.find((product) => {
                                        return product.id === tutorialProductResponse.id;
                                    }),
                            );
                            const updatedTutorialProducts = tutorialProducts.concat(newtutorialProductsResponse);

                            setIsUpdating(true);
                            setTutorialProducts(updatedTutorialProducts);

                            const productsToDisplay = productsResponse.filter(
                                (product) =>
                                    !tutorialProductsResponse.find((tutorialProduct) => {
                                        return tutorialProduct.id === product.id;
                                    }),
                            );
                            const updatedProducts = products.concat(productsToDisplay);

                            setProducts(updatedProducts.map((p) => ({ ...p, randomProduct: false })));
                            hasPagination && setPage(nextPage);
                        } else {
                            const updatedProducts = products.concat(productsResponse);
                            setProducts(updatedProducts.map((p) => ({ ...p, randomProduct: false })));
                            hasPagination && setPage(nextPage);
                        }
                    });
                }
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedProducts) : handleInsert(changedProducts);
    };

    const handleUpdate = (productIds: Array<number>): void => {
        const productsToSave: ITutorialProductsForm = { tutorialId: tutorialData.id, productIds };

        props.updateTutorialProducts(productsToSave, tutorialData.id).then(() => {
            history.push(tutorialConstants.TUTORIALS_ROUTES.TUTORIALS);
        });
    };

    const handleInsert = (productIds: Array<number>): void => {
        const productsToSave: ITutorialProductsForm = {
            tutorialId: tutorialData.id,
            productIds,
        };

        props.addTutorialProducts(productsToSave).then(() => {
            history.push(tutorialConstants.TUTORIALS_ROUTES.TUTORIALS);
        });
    };

    return (
        <div className={styles.addTutorialForm}>
            {products.length || tutorialProducts.length ? (
                <DragAndDrop
                    destinationColumnName={tutorialConstants.DRAG_AND_DROP.PRODUCTS_DESTINATION}
                    sourceColumnName={tutorialConstants.DRAG_AND_DROP.PRODUCTS_SOURCE}
                    items={tutorialProducts}
                    availableItems={products}
                    handleItemsChange={setChangedProducts}
                    endScroll={[setEndScroll, endScroll]}
                    getItemBackgroundColor={(item) =>
                        item['randomProduct'] ? styleConstants.COLORS.LIGHT_GREY : styleConstants.COLORS.WHITE
                    }
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handleSubmit}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_FINISH}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.prevStep(props.tutorialData)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    productsTotal: state.products.total,
});

const mapDispatchToProps = {
    getProducts: upsellingActions.getProducts,
    addTutorialProducts: tutorialActions.addTutorialProducts,
    getTutorialProducts: tutorialActions.getTutorialProducts,
    updateTutorialProducts: tutorialActions.updateRecipeProducts,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddProducts);
