import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { importEndpoints } from './import.endpoints';
import { headers } from '../../shared/helpers/headers';
import { importConstants } from './import.constants';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const importRecipes = async (file: File): Promise<AxiosResponse> => {
    const formPayload = new FormData();
    formPayload.append(importConstants.FILE_KEYS.RECIPE_FILE, file);

    const requestOptions: AxiosRequestConfig = {
        url: importEndpoints.IMPORT_RECIPES.url,
        method: routeConstants.METHODS.POST,
        headers: headers.formDataHeaders(),
        data: formPayload,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const importService = {
    importRecipes,
};
