import { CategoryTagTypeState, ICategoryTagTypeActions } from './category.tag.types.interfaces';
import { categoryTagTypeConstants } from './category.tag.types.constants';

const initialState: CategoryTagTypeState = { categoryTagTypes: [], categoryTagType: null, loading: false };

const categoryTagTypeReducer = (state = initialState, action: ICategoryTagTypeActions): CategoryTagTypeState => {
    switch (action.type) {
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.GET_CATEGORY_TAG_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.GET_CATEGORY_TAG_TYPES_SUCCESS:
            return {
                ...state,
                categoryTagTypes: action.categoryTagTypeData.categoryTagTypes,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.GET_CATEGORY_TAG_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_SUCCESS:
            return {
                ...state,
                categoryTagType: action.categoryTagType,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.UPDATE_CATEGORY_TAG_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.UPDATE_CATEGORY_TAG_TYPE_SUCCESS:
            return {
                ...state,
                categoryTagType: action.categoryTagType,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.UPDATE_CATEGORY_TAG_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { categoryTagTypeReducer };
