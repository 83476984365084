import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { tokenConstants } from '../constants/token.constants';
import { headers } from '../helpers/headers';
import { routeConstants } from '../routes/route.constants';
import { SHARED_ENDPOINTS } from './endpoints';
import { actionHelpers } from '../helpers/actionHelpers';

export const tokenService = {
    refreshToken,
    injectStore,
};

let store;

function injectStore(theStore) {
    store = theStore;
}

function refreshToken(refreshToken: string): Promise<AxiosResponse> {
    const requestOptions: AxiosRequestConfig = {
        url: SHARED_ENDPOINTS.REFRESH_TOKEN.url,
        method: routeConstants.METHODS.POST,
        headers: headers.routeHeaders(),
        data: JSON.stringify({
            refreshToken: refreshToken,
        }),
    };

    return axios(requestOptions).then((response) => {
        actionHelpers.setTokenData(response);
        store?.dispatch({ type: tokenConstants.TOKEN_REFRESHED, tokenInfo: response.data });

        return response;
    });
}
