import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import generalStyles from '../../../../../shared/styles/global.module.scss';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormGroup,
    Grid,
    TextField,
    Typography,
    InputAdornment,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { Fraction, fraction } from 'mathjs';

import { ingredientsAction } from '../../../../ingredients/ingredients.actions';
import { IIngredientUnit, IngredientData } from '../../../../ingredients/ingredients.interfaces';
import { IRecipeIngredients, IRecipeIngredientsForm, IRecipeIngredientsProps } from '../../../recipe.interfaces';
import { recipeAction } from '../../../recipe.actions';
import { Form, Formik, FormikProps } from 'formik';
import { IRowsDeleted } from '../../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../../shared/constants/mui.datatables.constants';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { ingredientsConstants } from '../../../../ingredients/ingredients.constants';
import { recipeDataTable } from '../../../../helpers/recipe.datatables';
import formInitialValues from '../FormModel/FormInitialValues';
import validationSchemas from '../FormModel/ValidationSchema';
import recipeFormModel from '../FormModel/RecipeFormModel';
import en from '../../../../../../assets/language/en.json';
import { recipeHelpers } from '../../../../helpers/recipe.helpers';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { ingredientMeasurementUnitsRegexps } from '../../../recipe.input.assistants';
import DragDropTable from '../../../../../shared/components/generics/dragDropTable/DragDropTable';

type Props = IRecipeIngredientsProps & typeof recipeFormModel.ingredientFormModel;

const AddIngredients = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [ingredients, setIngredients] = useState<Array<IngredientData>>([]);
    const [ingredientsToSave, setIngredientsToSave] = useState<Array<IRecipeIngredients>>([]);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [ingredientUnits, setIngredientUnits] = useState<Array<IIngredientUnit>>([]);
    const [ingredientToEdit, setIngredientToEdit] = useState<IRecipeIngredientsForm>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(open);
    const [searchText, setSearchText] = useState<string>('');
    const [isConversionOn, setConversionOn] = useState<boolean>(true);
    const [hasImperialError, setHasImperialError] = useState<boolean>(false);
    const [imperialHelperText, setImperialHelperText] = useState<string>('');
    const [imperialQuantityString, setImperialQuantityString] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const {
        ingredientFormField: { recipeIngredient, metricQuantity, imperialQuantity, isMeasurementUnitsConversionOn },
        recipe,
    } = props;

    const formValues = { recipeId: recipe.id, ...formInitialValues.ingredientsInitialValues };

    const tableColumns = [
        ...recipeDataTable.recipeIngredientsColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={generalStyles.formButton}
                            onClick={async () => await handleEditAction(tableMeta)}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const handleEditAction = async (tableMeta: MUIDataTableMeta): Promise<void> => {
        const index = tableMeta.rowIndex;

        setIsUpdating(true);

        const recipeIngredient = ingredientsToSave[index];
        const ingredient = await props.getIngredientById(recipeIngredient.ingredientId);

        setConversionOn(recipeIngredient.isMeasurementUnitsConversionOn);
        const imperialFractionString = imperialFloatToFractionString(recipeIngredient.imperialQuantity);
        setImperialQuantityString(imperialFractionString);
        setIngredientToEdit({
            id: recipeIngredient.id,
            ingredient,
            recipeId: recipe.id,
            metricQuantity: recipeIngredient.metricQuantity,
            imperialQuantity: recipeIngredient.imperialQuantity,
            isMeasurementUnitsConversionOn: recipeIngredient.isMeasurementUnitsConversionOn,
        });

        setDialogOpen(true);
    };

    useEffect(() => {
        props.getRecipeIngredients(recipe.id).then((response) => {
            setIngredientsToSave(
                response.recipeIngredients.map((ingredient, index) => ({
                    ...ingredient,
                    orderNumber: index,
                })),
            );
        });
    }, []);

    useEffect(() => {
        if (!genericHelpers.isNullOrUndefined(ingredientToEdit)) {
            props.getIngredients(globalConstants.DEFAULT_PAGINATION, searchText).then((response) => {
                const recipeIngredients = response.ingredients;

                setIngredientUnits(recipeHelpers.handleIngredientUnitDropdown(recipeIngredients, ingredientToEdit));
                setIngredients(response.ingredients);
            });
        }
    }, [ingredientToEdit]);

    useEffect(() => {
        let isActive = true;

        (async () => {
            props.getIngredients(globalConstants.INGREDIENT_AUTOCOMPLETE_PAGINATION, searchText).then((response) => {
                const ingredientsResponse: Array<IngredientData> = response.ingredients;
                setIngredients(recipeHelpers.filterRecipeIngredientsToDisplay(ingredientsResponse, ingredientsToSave));
            });
            await genericHelpers.sleep(1e3);

            if (isActive) {
                setLoading(false);
            }
        })();

        return () => {
            isActive = false;
        };
    }, [loading, searchText]);

    useEffect(() => {
        if (!dialogOpen) {
            setIsUpdating(false);
            setIngredientToEdit(null);
        }
    }, [dialogOpen]);

    const options = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.STANDARD,
        pagination: false,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const ingredientsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);

            setIngredientsToSave(
                ingredientsToSave
                    .filter((ingredients, index) => !ingredientsToRemoveIndex.includes(index))
                    .map((ingredient, index) => ({
                        ...ingredient,
                        orderNumber: index,
                    })),
            );
            setIsChanged(true);
        },
    };

    const handleFormSubmit = async (values: IRecipeIngredientsForm): Promise<void> => {
        setIsUpdating(false);
        setImperialQuantityString('');
        setConversionOn(true);
        setDialogOpen(false);
        setIsChanged(true);
        setIngredientsToSave((prev) => {
            if (ingredientToEdit) {
                const idx = prev.findIndex((ingredient) => ingredient.ingredientId === values.ingredient.id);
                const newIngredients = [...prev];
                newIngredients[idx] = {
                    id: values.id,
                    ingredientId: values.ingredient.id,
                    metricQuantity: values.metricQuantity,
                    imperialQuantity: values.imperialQuantity,
                    metricUnitId: values.ingredient.metricUnitId,
                    imperialUnitId: values.ingredient.imperialUnitId,
                    metricUnit: values.ingredient.metricUnit,
                    imperialUnit: values.ingredient.imperialUnit,
                    isMeasurementUnitsConversionOn: values.isMeasurementUnitsConversionOn,
                    orderNumber: prev[idx].orderNumber,
                    name_en: values.ingredient.nameEn,
                };
                return newIngredients;
            }
            return [
                ...prev,
                {
                    id: values.id,
                    ingredientId: values.ingredient.id,
                    metricQuantity: values.metricQuantity,
                    imperialQuantity: values.imperialQuantity,
                    metricUnitId: values.ingredient.metricUnitId,
                    imperialUnitId: values.ingredient.imperialUnitId,
                    metricUnit: values.ingredient.metricUnit,
                    imperialUnit: values.ingredient.imperialUnit,
                    isMeasurementUnitsConversionOn: values.isMeasurementUnitsConversionOn,
                    orderNumber: prev.length,
                    name_en: values.ingredient.nameEn,
                },
            ];
        });
    };
    // isUpdating ? await handleUpdate(values) : await handleInsert(values);

    const handleUpdate = async (): Promise<void> => {
        setLoading(true);
        await props.updateRecipeIngredients(recipe.id, ingredientsToSave);
        setLoading(false);
    };

    const handleUnitsChange = (value: IngredientData): void => {
        if (genericHelpers.isNullOrUndefined(value)) return;

        ingredients.map((ingredient) => {
            if (ingredient.id === value.id) {
                const imperialUnit = {
                    id: ingredient.imperialUnitId,
                    name: ingredient.imperialUnit,
                };

                const metricUnit = {
                    id: ingredient.metricUnitId,
                    name: ingredient.metricUnit,
                };
                setIngredientUnits([imperialUnit, metricUnit]);
            }
        });
    };

    const handleAutoCompleteChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (genericHelpers.isNullOrUndefined(event)) {
            return;
        }

        const inputValue = event.target.value;
        if (inputValue !== searchText) {
            setSearchText(inputValue);
            setLoading(true);
        }
    };

    const onConversionStateChange = (
        event: ChangeEvent<HTMLInputElement>,
        formikProps: FormikProps<IRecipeIngredientsForm>,
    ): void => {
        const value = event.target.checked;
        formikProps.setFieldValue(isMeasurementUnitsConversionOn.name, value);
        setConversionOn(value);
    };

    const handleImperialInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        formikProps: FormikProps<IRecipeIngredientsForm>,
    ): void => {
        const value = event.target.value.replaceAll(
            ingredientMeasurementUnitsRegexps.INGREDIENT_IMPERIAL_QUANTITY_ILLEGAL_CHARS_REGEXP,
            '',
        );
        setImperialQuantityString(value);
        const isFormatValid = ingredientMeasurementUnitsRegexps.INGREDIENT_IMPERIAL_QUANTITY_FORMAT_REGEXP.test(value);
        if (isFormatValid) {
            setHasImperialError(false);
            setImperialHelperText('');
            const [numerator, denominator] = value.split('/');
            const imperialQuantityNum =
                denominator != undefined ? parseFloat(numerator) / parseFloat(denominator) : parseFloat(numerator);
            const metricQuantityValue = Math.round(imperialQuantityNum / formikProps.values.ingredient.conversion);
            formikProps.setFieldValue(imperialQuantity.name, imperialQuantityNum);
            if (isConversionOn) {
                formikProps.setFieldValue(metricQuantity.name, metricQuantityValue);
            }
        } else {
            setHasImperialError(true);
            setImperialHelperText(en.not_the_required_format);
        }
    };

    const handleMetricInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        formikProps: FormikProps<IRecipeIngredientsForm>,
    ): void => {
        const metricValue = parseFloat(restrictMetricInput(event.target.value));
        const conversionRate = formikProps.values.ingredient.conversion;
        formikProps.setFieldValue(metricQuantity.name, metricValue);
        if (isConversionOn) {
            const imperialFloatValue = metricValue * conversionRate;
            formikProps.setFieldValue(imperialQuantity.name, imperialFloatValue);
            const fractionString = imperialFloatToFractionString(imperialFloatValue);
            setImperialQuantityString(fractionString);
        }
    };

    const restrictMetricInput = (value: string): string => (parseInt(value) < 1 ? '1' : value);
    const imperialFloatToFractionString = (value: number) => {
        const { n, d } = fraction((value || 0).toFixed(3)) as Fraction;
        return n ? `${n} / ${d}` : '';
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items: IRecipeIngredients[] = genericHelpers
            .reorder(ingredientsToSave, result.source.index, result.destination.index)
            .map((ingredient, index) => ({
                ...ingredient,
                orderNumber: index,
            }));

        setIsUpdating(true);
        setIsChanged(true);
        setIngredientsToSave(items.sort((a, b) => a.orderNumber - b.orderNumber));
    };

    return (
        <>
            <div className={clsx(styles.addIngredients, 'mt8')}>
                {dialogOpen ? renderAddEditDialog() : null}

                <div className={styles.bottomNavigation}>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'ml2', 'mb4')}
                        style={{ float: 'left' }}
                        onClick={() => setDialogOpen(true)}
                        variant={'outlined'}
                    >
                        {en.add_new_ingredient}
                    </Button>
                </div>

                <Grid container>
                    <Grid item xs={12}>
                        <DragDropTable
                            title={ingredientsConstants.INGREDIENT_LABELS.INGREDIENTS}
                            data={ingredientsToSave}
                            columns={tableColumns}
                            options={options}
                            onDragEnd={onDragEnd}
                        />
                    </Grid>
                </Grid>

                <div className={styles.bottomNavigation}>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={() => props.nextStep(recipe)}
                        variant={'outlined'}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                    </Button>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={async () => {
                            await handleUpdate();
                            props.nextStep(recipe);
                        }}
                        variant={'outlined'}
                        disabled={!isChanged}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                    </Button>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={() => props.prevStep(recipe)}
                        variant={'outlined'}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                    </Button>
                </div>
            </div>
        </>
    );

    function renderAddEditDialog() {
        return (
            <>
                <div className={styles.blurBackground} onClick={() => setDialogOpen(false)} />
                <Card variant={'outlined'} className={styles.addIngredientDialog}>
                    <CardContent>
                        <Typography variant={'h5'}>{ingredientsConstants.INGREDIENT_LABELS.ADD_INGREDIENTS}</Typography>
                        <Formik
                            initialValues={ingredientToEdit ? ingredientToEdit : formValues}
                            onSubmit={async (values: IRecipeIngredientsForm, { resetForm, setSubmitting }) => {
                                await handleFormSubmit({
                                    ...values,
                                    metricUnitId: ingredientUnits[1].id,
                                    imperialUnitId: ingredientUnits[0].id,
                                });
                                setSubmitting(false);
                                setIngredientToEdit(null);
                                resetForm();
                            }}
                            validationSchema={validationSchemas.ingredientSchema}
                            enableReinitialize={true}
                        >
                            {(props: FormikProps<IRecipeIngredientsForm>) => {
                                return (
                                    <Form className={styles.addIngredientForm}>
                                        <FormGroup className={clsx(styles.inputGroup, 'ml4')}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <FormControl className={clsx(styles.formControl, 'p4', 'mb8')}>
                                                        <Autocomplete
                                                            open={open}
                                                            onOpen={() => {
                                                                props.setFieldValue(metricQuantity.name, null);
                                                                props.setFieldValue(imperialQuantity.name, null);
                                                                setImperialQuantityString('');
                                                                setOpen(true);
                                                            }}
                                                            onClose={() => {
                                                                setOpen(false);
                                                            }}
                                                            getOptionLabel={(option: IngredientData) =>
                                                                option.metricUnit
                                                                    ? option.nameDe +
                                                                      ` (${option.nameEn}) - ${option.metricUnit}/${option.imperialUnit}`
                                                                    : option.nameDe + ` (${option.nameEn})`
                                                            }
                                                            getOptionSelected={(option, value) =>
                                                                option.nameDe === value.nameDe
                                                            }
                                                            options={ingredients}
                                                            onChange={(_, value: IngredientData) => {
                                                                props.setFieldValue(recipeIngredient.name, value);
                                                                handleUnitsChange(value);
                                                            }}
                                                            onInputChange={handleAutoCompleteChange}
                                                            value={props.values.ingredient}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={recipeIngredient.label}
                                                                    name={recipeIngredient.name}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {loading ? (
                                                                                    <CircularProgress
                                                                                        color="inherit"
                                                                                        size={20}
                                                                                    />
                                                                                ) : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl className={clsx(styles.formControl, 'p4', 'mb8')}>
                                                        <TextField
                                                            label={metricQuantity.label}
                                                            name={metricQuantity.name}
                                                            type="number"
                                                            onChange={(event) => {
                                                                handleMetricInputChange(event, props);
                                                            }}
                                                            value={props.values.metricQuantity || ''}
                                                            variant={'outlined'}
                                                            disabled={!props.values.ingredient?.metricUnitId}
                                                            required={Boolean(props.values.ingredient?.metricUnitId)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        /{' '}
                                                                        {ingredientUnits.length > 0
                                                                            ? ingredientUnits[1].name
                                                                            : en.metric_unit_label}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl className={clsx(styles.formControl, 'p4', 'mb8')}>
                                                        <TextField
                                                            label={imperialQuantity.label}
                                                            name={imperialQuantity.name}
                                                            disabled={!props.values.ingredient?.imperialUnitId}
                                                            type="text"
                                                            onChange={(event) => {
                                                                handleImperialInputChange(event, props);
                                                            }}
                                                            error={hasImperialError}
                                                            helperText={imperialHelperText}
                                                            onBlur={props.handleBlur}
                                                            value={imperialQuantityString}
                                                            variant={'outlined'}
                                                            required={Boolean(props.values.ingredient?.imperialUnitId)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        /{' '}
                                                                        {ingredientUnits.length > 0
                                                                            ? ingredientUnits[0].name
                                                                            : en.imperial_unit_label}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} justify={'flex-end'} spacing={2}>
                                                <FormControlLabel
                                                    className={clsx('mb8')}
                                                    control={
                                                        <Checkbox
                                                            name={isMeasurementUnitsConversionOn.name}
                                                            onChange={(event) => onConversionStateChange(event, props)}
                                                            checked={isConversionOn}
                                                        />
                                                    }
                                                    label={isMeasurementUnitsConversionOn.label}
                                                />

                                                <FormControl className={clsx('mb8')}>
                                                    <Button
                                                        type="submit"
                                                        className={generalStyles.formButton}
                                                        variant={'outlined'}
                                                        disabled={
                                                            props.isSubmitting || hasImperialError || !props.isValid
                                                        }
                                                    >
                                                        {ingredientToEdit
                                                            ? muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION
                                                            : globalConstants.NAVIGATION_BUTTONS_LABELS.ADD}
                                                    </Button>
                                                </FormControl>
                                            </Grid>
                                        </FormGroup>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardContent>
                </Card>
            </>
        );
    }
};

const mapDispatchToProps = {
    getIngredients: ingredientsAction.getIngredients,
    getRecipeIngredients: recipeAction.getRecipeIngredients,
    updateRecipeIngredients: recipeAction.updateRecipeIngredients,
    getIngredientById: ingredientsAction.getIngredientById,
};

export default compose(connect(null, mapDispatchToProps))(AddIngredients);
