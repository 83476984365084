import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import { richTextEditorHelpers } from '../../shared/helpers/rich.text.editor.helpers';
import { commonDatatableHelpers, commonOptions } from './common.datatable.options';

const ambassadorColumns = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'blogger_name',
        label: en.blogger_name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'blog_name_en',
        label: en.blog_name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'blog_name_de',
        label: de.blog_name,
        options: {
            filter: true,
            sort: true,
        },
    },
    ...commonOptions.objectTimestamps,
];

const influencerFeedColumns: MUIDataTableColumn[] = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'content',
        label: 'Content',
        options: {
            filter: false,
            sort: false,
            customBodyRender: commonDatatableHelpers.imageModalRenderer,
        },
    },
    {
        name: 'titleDe',
        label: 'Title DE',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'titleEn',
        label: 'Title EN',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'publiclyVisible',
        label: 'Publicly Visible',
        options: {
            filter: false,
            sort: false,
            customBodyRender: commonDatatableHelpers.booleanRenderer,
        },
    },
    ...commonOptions.objectTimestamps,
];

export const ambassadorDataTables = {
    ambassadorColumns,
    influencerFeedColumns,
};
