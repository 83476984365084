import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const formModel = {
    formId: uniqueId('form-'),
    formField: {
        nameEn: {
            name: 'name_en',
            label: en.name,
            requiredErrorMsg: en.title_error_msg,
        },
        nameDe: {
            name: 'name_de',
            label: de.name,
            requiredErrorMsg: de.title_error_msg,
        },
    },
};

const formModels = {
    cookingMethodFormModel: formModel,
};

export default formModels;
