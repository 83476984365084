import { Dispatch } from 'redux';
import { importService } from './import.service';
import { AxiosError, AxiosResponse } from 'axios';
import { importConstants } from './import.constants';
import { alertActions } from '../../shared/actions/alert.actions';
import { IImportActions } from './import.interfaces';
import en from '../../../assets/language/en.json';

function importRecipes(file: File): (dispatch: Dispatch) => Promise<AxiosResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return importService.importRecipes(file).then(
            (response: AxiosResponse) => {
                dispatch(alertActions.success(en.recipes_imported_successfully));
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IImportActions {
        return { type: importConstants.IMPORT_RECIPES_REQUEST };
    }

    function success(response: AxiosResponse): IImportActions {
        return { type: importConstants.IMPORT_RECIPES_SUCCESS, importData: response.data };
    }

    function failure(error: string): IImportActions {
        return { type: importConstants.IMPORT_RECIPES_FAILURE, error };
    }
}

export const importActions = {
    importRecipes,
};
