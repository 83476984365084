import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import en from '../../../assets/language/en.json';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { productConstants } from './upselling.constants';
import {
    IProduct,
    IProductActions,
    IProductCategoriesResponse,
    IProductForm,
    IProductResponse,
} from './upselling.interfaces';
import { upsellingService } from './upselling.service';

function getProducts(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IProductResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.getProducts(pagination, searchText, filters).then(
            (response: IProductResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_REQUEST };
    }

    function success(response: IProductResponse): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_SUCCESS,
            productsData: response,
        };
    }

    function failure(error: string): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_FAILURE, error };
    }
}

const addProduct = (product: IProductForm): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.addProduct(product).then(
            (response) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.product_added_successfully));
                if (response.data?.error) {
                    dispatch(alertActions.warn(response.data.error));
                }
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_REQUEST };
    }

    function success(response: AxiosResponse): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_SUCCESS,
            product: response.data,
        };
    }

    function failure(error: string): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_FAILURE, error };
    }
};

const updateProduct = (
    product: IProductForm,
    productId: number,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.updateProduct(product, productId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.product_updated_successfully));
                if (response.data?.error) {
                    dispatch(alertActions.warn(response.data.error));
                }
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_REQUEST };
    }

    function success(response: AxiosResponse): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_SUCCESS,
            product: response.data,
        };
    }

    function failure(error: string): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_FAILURE, error };
    }
};

const deleteProduct = (
    productId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.deleteProduct(productId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.product_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_REQUEST };
    }

    function success(response: AxiosResponse): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_SUCCESS,
        };
    }

    function failure(error: string): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_FAILURE,
            error,
        };
    }
};

function getProductById(productId: number): (dispatch: Dispatch) => Promise<IProduct | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.getProductById(productId).then(
            (response: IProduct) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_REQUEST };
    }

    function success(response: IProduct): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_SUCCESS,
            product: response,
        };
    }

    function failure(error: string): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_FAILURE, error };
    }
}

function getProductCategories(): (dispatch: Dispatch) => Promise<IProductCategoriesResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return upsellingService.getProductCategories().then(
            (response: IProductCategoriesResponse) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_REQUEST };
    }

    function success(): IProductActions {
        return {
            type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_SUCCESS,
        };
    }

    function failure(error: string): IProductActions {
        return { type: productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_FAILURE, error };
    }
}

export const upsellingActions = {
    getProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    getProductById,
    getProductCategories,
};
