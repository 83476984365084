import React from 'react';
import { FiberManualRecord } from '@material-ui/icons';
import moment from 'moment';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { ReactComponent as UserPlaceholder } from '../../../assets/icons/user_placeholder.svg';
import en from '../../../assets/language/en.json';
import RoundedImage from '../../shared/components/generics/roundedImage/RoundedImage';

const userReportRatingColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'profilePicture',
        label: en.profile_picture,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string, meta: MUIDataTableMeta) {
                return (
                    <div
                        style={{
                            minWidth: '128px',
                        }}
                    >
                        <RoundedImage
                            pictureUrl={pictureUrl}
                            imageSize="80px"
                            fallback={<UserPlaceholder width={'80px'} height={'80px'} />}
                        />
                    </div>
                );
            },
        },
    },
    {
        name: 'name',
        label: en.name,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'reason',
        label: 'The reason for the reporting',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'dateOfReport',
        label: 'Date of report',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

const reportedUserColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'profilePicture',
        label: en.profile_picture,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string, meta: MUIDataTableMeta) {
                return (
                    <div
                        style={{
                            minWidth: '128px',
                        }}
                    >
                        <RoundedImage
                            pictureUrl={pictureUrl}
                            imageSize="80px"
                            fallback={<UserPlaceholder width={'80px'} height={'80px'} />}
                        />
                    </div>
                );
            },
        },
    },
    {
        name: 'fullName',
        label: en.name,
        options: {
            filter: false,
            sort: false,
        },
    },
];

const reportsForUserColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'read',
        label: en.new,
        options: {
            sort: false,
            filter: false,
            customBodyRender(read: boolean, meta: MUIDataTableMeta) {
                return read ? null : <FiberManualRecord style={{ fill: '#b82b2b', height: '12px' }} />;
            },
        },
    },
    {
        name: 'profilePicture',
        label: en.profile_picture,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string, meta: MUIDataTableMeta) {
                return (
                    <div
                        style={{
                            minWidth: '128px',
                        }}
                    >
                        <RoundedImage
                            pictureUrl={pictureUrl}
                            imageSize="80px"
                            fallback={<UserPlaceholder width={'80px'} height={'80px'} />}
                        />
                    </div>
                );
            },
        },
    },
    {
        name: 'name',
        label: en.name,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'reason',
        label: 'The reason for the reporting',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'dateOfReport',
        label: 'Date of report',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

export const userReportDataTables = {
    userReportRatingColumns,
    reportedUserColumns,
    reportsForUserColumns,
};
