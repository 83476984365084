import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import { commonOptions } from './common.datatable.options';

const ingredientColumns = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            filter: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'nameDe',
        label: de.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'imperialUnitId',
        label: en.imperial_id_label,
        options: {
            display: false,
            filter: false,
            sort: false,
        },
    },
    {
        name: 'metricUnitId',
        label: en.metric_id_label,
        options: {
            display: false,
            filter: false,
            sort: false,
        },
    },
    {
        name: 'imperialUnit',
        label: en.imperial_label,
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: 'metricUnit',
        label: en.metric_label,
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: 'conversion',
        label: en.conversion_label,
        options: {
            filter: true,
            sort: false,
        },
    },
    ...commonOptions.objectTimestamps,
];

export const ingredientDataTables = {
    ingredientColumns,
};
