import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import formModels from './formModel/form.model';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import {
    FormControl,
    FormGroup,
    FormHelperText,
    Input,
    InputLabel,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import styles from './index.module.scss';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import {
    IAddOrEditCookingMethod,
    ICookingMethodForm,
    ICookingMethodsFormComponent,
} from '../../cooking.methods.interfaces';
import { cookingMethodActions } from '../../cooking.method.actions';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { cookingMethodsEndpoints } from '../../../../modules/cookingMethods/cooking.methods.endpoints';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type CookingMethodProps = ICookingMethodsFormComponent & typeof formModels.cookingMethodFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditCookingMethod) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditCookingMethod = (props: PropsWithChildren<CookingMethodProps>): JSX.Element => {
    const {
        formField: { nameEn, nameDe },
        cookingMethodData,
        history,
    } = props;

    const id = useIdParam();

    const [open, setOpen] = useState<boolean>(!!(id && id == cookingMethodData?.id));

    const formInitials = cookingMethodData ? cookingMethodData : formInitialValues.cookingMethod;

    const handleClickOpen = () => {
        setOpen(true);
        cookingMethodData &&
            history.push(`${cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD}/${cookingMethodData.id}`);
    };

    const handleClose = () => {
        setOpen(false);
        history.push(`${cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD}`);
    };

    const handleSubmit = (values: ICookingMethodForm): void => {
        cookingMethodData ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: ICookingMethodForm): void => {
        props.addCookingMethod(values).then(() => {
            props.refreshData(globalConstants.DEFAULT_PAGINATION);
            handleClose();
        });
    };

    const handleUpdate = (values: ICookingMethodForm): void => {
        const cookingMethodToUpdate: ICookingMethodForm = {
            name_en: values.name_en,
            name_de: values.name_de,
        };
        props.updateCookingMethod(cookingMethodToUpdate, values.id).then(() => {
            props.refreshData(globalConstants.DEFAULT_PAGINATION);
            handleClose();
        });
    };

    return (
        <div className={styles.cookingMethod}>
            {cookingMethodData ? (
                <Button className={styles.editCookingMethod} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addCookingMethod, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_cooking_method}
                </Button>
            )}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {cookingMethodData ? en.edit_cooking_method : en.add_new_cooking_method}
                </DialogTitle>
                <Formik
                    initialValues={formInitials}
                    onSubmit={(values: ICookingMethodForm, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.cookingMethodValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<ICookingMethodForm>) => (
                        <Form className={styles.addCookingMethodForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameEn.name}
                                            label={nameEn.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_en}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_en && props.touched.name_en ? props.errors.name_en : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameDe.name}
                                            label={nameDe.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_de}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_de && props.touched.name_de ? props.errors.name_de : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveCookingMethod, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    addCookingMethod: cookingMethodActions.addCookingMethod,
    updateCookingMethod: cookingMethodActions.updateCookingMethod,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditCookingMethod);
