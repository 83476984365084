import { selectionConstants } from './selection.constants';
import { ISelectionState, ISelectionAction } from './selection.interfaces';

const initialState: ISelectionState = {
    recipeSelections: [],
    selection: null,
    total: 0,
    loading: false,
};

const selectionReducer = (state = initialState, action: ISelectionAction): ISelectionState => {
    switch (action.type) {
        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_REQUEST:
        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_REQUEST:
        case selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_REQUEST:
        case selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_REQUEST:
        case selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_SUCCESS:
            return {
                ...state,
                recipeSelections: action.selectionsData.recipeSelections,
                total: action.selectionsData.total,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_FAILURE:
            return {
                ...state,
                recipeSelections: [],
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_SUCCESS:
            return {
                ...state,
                selection: action.selection,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_FAILURE:
        case selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_FAILURE:
            return {
                ...state,
                selection: null,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_SUCCESS:
            return {
                ...state,
                selection: action.selection,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_SUCCESS:
            return {
                ...state,
                selection: action.selection,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_FAILURE:
        case selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_SUCCESS:
            return {
                ...state,
                selection: action.selection,
                loading: false,
            };
        default:
            return state;
    }
};

export { selectionReducer };
