import { createSlice } from '@reduxjs/toolkit';
import { IInfluencerState as IInfluencerState } from './influencer.interfaces';
import { influencerThunks } from './influencer.thunks';

const initialState: IInfluencerState = {
    influencers: [],
    influencer: null,
    total: 0,
    influencersLoading: false,
    influencerLoading: false,
};

export const influencerSlice = createSlice({
    name: 'influencers',
    initialState,
    reducers: {
        // Normal reducers
    },
    extraReducers: (builder) => {
        const { addInfluencer, deleteInfluencer, fetchInfluencers, getInfluencerById, updateInfluencer } =
            influencerThunks;
        builder.addCase(fetchInfluencers.pending, (state) => {
            state.influencersLoading = true;
        });
        builder.addCase(fetchInfluencers.fulfilled, (state, action) => {
            state.influencersLoading = false;
            state.influencers = action.payload.ambassadors;
            state.total = action.payload.total;
        });
        builder.addCase(fetchInfluencers.rejected, (state) => {
            state.influencersLoading = false;
        });

        builder.addCase(addInfluencer.pending, (state) => {
            state.influencerLoading = true;
        });
        builder.addCase(addInfluencer.fulfilled, (state) => {
            state.influencerLoading = false;
        });
        builder.addCase(addInfluencer.rejected, (state) => {
            state.influencerLoading = false;
        });

        builder.addCase(updateInfluencer.pending, (state) => {
            state.influencerLoading = true;
        });
        builder.addCase(updateInfluencer.fulfilled, (state) => {
            state.influencerLoading = false;
        });
        builder.addCase(updateInfluencer.rejected, (state) => {
            state.influencerLoading = false;
        });

        builder.addCase(deleteInfluencer.pending, (state) => {
            state.influencerLoading = true;
        });
        builder.addCase(deleteInfluencer.fulfilled, (state) => {
            state.influencerLoading = false;
        });
        builder.addCase(deleteInfluencer.rejected, (state) => {
            state.influencerLoading = false;
        });

        builder.addCase(getInfluencerById.pending, (state) => {
            state.influencerLoading = true;
        });
        builder.addCase(getInfluencerById.fulfilled, (state, action) => {
            state.influencerLoading = false;
            state.influencer = action.payload;
        });
        builder.addCase(getInfluencerById.rejected, (state) => {
            state.influencerLoading = false;
        });
    },
});
