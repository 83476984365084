import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import { commonOptions } from './common.datatable.options';

const categoryTagColumns = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'tagTypeId',
        label: en.tag_type_id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'nameDe',
        label: de.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'tagType',
        label: en.tag_type_label,
        options: {
            filter: false,
            sort: false,
        },
    },
    ...commonOptions.objectTimestamps,
];

export const categoryTagDataTables = {
    categoryTagColumns,
};
