import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { muiStyles } from '../../../shared/styles/mui.styles';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { IReviewReportListProps, IUserReportReview, IUserReportReviewResponse } from '../user.report.interfaces';
import { userReportActions } from '../user.report.actions';
import { userReportDataTables } from '../../helpers/user.report.datatables';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { muiDataTablesConstants } from '../../../shared/constants/mui.datatables.constants';
import { Button, Paper } from '@material-ui/core';
import { reviewActions } from '../../reviews/reviews.actions';
import { reviewConstants } from '../../reviews/reviews.constants';

type OwnProps = IReviewReportListProps;

const ReviewReportList = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history } = props;
    const { reviewId } = useParams() as any;
    const [reports, setReports] = useState<IUserReportReview[]>([]);
    const [reportedComment, setReportedComment] = useState<IUserReportReviewResponse['reportedRating']>(null);

    useEffect(() => {
        props.getReviewReports(reviewId).then((response) => {
            setReports(response.data.reports);
            setReportedComment(response.data.reportedRating);
        });
    }, []);

    const columns = userReportDataTables.userReportRatingColumns;

    const options: MUIDataTableOptions = {
        selectableRows: 'none',
        pagination: false,
        filterType: muiDataTablesConstants.FILTER_TYPE.MULTISELECT,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        serverSide: true,
    };

    return (
        <div className={clsx(styles.reports, 'mt16')}>
            <Button
                className={clsx(styles.blockButton, 'mb4')}
                onClick={async () => {
                    if (!reportedComment) return;

                    props.deleteReview(reportedComment.id).then(() => {
                        history.push(`${reviewConstants.REVIEW_ROUTES.REVIEWS}`);
                    });
                }}
            >
                DELETE REVIEW
            </Button>
            <Paper>
                <MUIDataTable title={'Report'} options={options} columns={columns} data={reports} />
            </Paper>
        </div>
    );
};

const mapDispatchToProps = {
    getReviewReports: userReportActions.getReviewReports,
    deleteReview: reviewActions.deleteReview,
};

export default compose(withStyles(muiStyles.customStyles), connect(null, mapDispatchToProps))(ReviewReportList);
