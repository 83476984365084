import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import LanguageFlag from '../languageFlag/LanguageFlag';
import styles from './index.module.scss';

interface OwnProps {
    en: {
        value: string;
        label: string;
        alert: string;
        onSave: (data: string) => void;
    };
    de: {
        value: string;
        label: string;
        alert: string;
        onSave: (data: string) => void;
    };
    displayTitle?: boolean;
}

export type BilingualTextEditorRef = {
    saveText: () => void;
};

const BilingualTextEditor = forwardRef<BilingualTextEditorRef, OwnProps>((props: OwnProps, ref) => {
    const editorControls = [
        'title',
        'italic',
        'highlight',
        'undo',
        'redo',
        'link',
        'media',
        'numberList',
        'bulletList',
        'change-language',
    ];

    const [currentlySelected, setCurrentlySelected] = useState<'en' | 'de'>('en');
    const textEditorRef = useRef<TMUIRichTextEditorRef>();

    useImperativeHandle(ref, () => ({
        saveText() {
            textEditorRef.current.save();
        },
    }));

    return (
        <div className={clsx(styles.editorContainer)}>
            {props.displayTitle ? <span className={styles.headerLabel}>{props[currentlySelected].label}</span> : null}
            <MUIRichTextEditor
                classes={{
                    root: styles.textEditor,
                    container: styles.editor,
                    editorContainer: styles.editor,
                    editor: styles.editor,
                    toolbar: styles.toolbar,
                }}
                label={props[currentlySelected].label}
                onSave={(data) => {
                    props[currentlySelected].onSave(data);
                    // alertActions.info(`${currentlySelected}: ${props[currentlySelected].alert}`);
                }}
                ref={textEditorRef}
                inlineToolbar={true}
                defaultValue={props[currentlySelected].value}
                controls={editorControls}
                customControls={[
                    {
                        name: 'change-language',
                        type: 'callback',
                        icon: (
                            <TextField
                                select
                                value={currentlySelected}
                                onChange={(e) => {
                                    textEditorRef.current.save();
                                    setCurrentlySelected(e.target.value as any);
                                }}
                                variant={'outlined'}
                                SelectProps={{
                                    multiple: false,
                                }}
                            >
                                <MenuItem value="en">
                                    <span className={styles.languageWrapper}>
                                        <span className="mx-2">English</span>
                                        <LanguageFlag country="us" />
                                    </span>
                                </MenuItem>
                                <MenuItem value="de">
                                    <span className={styles.languageWrapper}>
                                        <span className="mx-2">Deutsch</span>
                                        <LanguageFlag country="de" />
                                    </span>
                                </MenuItem>
                            </TextField>
                        ),
                    },
                ]}
            />
        </div>
    );
});

BilingualTextEditor.displayName = 'BilingualTextEditor';

export default BilingualTextEditor;

const MyCard = (props: any) => {
    return <div>Argus</div>;
};
