import React from 'react';
import { CookingStepTypes as CookingStepType, ICookingStepsForm } from '../../../../recipe.interfaces';
import styles from './index.module.scss';
import AddDashboardCookingStep from './AddDashboardCookingStep';
import AddNormalCookingStep from './AddNormalCookingStep';
import { ReactComponent as FlameIcon } from '../../../../../../../assets/icons/fire_icon.svg';
import { ReactComponent as DashboardRelevantIcon } from '../../../../../../../assets/icons/relevant_icon.svg';
import { ReactComponent as LinkedRecipeIcon } from '../../../../../../../assets/icons/linked_icon.svg';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

type DialogProps = {
    toggleOpen: () => void;
    handleNewCookingStep: (values: ICookingStepsForm) => void;
    selectedStepType: CookingStepType;
    setSelectedStepType: (type: CookingStepType) => unknown;
    cookingStepToEdit?: ICookingStepsForm;
    recipeId: number;
};
export const StepDialog = (props: DialogProps) => {
    const backToSelectStepType = () => {
        if (props.cookingStepToEdit != null) props.toggleOpen();
        props.setSelectedStepType(null);
    };

    const handleNewCookingStep = (values: ICookingStepsForm) => {
        props.handleNewCookingStep(values);
        props.toggleOpen();
    };

    const renderCorrectDialog = () => {
        switch (props.selectedStepType) {
            case CookingStepType.NORMAL:
                return (
                    <AddNormalCookingStep
                        handleNewCookingStep={handleNewCookingStep}
                        cookingStepToEdit={props.cookingStepToEdit}
                        backToSelectStepType={backToSelectStepType}
                    />
                );
            case CookingStepType.DASHBOARD_RELEVANT:
                return (
                    <AddDashboardCookingStep
                        handleNewCookingStep={handleNewCookingStep}
                        cookingStepToEdit={props.cookingStepToEdit}
                        backToSelectStepType={backToSelectStepType}
                    />
                );
            default:
                return <SelectStepTypeContent />;
        }
    };

    return (
        <>
            <div className={styles.blurBackground} />
            <div className={styles.stepDialog}>
                <IconButton className={styles.closeButton} onClick={props.toggleOpen}>
                    <Close style={{ color: 'black' }} />
                </IconButton>
                {renderCorrectDialog()}
            </div>
        </>
    );

    function SelectStepTypeContent() {
        return (
            <>
                <div className={styles.header}>CHOOSE OPTION</div>
                <div className={styles.selectStepType}>
                    <div
                        className={styles.stepTypeCard}
                        onClick={() => props.setSelectedStepType(CookingStepType.NORMAL)}
                    >
                        <FlameIcon className={clsx(styles.icon)} />
                        <span>Normal</span>
                    </div>
                    <div
                        className={styles.stepTypeCard}
                        onClick={() => props.setSelectedStepType(CookingStepType.DASHBOARD_RELEVANT)}
                    >
                        <DashboardRelevantIcon className={clsx(styles.icon)} />
                        <span>Relevant for Dashboard</span>
                    </div>
                </div>
            </>
        );
    }
};
