import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { actionNamesEndpoints } from './action.names.endpoints';
import { authHeader } from '../../shared/helpers/authHeader';
import { IActionNameForm, IActionNamesResponse } from './action.names.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getActionNames = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IActionNamesResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: actionNamesEndpoints.GET_ACTION_NAMES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: camelToSnakeCase(paging.orderBy),
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addActionName = async (actionNameData: IActionNameForm): Promise<IActionNamesResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: actionNamesEndpoints.ADD_ACTION_NAME.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: actionNameData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteActionName = async (actionNameId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: actionNamesEndpoints.DELETE_ACTION_NAME.url + actionNameId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateActionName = async (
    actionNameData: IActionNameForm,
    actionNameId: number,
): Promise<IActionNamesResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: actionNamesEndpoints.UPDATE_ACTION_NAME.url + actionNameId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: actionNameData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const actionNamesService = {
    getActionNames,
    addActionName,
    deleteActionName,
    updateActionName,
};
