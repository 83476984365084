import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { ISelectionForm, IPinnedSelectionRecipe, IAddRecipeForm, IValueTagsForm } from '../../../selection.interfaces';

const {
    formField: { name_en, name_de, displays },
} = formModels.selectionFormModel;

const selectionValidationSchema: ObjectSchema<ISelectionForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(name_en.requiredErrorMsg),
        name_de: Yup.string().required(name_de.requiredErrorMsg),
        seasonalStart: Yup.date().optional().nullable(),
        seasonalEnd: Yup.date().optional().nullable(),
        displays: Yup.array()
            .of(Yup.string())
            .required(displays.requiredErrorMsg)
            .test(
                'NOT_DISPLAYED_is_standalone',
                "'NOT_DISPLAYED' cannot go with other displays",
                (displays) => !(displays.includes('NOT_DISPLAYED') && displays.length > 1),
            ),
    });

const valueTagsValidationSchema: ObjectSchema<IValueTagsForm> = Yup.object()
    .defined()
    .shape({
        preperationTime: Yup.number().optional().min(0, 'Number must be a positive integer'),
        cookingMethodId: Yup.number().optional(),
        restingTime: Yup.number().optional().min(0, 'Number must be a positive integer'),
        ambassadorId: Yup.number().optional(),
        totalTime: Yup.number().optional().min(0),
        grillModelId: Yup.number().optional(),
        cookingTimeStart: Yup.number().optional().min(0),
        cookingTimeEnd: Yup.number().optional().min(0),
        rating: Yup.number().optional().min(0),
        servings: Yup.number().optional().min(1),
        zoneTeamperatureStart: Yup.number().optional().min(0),
        zoneTeamperatureEnd: Yup.number().optional().min(0),
        difficulty: Yup.string().optional(),
        // ingredientId: Yup.number().optional(),
    });

const recipeValidationSchema: ObjectSchema<IAddRecipeForm> = Yup.object()
    .defined()
    .shape({
        id: Yup.number().optional(),
        pinnedRecipes: Yup.array().of(Yup.object<IPinnedSelectionRecipe>()),
    });

const validationSchemas = {
    selectionValidationSchema,
    valueTagsValidationSchema,
    recipeValidationSchema,
};

export default validationSchemas;
