import { recipeConstants } from './recipe.constants';
import { recipeService } from './recipe.service';
import { Dispatch } from 'redux';
import {
    IGrillResponse,
    IRecipe,
    IRecipeCategoryTagsForm,
    IRecipeCategoryTagsResponse,
    IRecipeCookingMethodResponse,
    IRecipeCookingSteps,
    IRecipeCookingStepsToSave,
    IRecipeForm,
    IRecipeGrillModelResponse,
    IRecipeIngredientsForm,
    IRecipeIngredientsResponse,
    IRecipeProductsForm,
    IRecipeProductsResponse,
    IRecipeResponse,
    IRecipeTutorialResponse,
    RecipeActions,
    IActionNamesResponse,
    IActionNamesActions,
    IRecipeEquipmentResponse,
    IRecipeEquipmentForm,
    IRecipeCookingStepsData,
    IRecipeIngredients,
    LinkedRecipe,
} from './recipe.interfaces';
import { alertActions } from '../../shared/actions/alert.actions';
import { AxiosError, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import en from '../../../assets/language/en.json';
import { recipeHelpers } from '../helpers/recipe.helpers';

function getRecipes(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return recipeService.getRecipes(paging, searchText, filters).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_REQUEST };
    }

    function success(response: IRecipeResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_SUCCESS,
            recipeData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPES_FAILURE, error };
    }
}

function getActionNames(): (dispatch: Dispatch) => Promise<IActionNamesResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return recipeService.getActionNames().then(
            (response: IActionNamesResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IActionNamesActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_ACTION_NAMES_REQUEST };
    }
    function success(response: IActionNamesResponse): IActionNamesActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_ACTION_NAMES_SUCCESS,
            actionNamesData: response,
        };
    }

    function failure(error: string): IActionNamesActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_ACTION_NAMES_FAILURE, error };
    }
}

function getRecipeById(recipeId: number): (dispatch: Dispatch) => Promise<IRecipe | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeById(recipeId).then(
            (response: IRecipe) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_REQUEST };
    }

    function success(response: IRecipe): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_SUCCESS,
            recipeById: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_BY_ID_FAILURE, error };
    }
}

const addRecipe = (recipeData: IRecipeForm): ((dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.addRecipe(recipeHelpers.mapRecipeForApi(recipeData)).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success('Recipe added successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_REQUEST };
    }

    function success(response: IRecipeResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_SUCCESS,
            recipeData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_FAILURE, error };
    }
};

const updateRecipe = (
    recipeData: IRecipeForm,
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateRecipe(recipeHelpers.mapRecipeForApi(recipeData), recipeId).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success('Recipe updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_REQUEST };
    }

    function success(response: IRecipeResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_SUCCESS,
            recipeData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_FAILURE, error };
    }
};
/**
 * RecipeEquipment
 */
const getRecipeEquipment = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeEquipmentResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeEquipment(recipeId).then(
            (response: IRecipeEquipmentResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_REQUEST };
    }

    function success(response: IRecipeEquipmentResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_SUCCESS,
            recipeEquipmentsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_EQUIPMENT_FAILURE, error };
    }
};

const addRecipeEquipment = (
    data: IRecipeEquipmentForm,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.addRecipeEquipment(data).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(recipeConstants.RECIPE_MESSAGES.RECIPE_EQUIPMENT_SUCCESS));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_CATEGORY_TAGS_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_CATEGORY_TAGS_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_FAILURE, error };
    }
};

const updateRecipeEquipment = (
    recipeId: number,
    equipmentToSave: IRecipeEquipmentForm,
): ((dispatch: Dispatch) => Promise<IRecipeEquipmentResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateRecipeEquipment(recipeId, equipmentToSave).then(
            (response: IRecipeEquipmentResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success('Recipe equipment updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_EQUIPMENT_REQUEST };
    }

    function success(response: IRecipeEquipmentResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_EQUIPMENT_SUCCESS,
            recipeEquipmentsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_EQUIPMENT_FAILURE, error };
    }
};

const getRecipeCategoryTags = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeCategoryTags(recipeId).then(
            (response: IRecipeCategoryTagsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IRecipeCategoryTagsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_SUCCESS,
            recipeCategoryTagsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_CATEGORY_TAGS_FAILURE, error };
    }
};

const addRecipeCategoryTags = (
    recipeCategoryTags: IRecipeCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.addRecipeCategoryTags(recipeCategoryTags).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(recipeConstants.RECIPE_MESSAGES.RECIPE_CATEGORY_TAGS_SUCCESS));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_CATEGORY_TAGS_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_CATEGORY_TAGS_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_FAILURE, error };
    }
};

const updateRecipeCategoryTags = (
    categoryTagsToSave: IRecipeCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<IRecipeCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateRecipeCategoryTags(categoryTagsToSave).then(
            (response: IRecipeCategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success('Recipe Category Tags updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IRecipeCategoryTagsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_CATEGORY_TAGS_SUCCESS,
            recipeCategoryTagsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_CATEGORY_TAGS_FAILURE, error };
    }
};

const getRecipeIngredients = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeIngredientsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeIngredients(recipeId).then(
            (response: IRecipeIngredientsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_REQUEST };
    }

    function success(response: IRecipeIngredientsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_SUCCESS,
            recipeIngredientsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_INGREDIENTS_FAILURE, error };
    }
};

const updateRecipeIngredients = (
    recipeId: number,
    recipeIngredient: IRecipeIngredients[],
): ((dispatch: Dispatch) => Promise<IRecipeIngredientsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateRecipeIngredients(recipeId, recipeIngredient).then(
            (response: IRecipeIngredientsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_ingredients_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_INGREDIENTS_REQUEST };
    }

    function success(response: IRecipeIngredientsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_INGREDIENTS_SUCCESS,
            recipeIngredientsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_INGREDIENTS_FAILURE, error };
    }
};

const deleteRecipeIngredient = (
    recipeIngredientId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.deleteRecipeIngredient(recipeIngredientId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.recipe_ingredient_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT_FAILURE, error };
    }
};

const addRecipeCookingSteps = (
    recipeCookingSteps: IRecipeCookingSteps,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.addRecipeCookingSteps(recipeCookingSteps).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(recipeConstants.RECIPE_MESSAGES.RECIPE_COOKING_STEPS_SUCCESS));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_COOKING_STEPS_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_COOKING_STEPS_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_COOKING_STEPS_FAILURE, error };
    }
};

const getRecipeCookingSteps = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<Array<IRecipeCookingStepsData> | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeCookingSteps(recipeId).then(
            (response: Array<IRecipeCookingStepsData>) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_STEPS_REQUEST };
    }

    function success(response: Array<IRecipeCookingStepsData>): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_STEPS_SUCCESS,
            recipeCookingStepsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_STEPS_FAILURE, error };
    }
};

const updateRecipeCookingSteps = (
    recipeCookingStepsToSave: IRecipeCookingStepsToSave,
    recipeId: number,
): ((dispatch: Dispatch) => Promise<Array<IRecipeCookingStepsData> | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());
        return recipeService.updateRecipeCookingSteps(recipeCookingStepsToSave, recipeId).then(
            (response: Array<IRecipeCookingStepsData>) => {
                dispatch(success(response));
                dispatch(alertActions.success('Recipe Cooking Steps updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_COOKING_STEPS_REQUEST };
    }

    function success(response: Array<IRecipeCookingStepsData>): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_COOKING_STEPS_SUCCESS,
            recipeCookingStepsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_COOKING_STEPS_FAILURE, error };
    }
};

const deleteRecipe = (
    recipeId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.deleteRecipe(recipeId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success('Recipe deleted successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.DELETE_RECIPE_FAILURE, error };
    }
};

const getRecipeCookingMethods = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeCookingMethodResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeCookingMethods(recipeId).then(
            (response: IRecipeCookingMethodResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_METHODS_REQUEST };
    }

    function success(response: IRecipeCookingMethodResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_METHODS_SUCCESS,
            recipeCookingMethodsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_COOKING_METHODS_FAILURE, error };
    }
};

const getRecipeProducts = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeProducts(recipeId).then(
            (response: IRecipeProductsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_PRODUCTS_REQUEST };
    }

    function success(response: IRecipeProductsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_PRODUCTS_SUCCESS,
            recipeProductsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_PRODUCTS_FAILURE, error };
    }
};

const addRecipeProducts = (
    recipeProducts: IRecipeProductsForm,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.addRecipeProducts(recipeProducts).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_products_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_PRODUCTS_REQUEST };
    }

    function success(response: AxiosResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_PRODUCTS_SUCCESS,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.ADD_RECIPE_PRODUCTS_FAILURE, error };
    }
};

const updateRecipeProducts = (
    recipeProducts: IRecipeProductsForm,
): ((dispatch: Dispatch) => Promise<IRecipeProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateRecipeProducts(recipeProducts).then(
            (response: IRecipeProductsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_products_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_PRODUCTS_REQUEST };
    }

    function success(response: IRecipeProductsResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_PRODUCTS_SUCCESS,
            recipeProductsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_RECIPE_PRODUCTS_FAILURE, error };
    }
};

const getGrillModels = (): ((dispatch: Dispatch) => Promise<IGrillResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getGrillModels().then(
            (response: IGrillResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_GRILLS_REQUEST };
    }

    function success(response: IGrillResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_GRILLS_SUCCESS,
            grillModelsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_GRILLS_FAILURE, error };
    }
};

const getRecipeGrillModels = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeGrillModelResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeGrillModels(recipeId).then(
            (response: IRecipeGrillModelResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_GRILL_MODELS_REQUEST };
    }

    function success(response: IRecipeGrillModelResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_GRILL_MODELS_SUCCESS,
            recipeGrillModelsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_GRILL_MODELS_FAILURE, error };
    }
};

const getRecipeTutorials = (
    recipeId: number,
): ((dispatch: Dispatch) => Promise<IRecipeTutorialResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.getRecipeTutorials(recipeId).then(
            (response: IRecipeTutorialResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_TUTORIALS_REQUEST };
    }

    function success(response: IRecipeTutorialResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_TUTORIALS_SUCCESS,
            recipeTutorialsData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.GET_RECIPE_TUTORIALS_FAILURE, error };
    }
};

const updateLinkedRecipes = (
    linkedRecipes: LinkedRecipe[],
    recipeId: number,
): ((dispatch: Dispatch) => Promise<AxiosResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return recipeService.updateLinkedRecipes(linkedRecipes, recipeId).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success('Linked recipes updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_REQUEST };
    }

    function success(response: IRecipeResponse): RecipeActions {
        return {
            type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_SUCCESS,
            recipeData: response,
        };
    }

    function failure(error: string): RecipeActions {
        return { type: recipeConstants.RECIPE_ACTION_TYPES.UPDATE_LINKED_RECIPES_FAILURE, error };
    }
};

export const recipeAction = {
    addRecipeEquipment,
    getRecipeEquipment,
    updateRecipeEquipment,
    getRecipes,
    getRecipeById,
    addRecipe,
    updateRecipe,
    getRecipeCategoryTags,
    addRecipeCategoryTags,
    updateRecipeCategoryTags,
    getRecipeIngredients,
    updateRecipeIngredients,
    addRecipeCookingSteps,
    getRecipeCookingSteps,
    updateRecipeCookingSteps,
    deleteRecipe,
    getRecipeCookingMethods,
    getRecipeProducts,
    addRecipeProducts,
    updateRecipeProducts,
    deleteRecipeIngredient,
    getRecipeGrillModels,
    getGrillModels,
    getRecipeTutorials,
    getActionNames,
    updateLinkedRecipes,
};
