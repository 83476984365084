import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { globalSearchService } from './global.search.service';
import { IGlobalSearchPageItem } from './global.search.interfaces';

function getGlobalSearchAppPages(): (dispatch: Dispatch) => Promise<IGlobalSearchPageItem[]> {
    return (dispatch) => {
        return globalSearchService.getGlobalSearchAppPages().then(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.toString()));
                return [];
            },
        );
    };
}

const updateGlobalSearchAppPages = (
    data: IGlobalSearchPageItem[],
): ((dispatch: Dispatch) => Promise<IGlobalSearchPageItem[]>) => {
    return (dispatch) => {
        return globalSearchService.updateGlobalSearchAppPages(data).then(
            (response) => {
                dispatch(alertActions.success('Global Search App Pages updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return null;
            },
        );
    };
};

export const globalSearchActions = {
    updateGlobalSearchAppPages,
    getGlobalSearchAppPages,
};
