import { z } from 'zod';

const tagsRegex = /^(?:[^{}[\]:"]*)?$/;
const relativeUrlRegex = /^\/([a-zA-Z0-9-_]+\/?)*$/;

const baseGlobalSearchPageItemSchema = z.object({
    id: z.number().optional().nullable(),
    nameEn: z.string().min(1),
    nameDe: z.string().min(1),
    tagsEn: z.string().regex(tagsRegex).optional().nullable(),
    tagsDe: z.string().regex(tagsRegex).optional().nullable(),
    link: z
        .string()
        .regex(relativeUrlRegex, {
            message: 'Link must be a relative URL.',
        })
        .min(1),
    parentId: z.number().optional().nullable(),
    isGrillRelated: z.boolean(),
});

const globalSearchSchema = z.array(
    baseGlobalSearchPageItemSchema.extend({
        children: z.lazy(() => globalSearchSchema),
    }),
);

const validationSchemas = {
    baseGlobalSearchPageItemSchema,
    globalSearchSchema,
};

export default validationSchemas;
