import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import styles from '../../../ingredients/pages/ingredient/index.module.scss';
import { AppState } from '../../../../shared/store';
import { connect } from 'react-redux';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IRowsDeleted, IFilters, IPagination, ISort } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { IActionNameProps, IActionNameForm, IActionName } from '../../action.names.interfaces';
import { withStyles } from '@material-ui/core/styles';
import { actionNamesAction } from '../../action.names.actions';
import { compose } from 'recompose';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import AddOrEditActionName from '../addOrEditActionName';
import formModels from '../addOrEditActionName/formModel/action.name.form.model';
import { actionNameDataTables } from '../../../helpers/action.name.datatables';
import { actionNamesConstants } from '../../action.names.constants';

import clsx from 'clsx';
type OwnProps = IActionNameProps & ReturnType<typeof mapStateToProps>;
const ActionName = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const [actionNamesData, setActionNamesData] = useState<Array<IActionName>>([]);
    const { formId, formField } = formModels.actionNameFormModel;
    const { actionNames, total, loading } = props;
    const [filters, setFilters] = useState<IFilters | null>();
    const [sort, setSort] = useState<ISort | null>();
    const [pagination, setPagination] = useState<IPagination | null>();
    const componentIsMounted = useRef(true);

    const tableColumns = [
        ...actionNameDataTables.actionNameColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: IActionNameForm = {
                        id: tableMeta.rowData[actionNamesConstants.ACTION_NAME_ROW_ELEMENTS.ID],
                        nameEn: tableMeta.rowData[actionNamesConstants.ACTION_NAME_ROW_ELEMENTS.NAME_EN],
                        nameDe: tableMeta.rowData[actionNamesConstants.ACTION_NAME_ROW_ELEMENTS.NAME_DE],
                    };

                    return (
                        <AddOrEditActionName
                            formId={formId}
                            formField={formField}
                            refreshData={_refreshActionNames}
                            actionNamesData={row}
                        />
                    );
                },
            },
        },
    ];

    const _refreshActionNames = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        props.getActionNames(pagination, searchText, filters).then((response) => {
            setActionNamesData(response.actionNames);
        });
    };

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const actionsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const actionsToRemoveLength = actionsToRemoveIndex.length;

            actionsToRemoveIndex.map(async (actionIndex, index) => {
                const actionId = actionNames[actionIndex].id;
                const completed = actionsToRemoveLength === index + 1;

                await props.deleteActionName(actionId, completed);
                completed && (await _refreshActionNames(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    useEffect(() => {
        props.getActionNames(globalConstants.DEFAULT_PAGINATION).then((response) => {
            if (componentIsMounted.current) {
                setActionNamesData(response.actionNames);
                componentIsMounted.current = false;
            }
        });
    }, []);

    return (
        <div className={clsx(styles.ingredient, 'mt16')}>
            <AddOrEditActionName formId={formId} formField={formField} refreshData={_refreshActionNames} />

            <DynamicTable
                title={en.action_names_label}
                options={options}
                columns={tableColumns}
                data={actionNamesData}
                total={total}
                loading={loading}
                refreshData={_refreshActionNames}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    actionNames: state.actionNames.actionNames,
    total: state.actionNames.total,
    loading: state.actionNames.loading,
});

const mapDispatchToProps = {
    getActionNames: actionNamesAction.getActionNames,
    deleteActionName: actionNamesAction.deleteActionName,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(ActionName);
