import { quizQuestionConstants } from './quiz.questions.constants';
import { IQuizQuestionActions, IQuizQuestionState } from './quiz.questions.interfaces';

const initialState: IQuizQuestionState = {
    quizQuestions: [],
    loading: false,
    total: 0,
    quizRounds: [],
    totalQuizRounds: 0,
};

const quizQuestionReducer = (state = initialState, action: IQuizQuestionActions): IQuizQuestionState => {
    switch (action.type) {
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_REQUEST:
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_FAILURE:
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                quizQuestions: action.quizQuestionsData.page,
                total: action.quizQuestionsData.total,
            };
        case quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                quizRounds: action.quizRoundData.page,
                totalQuizRounds: action.quizRoundData.total,
            };
        default:
            return state;
    }
};

export { quizQuestionReducer };
