import { tutorialConstants } from './tutorial.constants';
import { ITutorialActions, ITutorialState } from './tutorial.interfaces';

const initialState: ITutorialState = {
    tutorials: [],
    tutorial: null,
    tutorialStep: null,
    tutorialProducts: [],
    total: 0,
    loading: false,
    progressUpload: 0,
};

const tutorialsReducer = (state = initialState, action: ITutorialActions): ITutorialState => {
    switch (action.type) {
        case tutorialConstants.TUTORIAL_ACTION_TYPES.IMPORT_FILE_TUTORIAL_STEP:
            return {
                ...state,
                progressUpload: action.progressUpload,
                loading: true,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_SUCCESS:
            return {
                ...state,
                tutorials: action.tutorialsData.tutorials,
                total: action.tutorialsData.total,
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_SUCCESS:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_SUCCESS:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_BY_ID_SUCCESS:
            return {
                ...state,
                tutorial: action.tutorial,
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_REQUEST:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIALS_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.DELETE_TUTORIAL_STEP_SUCCESS:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_FAILURE:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_FAILURE:
            return {
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_STEPS_SUCCESS:
            return {
                ...state,
                tutorialStep: action.tutorialStep,
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_STEP_SUCCESS:
            return {
                ...state,
                tutorialStep: action.tutorialStep,
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_STEP_SUCCESS:
            return {
                ...state,
                tutorialStep: action.tutorialStep,
                loading: false,
            };
        case tutorialConstants.TUTORIAL_ACTION_TYPES.GET_TUTORIAL_PRODUCTS_SUCCESS:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.ADD_TUTORIAL_PRODUCTS_SUCCESS:
        case tutorialConstants.TUTORIAL_ACTION_TYPES.UPDATE_TUTORIAL_PRODUCTS_SUCCESS:
            return {
                ...state,
                tutorialProducts: action.tutorialProducts,
                loading: false,
            };
        default:
            return state;
    }
};

export { tutorialsReducer };
