import { filterConstants } from './filter.constants';

export const filterEndpoints = {
    FILTERS: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS_WITH_PARAMS,
    },
    GET_FILTERS: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS,
    },
    GET_FILTER_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS_WITH_PARAMS,
    },
    ADD_FILTER: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS,
    },
    DELETE_FILTER: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS_WITH_PARAMS,
    },
    UPDATE_FILTER: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTERS_WITH_PARAMS,
    },
    GET_FILTER_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTER_CATEGORY_TAGS + '/',
    },
    ADD_FILTER_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTER_CATEGORY_TAGS,
    },
    UPDATE_FILTER_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + filterConstants.FILTERS_ROUTES.FILTER_CATEGORY_TAGS + '/',
    },
};
