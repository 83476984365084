import React from 'react';
import { Box, Button, IconButton, MenuItem, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FiberManualRecord, Star } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import clsx from 'clsx';
import { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, ReactElement, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import en from '../../../../assets/language/en.json';
import SubmitDialog from '../../../shared/components/generics/dialog/SubmitDialog';
import DynamicTable from '../../../shared/components/generics/table/DynamicTable';
import { IFilters } from '../../../shared/components/interfaces';
import { globalConstants } from '../../../shared/constants/global.constants';
import store, { AppState } from '../../../shared/store';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { reviewDataTables } from '../../helpers/reviews.datatables';
import { reviewActions } from '../reviews.actions';
import { reviewConstants } from '../reviews.constants';
import { InternalRatingReportDecision, IReviewProps } from '../reviews.interfaces';
import styles from './index.module.scss';
import { filterAction } from '../../filters/filter.actions';
import { userReportActions } from '../../userReports/user.report.actions';
import { userReportConstants } from '../../userReports/user.report.constants';

type OwnProps = IReviewProps & ReturnType<typeof mapStateToProps>;

const Review = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history, reviews, total, loading } = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [rowIndexesToDelete, setRowIndexesToDelete] = useState<number[]>([]);
    const [nameFilter, setNameFilter] = useState<{ userId: number; fullName: string }>(null);
    const [recipeFilter, setRecipeFilter] = useState<{ recipeId: number; recipeName: string }>(null);
    const [, rerender] = useReducer((x) => x + 1, 0);

    const reviewColumns = [...reviewDataTables.reviewColumns];

    reviewColumns.splice(1, 0, {
        name: 'read',
        label: en.new,
        options: {
            sort: true,
            filter: false,
            customBodyRender(read: boolean, meta: MUIDataTableMeta) {
                return (
                    <IconButton
                        className={styles.newButton}
                        onClick={() => {
                            updateReviewStatus(meta.rowIndex);
                        }}
                    >
                        {read ? null : <FiberManualRecord style={{ fill: '#b82b2b', height: '12px' }} />}
                    </IconButton>
                );
            },
        },
    });

    reviewColumns.splice(4, 0, {
        name: 'fullName',
        label: en.name,
        options: {
            filter: false,
            sort: true,
            customBodyRender: renderNameColumn,
            filterList: nameFilter ? [nameFilter.fullName] : [],
        },
    });

    reviewColumns.splice(5, 0, {
        name: 'recipeName',
        label: en.recipe_name,
        options: {
            filter: false,
            sort: true,
            customBodyRender: renderRecipeNameColumn,
            filterList: recipeFilter ? [recipeFilter.recipeName] : [],
        },
    });

    reviewColumns.splice(6, 0, {
        name: 'rating',
        label: en.rating_label,
        options: {
            sort: true,
            filter: true,
            filterType: 'dropdown',
            customBodyRender: renderRatingColumn,
            filterOptions: {
                fullWidth: true,
                names: [...Array(5).keys()].map((rating) => (rating + 1).toString()),
            },
        },
    });

    reviewColumns.splice(7, 0, {
        name: 'totalReports',
        label: en.comment_reports,
        options: {
            filter: false,
            sort: false,
            customBodyRender: renderReportCountColumn,
        },
    });

    reviewColumns.splice(8, 0, {
        name: 'internalDecision',
        label: en.internal_decision,
        options: {
            filter: false,
            sort: false,
            customBodyRender: renderReportInternalDecisionColumn,
        },
    });

    reviewColumns.push({
        name: 'userBlocked',
        label: en.block_label,
        options: {
            filter: false,
            sort: true,
            customBodyRender: renderBlockUserButton,
        },
    });

    reviewColumns.push({
        name: 'filters',
        label: en.filter_label,
        options: {
            display: false,
            filter: true,
            filterType: 'dropdown',
            customFilterListRender: (v) => {
                const name = props.filters?.find(({ id }) => id === parseInt(v))?.name_en;
                return `Filter: ${name}`;
            },
            filterOptions: {
                fullWidth: true,
                names: props.filters.map(({ id }) => id.toString()),
                renderValue: (v) => props.filters.find(({ id }) => id === parseInt(v)).name_en,
            },
        },
    });

    useEffect(() => {
        props.getFilters(globalConstants.PAGINATION_NO_LIMIT);
    }, []);

    useEffect(() => {
        if (!nameFilter) return;
        _refreshReviews(globalConstants.DEFAULT_PAGINATION, undefined, [
            {
                filterParameterName: 'user_id',
                filterValue: nameFilter.userId,
            },
        ]);
    }, [nameFilter]);

    useEffect(() => {
        if (!recipeFilter) return;
        _refreshReviews(globalConstants.DEFAULT_PAGINATION, undefined, [
            {
                filterParameterName: 'recipe_id',
                filterValue: recipeFilter.recipeId,
            },
        ]);
    }, [recipeFilter]);

    const options: MUIDataTableOptions = {
        onRowsDelete(rowsDeleted) {
            const reviewIndexesToDelete = rowsDeleted.data.map((data) => data.dataIndex);
            setRowIndexesToDelete(reviewIndexesToDelete);
            setDialogOpen(true);
        },
        onFilterChipClose(index, removedFilter, filterList) {
            if (nameFilter && nameFilter.fullName === removedFilter) {
                setNameFilter(null);
                return;
            }
            if (recipeFilter && recipeFilter.recipeName === removedFilter) {
                setRecipeFilter(null);
            }
        },
    };

    const handleDelete = () => {
        rowIndexesToDelete.map(async (reviewIndex, index) => {
            const reviewId = reviews[reviewIndex].id;
            const completed = rowIndexesToDelete.length === index + 1;

            await props.deleteReview(reviewId, completed);
            completed && (await _refreshReviews(globalConstants.DEFAULT_PAGINATION));
        });
    };

    const blockUser = (rowIndex: number) => {
        props.blockUser(reviews[rowIndex].userId, !reviews[rowIndex].userBlocked).then((data) => {
            reviews.forEach((review) => {
                if (review.userId === data.id) {
                    review.userBlocked = data.blocked;
                }
            });
            rerender();
        });
    };

    const _refreshReviews = async (pagination: any, searchText?: string, filters?: IFilters[]): Promise<void> => {
        await props.getReviews(pagination, searchText, filters);
        reviewActions.countUnreadReviews().then((action) => store.dispatch(action));
    };

    const editBlacklist = (): void => {
        history.push(reviewConstants.REVIEW_ROUTES.EDIT_BLACKLIST);
        // throw new Error('Not yet implemented');
    };

    return (
        <div className={clsx(styles.reviews, 'mt16')}>
            <SubmitDialog
                submitLabel="DELETE"
                cancelLabel="BACK"
                title={`Do you really want to delete the ${rowIndexesToDelete.length} selected reviews?`}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                handleSubmit={handleDelete}
            />
            <Button className={clsx(styles.addReviews, 'mb4')} onClick={editBlacklist}>
                {en.edit_blacklist_button}
            </Button>
            <DynamicTable
                title={en.reviews_label}
                options={options}
                columns={reviewColumns}
                data={reviews}
                total={total}
                loading={loading}
                refreshData={_refreshReviews}
            />
        </div>
    );

    function updateReviewStatus(rowIndex: number) {
        reviews[rowIndex].read = !!!reviews[rowIndex].read;
        rerender();
        props
            .updateReviewStatus(reviews[rowIndex].id, reviews[rowIndex].read, reviews[rowIndex].internalDecision)
            .then(() => {
                reviewActions.countUnreadReviews().then((action) => store.dispatch(action));
            })
            .catch((err) => {
                _refreshReviews(globalConstants.DEFAULT_PAGINATION);
            });
    }

    /* Custom column renderers */

    function renderNameColumn(fullName: string, meta: MUIDataTableMeta) {
        return (
            <div
                className={clsx(styles.wideColumn, styles.selectableColumn)}
                onClick={() => {
                    return setNameFilter({ fullName: fullName, userId: reviews[meta.rowIndex]?.userId });
                }}
            >
                <p className="mb-0">{fullName}</p>
                <p className={styles.reviewsText}>
                    {reviews[meta.rowIndex]?.userReviews +
                        (reviews[meta.rowIndex]?.userReviews == 1 ? ' review' : ' reviews')}
                </p>
            </div>
        );
    }

    function renderRecipeNameColumn(recipeName: string, meta: MUIDataTableMeta) {
        return (
            <div
                className={clsx(styles.wideColumn, styles.selectableColumn)}
                onClick={() => {
                    return setRecipeFilter({ recipeName, recipeId: reviews[meta.rowIndex]?.recipeId });
                }}
            >
                {recipeName}
                <p className={styles.reviewsText}>
                    {reviews[meta.rowIndex]?.recipeReviews +
                        (reviews[meta.rowIndex]?.recipeReviews == 1 ? ' review' : ' reviews')}
                </p>
            </div>
        );
    }

    function renderRatingColumn(rating: string, meta: MUIDataTableMeta) {
        return (
            <div className={styles.wideColumn}>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Rating
                        precision={0.5}
                        readOnly
                        value={parseInt(rating)}
                        icon={<Star className={styles.ratingStarFilled} />}
                        emptyIcon={<Star className={styles.ratingStarEmpty} />}
                    />
                    <span
                        style={{
                            marginLeft: '8px',
                        }}
                    >
                        {rating}
                    </span>
                </Box>
            </div>
        );
    }

    function renderReportCountColumn(reportCount: number, meta: MUIDataTableMeta) {
        return (
            <div
                className={clsx(styles.wideColumn, styles.selectableColumn)}
                onClick={() => {
                    history.push(
                        `${userReportConstants.USER_REPORT_ROUTES.REVIEW_REPORTS}/${reviews[meta.rowIndex].id}`,
                    );
                }}
            >
                {`${reportCount} ${reportCount == 1 ? 'report' : 'reports'}`}
                <p className={styles.reviewsText}>Details</p>
            </div>
        );
    }

    function renderReportInternalDecisionColumn(
        internalDecision: InternalRatingReportDecision,
        meta: MUIDataTableMeta,
    ) {
        return (
            <TextField
                className={styles.formInput}
                label={'Internal Decision'}
                name={'internalDecision'}
                select
                type={'text'}
                onChange={(event) => {
                    reviews[meta.rowIndex].internalDecision = event.target.value as InternalRatingReportDecision;
                    rerender();
                    props.updateReviewStatus(
                        reviews[meta.rowIndex].id,
                        reviews[meta.rowIndex].read,
                        event.target.value as InternalRatingReportDecision,
                    );
                }}
                value={reviews[meta.rowIndex].internalDecision}
                variant={'outlined'}
            >
                <MenuItem className={clsx('py4')} key={'internalDecision'} value={'Internal Decision'} disabled>
                    {'Internal Decision'}
                </MenuItem>
                {Object.keys(InternalRatingReportDecision).map((key) => (
                    <MenuItem key={key} value={InternalRatingReportDecision[key]}>
                        {InternalRatingReportDecision[key]}
                    </MenuItem>
                ))}
            </TextField>
        );
    }

    function renderBlockUserButton(blocked: boolean, meta: MUIDataTableMeta) {
        return (
            <Button onClick={() => blockUser(meta.rowIndex)} className={styles.blockUser}>
                {blocked ? en.unblock_button_label : en.block_button_label}
            </Button>
        );
    }
};

const mapStateToProps = (state: AppState) => ({
    reviews: state.reviews.reviews,
    total: state.reviews.total,
    loading: state.reviews.loading,
    filters: state.filters.filters,
});

const mapDispatchToProps = {
    getReviews: reviewActions.getReviews,
    deleteReview: reviewActions.deleteReview,
    blockUser: reviewActions.blockUser,
    getFilters: filterAction.getFilters,
    updateReviewStatus: userReportActions.updateReviewStatus,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Review);
