import axios, { AxiosRequestConfig } from 'axios';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { globalSearchEndpoints } from './global.search.endpoints';
import { IGlobalSearchPageItem } from './global.search.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getGlobalSearchAppPages = async (): Promise<IGlobalSearchPageItem[]> => {
    const requestOptions: AxiosRequestConfig = {
        url: globalSearchEndpoints.GET_APP_PAGES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const updateGlobalSearchAppPages = async (data: IGlobalSearchPageItem[]): Promise<IGlobalSearchPageItem[]> => {
    const requestOptions: AxiosRequestConfig = {
        url: globalSearchEndpoints.UPDATE_APP_PAGES.url,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data,
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

export const globalSearchService = {
    updateGlobalSearchAppPages,
    getGlobalSearchAppPages,
};
