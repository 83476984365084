import { MUIDataTableColumn } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import { commonOptions } from './common.datatable.options';
import ImageModal from '../../shared/components/generics/imageModal/ImageModal';
import React from 'react';
import moment from 'moment/moment';

const promotionCampaignColumns: MUIDataTableColumn[] = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'campaignName',
        label: en.campaign_name,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'titleEn',
        label: en.campaign_banner_title,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'descriptionEn',
        label: en.campaign_body_text,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'buttonTitleEn',
        label: en.campaign_button_text,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'pictureEn',
        label: en.campaign_media_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string): JSX.Element {
                if (pictureUrl) {
                    return (
                        <ImageModal
                            baseStyle={{
                                width: '96px',
                                height: '96px',
                                borderRadius: '8px',
                            }}
                            title={en.campaign_media_label}
                            image={pictureUrl}
                            alt={en.campaign_media_label}
                        />
                    );
                }
                return <></>;
            },
        },
    },
    {
        name: 'modifiedAt',
        label: en.modify_date_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

export const promotionCampaignDataTables = {
    promotionCampaignColumns,
};
