import React, { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { Button } from '@material-ui/core';
import { filterDataTables } from '../../../helpers/filter.datatables';
import { IFilterProps } from '../../filter.interfaces';
import { filterAction } from '../../filter.actions';
import { filterConstants } from '../../filter.constants';
import { AppState } from '../../../../shared/store';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type OwnProps = IFilterProps & ReturnType<typeof mapStateToProps>;

const Filter = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history, filters, total, loading } = props;
    const tableColumns = [
        ...filterDataTables.filterColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={styles.editFilter}
                            component={Link}
                            to={`${filterConstants.FILTERS_ROUTES.EDIT_FILTER}/${
                                filterConstants.FILTERS_SUB_ROUTES.FILTER
                            }/${filters[tableMeta.rowIndex].id}`}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const row = {
                id: filters[rowsDeleted.data[0].index].id,
            };

            const filtersToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const filtersToRemoveLength = filtersToRemoveIndex.length;

            filtersToRemoveIndex.map(async (filterIndex, index) => {
                const filterId = filters[filterIndex].id;
                const completed = filtersToRemoveLength === index + 1;
                await props.deleteFilter(filterId, completed);
                completed && (await _refreshFilters(globalConstants.DEFAULT_PAGINATION));
            });

            await _refreshFilters(globalConstants.DEFAULT_PAGINATION);
        },
    };

    const _refreshFilters = async (pagination: IPagination, searchText?: string, filters?: IFilters): Promise<void> => {
        await props.getFilters(pagination, searchText, filters);
    };

    const addNewFilter = (): void => {
        history.push(filterConstants.FILTERS_ROUTES.ADD_FILTER);
    };

    return (
        <div className={clsx(styles.filter, 'mt16')}>
            <Button className={clsx(styles.addFilter, 'mb4')} onClick={addNewFilter}>
                {en.add_new_filter}
            </Button>
            <DynamicTable
                title={en.filters_label}
                options={options}
                columns={tableColumns}
                data={filters}
                total={total}
                loading={loading}
                refreshData={_refreshFilters}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    filters: state.filters.filters,
    total: state.filters.total,
    loading: state.filters.loading,
});

const mapDispatchToProps = {
    getFilters: filterAction.getFilters,
    deleteFilter: filterAction.deleteFilter,
    getFilterById: filterAction.getFilterById,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Filter);
