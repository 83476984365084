import React, { ReactElement } from 'react';
import { countries } from '../../../../../assets/icons';
import styles from './index.module.scss';

type Props = {
    country: string;
};

const LanguageFlag = ({ country }: Props) => {
    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: `url(${countries[country]})`,
            }}
        />
    );
};

export default LanguageFlag;
