export const reviewConstants = {
    REVIEW_ACTION_TYPES: {
        GET_REVIEWS_REQUEST: 'GET_REVIEWS_REQUEST',
        GET_REVIEWS_SUCCESS: 'GET_REVIEWS_SUCCESS',
        GET_REVIEWS_FAILURE: 'GET_REVIEWS_FAILURE',
        DELETE_REVIEW_REQUEST: 'DELETE_REVIEW_REQUEST',
        DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS',
        DELETE_REVIEW_FAILURE: 'DELETE_REVIEW_FAILURE',
        BLOCK_USER_REQUEST: 'BLOCK_USER_REQUEST',
        BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
        BLOCK_USER_FAILURE: 'BLOCK_USER_FAILURE',
        READ_REVIEW_REQUEST: 'READ_REVIEW_REQUEST',
        READ_REVIEW_SUCCESS: 'READ_REVIEW_SUCCESS',
        READ_REVIEW_FAILURE: 'READ_REVIEW_FAILURE',
        COUNT_UNREAD_REQUEST: 'COUNT_UNREAD_REQUEST',
        COUNT_UNREAD_SUCCESS: 'COUNT_UNREAD_SUCCESS',
        COUNT_UNREAD_FAILURE: 'COUNT_UNREAD_FAILURE',
        GET_BLACKLIST_REQUEST: 'GET_BLACKLIST_REQUEST',
        GET_BLACKLIST_SUCCESS: 'GET_BLACKLIST_SUCCESS',
        GET_BLACKLIST_FAILURE: 'GET_BLACKLIST_FAILURE',
        ADD_BLACKLIST_REQUEST: 'ADD_BLACKLIST_REQUEST',
        ADD_BLACKLIST_SUCCESS: 'ADD_BLACKLIST_SUCCESS',
        ADD_BLACKLIST_FAILURE: 'ADD_BLACKLIST_FAILURE',
        UPDATE_BLACKLIST_REQUEST: 'UPDATE_BLACKLIST_REQUEST',
        UPDATE_BLACKLIST_SUCCESS: 'UPDATE_BLACKLIST_SUCCESS',
        UPDATE_BLACKLIST_FAILURE: 'UPDATE_BLACKLIST_FAILURE',
        DELETE_BLACKLIST_REQUEST: 'DELETE_BLACKLIST_REQUEST',
        DELETE_BLACKLIST_SUCCESS: 'DELETE_BLACKLIST_SUCCESS',
        DELETE_BLACKLIST_FAILURE: 'DELETE_BLACKLIST_FAILURE',
    },
    PRODUCT_ROW_ELEMENTS: {
        ID: 1,
        TITLE_EN: 2,
        TITLE_DE: 3,
        LINK_US_SHOP: 4,
        LINK_DE_SHOP: 5,
        LINK_OTHER_SHOP: 6,
    },
    REVIEW_ROUTES: {
        REVIEWS: '/reviews',
        REVIEW_WITH_PARAMS: '/review/',
        UNREAD_REVIEWS_COUNT: '/unread-reviews-count',
        TOGGLE_READ_STATUS: '/review/toggle-read-status/',
        EDIT_BLACKLIST: '/reviews/edit-blacklist',
        BLACKLIST: '/review/blacklist',
        BLACKLIST_WITH_PARAMS: '/review/blacklist/',
    },
};
