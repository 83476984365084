import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IPagination } from '../../shared/components/interfaces';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { InternalRatingReportDecision } from '../reviews/reviews.interfaces';
import { userReportEndpoints } from '../userReports/user.report.endpoints';
import {
    InternalUserReportDecision,
    IReportedUserResponse,
    IReportsForUserResponse,
    IUserReportReviewResponse,
} from './user.report.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const updateReviewStatus = async (
    reviewId: number,
    read: boolean,
    internalDecision: InternalRatingReportDecision,
): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.UPDATE_REVIEW_REPORT.url(reviewId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: {
            read,
            internalDecision,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getReviewReports = async (reviewId: number): Promise<IUserReportReviewResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.GET_REVIEW_REPORTS.url(reviewId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getReportedUsers = async (pagination: IPagination, searchText: string): Promise<IReportedUserResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.GET_REPORTED_USERS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateReportedUser = async (
    userId: number,
    read: boolean,
    internalDecision: InternalUserReportDecision,
): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.UPDATE_REPORTED_USER.url(userId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: {
            read,
            internalDecision,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getReportsForUser = async (userId: number): Promise<IReportsForUserResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.GET_REPORTS_FOR_USER.url(userId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const userReportService = {
    updateReviewStatus,
    getReviewReports,
    getReportedUsers,
    updateReportedUser,
    getReportsForUser,
};
