import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { IUnitResponse, IUnit, UnitConversionRate } from './unit.interfaces';
import { unitEndpoints } from './unit.endpoints';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getUnits = async (): Promise<IUnitResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: unitEndpoints.GET_UNITS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addUnit = async (unitData: IUnit): Promise<IUnitResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: unitEndpoints.ADD_UNIT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: unitData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateUnit = async (unitData: IUnit, unitId: number): Promise<IUnitResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: unitEndpoints.UPDATE_UNIT.url + unitId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: unitData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteUnit = async (unitId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: unitEndpoints.DELETE_UNIT.url + unitId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getFixedConversionRates = async (): Promise<UnitConversionRate[]> => {
    const requestOptions: AxiosRequestConfig = {
        url: unitEndpoints.FIXED_CONVERSION_RATES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const unitService = {
    getUnits,
    addUnit,
    updateUnit,
    deleteUnit,
    getFixedConversionRates,
};

export default unitService;
