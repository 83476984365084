const selection = {
    name: '',
    pinnedRecipes: [],
    displays: [],
    seasonalStart: null,
    seasonalEnd: null,
};

const valueTags = {
    preparationTime: '',
    restingTime: '',
    totalTime: '',
    cookingTimeFrom: '',
    cookingTimeTo: '',
    rating: '',
    servings: '',
    zoneTemperatureFrom: '',
    zoneTemperatureTo: '',
    difficulty: '',
};

const recipes = {
    pinnedRecipes: [],
};

export const formInitialValues = {
    selection,
    recipes,
    valueTags,
};
