import React from 'react';
import us from './usa.png';
import de from './de.png';
import marketingCampaignIcon from './marketing_campaign_icon.svg';
import quizQuestionIcon from './quiz_question_icon.svg';
import ambassadorIcon from './ambassador_icon.svg';
import recipeIcon from './recipe_icon.svg';
import selectionIcon from './selection_icon.svg';
import tutorialIcon from './tutorial_icon.svg';
import upsellingIcon from './upselling_icon.svg';
import troubleshootIcon from './troubleshoot_icon.svg';
import troubleshootBasicIcon from './troubleshoot_basic_icon.svg';
import troubleshootPlatformSpecificIcon from './troubleshoot_platform_specific_icon.svg';
export const countries = { us, de };

type PrivateProps = {
    src: string;
} & Props;

type Props = {
    alt?: string;
    width?: string;
    height?: string;
} & React.HTMLAttributes<HTMLImageElement>;

const CustomImageIcon = ({ src, alt, height, width, ...otherProps }: PrivateProps) => (
    <img
        src={src}
        alt={alt || 'Image icon'}
        style={{
            ...otherProps.style,
            height: height || undefined,
            width: width || undefined,
        }}
        {...otherProps}
    />
);

const MarketingCampaignIcon = (props: Props) => <CustomImageIcon src={marketingCampaignIcon} {...props} />;
const AmbassadorIcon = (props: Props) => <CustomImageIcon src={ambassadorIcon} {...props} />;
const RecipeIcon = (props: Props) => <CustomImageIcon src={recipeIcon} {...props} />;
const SelectionIcon = (props: Props) => <CustomImageIcon src={selectionIcon} {...props} />;
const TutorialIcon = (props: Props) => <CustomImageIcon src={tutorialIcon} {...props} />;
const UpsellingIcon = (props: Props) => <CustomImageIcon src={upsellingIcon} {...props} />;
const QuizQuestionIcon = (props: Props) => <CustomImageIcon src={quizQuestionIcon} {...props} />;
const TroubleshootIcon = (props: Props) => <CustomImageIcon src={troubleshootIcon} {...props} />;
const TroubleshootBasicIcon = (props: Props) => <CustomImageIcon src={troubleshootBasicIcon} {...props} />;
const TroubleshootPlatformSpecificIcon = (props: Props) => (
    <CustomImageIcon src={troubleshootPlatformSpecificIcon} {...props} />
);

export const CustomIcons = {
    MarketingCampaignIcon,
    AmbassadorIcon,
    RecipeIcon,
    SelectionIcon,
    TutorialIcon,
    UpsellingIcon,
    QuizQuestionIcon,
    TroubleshootIcon,
    TroubleshootBasicIcon,
    TroubleshootPlatformSpecificIcon,
};
