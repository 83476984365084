import React, { ChangeEvent, PropsWithChildren, ReactElement } from 'react';
import { Button } from '@material-ui/core';
import styles from './index.module.scss';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { alertActions } from '../../../shared/actions/alert.actions';
import { importActions } from '../import.actions';
import { connect } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import en from '../../../../assets/language/en.json';
import { globalConstants } from '../../../shared/constants/global.constants';
import { IFilters, IPagination } from '../../../shared/components/interfaces';

type Props = {
    importRecipes: (file: File | ArrayBuffer) => Promise<AxiosResponse | AxiosError>;
    refreshData: (pagination: IPagination, searchText?: string, filters?: IFilters[]) => void;
};

const ImportExcel = (props: PropsWithChildren<Props>): ReactElement => {
    const hiddenFileInput = React.useRef(null);

    const handleImportClick = (): void => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        const fileUploaded: File = event.target.files[0];

        const reader: FileReader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const extension = fileUploaded.name.split('.').pop().toLowerCase();
                if (globalConstants.FILE_TYPES.indexOf(extension) <= -1) {
                    alertActions.error(en.file_is_not_excel_type_error_msg);
                }
            }
        };
        await reader.readAsDataURL(fileUploaded);
        await props.importRecipes(fileUploaded);
        props.refreshData(globalConstants.DEFAULT_PAGINATION);
    };

    return (
        <div className={styles.import}>
            <Button className={styles.importRecipe} startIcon={<CloudUploadIcon />} onClick={handleImportClick}>
                {en.import_recipes_label}
            </Button>
            <input
                accept="file/"
                id="importFile"
                type="file"
                className={styles.input}
                ref={hiddenFileInput}
                onChange={handleChange}
            />
        </div>
    );
};

const mapDispatchToProps = {
    importRecipes: importActions.importRecipes,
};

export default connect(null, mapDispatchToProps)(ImportExcel);
