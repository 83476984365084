import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import en from '../../../assets/language/en.json';
import { ICookingMethodActions, ICookingMethodForm, ICookingMethodResponse } from './cooking.methods.interfaces';
import { cookingMethodService } from './cooking.method.service';
import { cookingMethodsConstants } from './cooking.methods.contants';
import { IFilters, IPagination } from '../../shared/components/interfaces';

function getCookingMethods(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<ICookingMethodResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return cookingMethodService.getCookingMethods(paging, searchText, filters).then(
            (response: ICookingMethodResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_REQUEST };
    }

    function success(response: ICookingMethodResponse): ICookingMethodActions {
        return {
            type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_SUCCESS,
            cookingMethodsData: response,
        };
    }

    function failure(error: string): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_FAILURE, error };
    }
}

const addCookingMethod = (
    cookingMethodData: ICookingMethodForm,
): ((dispatch: Dispatch) => Promise<ICookingMethodResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return cookingMethodService.addCookingMethod(cookingMethodData).then(
            (response: ICookingMethodResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.cooking_method_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_REQUEST };
    }

    function success(response: ICookingMethodResponse): ICookingMethodActions {
        return {
            type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_SUCCESS,
            cookingMethod: response.data,
        };
    }

    function failure(error: string): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_FAILURE, error };
    }
};

const deleteCookingMethod = (
    cookingMethodId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return cookingMethodService.deleteCookingMethod(cookingMethodId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.cooking_method_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_REQUEST };
    }

    function success(response: AxiosResponse): ICookingMethodActions {
        return {
            type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_SUCCESS,
        };
    }

    function failure(error: string): ICookingMethodActions {
        return {
            type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_FAILURE,
            error,
        };
    }
};

const updateCookingMethod = (
    cookingMethodData: ICookingMethodForm,
    cookingMethodId: number,
): ((dispatch: Dispatch) => Promise<ICookingMethodResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return cookingMethodService.updateCookingMethod(cookingMethodData, cookingMethodId).then(
            (response: ICookingMethodResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.cooking_method_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_REQUEST };
    }

    function success(response: ICookingMethodResponse): ICookingMethodActions {
        return {
            type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_SUCCESS,
            cookingMethod: response.data,
        };
    }

    function failure(error: string): ICookingMethodActions {
        return { type: cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_FAILURE, error };
    }
};

export const cookingMethodActions = {
    getCookingMethods,
    addCookingMethod,
    deleteCookingMethod,
    updateCookingMethod,
};
