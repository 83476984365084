import {
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Paper,
    TextField,
} from '@material-ui/core';
import { AccessTime, Star } from '@material-ui/icons';
import { Autocomplete, Rating } from '@material-ui/lab';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../../assets/language/en.json';
import { ReactComponent as TemperatureIcon } from '../../../../../../assets/svg/temperature_icon.svg';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { IInfluencer } from '../../../../influencers/influencer.interfaces';
import { influencerService } from '../../../../influencers/influencer.service';
import { cookingMethodActions } from '../../../../cookingMethods/cooking.method.actions';
import { ICookingMethodData } from '../../../../cookingMethods/cooking.methods.interfaces';
import { ingredientsAction } from '../../../../ingredients/ingredients.actions';
import { IngredientData } from '../../../../ingredients/ingredients.interfaces';
import { recipeAction } from '../../../../recipe/recipe.actions';
import { IGrillModel, RecipeDifficulty } from '../../../../recipe/recipe.interfaces';
import { selectionActions } from '../../../selection.actions';
import { IRecipeSelectionValueTagsFormComponent, IValueTagsForm } from '../../../selection.interfaces';
import { formInitialValues } from '../formModel/form.initial.values';
import validationSchemas from '../formModel/validation.schema';
import styles from '../index.module.scss';

type Props = IRecipeSelectionValueTagsFormComponent;

const ValueTagForm = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const {
        formField: { formField },
        selectionData: { selection, valueTags },
    } = props;
    const [updatingValueTags, setUpdatingValueTags] = useState<IValueTagsForm | null>(valueTags);
    const [formInitials, setFormInitials] = useState(valueTags ? valueTags : formInitialValues.valueTags);

    const [cookingMethods, setCookingMethods] = useState<ICookingMethodData[]>([]);
    const [grillModels, setGrillModels] = useState<IGrillModel[]>([]);
    const [ingredients, setIngredients] = useState<IngredientData[]>([]);
    const [ambassadors, setAmbassadors] = useState<IInfluencer[]>([]);

    const componentIsMounted = useRef(true);

    useEffect(() => {
        if (componentIsMounted) {
            getSelectionData();
            componentIsMounted.current = false;
        }
    }, []);

    const getSelectionData = (): void => {
        if (!genericHelpers.isNullOrUndefined(selection?.id)) {
            props.getRecipeSelectionValueTags(selection.id).then((response) => {
                setUpdatingValueTags(response);
            });
        }
        props.getCookingMethods(globalConstants.INGREDIENT_AUTOCOMPLETE_PAGINATION).then((response) => {
            setCookingMethods(response.cookingMethods);
        });
        props.getGrillModels().then((response) => {
            setGrillModels(response.grillModels);
        });
        props.getIngredients(globalConstants.INGREDIENT_AUTOCOMPLETE_PAGINATION).then((response) => {
            setIngredients(response.ingredients);
        });
        influencerService.getInfluencers(globalConstants.INGREDIENT_AUTOCOMPLETE_PAGINATION).then((response) => {
            setAmbassadors(response.ambassadors);
        });
    };

    useEffect(() => {
        if (!genericHelpers.isNullOrUndefined(updatingValueTags)) {
            setFormInitials(updatingValueTags);
        }
    }, [updatingValueTags]);

    const handleSubmit = (valueTags: IValueTagsForm): void => {
        valueTags?.id ? handleUpdate(valueTags) : handleInsert(valueTags);
    };

    const handleUpdate = (valueTags: IValueTagsForm): void => {
        props.updateRecipeSelectionValueTags(valueTags, selection.id).then((response) => {
            props.selectionData.valueTags = response;
            props.handleCompleteStep(props.selectionData);
        });
    };

    const handleInsert = (valueTags: IValueTagsForm): void => {
        props.addRecipeSelectionValueTags(valueTags, selection.id).then((response) => {
            props.selectionData.valueTags = response;
            props.handleCompleteStep(props.selectionData);
        });
    };

    const handlePrevStep = (): void => {
        props.prevStep(props.selectionData);
    };

    const nextStep = (): void => {
        props.nextStep(props.selectionData);
    };

    return (
        <div className={clsx(styles.selection, 'mt8', 'flex')}>
            <Paper className={clsx(styles.valueTagsForm)} variant={'outlined'}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={async (values: IValueTagsForm, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.valueTagsValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IValueTagsForm>) => {
                        return (
                            <Form className={styles.addSelectionForm}>
                                <Grid container spacing={2}>
                                    {/* 1st row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.preperationTime.label}
                                                    name={formField.preperationTime.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.preparationTime}
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.preparationTime > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <AccessTime />
                                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.preparationTime && props.touched.preparationTime
                                                        ? props.errors.preparationTime
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    label={formField.cookingMethod.label}
                                                    select
                                                    name={formField.cookingMethod.name}
                                                    value={props.values.cookingMethodIds || []}
                                                    helperText={
                                                        props.errors.cookingMethodIds && props.touched.cookingMethodIds
                                                            ? props.errors.cookingMethodIds
                                                            : ''
                                                    }
                                                    onChange={props.handleChange(formField.cookingMethod.name)}
                                                    onBlur={props.handleBlur(formField.cookingMethod.name)}
                                                    FormHelperTextProps={{
                                                        className: clsx(styles.error_span, 'mt2'),
                                                    }}
                                                    variant={'outlined'}
                                                    SelectProps={{
                                                        multiple: true,
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>{formField.cookingMethod.label}</em>
                                                    </MenuItem>
                                                    {cookingMethods
                                                        ? cookingMethods.map((cookingMethod, index) => (
                                                              <MenuItem key={index} value={cookingMethod.id}>
                                                                  {cookingMethod.nameEn}
                                                              </MenuItem>
                                                          ))
                                                        : null}
                                                </TextField>
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.cookingMethodIds && props.touched.cookingMethodIds
                                                        ? props.errors.cookingMethodIds
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 2nd row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.restingTime.label}
                                                    name={formField.restingTime.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.restingTime}
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.restingTime > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <AccessTime />
                                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.restingTime && props.touched.restingTime
                                                        ? props.errors.restingTime
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.ambassador.label}
                                                    name={formField.ambassador.name}
                                                    select
                                                    type={'text'}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.ambassadorId}
                                                    variant={'outlined'}
                                                >
                                                    <MenuItem className={clsx('py4')} key={''} value={''}>
                                                        {''}
                                                    </MenuItem>
                                                    {ambassadors
                                                        ? ambassadors.map((ambassador, index) => (
                                                              <MenuItem key={index} value={ambassador.id}>
                                                                  {ambassador.blogger_name}
                                                              </MenuItem>
                                                          ))
                                                        : null}
                                                </TextField>
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.ambassadorId && props.touched.ambassadorId
                                                        ? props.errors.ambassadorId
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 3rd row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.totalTime.label}
                                                    name={formField.totalTime.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.totalTime}
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.totalTime > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <AccessTime />
                                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.totalTime && props.touched.totalTime
                                                        ? props.errors.totalTime
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.grillModel.label}
                                                    name={formField.grillModel.name}
                                                    select
                                                    type={'text'}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.grillModelIds || []}
                                                    variant={'outlined'}
                                                    SelectProps={{
                                                        multiple: true,
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>{formField.grillModel.label}</em>
                                                    </MenuItem>
                                                    {grillModels
                                                        ? grillModels.map((grillModel, index) => (
                                                              <MenuItem key={index} value={grillModel.id}>
                                                                  {grillModel.name}
                                                              </MenuItem>
                                                          ))
                                                        : null}
                                                </TextField>
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.grillModelIds && props.touched.grillModelIds
                                                        ? props.errors.grillModelIds
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 4th row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.rating.label}
                                                    name={formField.rating.name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.rating}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <Rating
                                                                precision={0.5}
                                                                name={formField.rating.name}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.rating}
                                                                icon={<Star className={styles.ratingStarFilled} />}
                                                                emptyIcon={<Star className={styles.ratingStarEmpty} />}
                                                            />
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.rating && props.touched.rating
                                                        ? props.errors.rating
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.servings.label}
                                                    name={formField.servings.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.servings}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.servings && props.touched.servings
                                                        ? props.errors.servings
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 5th row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.cookingTimeFrom.label}
                                                    name={formField.cookingTimeFrom.name}
                                                    placeholder={formField.cookingTimeFrom.placeholder}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.cookingTimeFrom}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.cookingTimeFrom > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <AccessTime />
                                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.cookingTimeFrom && props.touched.cookingTimeFrom
                                                        ? props.errors.cookingTimeFrom
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.cookingTimeTo.label}
                                                    name={formField.cookingTimeTo.name}
                                                    placeholder={formField.cookingTimeTo.placeholder}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.cookingTimeTo}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.cookingTimeTo > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <AccessTime />
                                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.cookingTimeTo && props.touched.cookingTimeTo
                                                        ? props.errors.cookingTimeTo
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 6th row */}
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.zoneTemperatureFrom.label}
                                                    name={formField.zoneTemperatureFrom.name}
                                                    placeholder={formField.zoneTemperatureFrom.placeholder}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.zoneTemperatureFrom}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.zoneTemperatureFrom > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <TemperatureIcon />
                                                                <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.zoneTemperatureFrom &&
                                                    props.touched.zoneTemperatureFrom
                                                        ? props.errors.zoneTemperatureFrom
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    placeholder={formField.zoneTemperatureTo.placeholder}
                                                    label={formField.zoneTemperatureTo.label}
                                                    name={formField.zoneTemperatureTo.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.zoneTemperatureTo}
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={
                                                                    props.values.zoneTemperatureTo > 0
                                                                        ? styles.adornmentTouched
                                                                        : styles.adornmentUntouched
                                                                }
                                                            >
                                                                <TemperatureIcon />
                                                                <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.zoneTemperatureTo && props.touched.zoneTemperatureTo
                                                        ? props.errors.zoneTemperatureTo
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 7th row - difficulty dropdown */}
                                    <Grid item xs={12}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={formField.difficulty.label}
                                                    name={formField.difficulty.name}
                                                    select
                                                    type={'text'}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.difficulty}
                                                    variant={'outlined'}
                                                >
                                                    <MenuItem
                                                        className={clsx('py4')}
                                                        key={formInitialValues.valueTags.difficulty}
                                                        value={formInitialValues.valueTags.difficulty}
                                                    >
                                                        {formInitialValues.valueTags.difficulty}
                                                    </MenuItem>
                                                    {Object.keys(RecipeDifficulty).map((key) => (
                                                        <MenuItem key={key} value={RecipeDifficulty[key]}>
                                                            {RecipeDifficulty[key]}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.difficulty && props.touched.difficulty
                                                        ? props.errors.difficulty
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    {/* 8th row - ingredient search */}
                                    <Grid item xs={12}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb8')}>
                                                <Autocomplete
                                                    multiple
                                                    getOptionLabel={(option) => `${option.nameEn} (${option.nameDe})`}
                                                    getOptionSelected={(option, value) => {
                                                        return option.id === value.id;
                                                    }}
                                                    onChange={(_, data: IngredientData[]) => {
                                                        props.setFieldValue(formField.ingredientId.name, data);
                                                    }}
                                                    options={ingredients || []}
                                                    value={
                                                        genericHelpers.isNullOrUndefined(props.values.ingredientIds)
                                                            ? []
                                                            : props.values.ingredientIds
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={formField.ingredientId.label}
                                                            name={formField.ingredientId.name}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <div className={styles.bottomNavigation}>
                                    <Button
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        onClick={nextStep}
                                        disabled={!selection}
                                    >
                                        {en.next}
                                    </Button>
                                    <Button
                                        type="submit"
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        disabled={props.values == formInitials}
                                    >
                                        {en.save_button_label}
                                    </Button>
                                    <Button
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        onClick={handlePrevStep}
                                        variant={'outlined'}
                                    >
                                        {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Paper>
        </div>
    );
};

const mapDispatchToProps = {
    addRecipeSelectionValueTags: selectionActions.addValueTag,
    updateRecipeSelectionValueTags: selectionActions.updateValueTag,
    getRecipeSelectionValueTags: selectionActions.getValueTag,
    getCookingMethods: cookingMethodActions.getCookingMethods,
    getGrillModels: recipeAction.getGrillModels,
    getIngredients: ingredientsAction.getIngredients,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(ValueTagForm);
