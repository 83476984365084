import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import en from '../../../../assets/language/en.json';
import ImageUpload from '../imageUpload';
import Cropper, { Area } from 'react-easy-crop';
import { Button, Dialog } from '@material-ui/core';
import getCroppedImg from './cropImage';
import { alertActions } from '../../actions/alert.actions';
import { uniqueId } from 'lodash';

type OwnProps = {
    fileValue: string | null;
    label: string;
    onChange: (file: File) => void;
    aspectRatio: number;
    inputFieldName?: string;
};

export default function ImageUploadCropper(props: OwnProps) {
    const { fileValue, label, onChange, inputFieldName, aspectRatio } = props;

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);
    const [rawImage, setRawImage] = useState<string>(null);
    const [cropOpen, setCropOpen] = useState(false);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(rawImage, croppedAreaPixels);

            // Convert the image url to a file
            const response = await fetch(croppedImage);
            const blob = await response.blob();
            const file = new File([blob], `${uniqueId('upload-')}.png`, { type: 'image/png' });

            onChange(file);
            setCropOpen(false);
        } catch (e) {
            alertActions.error('Error cropping image, please refresh the page and try again');
        }
    }, [croppedAreaPixels]);

    return (
        <>
            <ImageUpload
                fileValue={fileValue}
                label={label}
                fileUrlCallback={(fileUrl) => {
                    setRawImage(fileUrl);
                }}
                onChange={(file) => {
                    onChange(file);
                    if (file) {
                        setCropOpen(true);
                    }
                }}
                inputFieldName={inputFieldName}
            />
            <Dialog
                key="crop-dialog"
                className={styles.dialog}
                maxWidth="lg"
                fullWidth
                open={cropOpen}
                onClose={() => {
                    setCropOpen(false);
                }}
            >
                <div className={styles.cropWrapper}>
                    <Cropper
                        image={rawImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <Button className={styles.saveButton} onClick={showCroppedImage}>
                    Save
                </Button>
            </Dialog>
        </>
    );
}
