import { Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { ChangeEvent, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import CustomTableToolbar from '../../../../shared/components/partials/filterDuplicates';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { filterParams } from '../../../../shared/helpers/filter.params';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { categoryTagsAction } from '../../../categoryTags/category.tags.actions';
import { ICategoryTagsData } from '../../../categoryTags/category.tags.interfaces';
import { cookingMethodActions } from '../../../cookingMethods/cooking.method.actions';
import { filterAction } from '../../../filters/filter.actions';
import { recipeDataTable } from '../../../helpers/recipe.datatables';
import ImportExcel from '../../../import/pages';
import { ingredientsAction } from '../../../ingredients/ingredients.actions';
import { IngredientData } from '../../../ingredients/ingredients.interfaces';
import { recipeAction } from '../../recipe.actions';
import { recipeConstants } from '../../recipe.constants';
import { IGrillModel, IRecipeData, IRecipeProps } from '../../recipe.interfaces';
import styles from './index.module.scss';

type OwnProps = IRecipeProps & ReturnType<typeof mapStateToProps>;

const Recipe = (props: PropsWithChildren<OwnProps>): ReactElement<FunctionComponent<OwnProps>> => {
    const { history, loading, total, cookingMethods, categoryTags, ingredients, filters } = props;
    const [grills, setGrills] = useState<Array<IGrillModel>>([]);
    const [recipes, setRecipes] = useState<IRecipeData[]>([]);
    const [showDuplicates, setShowDuplicates] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<Array<number>>([]);
    const [categoryTagOpen, setCategoryTagOpen] = useState<boolean>(false);
    const [selectedCategoryTags, setSelectedCategoryTags] = useState<ICategoryTagsData[]>([]);
    const [ingredientOpen, setIngredientOpen] = useState<boolean>(false);
    const [selectedIngredients, setSelectedIngredients] = useState<IngredientData[]>([]);
    const [proRecipeFilter, setProRecipeFilter] = useState<boolean>(false);
    const [adminRecipeFilter, setAdminRecipeFilter] = useState<boolean>(false);

    const applyFilterDuplicatesFilter = () => {
        setShowDuplicates(!showDuplicates);
    };

    useEffect(() => {
        props.getRecipes(globalConstants.DEFAULT_PAGINATION).then((recipeResponse) => {
            setRecipes(recipeResponse.recipes);
        });
        props.getCookingMethods(globalConstants.DEFAULT_PAGINATION);
        props.getGrills().then((response) => {
            setGrills(response.grillModels);
        });
        props.getCategoryTags(globalConstants.DEFAULT_PAGINATION);
        props.getIngredients(globalConstants.DEFAULT_PAGINATION);
        props.getFilters(globalConstants.PAGINATION_NO_LIMIT);
    }, []);

    useEffect(() => {
        if (showDuplicates) {
            props
                .getRecipes(globalConstants.DEFAULT_PAGINATION, undefined, [filterParams.duplicateFilter])
                .then(({ recipes }) => {
                    recipes.sort((r1, r2) => {
                        const nameComp = r1.nameEn.localeCompare(r2.nameEn);
                        if (nameComp === 0) {
                            return r2.createdAt > r1.createdAt ? 1 : r2.createdAt < r1.createdAt ? -1 : 0;
                        }
                        return nameComp;
                    });
                    const rowsToSelect = [];
                    let lastChecked = '';
                    for (const [i, recipe] of recipes.entries()) {
                        if (lastChecked.toLowerCase() === recipe.nameEn.toLowerCase()) {
                            rowsToSelect.push(i);
                        }
                        lastChecked = recipe.nameEn;
                    }
                    setRecipes(recipes);
                    setSelectedRows(rowsToSelect);
                });
        } else {
            props.getRecipes(globalConstants.DEFAULT_PAGINATION).then((recipeResponse) => {
                setRecipes(recipeResponse.recipes);
                setSelectedRows([]);
            });
        }
    }, [showDuplicates]);

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const rowsToDeleteLength = rowsDeleted.data.length;

            rowsDeleted.data.map(async ({ index }) => {
                const row = { id: recipes[index].id };
                const completed = rowsToDeleteLength === index + 1;
                await props.deleteRecipe(row.id, completed);
                completed && (await _refreshRecipes(globalConstants.DEFAULT_PAGINATION, undefined, []));
            });
            setShowDuplicates(false);
            setSelectedRows([]);
        },
        /* eslint-disable react/display-name */
        customToolbar: () => (
            <CustomTableToolbar applyDuplicatesFilter={applyFilterDuplicatesFilter}></CustomTableToolbar>
        ),
        rowsSelected: selectedRows,
        selectToolbarPlacement: 'above',
    };

    const tableColumns: MUIDataTableColumn[] = [
        ...recipeDataTable.recipeColumns,
        {
            name: 'cookingMethods',
            label: en.cooking_method_label,
            options: {
                display: false,
                filter: true,
                customFilterListRender: (v) => {
                    const name = cookingMethods.find(({ id }) => id == v).nameEn;
                    return `Cooking Methods: ${name}`;
                },
                filterOptions: {
                    names: cookingMethods.map(({ id }) => id.toString()),
                    renderValue: (v) => cookingMethods.find(({ id }) => id === Number(v)).nameEn,
                },
            },
        },
        {
            name: 'grills',
            label: en.grill_models_label,
            options: {
                display: false,
                filter: true,
                customFilterListRender: (v) => {
                    const name = grills.find(({ id }) => id == v).name;
                    return `Grills: ${name}`;
                },
                filterOptions: {
                    names: grills.map(({ id }) => id.toString()),
                    renderValue: (v) => grills.find(({ id }) => id === Number(v)).name,
                },
            },
        },
        {
            name: 'filters',
            label: en.filter_label,
            options: {
                display: false,
                filter: true,
                filterType: 'dropdown',
                customFilterListRender: (v) => {
                    const name = filters?.find(({ id }) => id == Number(v))?.name_en;
                    return `Filter: ${name}`;
                },
                filterOptions: {
                    fullWidth: true,
                    names: filters.map(({ id }) => id.toString()),
                    renderValue: (v) => filters.find(({ id }) => id === Number(v)).name_en,
                },
            },
        },
        {
            name: 'categoryTags',
            label: en.category_tags_label,
            options: {
                display: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => {
                        const names = selectedCategoryTags
                            .filter(({ id }) => v.includes(id.toString()))
                            ?.map((v) => v.nameEn);
                        return names.map((name) => `Category Tag: ${name}`);
                    },
                },
                filterOptions: {
                    fullWidth: true,
                    display: renderCategoryTagAutocomplete,
                },
            },
        },
        {
            name: 'ingredients',
            label: en.ingredients_label,
            options: {
                display: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => {
                        const names = selectedIngredients
                            .filter(({ id }) => v.includes(id.toString()))
                            ?.map((v) => v.nameEn);
                        return names.map((name) => `Ingredient: ${name}`);
                    },
                },
                filterOptions: {
                    fullWidth: true,
                    display: renderIngredientAutocomplete,
                },
            },
        },
        {
            name: 'proRecipe',
            label: en.pro_recipe,
            options: {
                display: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => `Pro recipes: ${String(proRecipeFilter)}`,
                },
                filterOptions: {
                    display: renderProRecipeFilter,
                },
            },
        },
        {
            name: 'adminRecipe',
            label: en.admin_recipe,
            options: {
                display: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: (v) => `Admin recipes: ${String(adminRecipeFilter)}`,
                },
                filterOptions: {
                    display: renderAdminRecipeFilter,
                },
            },
        },
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            component={Link}
                            to={`${recipeConstants.RECIPE_ROUTES.EDIT_RECIPE}/${
                                recipeConstants.RECIPE_SUB_ROUTES.RECIPE
                            }/${recipes[tableMeta.rowIndex].id}`}
                            className={styles.editRecipe}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const _refreshRecipes = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters[],
    ): Promise<void> => {
        const recipes = (await props.getRecipes(pagination, searchText, filters)).recipes;
        setRecipes(recipes);
    };

    const addNewRecipe = (): void => {
        history.push(recipeConstants.RECIPE_ROUTES.ADD_RECIPE);
    };

    const handleCategoryTagAutoCompleteChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event) {
            return;
        }

        const inputValue = event.target.value;
        props.getCategoryTags(globalConstants.DEFAULT_PAGINATION, inputValue);
    };

    const handleIngredientAutoCompleteChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event) {
            return;
        }

        const inputValue = event.target.value;
        props.getIngredients(globalConstants.DEFAULT_PAGINATION, inputValue);
    };

    return (
        <div className={clsx(styles.recipe, 'mt16')}>
            <ButtonGroup className={clsx(styles.actionButtons, 'mb4')}>
                <Button className={clsx(styles.addRecipe, 'mr4')} onClick={addNewRecipe}>
                    {en.add_new_recipe_label}
                </Button>
                <ImportExcel refreshData={_refreshRecipes} />
            </ButtonGroup>
            <DynamicTable
                title={en.recipes_label}
                options={options}
                columns={tableColumns}
                data={recipes}
                total={total}
                loading={loading}
                refreshData={_refreshRecipes}
            />
        </div>
    );

    function renderCategoryTagAutocomplete(
        filterList: string[][],
        onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
        index: number,
        column: MUIDataTableColumn,
    ) {
        return (
            <Autocomplete
                open={categoryTagOpen}
                onOpen={() => {
                    setCategoryTagOpen(true);
                }}
                onClose={() => {
                    setCategoryTagOpen(false);
                }}
                getOptionLabel={(option: ICategoryTagsData) => option.nameEn || ''}
                getOptionSelected={(option, value) => option.id == value.id}
                options={categoryTags.filter((ct) => !filterList[index].includes(ct.id.toString()))}
                onChange={(_, value: ICategoryTagsData) => {
                    if (!value) return;
                    if (filterList[index].includes(value.id.toString())) return;

                    setSelectedCategoryTags((prev) => [...prev, value]);
                    onChange([...filterList[index], value.id.toString()], index, column);
                }}
                onInputChange={handleCategoryTagAutoCompleteChange}
                value={''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={en.category_tags_label}
                        name={en.category_tags_label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <Search />,
                            endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                        }}
                    />
                )}
            />
        );
    }

    function renderIngredientAutocomplete(
        filterList: string[][],
        onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
        index: number,
        column: MUIDataTableColumn,
    ) {
        return (
            <Autocomplete
                open={ingredientOpen}
                onOpen={() => {
                    setIngredientOpen(true);
                }}
                onClose={() => {
                    setIngredientOpen(false);
                }}
                getOptionLabel={(option: IngredientData) =>
                    option.nameEn ? `${option.nameEn || ''} (${option.nameDe || ''})` : ''
                }
                getOptionSelected={(option, value) => option.id == value.id}
                options={ingredients.filter((ingredient) => !filterList[index].includes(ingredient.id.toString()))}
                onChange={(_, value: IngredientData) => {
                    if (!value) return;
                    if (filterList[index].includes(value.id.toString())) return;

                    setSelectedIngredients((prev) => [...prev, value]);
                    onChange([...filterList[index], value.id.toString()], index, column);
                }}
                onInputChange={handleIngredientAutoCompleteChange}
                value={''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={en.ingredient_label}
                        name={en.ingredient_label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <Search />,
                            endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                        }}
                    />
                )}
            />
        );
    }

    function renderProRecipeFilter(
        filterList: string[][],
        onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
        index: number,
        column: MUIDataTableColumn,
    ) {
        return (
            <FormControlLabel
                name={'proRecipe'}
                value={proRecipeFilter}
                control={<Checkbox color="primary" />}
                label={en.pro_recipe}
                labelPlacement="end"
                onChange={() => {
                    onChange([String(!proRecipeFilter)], index, column);
                    return setProRecipeFilter((prev) => !prev);
                }}
                checked={proRecipeFilter}
            />
        );
    }

    function renderAdminRecipeFilter(
        filterList: string[][],
        onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
        index: number,
        column: MUIDataTableColumn,
    ) {
        return (
            <FormControlLabel
                name={'adminRecipe'}
                value={adminRecipeFilter}
                control={<Checkbox color="primary" />}
                label={en.admin_recipe}
                labelPlacement="end"
                onChange={() => {
                    onChange([String(!adminRecipeFilter)], index, column);
                    return setAdminRecipeFilter((prev) => !prev);
                }}
                checked={adminRecipeFilter}
            />
        );
    }
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
    recipes: state.recipe.recipes,
    total: state.recipe.total,
    loading: state.recipe.loading,
    cookingMethods: state.cookingMethods.cookingMethods,
    categoryTags: state.categoryTags.categoryTags,
    ingredients: state.ingredients.ingredients,
    filters: state.filters.filters,
});

const mapDispatchToProps = {
    getRecipes: recipeAction.getRecipes,
    deleteRecipe: recipeAction.deleteRecipe,
    getRecipeCookingMethods: recipeAction.getRecipeCookingMethods,
    getRecipeGrillModels: recipeAction.getRecipeGrillModels,
    getRecipeById: recipeAction.getRecipeById,
    getRecipeTutorials: recipeAction.getRecipeTutorials,
    getCookingMethods: cookingMethodActions.getCookingMethods,
    getGrills: recipeAction.getGrillModels,
    getCategoryTags: categoryTagsAction.getCategoryTags,
    getIngredients: ingredientsAction.getIngredients,
    getFilters: filterAction.getFilters,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Recipe);
