import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta } from 'mui-datatables';
import { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { troubleshotActions } from '../../troubleshoot.actions';
import styles from './index.module.scss';
import { troubleshootTables } from '../../../helpers/troubleshoot.datatables';
import { troubleshootEndpoints } from '../../troubleshoot.endpoints';

type OwnProps = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

const TroubleshootOverview = (props: PropsWithChildren<OwnProps>) => {
    const { deleteTroubleshoot, getTroubleshoots, troubleshoots, loading, total } = props;
    const tableColumns = [...troubleshootTables.troubleshootColumns];

    tableColumns.push({
        name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                return (
                    <Button
                        component={Link}
                        to={`${troubleshootEndpoints.ROUTES.EDIT_TROUBLESHOOT}/${
                            troubleshootEndpoints.SUB_ROUTES.INFO
                        }/${troubleshoots[tableMeta.rowIndex].id}`}
                        className={styles.editCampaign}
                    >
                        {en.edit_button_label}
                    </Button>
                );
            },
        },
    });

    const options = {
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const itemToRemove = rowsDeleted.data.map(({ index }) => index);
            const itemsToRemoveLength = itemToRemove.length;

            itemToRemove.map(async (itemIndex, index) => {
                const itemId = troubleshoots[itemIndex].id;
                const completed = itemsToRemoveLength === index + 1;

                await deleteTroubleshoot(itemId, completed);
                completed && (await _refreshtroubleshoots(globalConstants.DEFAULT_PAGINATION, null));
            });
        },
    };

    useEffect(() => {
        _refreshtroubleshoots();
    }, []);

    const _refreshtroubleshoots = async (
        pagination?: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await getTroubleshoots(pagination, searchText, filters);
    };

    return (
        <div className={clsx(styles.campaigns, 'mt16')}>
            <Button
                className={clsx(styles.addCampaign, 'mb4')}
                component={Link}
                to={troubleshootEndpoints.ROUTES.ADD_TROUBLESHOOT}
            >
                {'Add Troubleshoot'}
            </Button>
            <DynamicTable
                title={'Troubleshoots'}
                options={options}
                columns={tableColumns}
                data={troubleshoots}
                total={total}
                loading={loading}
                refreshData={_refreshtroubleshoots}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    troubleshoots: state.troubleshoot.troubleshoots,
    total: state.troubleshoot.total,
    loading: state.troubleshoot.loading,
});

const mapDispatchToProps = {
    getTroubleshoots: troubleshotActions.getTroubleshoots,
    deleteTroubleshoot: troubleshotActions.deleteTroubleshoot,
};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(TroubleshootOverview);
