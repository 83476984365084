import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    TextField,
} from '@material-ui/core';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import generalStyles from '../../../../../shared/styles/global.module.scss';
import styles from '../index.module.scss';

import { FilterList, Search } from '@material-ui/icons';
import clsx from 'clsx';
import en from '../../../../../../assets/language/en.json';
import { IFilters, IPagination } from '../../../../../shared/components/interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../../shared/constants/mui.datatables.constants';
import { recipeDataTable } from '../../../../helpers/recipe.datatables';
import { recipeAction } from '../../../recipe.actions';
import { IAddLinkedRecipeProps, IRecipeData, IRecipeResponse, LinkedRecipe } from '../../../recipe.interfaces';
import { filterAction } from '../../../../filters/filter.actions';
import { AppState } from '../../../../../shared/store';
import { withRouter } from 'react-router-dom';
import { IFilter } from '../../../../filters/filter.interfaces';

type Props = IAddLinkedRecipeProps & ReturnType<typeof mapStateToProps>;

const AddLinkedRecipe = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const { recipe } = props;

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [linkedRecipes, setLinkedRecipes] = useState<LinkedRecipe[]>(recipe.linkedRecipes || []);
    const [indexToEdit, setIndexToEdit] = useState<number>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    useEffect(() => {
        props.getFilters(globalConstants.PAGINATION_NO_LIMIT);
    }, []);

    const tableColumns = [
        ...recipeDataTable.linkedRecipeColumns,
        {
            name: 'quantityScalingFactor',
            label: 'Ingredient quantity scaling factor',
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: number, tableMeta: MUIDataTableMeta) {
                    return (
                        <FormControl className={styles.formControl}>
                            <TextField
                                label={'Scaling factor'}
                                name={'quantityScalingFactor'}
                                type="number"
                                onChange={(event) => {
                                    setLinkedRecipes((old) => {
                                        const newState = [...old];
                                        newState[tableMeta.rowIndex].quantityScalingFactor = +event.target.value;
                                        return newState;
                                    });
                                    setIsUpdating(true);
                                }}
                                value={Number(value)}
                                InputProps={{ inputProps: { min: 0.001, step: 0.001 } }}
                                variant={'outlined'}
                                required
                            />
                        </FormControl>
                    );
                },
            },
        },
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={generalStyles.formButton}
                            onClick={() => {
                                setIndexToEdit(tableMeta.rowIndex);
                                setDialogOpen(true);
                            }}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options: MUIDataTableOptions = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.STANDARD,
        pagination: false,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        onRowsDelete(rowsDeleted) {
            const rowsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            setLinkedRecipes((prev) => prev.filter((linkedRecipe, index) => !rowsToRemoveIndex.includes(index)));
            setIsUpdating(true);
        },
    };

    const saveLinkedRecipe = (linkedRecipe: LinkedRecipe) => {
        if (indexToEdit === null) {
            setLinkedRecipes((prev) => [...prev, linkedRecipe]);
        } else {
            setLinkedRecipes((prev) => {
                prev[indexToEdit] = linkedRecipe;
                return prev;
            });
        }
        setIsUpdating(true);
        setIndexToEdit(null);
        setDialogOpen(false);
    };

    const closeDialog = () => {
        setIndexToEdit(null);
        setDialogOpen(false);
    };

    const handleSubmit = (): void => {
        recipe.linkedRecipes = linkedRecipes;

        props.updateLinkedRecipes(linkedRecipes, recipe.id).then(() => {
            props.nextStep(recipe);
        });
    };

    return (
        <>
            <div className={clsx(styles.addIngredients, 'mt8')}>
                {dialogOpen ? (
                    <AddEditDialog
                        saveLinkedRecipe={saveLinkedRecipe}
                        closeDialog={closeDialog}
                        alreadySelectedIds={[...linkedRecipes.map((r) => r.id), recipe.id]}
                        filters={props.filters}
                        getRecipes={props.getRecipes}
                        linkedToEdit={linkedRecipes[indexToEdit]}
                    />
                ) : null}

                <div className={styles.bottomNavigation}>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'ml2', 'mb4')}
                        style={{ float: 'left' }}
                        onClick={() => setDialogOpen(true)}
                        variant={'outlined'}
                    >
                        {en.add_linked_recipe}
                    </Button>
                </div>

                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={en.linked_recipe}
                            data={linkedRecipes}
                            columns={tableColumns}
                            options={options}
                        />
                    </Grid>
                </Grid>

                <div className={styles.bottomNavigation}>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={() => props.nextStep(props.recipe)}
                        variant={'outlined'}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                    </Button>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={() => props.prevStep(props.recipe)}
                        variant={'outlined'}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                    </Button>
                    <Button
                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                        onClick={handleSubmit}
                        variant={'outlined'}
                        disabled={!isUpdating}
                    >
                        {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                    </Button>
                </div>
            </div>
        </>
    );
};

type DialogProps = {
    getRecipes: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<IRecipeResponse>;
    closeDialog: () => void;
    filters: IFilter[];
    alreadySelectedIds: number[];
    saveLinkedRecipe: (recipe: LinkedRecipe) => any;
    linkedToEdit?: LinkedRecipe;
};

function AddEditDialog(props: DialogProps) {
    const [searchText, setSearchText] = useState<string>(null);
    const [filterId, setFilterId] = useState<number>(null);
    const [recipes, setRecipes] = useState<IRecipeData[]>([]);
    const [linkedRecipe, setLinkedRecipe] = useState<LinkedRecipe>(props.linkedToEdit || null);

    useEffect(() => {
        props
            .getRecipes(
                globalConstants.SEARCH_PAGINATION,
                searchText,
                filterId
                    ? ([
                          {
                              filterParameterName: 'filters',
                              filterValue: filterId,
                          },
                      ] as any)
                    : undefined,
            )
            .then((response) => {
                setRecipes(response.recipes);
            });
    }, [searchText, filterId]);

    const getRecipeList = () =>
        recipes.filter((r) => !props.alreadySelectedIds.includes(r.id) || props.linkedToEdit?.id === r.id);

    return (
        <>
            <div className={styles.blurBackground} onClick={props.closeDialog} />
            <Card variant={'outlined'} className={styles.addIngredientDialog}>
                <CardContent>
                    <div className={styles.headerLabel}>Choose recipe</div>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            <TextField
                                className={styles.searchBarStyle}
                                variant="standard"
                                onChange={(event) => setSearchText(event.target.value)}
                                InputProps={{
                                    inputProps: { min: 0 },
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ paddingLeft: '1.5rem' }}>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Divider orientation="vertical" flexItem />
                            <TextField
                                className={styles.searchBarStyle}
                                placeholder={en.filter_label}
                                variant="standard"
                                onChange={(event) => setFilterId(+event.target.value)}
                                select
                                value={filterId}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ paddingRight: '1.5rem' }}>
                                            <FilterList />
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <MenuItem value={null}>All</MenuItem>
                                {props.filters
                                    ? props.filters.map((filter) => (
                                          <MenuItem key={filter.id} value={filter.id}>
                                              {filter.name_en}
                                          </MenuItem>
                                      ))
                                    : null}
                            </TextField>
                        </Grid>
                    </Grid>
                    <List className={styles.listStyle} disablePadding>
                        {getRecipeList().map((recipe, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Divider />
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <Radio
                                                checked={linkedRecipe?.id === recipe.id}
                                                onClick={() => {
                                                    setLinkedRecipe((prev) => {
                                                        if (prev?.id === recipe.id) {
                                                            return null;
                                                        }
                                                        return {
                                                            id: recipe.id,
                                                            nameEn: recipe.nameEn,
                                                            quantityScalingFactor: 1.0,
                                                        };
                                                    });
                                                }}
                                                classes={{
                                                    checked: styles.radioButton,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={index.toString()}
                                            primary={
                                                <>
                                                    {index + 1}
                                                    <span className="ml-4">{recipe.nameEn}</span>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                </React.Fragment>
                            );
                        })}
                    </List>
                    <div className={styles.bottomNavigation}>
                        <Button
                            className={clsx(styles.bottomNavigationButton, 'mr2', 'my-4')}
                            onClick={() => props.saveLinkedRecipe(linkedRecipe)}
                            disabled={!linkedRecipe}
                        >
                            {en.save_button_label}
                        </Button>
                        <Button
                            className={clsx(styles.bottomNavigationButton, 'mr2', 'my-4')}
                            onClick={props.closeDialog}
                        >
                            {en.back}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}

const mapDispatchToProps = {
    getRecipes: recipeAction.getRecipes,
    getFilters: filterAction.getFilters,
    updateLinkedRecipes: recipeAction.updateLinkedRecipes,
};

const mapStateToProps = (state: AppState) => ({
    filters: state.filters.filters,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddLinkedRecipe);
