import { uniqueId } from 'lodash';
// tagsEn: string;
// tagsDe: string;
// nameEn: string;
// nameDe: string;
// link: string;
const appPageFormModel = {
    formId: uniqueId('form-'),
    formField: {
        nameEn: {
            name: 'nameEn',
            label: 'Name (English)',
        },
        nameDe: {
            name: 'nameDe',
            label: 'Name (German)',
        },
        tagsEn: {
            name: 'tagsEn',
            label: 'Tags (English)',
        },
        tagsDe: {
            name: 'tagsDe',
            label: 'Tags (German)',
        },
        link: {
            name: 'link',
            label: 'Link',
        },
        isGrillRelated: {
            name: 'isGrillRelated',
            label: 'Is Grill Related',
        },
    },
};

const formModels = {
    appPageFormModel,
};

export default formModels;
