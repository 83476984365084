import React, { PropsWithChildren, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { FormGroup, FormControl, Input, InputLabel, Button } from '@material-ui/core';
import styles from './index.module.scss';
import { loginActions } from '../../login.actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IFormValues, IValidationSchema } from '../../login.interfaces';
import { AppState } from '../../../../shared/store';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { loginConstants } from '../../login.constants';

type OwnProps = DispatchPropsReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Register = (props: PropsWithChildren<OwnProps>) => {
    const initialValues: IFormValues = { email: '', password: '' };
    const [justRegistered, setJustRegistered] = useState(false);

    const validationSchema: ObjectSchema<IValidationSchema> = loginConstants.validationSchema;

    const handleSubmit = async (values: IFormValues) => {
        const result = await props.register(values);
        if (result) {
            setJustRegistered(true);
        }
    };

    return (
        <div className={styles.login}>
            <div className={styles.loginContainer}>
                <h1 className={styles.title}>Register to Otto Wilde CMS</h1>
                {justRegistered && (
                    <h3 className={styles.verifiedTitle}>Successfully registered, please check your emails.</h3>
                )}
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<IFormValues>) => (
                        <Form className={styles.loginForm}>
                            <FormGroup>
                                <FormControl className={clsx(styles.formControl, 'mb8')}>
                                    <InputLabel id="emailLabel" className={styles.formLabel}>
                                        Email
                                    </InputLabel>
                                    <Input
                                        className={styles.formInput}
                                        name="email"
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                    />
                                    <span className={clsx(styles.error_span, 'mt2')}>
                                        {props.errors.email && props.touched.email ? props.errors.email : ''}
                                    </span>
                                </FormControl>
                                <FormControl className={clsx(styles.formControl, 'mb8')}>
                                    <InputLabel id="passwordLabel" className={styles.formLabel}>
                                        Password
                                    </InputLabel>
                                    <Input
                                        className={styles.formInput}
                                        name="password"
                                        type="password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                    />
                                    <span className={clsx(styles.error_span, 'mt2')}>
                                        {props.errors.password && props.touched.password ? props.errors.password : ''}
                                    </span>
                                </FormControl>
                                <Button
                                    className={styles.submitBtn}
                                    type="submit"
                                    variant={'outlined'}
                                    color={'inherit'}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty) || justRegistered}
                                >
                                    Submit
                                </Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
                <Link className="mt-2" to={loginConstants.ROUTES.LOGIN}>
                    Already have an account? Login here
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
});

const mapDispatchToProps = {
    register: loginActions.register,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
