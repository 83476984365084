export const quizQuestionConstants = {
    QUIZ_QUESTION_ACTION_TYPES: {
        GET_QUIZ_QUESTIONS_REQUEST: 'GET_QUIZ_QUESTIONS_REQUEST',
        GET_QUIZ_QUESTIONS_SUCCESS: 'GET_QUIZ_QUESTIONS_SUCCESS',
        GET_QUIZ_QUESTIONS_FAILURE: 'GET_QUIZ_QUESTIONS_FAILURE',
        GET_QUIZ_ROUND_REQUEST: 'GET_QUIZ_QUESTION_ROUND_REQUEST',
        GET_QUIZ_ROUND_SUCCESS: 'GET_QUIZ_QUESTION_ROUND_SUCCESS',
        GET_QUIZ_ROUND_FAILURE: 'GET_QUIZ_QUESTION_ROUND_FAILURE',
    },
};
