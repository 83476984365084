import { ZodError, ZodType, z } from 'zod';

const numberOrNullSchema = z.union([z.coerce.number(), z.literal('null').transform(() => null)]);

const fileSchema = z.union([z.instanceof(File), z.string()]);

const colorHexSchema = z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/);

function zodFormikValidator(validator: ZodType) {
    return async (values: any) => {
        try {
            // Validate the form data against the schema
            const validationResult = await validator.parseAsync(values);
            return {};
        } catch (error) {
            if (error instanceof ZodError) {
                // Handle Zod error
                const finalError = {};
                error.errors.forEach((err) => {
                    const key = err.path.join('.');
                    finalError[key] = err.message;
                });
                return finalError;
            }
        }
    };
}

const localizationSchema = (stringSchema: z.ZodString) =>
    z.object({
        enEnTranslation: stringSchema,
        deDeTranslation: stringSchema,
    });

export type ILocalization = z.infer<ReturnType<typeof localizationSchema>>;

export const zodUtils = {
    numberOrNullSchema,
    fileSchema,
    colorHexSchema,
    zodFormikValidator,
    localizationSchema,
};
