import React from 'react';
import { Button, IconButton, MenuItem, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { userReportDataTables } from '../../helpers/user.report.datatables';
import { InternalUserReportDecision, IReportedUser, IUserReportsProps } from '../user.report.interfaces';
import { userReportActions } from '../user.report.actions';
import { globalConstants } from '../../../shared/constants/global.constants';
import DynamicTable from '../../../shared/components/generics/table/DynamicTable';
import { AppState } from '../../../shared/store';
import en from '../../../../assets/language/en.json';
import styles from './index.module.scss';
import { userReportConstants } from '../user.report.constants';
import { reviewActions } from '../../reviews/reviews.actions';
import { FiberManualRecord } from '@material-ui/icons';

type OwnProps = IUserReportsProps & ReturnType<typeof mapStateToProps>;

const UserReports = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { loading, history } = props;
    const { reviewId } = useParams() as any;
    const [reportedUsers, setReportedUsers] = useState<IReportedUser[]>([]);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        props.getReportedUsers(globalConstants.DEFAULT_PAGINATION).then((response) => {
            setReportedUsers(response.data.reportedUsers);
            setTotal(response.data.count);
        });
    }, []);

    const columns = [...userReportDataTables.reportedUserColumns];

    columns.splice(1, 0, {
        name: 'read',
        label: en.new,
        options: {
            sort: false,
            filter: false,
            customBodyRender(read: boolean, meta: MUIDataTableMeta) {
                return (
                    <IconButton
                        className={styles.newButton}
                        onClick={() => {
                            toggleReadStatus(meta.rowIndex);
                        }}
                    >
                        {read ? null : <FiberManualRecord style={{ fill: '#b82b2b', height: '12px' }} />}
                    </IconButton>
                );
            },
        },
    });

    const toggleReadStatus = (rowIndex: number) => {
        const newReadVal = !reportedUsers[rowIndex].read;
        setReportedUsers((old) => {
            const newState = [...old];
            newState[rowIndex].read = newReadVal;
            return newState;
        });
        props
            .updateReportedUser(reportedUsers[rowIndex].userId, newReadVal, reportedUsers[rowIndex].internalDecision)
            .catch(() => _refreshReports(globalConstants.DEFAULT_PAGINATION));
    };

    columns.push({
        name: 'reportsCount',
        label: en.user_reports,
        options: {
            filter: false,
            sort: false,
            customBodyRender: renderReportCountColumn,
        },
    });

    columns.push({
        name: 'internalDecision',
        label: en.internal_decision,
        options: {
            filter: false,
            sort: false,
            customBodyRender: renderReportInternalDecisionColumn,
        },
    });

    columns.push({
        name: 'blocked',
        label: en.block_label,
        options: {
            filter: false,
            sort: true,
            customBodyRender: renderBlockUserButton,
        },
    });

    const options: MUIDataTableOptions = {};

    const _refreshReports = async (pagination: any, searchText?: string): Promise<void> => {
        props.getReportedUsers(pagination, searchText).then((response) => {
            setReportedUsers(response.data.reportedUsers);
            setTotal(response.data.count);
        });
    };

    return (
        <div className={clsx(styles.userReports, 'mt16')}>
            <Paper>
                <DynamicTable
                    title={'Reports'}
                    options={options}
                    columns={columns}
                    data={reportedUsers}
                    total={total}
                    loading={loading}
                    refreshData={_refreshReports}
                />
            </Paper>
        </div>
    );

    function renderReportCountColumn(reportCount: number, meta: MUIDataTableMeta) {
        return (
            <div
                className={clsx(styles.wideColumn, styles.selectableColumn)}
                onClick={() => {
                    history.push(
                        `${userReportConstants.USER_REPORT_ROUTES.REPORTS_FOR_USER}/${
                            reportedUsers[meta.rowIndex].userId
                        }`,
                    );
                }}
            >
                {`${reportCount} ${reportCount == 1 ? 'report' : 'reports'}`}
                <p className={styles.reviewsText}>Details</p>
            </div>
        );
    }

    function renderReportInternalDecisionColumn(internalDecision: InternalUserReportDecision, meta: MUIDataTableMeta) {
        return (
            <TextField
                className={styles.formInput}
                label={'Internal Decision'}
                name={'internalDecision'}
                select
                type={'text'}
                onChange={(event) => {
                    const newInternalDecision = event.target.value as InternalUserReportDecision;
                    setReportedUsers((old) => {
                        const newState = [...old];
                        newState[meta.rowIndex].internalDecision = newInternalDecision;
                        return newState;
                    });
                    props
                        .updateReportedUser(
                            reportedUsers[meta.rowIndex].userId,
                            reportedUsers[meta.rowIndex].read,
                            newInternalDecision,
                        )
                        .catch(() => _refreshReports(globalConstants.DEFAULT_PAGINATION));
                }}
                value={reportedUsers[meta.rowIndex].internalDecision}
                variant={'outlined'}
            >
                <MenuItem className={clsx('py4')} key={'internalDecision'} value={'Internal Decision'} disabled>
                    {'Internal Decision'}
                </MenuItem>
                {Object.keys(InternalUserReportDecision).map((key) => (
                    <MenuItem key={key} value={InternalUserReportDecision[key]}>
                        {InternalUserReportDecision[key]}
                    </MenuItem>
                ))}
            </TextField>
        );
    }

    function renderBlockUserButton(blocked: boolean, meta: MUIDataTableMeta) {
        return (
            <Button
                onClick={() => {
                    props
                        .blockUser(reportedUsers[meta.rowIndex].userId, !reportedUsers[meta.rowIndex].blocked)
                        .then((data) => {
                            setReportedUsers((old) => {
                                const newState = [...old];
                                newState[meta.rowIndex].blocked = data.blocked;
                                return newState;
                            });
                        });
                }}
                className={styles.blockUser}
            >
                {reportedUsers[meta.rowIndex].blocked ? en.unblock_button_label : en.block_button_label}
            </Button>
        );
    }
};

const mapStateToProps = (state: AppState) => ({
    loading: state.userReports.loading,
});

const mapDispatchToProps = {
    getReportedUsers: userReportActions.getReportedUsers,
    updateReportedUser: userReportActions.updateReportedUser,
    blockUser: reviewActions.blockUser,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(UserReports);
