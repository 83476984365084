import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
    IAddOrEditCategoryTagType,
    ICategoryTagTypeForm,
    ICategoryTagTypeFormComponent,
} from '../../category.tag.types.interfaces';
import formModels from './formModel/category.tag.type.form.model';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import {
    FormControl,
    FormGroup,
    FormHelperText,
    Input,
    InputLabel,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { categoryTagTypesActions } from '../../category.tag.types.actions';
import styles from './index.module.scss';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import clsx from 'clsx';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { categoryTagTypesEndpoints } from '../../../../modules/categoryTagTypes/category.tag.types.endpoints';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type CategoryTagTypeProps = ICategoryTagTypeFormComponent & typeof formModels.categoryTagTypeFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditCategoryTagType) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditCategoryTagType = (props: PropsWithChildren<CategoryTagTypeProps>): JSX.Element => {
    const id = useIdParam();
    const [open, setOpen] = useState<boolean>(id && id == props.categoryTagTypeData?.id ? true : false);

    const {
        formField: { name_en, name_de },
        categoryTagTypeData,
        history,
    } = props;

    const formInitials = categoryTagTypeData ? categoryTagTypeData : formInitialValues.categoryTagType;

    const handleClickOpen = () => {
        setOpen(true);
        categoryTagTypeData &&
            history.push(
                `${categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES}/${categoryTagTypeData.id}`,
            );
    };
    const handleClose = () => {
        setOpen(false);
        history.push(`${categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES}`);
    };

    const handleSubmit = (values: ICategoryTagTypeForm): void => {
        categoryTagTypeData ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: ICategoryTagTypeForm): void => {
        props.addCategoryTagType(values).then(() => {
            props.refreshData();
            handleClose();
        });
    };
    const handleUpdate = (values: ICategoryTagTypeForm): void => {
        const categoryTagTypeToUpdate: ICategoryTagTypeForm = {
            name_en: values.name_en,
            name_de: values.name_de,
        };
        props.updateCategoryTagType(categoryTagTypeToUpdate, values.id).then(() => {
            props.refreshData();
            handleClose();
        });
    };

    return (
        <div className={styles.categoryTagType}>
            {categoryTagTypeData ? (
                <Button className={styles.editCategoryTagType} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addCategoryTagType, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_category_tag_type}
                </Button>
            )}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {categoryTagTypeData ? en.edit_category_tag_type : en.add_new_category_tag_type}
                </DialogTitle>
                <Formik
                    initialValues={formInitials}
                    onSubmit={(values: ICategoryTagTypeForm, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.categoryTagTypeValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<ICategoryTagTypeForm>) => (
                        <Form className={styles.addCategoryTagTypeForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={name_en.name}
                                            label={name_en.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_en}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_en && props.touched.name_en ? props.errors.name_en : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={name_de.name}
                                            label={name_de.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_de}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_de && props.touched.name_de ? props.errors.name_de : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveCategoryTagType, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    addCategoryTagType: categoryTagTypesActions.addCategoryTagType,
    updateCategoryTagType: categoryTagTypesActions.updateCategoryTagType,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditCategoryTagType);
