import React, { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import formModels from './formModel/form.model';
import { tutorialActions } from '../../tutorial.actions';
import { IAddOrEditTutorial, ITutorialData } from '../../tutorial.interfaces';
import TutorialStepper from './stepper';
import { useParams } from 'react-router-dom';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type TutorialProps = typeof formModels.tutorialFormModel & IAddOrEditTutorial;

const AddOrEditTutorial = (props: PropsWithChildren<TutorialProps>): JSX.Element => {
    const [tutorialToUpdate, setTutorialToUpdate] = useState<ITutorialData>(null);

    const id = useIdParam();

    useEffect(() => {
        async function getTutorial() {
            const tutorialToUpdate = await props.getTutorialById(id);
            setTutorialToUpdate(tutorialToUpdate);
        }

        id && getTutorial();
    }, []);

    return <TutorialStepper key={tutorialToUpdate} tutorialData={tutorialToUpdate} />;
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    getTutorialById: tutorialActions.getTutorialById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditTutorial);
