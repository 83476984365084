export const referralOrderConstants = {
    REFERRAL_ORDER_ROUTES: {
        REFERRAL_ORDERS: `/referral-order`,
    },
    REFERRAL_ORDER_ACTION_TYPES: {
        GET_REFERRAL_ORDERS_REQUEST: 'GET_REFERRAL_ORDERS_REQUEST',
        GET_REFERRAL_ORDERS_SUCCESS: 'GET_REFERRAL_ORDERS_SUCCESS',
        GET_REFERRAL_ORDERS_FAILURE: 'GET_REFERRAL_ORDERS_FAILURE',
        PATCH_REFERRAL_ORDER_REQUEST: 'PATCH_REFERRAL_ORDER_REQUEST',
        PATCH_REFERRAL_ORDER_SUCCESS: 'PATCH_REFERRAL_ORDER_SUCCESS',
        PATCH_REFERRAL_ORDER_FAILURE: 'PATCH_REFERRAL_ORDER_FAILURE',
        POST_VOUCHERS_FILE_REQUEST: 'POST_VOUCHERS_FILE_REQUEST',
        POST_VOUCHERS_FILE_SUCCESS: 'POST_VOUCHERS_FILE_SUCCESS',
        POST_VOUCHERS_FILE_FAILURE: 'POST_VOUCHERS_FILE_FAILURE',
        GET_UNUSED_VOUCHERS_COUNT_REQUEST: 'GET_UNUSED_VOUCHERS_COUNT_REQUEST',
        GET_UNUSED_VOUCHERS_COUNT_SUCCESS: 'GET_UNUSED_VOUCHERS_COUNT_SUCCESS',
        GET_UNUSED_VOUCHERS_COUNT_FAILURE: 'GET_UNUSED_VOUCHERS_COUNT_FAILURE',
    },
};
