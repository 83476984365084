import { size } from 'lodash';

export const modifyFiles = (existingFiles: any, file: File) => {
    let fileToUpload = {};
    const id = size(existingFiles) + 1;
    fileToUpload = {
        ...fileToUpload,
        [id]: {
            id,
            file: file,
            progress: 0,
        },
    };

    return fileToUpload;
};
