import * as Yup from 'yup';
import formModels from './category.tag.type.form.model';
import { ObjectSchema } from 'yup';
import { ICategoryTagTypeForm } from '../../../category.tag.types.interfaces';

const {
    formField: { name_en, name_de },
} = formModels.categoryTagTypeFormModel;

const categoryTagTypeValidationSchema: ObjectSchema<ICategoryTagTypeForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${name_en.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${name_de.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    categoryTagTypeValidationSchema,
};

export default validationSchemas;
