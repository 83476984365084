export const quizQuestionEndpoints = {
    QUIZ_QUESTION_ROUTES: {
        QUIZ_QUESTIONS: '/user-engagement/quiz-questions',
        ADD_QUIZ_QUESTION: '/user-engagement/quiz-question/add',
        EDIT_QUIZ_QUESTION: '/user-engagement/quiz-question',
        QUIZ_ROUNDS: '/user-engagement/quiz-rounds',
        ADD_QUIZ_ROUND: '/user-engagement/quiz-round/add',
        EDIT_QUIZ_ROUND: '/user-engagement/quiz-round',
    },
    GET_QUIZ_QUESTIONS: {
        url: process.env.REACT_APP_SERVER_URL + '/quiz-questions',
    },
    GET_QUIZ_QUESTION: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-questions/${id}`,
    },
    ADD_QUIZ_QUESTION: {
        url: process.env.REACT_APP_SERVER_URL + '/quiz-questions',
    },
    UPDATE_QUIZ_QUESTION: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-questions/${id}`,
    },
    DELETE_QUIZ_QUESTION: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-questions/${id}`,
    },
    GET_QUIZ_ROUNDS: {
        url: process.env.REACT_APP_SERVER_URL + '/quiz-rounds',
    },
    GET_QUIZ_ROUND: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-rounds/${id}`,
    },
    ADD_QUIZ_ROUND: {
        url: process.env.REACT_APP_SERVER_URL + '/quiz-rounds',
    },
    UPDATE_QUIZ_ROUND: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-rounds/${id}`,
    },
    DELETE_QUIZ_ROUND: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/quiz-rounds/${id}`,
    },
};
