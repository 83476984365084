import { IReviewActions, IReviewState } from './reviews.interfaces';
import { reviewConstants } from './reviews.constants';

const initialState: IReviewState = {
    reviews: [],
    total: 0,
    loading: false,
    noUnreadReviews: 0,
};

const reviewReducer = (state = initialState, action: IReviewActions): IReviewState => {
    switch (action.type) {
        case reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.COUNT_UNREAD_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.ADD_BLACKLIST_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.GET_BLACKLIST_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.DELETE_BLACKLIST_REQUEST:
        case reviewConstants.REVIEW_ACTION_TYPES.UPDATE_BLACKLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_SUCCESS:
            return {
                ...state,
                reviews: action.reviewData.reviews,
                total: action.reviewData.count,
                loading: false,
            };
        case reviewConstants.REVIEW_ACTION_TYPES.COUNT_UNREAD_SUCCESS:
            return {
                ...state,
                noUnreadReviews: action.noUnreadReviews,
                loading: false,
            };
        case reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_SUCCESS:
        case reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.COUNT_UNREAD_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.ADD_BLACKLIST_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.GET_BLACKLIST_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.DELETE_BLACKLIST_FAILURE:
        case reviewConstants.REVIEW_ACTION_TYPES.UPDATE_BLACKLIST_FAILURE:
            return {
                loading: false,
            };
        default:
            return state;
    }
};

export { reviewReducer };
