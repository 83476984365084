import { ICookingMethodActions, ICookingMethodState } from './cooking.methods.interfaces';
import { cookingMethodsConstants } from './cooking.methods.contants';

const initialState: ICookingMethodState = { cookingMethods: [], cookingMethod: null, loading: false };

const cookingMethodReducer = (state = initialState, action: ICookingMethodActions): ICookingMethodState => {
    switch (action.type) {
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_SUCCESS:
            return {
                ...state,
                cookingMethods: action.cookingMethodsData.cookingMethods,
                total: action.cookingMethodsData.total,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.GET_COOKING_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_SUCCESS:
            return {
                ...state,
                cookingMethod: action.cookingMethod,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.ADD_COOKING_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_SUCCESS:
            return {
                ...state,
                cookingMethod: action.cookingMethod,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.UPDATE_COOKING_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case cookingMethodsConstants.COOKING_METHODS_ACTION_TYPES.DELETE_COOKING_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { cookingMethodReducer };
