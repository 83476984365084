import React, { useState } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import styles from './index.module.scss';
import { Checkbox } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

type Props = {
    data: any;
    columns: MUIDataTableColumnDef[];
    options?: MUIDataTableOptions;
    title: string | React.ReactNode;
    /**
     * Function to handle drag and drop behavior
     * @param result Drop result (containing row destination/index)
     */
    onDragEnd: (result: any) => void;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgrey' : '',
    color: isDragging ? 'transparent' : '',
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightgrey' : '',
});

export default function DragDropTable(props: Props) {
    const [selectedRow, setSelectedRow] = useState<number[]>([]);

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'sortIcon',
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender(value, tableMeta, updateValue) {
                    return <Menu style={{ color: 'grey' }} />;
                },
            },
        },
        {
            name: '',
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Checkbox
                            color="primary"
                            checked={selectedRow.length == 1 && selectedRow[0] === tableMeta.rowIndex}
                            onChange={(event, checked) => {
                                if (checked) {
                                    setSelectedRow([tableMeta.rowIndex]);
                                } else {
                                    setSelectedRow([]);
                                }
                            }}
                        />
                    );
                },
            },
        },
        ...props.columns,
    ];

    return (
        <DragDropContext
            onDragEnd={(result) => {
                setSelectedRow([]);
                props.onDragEnd(result);
            }}
        >
            <Droppable droppableId="droppable-list">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        <MUIDataTable
                            title={props.title}
                            data={props.data}
                            columns={columns}
                            options={{
                                ...props.options,
                                onRowsDelete(rowsDeleted, newTableData) {
                                    setSelectedRow([]);
                                    props.options?.onRowsDelete(rowsDeleted, newTableData);
                                },
                                rowsSelected: selectedRow,
                                selectableRows: 'single',
                                customRowRender(data, dataIndex, rowIndex) {
                                    return (
                                        <>
                                            <Draggable
                                                key={rowIndex}
                                                draggableId={dataIndex.toString()}
                                                index={rowIndex}
                                            >
                                                {(provided, snapshot) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={
                                                            selectedRow.length == 1 && selectedRow[0] === rowIndex
                                                                ? clsx(
                                                                      styles.customTableRow,
                                                                      styles.customTableRowSelected,
                                                                  )
                                                                : clsx(styles.customTableRow)
                                                        }
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                        )}
                                                    >
                                                        {data.map((columnData, index) => (
                                                            <td key={index} className={styles.customTableData}>
                                                                {columnData}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                )}
                                            </Draggable>
                                        </>
                                    );
                                },
                            }}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
