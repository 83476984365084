import React from 'react';
import styles from './footer.module.scss';

const Footer: React.FC = () => {
    return (
        <div className={styles.footer}>
            Copyright &copy; {new Date().getFullYear()} <strong> Otto Wilde Grillers GmbH</strong>
        </div>
    );
};

export default Footer;
