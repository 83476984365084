import { Button, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren, ReactElement, useState } from 'react';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IRowsDeleted } from '../../../../shared/components/interfaces';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { ambassadorDataTables } from '../../../helpers/ambassador.datatables';
import EditFeedToggle from './EditFeedToggle';
import { useInfluencerFeedData } from './hooks';
import styles from './index.module.scss';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { MUIDataTableMeta } from 'mui-datatables';
import AddEditInfluencerFeedEntry from './AddEditInfluencerFeedEntry';

type Props = {
    // Props...
};

function useAddEditDialogState() {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [editingId, setEditingId] = useState<number | null>(null);

    function addEntry() {
        setEditingId(null);
        setIsDialogOpen(true);
    }

    function editEntry(entryId: number) {
        setEditingId(entryId);
        setIsDialogOpen(true);
    }

    function closeDialog() {
        setIsDialogOpen(false);
        setEditingId(null);
    }

    return { isDialogOpen, editingId, addEntry, editEntry, closeDialog };
}

export default function InfluencerFeed({}: PropsWithChildren<Props>): ReactElement {
    const id = useIdParam();

    const { getFeedQuery, deleteFeedEntryMutation } = useInfluencerFeedData(id);
    const { isDialogOpen, editingId, addEntry, editEntry, closeDialog } = useAddEditDialogState();

    const tableColumns = [
        ...ambassadorDataTables.influencerFeedColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            onClick={() => {
                                editEntry(getFeedQuery.data[tableMeta.rowIndex].id);
                            }}
                            className={styles.editAmbassador}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const toRemoveIndices = rowsDeleted.data.map(({ index }) => index);
            toRemoveIndices.map(async (entityIndex) => {
                const entityId = getFeedQuery.data[entityIndex].id;
                deleteFeedEntryMutation.mutate(entityId);
            });
        },
    };

    return (
        <Paper className={clsx(styles.ambassadorPaper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.ambassador, styles.influencerFeed, 'mb2')}>
                <EditFeedToggle influencerId={id} />
                <Typography variant="h3" gutterBottom className={clsx(styles.typography, 'mt2')}>
                    {"Influencer's Feed"}
                </Typography>
                <Button
                    onClick={() => {
                        addEntry();
                    }}
                    className={clsx(styles.editAmbassador, styles.addButton)}
                >
                    {'Add New Feed Entry'}
                </Button>
            </div>
            <DynamicTable
                title={en.ambassadors_label}
                options={options}
                columns={tableColumns}
                data={getFeedQuery.data || []}
                total={getFeedQuery.data?.length || 0}
                loading={getFeedQuery.isLoading}
                refreshData={getFeedQuery.refetch}
            />
            <AddEditInfluencerFeedEntry
                closeDialog={closeDialog}
                editingEntry={editingId ? getFeedQuery.data?.find((entry) => entry.id === editingId) : null}
                isOpen={isDialogOpen}
                influencerId={id}
            />
        </Paper>
    );
}
