import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IAddOrEditUnit, IUnit, IUnitFormComponent, UnitConversionRate } from '../../unit.interfaces';
import formModels from './formModel/units.form.model';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/units.form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import {
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { unitActions } from '../../unit.actions';
import styles from './index.module.scss';
import { compose } from 'recompose';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import clsx from 'clsx';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { unitConstants } from '../../../../modules/unitManagement/unit.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type UnitProps = IUnitFormComponent & typeof formModels.unitFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditUnit) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditUnit = (props: PropsWithChildren<UnitProps>): JSX.Element => {
    const {
        formField: { name, nameDe, type },
        unitsData,
        history,
    } = props;
    const id = useIdParam();

    const [open, setOpen] = useState<boolean>(!!(id && id == unitsData?.id));

    const formValues = unitsData ? unitsData : formInitialValues.unit;

    const handleClickOpen = () => {
        setOpen(true);
        unitsData && history.push(`${unitConstants.UNIT_ROUTES.UNITS}/${unitsData.id}`);
    };

    const handleClose = () => {
        setOpen(false);
        history.push(`${unitConstants.UNIT_ROUTES.UNITS}`);
    };

    const handleSubmit = (values: IUnit): void => {
        unitsData ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: IUnit): void => {
        props.addUnit(values).then(() => {
            props.refreshData();
            handleClose();
        });
    };

    const handleUpdate = (values: IUnit): void => {
        const unitToUpdate: IUnit = {
            name: values.name,
            nameDe: values.nameDe,
            type: values.type,
        };
        props.updateUnit(unitToUpdate, values.id).then(() => {
            props.refreshData();
            handleClose();
        });
    };

    return (
        <div className={styles.units}>
            {unitsData ? (
                <Button className={styles.editUnit} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addUnits, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_unit}
                </Button>
            )}

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {unitsData ? en.edit_unit : en.add_new_unit}
                </DialogTitle>
                <Formik
                    initialValues={formValues}
                    onSubmit={(values: IUnit, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.unitValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IUnit>) => (
                        <Form className={styles.addUnitForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={name.name}
                                            label={name.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name && props.touched.name ? props.errors.name : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameDe.name}
                                            label={nameDe.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.nameDe}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.nameDe && props.touched.nameDe ? props.errors.nameDe : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <InputLabel id={type.label}>{type.label}</InputLabel>
                                        <Select
                                            name={type.name}
                                            value={props.values.type}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        >
                                            <MenuItem disabled value="">
                                                <em>{type.label}</em>
                                            </MenuItem>
                                            <MenuItem value={unitConstants.TYPE.NONE.value}>
                                                {unitConstants.TYPE.NONE.label}
                                            </MenuItem>
                                            <MenuItem value={unitConstants.TYPE.IMPERIAL.value}>
                                                {unitConstants.TYPE.IMPERIAL.label}
                                            </MenuItem>
                                            <MenuItem value={unitConstants.TYPE.METRIC.value}>
                                                {unitConstants.TYPE.METRIC.label}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveUnit, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    addUnit: unitActions.addUnit,
    updateUnit: unitActions.updateUnit,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditUnit);
