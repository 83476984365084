import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IAddOrEditCategoryTag, ICategoryTagForm, ICategoryTagFormComponent } from '../../category.tags.interfaces';
import formModels from './formModel/category.tag.form.model';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import {
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { categoryTagsAction } from '../../category.tags.actions';
import styles from './index.module.scss';
import { compose } from 'recompose';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import clsx from 'clsx';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { categoryTagsEndpoints } from '../../../../modules/categoryTags/category.tags.endpoints';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type CategoryTagProps = ICategoryTagFormComponent & typeof formModels.categoryTagFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditCategoryTag) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditCategoryTag = (props: PropsWithChildren<CategoryTagProps>): JSX.Element => {
    const {
        formField: { nameEn, nameDe, tagTypeId },
        categoryTagsData,
        categoryTagTypes,
        history,
    } = props;

    const id = useIdParam();

    const [open, setOpen] = useState<boolean>(!!(id && id == categoryTagsData?.id));

    const formValues = categoryTagsData ? categoryTagsData : formInitialValues.categoryTag;

    const handleClickOpen = () => {
        setOpen(true);
        categoryTagsData &&
            history.push(`${categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS}/${categoryTagsData.id}`);
    };

    const handleClose = () => {
        setOpen(false);
        history.push(`${categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS}`);
    };

    const handleSubmit = (values: ICategoryTagForm): void => {
        categoryTagsData ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: ICategoryTagForm): void => {
        props.addCategoryTag(values).then(() => {
            props.refreshData();
            handleClose();
        });
    };
    const handleUpdate = (values: ICategoryTagForm): void => {
        const categoryTagToUpdate: ICategoryTagForm = {
            name_en: values.name_en,
            name_de: values.name_de,
            tag_type_id: values.tag_type_id,
        };
        props.updateCategoryTag(categoryTagToUpdate, values.id).then(() => {
            props.refreshData();
            handleClose();
        });
    };
    return (
        <div className={styles.categoryTag}>
            {categoryTagsData ? (
                <Button className={styles.editCategoryTag} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addCategoryTag, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_category_tag}
                </Button>
            )}

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {categoryTagsData ? en.edit_category_tag : en.add_new_category_tag}
                </DialogTitle>
                <Formik
                    initialValues={formValues}
                    onSubmit={(values: ICategoryTagForm, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.categoryTagValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<ICategoryTagForm>) => (
                        <Form className={styles.addCategoryTagForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameEn.name}
                                            label={nameEn.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_en}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_en && props.touched.name_en ? props.errors.name_en : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameDe.name}
                                            label={nameDe.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name_de}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.name_de && props.touched.name_de ? props.errors.name_de : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <InputLabel id={tagTypeId.label}>{tagTypeId.label}</InputLabel>
                                        <Select
                                            name={tagTypeId.name}
                                            value={props.values.tag_type_id}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        >
                                            <MenuItem disabled value="">
                                                <em>{tagTypeId.label}</em>
                                            </MenuItem>
                                            {categoryTagTypes.map((categoryTagType) => (
                                                <MenuItem key={categoryTagType.id} value={categoryTagType.id}>
                                                    {categoryTagType.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveCategoryTag, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    addCategoryTag: categoryTagsAction.addCategoryTag,
    updateCategoryTag: categoryTagsAction.updateCategoryTag,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditCategoryTag);
