import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta } from 'mui-datatables';
import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { ambassadorDataTables } from '../../../helpers/ambassador.datatables';
import { influencerConstants } from '../../influencer.constants';
import { IInfluencerProps } from '../../influencer.interfaces';
import styles from './index.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../shared/store';
import { influencerThunks } from '../../influencer.thunks';

type OwnProps = IInfluencerProps;

function useInfluencerState() {
    const influencers = useAppSelector((state) => state.influencers.influencers);
    const loading = useAppSelector((state) => state.influencers.influencersLoading);
    const total = useAppSelector((state) => state.influencers.total);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(influencerThunks.fetchInfluencers({ pagination: globalConstants.DEFAULT_PAGINATION }));
    }, []);

    return { influencers, loading, dispatch, total };
}

const Ambassador = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history } = props;
    const { influencers, loading, dispatch, total } = useInfluencerState();

    const tableColumns = [
        ...ambassadorDataTables.ambassadorColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            component={Link}
                            to={`${influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER}/${
                                influencers[tableMeta.rowIndex].id
                            }`}
                            className={styles.editAmbassador}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const amabassadorsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const ambassadorsToRemoveLength = amabassadorsToRemoveIndex.length;

            amabassadorsToRemoveIndex.map(async (ambassadorIndex, index) => {
                const ambassadorId = influencers[ambassadorIndex].id;
                const completed = ambassadorsToRemoveLength === index + 1;

                await dispatch(influencerThunks.deleteInfluencer({ influencerId: ambassadorId, completed })).unwrap();
                completed && _refreshAmbassadors(globalConstants.DEFAULT_PAGINATION);
            });
        },
    };

    const _refreshAmbassadors = (pagination: IPagination, searchText?: string, filters?: IFilters) => {
        dispatch(influencerThunks.fetchInfluencers({ pagination, searchText, filters }));
    };

    const addNewAmbassador = (): void => {
        history.push(influencerConstants.INFLUENCER_ROUTES.ADD_INFLUENCER);
    };

    const getAmbassadorDataForEdit = async (rowIndex: number): Promise<void> => {
        const ambassadorToUpdate = influencers[rowIndex];
        const response = await dispatch(influencerThunks.getInfluencerById(ambassadorToUpdate.id)).unwrap();

        history.push(influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER, response);
    };

    return (
        <div className={clsx(styles.ambassador, 'mt16')}>
            <Button className={clsx(styles.addAmbassador, 'mb4')} onClick={addNewAmbassador}>
                {en.add_new_ambassador}
            </Button>
            <DynamicTable
                title={en.ambassadors_label}
                options={options}
                columns={tableColumns}
                data={influencers}
                total={total}
                loading={loading}
                refreshData={_refreshAmbassadors}
            />
        </div>
    );
};

export default compose(withStyles(muiStyles.customStyles), connect(null, null))(Ambassador);
