import * as Yup from 'yup';
import formModels from './equipment.form.model';
import { ObjectSchema } from 'yup';
import { IEquipment } from '../../../equipment.interfaces';

const {
    formField: { nameEn, nameDe },
} = formModels.equipmentFormModel;

const equipmentValidationSchema: ObjectSchema<IEquipment> = Yup.object()
    .defined()
    .shape({
        nameEn: Yup.string().required(`${nameEn.requiredErrorMsg}`).defined(),
        nameDe: Yup.string().required(`${nameDe.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    equipmentValidationSchema: equipmentValidationSchema,
};

export default validationSchemas;
