export {};
import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const unitFormModel = {
    formId: uniqueId('form-'),
    formField: {
        name: {
            name: 'name',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        nameDe: {
            name: 'nameDe',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        type: {
            name: 'type',
            label: en.type,
            requiredErrorMsg: en.type_required_error_msg,
        },
    },
};

const formModels = {
    unitFormModel,
};

export default formModels;
