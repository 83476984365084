import { createAsyncThunk } from '@reduxjs/toolkit';
import en from '../../../assets/language/en.json';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { IInfluencerForm } from './influencer.interfaces';
import { influencerService } from './influencer.service';

const fetchInfluencers = createAsyncThunk(
    'influencers/fetchInfluencers',
    async (
        {
            pagination,
            filters,
            searchText,
        }: {
            pagination: IPagination;
            searchText?: string;
            filters?: IFilters;
        },
        thunkApi,
    ) => {
        try {
            const response = await influencerService.getInfluencers(pagination, searchText, filters);

            return response;
        } catch (error) {
            if (error.response) {
                error = error.response.data;
            }
            thunkApi.dispatch(alertActions.error(error?.message));
            return thunkApi.rejectWithValue(error);
        }
    },
);

const addInfluencer = createAsyncThunk('influencers/addInfluencer', async (influencer: IInfluencerForm, thunkApi) => {
    try {
        const response = await influencerService.addInfluencer(influencer);
        thunkApi.dispatch(alertActions.success(en.ambassador_added_successfully));
        return response;
    } catch (error) {
        if (error.response) {
            error = error.response.data;
        }
        thunkApi.dispatch(alertActions.error(error?.message));
        return thunkApi.rejectWithValue(error);
    }
});

const updateInfluencer = createAsyncThunk(
    'influencers/updateInfluencer',
    async ({ influencer, influencerId }: { influencer: IInfluencerForm; influencerId: number }, thunkApi) => {
        try {
            const response = await influencerService.updateInfluencer(influencer, influencerId);
            thunkApi.dispatch(alertActions.success(en.ambassador_updated_successfully));
            return response;
        } catch (error) {
            thunkApi.dispatch(alertActions.error(error.response?.data?.message ?? error?.message));
            throw error;
        }
    },
);

const deleteInfluencer = createAsyncThunk(
    'influencers/deleteInfluencer',
    async ({ influencerId, completed }: { influencerId: number; completed: boolean }, thunkApi) => {
        try {
            const response = await influencerService.deleteInfluencer(influencerId);
            completed && thunkApi.dispatch(alertActions.success(en.ambassador_deleted_successfully));
            return response;
        } catch (error) {
            if (error.response) {
                error = error.response.data;
            }
            thunkApi.dispatch(alertActions.error(error?.message));
            return thunkApi.rejectWithValue(error);
        }
    },
);

const getInfluencerById = createAsyncThunk('influencers/getInfluencerById', async (influencerId: number, thunkApi) => {
    try {
        const response = await influencerService.getInfluencerById(influencerId);
        return response;
    } catch (error) {
        if (error.response) {
            error = error.response.data;
        }
        thunkApi.dispatch(alertActions.error(error?.message));
        return thunkApi.rejectWithValue(error);
    }
});

export const influencerThunks = {
    addInfluencer,
    updateInfluencer,
    deleteInfluencer,
    getInfluencerById,
    fetchInfluencers,
};
