import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import { IUnitActions, IUnitResponse, IUnit, UnitConversionRate } from './unit.interfaces';
import unitService from './unit.service';
import { unitConstants } from './unit.constants';
import en from '../../../assets/language/en.json';

function getUnits(): (dispatch: Dispatch) => Promise<IUnitResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return unitService.getUnits().then(
            (response: IUnitResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.GET_UNITS_REQUEST };
    }

    function success(response: IUnitResponse): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.GET_UNITS_SUCCESS,
            unitsData: response,
        };
    }

    function failure(error: string): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.GET_UNITS_FAILURE, error };
    }
}

const addUnit = (unitData: IUnit): ((dispatch: Dispatch) => Promise<IUnitResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return unitService.addUnit(unitData).then(
            (response: IUnitResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.unit_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_REQUEST };
    }

    function success(response: IUnitResponse): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_SUCCESS,
            unitData: response.data,
        };
    }

    function failure(error: string): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_FAILURE, error };
    }
};

const updateUnit = (unitData: IUnit, unitId: number): ((dispatch: Dispatch) => Promise<IUnitResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return unitService.updateUnit(unitData, unitId).then(
            (response: IUnitResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.unit_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_REQUEST };
    }

    function success(response: IUnitResponse): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_SUCCESS,
            unitData: response.data,
        };
    }

    function failure(error: string): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_FAILURE, error };
    }
};

const deleteUnit = (
    unitId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return unitService.deleteUnit(unitId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.unit_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_REQUEST };
    }

    function success(response: AxiosResponse): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_SUCCESS,
        };
    }

    function failure(error: string): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_FAILURE,
            error,
        };
    }
};

// function getFixedConversionRates(): Promise<UnitConversionRate[]> {
//     return unitService.getFixedConversionRates();
// }

function getFixedConversionRates(): (dispatch: Dispatch) => Promise<UnitConversionRate[] | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return unitService.getFixedConversionRates().then(
            (response) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_REQUEST };
    }

    function success(response: UnitConversionRate[]): IUnitActions {
        return {
            type: unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_SUCCESS,
            conversionRates: response,
        };
    }

    function failure(error: string): IUnitActions {
        return { type: unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_FAILURE, error };
    }
}

export const unitActions = {
    getUnits,
    addUnit,
    updateUnit,
    deleteUnit,
    getFixedConversionRates,
};
