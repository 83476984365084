import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropsWithChildren, ReactElement, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useAppDispatch } from '../../../shared/store';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { useAnalyticsQueryOptions } from '../analytics.hooks';
import { SetFilterPayload, analyticsActions } from '../analytics.slice';
import { AnalyticsFilterSection } from '../components/filter.section';
import { AnalyticsQuerySection } from '../components/query.section';
import styles from './index.module.scss';

type OwnProps = {
    // ...
};

const AnalyticsOverview = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const queryOptionsQuery = useAnalyticsQueryOptions();
    const dispatch = useAppDispatch();

    const globalFiterOptions = queryOptionsQuery.data?.globalFilters ?? {};
    const queryOptions = queryOptionsQuery.data?.queryOptions ?? {};
    const queryKeys = Object.keys(queryOptions).sort((a, b) => queryOptions[a].position - queryOptions[b].position);

    const setFilterOptions = useCallback(
        (filterPayload: SetFilterPayload) => void dispatch(analyticsActions.setGlobalSelectedFilters(filterPayload)),
        [dispatch],
    );

    return (
        <div className={clsx('mt16')}>
            <Paper className={clsx(styles.paper)}>
                <h1>Analytics - Reporting</h1>
                <div className={clsx(styles.area, styles.filterArea, styles.globalFilters)}>
                    <AnalyticsFilterSection
                        key="global"
                        label="Global Filters"
                        filterOptions={globalFiterOptions}
                        setFilterOption={setFilterOptions}
                    />
                </div>
                <div className={clsx(styles.area, styles.queryOptionsArea)}>
                    {queryKeys.map((queryKey, index) => (
                        <div
                            key={queryKey}
                            // className={clsx(styles.area, styles.resultArea)}
                        >
                            <AnalyticsQuerySection queryKey={queryKey} queryOption={queryOptions[queryKey]} />
                        </div>
                    ))}
                </div>
            </Paper>
        </div>
    );
};

export default compose(withStyles(muiStyles.customStyles), connect(null, null))(AnalyticsOverview);
