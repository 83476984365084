import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta } from 'mui-datatables';
import { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { quizQuestionTables } from '../../../helpers/quiz.question.datatables';
import { quizQuestionActions } from '../../quiz.questions.actions';
import { quizQuestionEndpoints } from '../../quiz.questions.endpoints';
import styles from './index.module.scss';
import moment from 'moment';

const mapStateToProps = (state: AppState) => ({
    quizRounds: state.quizQuestions.quizRounds,
    total: state.quizQuestions.totalQuizRounds,
    loading: state.quizQuestions.loading,
});

const mapDispatchToProps = {
    getQuizRounds: quizQuestionActions.getQuizRounds,
    deleteQuizRound: quizQuestionActions.deleteQuizRound,
};

type OwnProps = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

export const RoundOverview = compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)((props: PropsWithChildren<OwnProps>) => {
    const { deleteQuizRound, getQuizRounds, loading, quizRounds, total } = props;
    const tableColumns = [...quizQuestionTables.quizRoundColumns];

    tableColumns.push({
        name: 'endDate',
        label: 'End Date',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: never, tableMeta: MUIDataTableMeta) {
                const row = quizRounds[tableMeta.rowIndex];
                return (
                    <p className="mb-0">
                        {moment(row.startDate).add(row.questionsCount, 'weeks').calendar()}
                        <br />
                        {row.questionsCount} Questions in total
                    </p>
                );
            },
        },
    });

    tableColumns.push({
        name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                return (
                    <Button
                        component={Link}
                        to={`${quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_ROUND}/${
                            quizRounds[tableMeta.rowIndex].id
                        }`}
                        className={styles.editCampaign}
                    >
                        {en.edit_button_label}
                    </Button>
                );
            },
        },
    });

    const options = {
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const itemToRemove = rowsDeleted.data.map(({ index }) => index);
            const itemsToRemoveLength = itemToRemove.length;

            itemToRemove.map(async (itemIndex, index) => {
                const itemId = quizRounds[itemIndex].id;
                const completed = itemsToRemoveLength === index + 1;

                await deleteQuizRound(itemId, completed);
                completed && (await _refreshQuizRounds(globalConstants.DEFAULT_PAGINATION, null));
            });
        },
    };

    useEffect(() => {
        _refreshQuizRounds();
    }, []);

    const _refreshQuizRounds = async (
        pagination?: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await getQuizRounds(pagination, searchText, filters);
    };

    return (
        <>
            <Button
                className={clsx(styles.addCampaign, 'mb4')}
                component={Link}
                to={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_ROUND}
            >
                {'Add Quiz Round'}
            </Button>
            <DynamicTable
                title={'Quiz Rounds'}
                options={options}
                columns={tableColumns}
                data={quizRounds}
                total={total}
                loading={loading}
                refreshData={_refreshQuizRounds}
            />
        </>
    );
});
