import { Card, CardActionArea, CardActions, Button } from '@material-ui/core';
import React from 'react';
import ImportFile from '../../../modules/import/pages/import.file';
import { globalConstants } from '../../constants/global.constants';
import styles from './index.module.scss';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import en from '../../../../assets/language/en.json';

type OwnProps = {
    fileValue: string | null;
    label: string;
    onChange: (file: File) => void;
    inputFieldName?: string;
    fileUrlCallback?: (fileUrl: string) => void;
    uploadButtonLabel?: string;
};

export default function ImageUpload(props: OwnProps) {
    const { fileValue, label, onChange, inputFieldName, fileUrlCallback, uploadButtonLabel } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <Card className={styles.card}>
            <CardActionArea className={styles.actionArea}>
                <ImportFile
                    file={fileValue}
                    title={label}
                    style={styles.imgCard}
                    remove={() => onChange(null)}
                    fileUrlCallback={fileUrlCallback}
                />
            </CardActionArea>
            <CardActions>
                <input
                    accept="file/"
                    id={inputFieldName ?? `upload-img-${label}`}
                    type="file"
                    name={inputFieldName ?? `upload-img-${label}`}
                    className={styles.fileInput}
                    ref={inputRef}
                    onChange={(event) => {
                        onChange(event.currentTarget.files[globalConstants.KEYS.FIRST_FILE_INDEX]);
                    }}
                />
                <Button
                    className={styles.uploadImg}
                    onClick={() => inputRef.current.click()}
                    startIcon={<CloudUploadIcon />}
                >
                    {uploadButtonLabel || en.upload_image}
                </Button>
            </CardActions>
        </Card>
    );
}
