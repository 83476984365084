export const troubleshootEndpoints = {
    ROUTES: {
        TROUBLESHOOTS: '/remote-settings/troubleshoot',
        ADD_TROUBLESHOOT: '/remote-settings/troubleshoot/add',
        EDIT_TROUBLESHOOT: '/remote-settings/troubleshoot',
    },
    SUB_ROUTES: {
        INFO: 'info',
        PRIMARY_STEPS: 'primary-steps',
        STEPS: 'steps',
    },
    GET_TROUBLESHOOTS: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/troubleshoot',
    },
    GET_TROUBLESHOOT: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot/${id}`,
    },
    ADD_TROUBLESHOOT: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/troubleshoot',
    },
    UPDATE_TROUBLESHOOT: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot/${id}`,
    },
    DELETE_TROUBLESHOOT: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot/${id}`,
    },
    UPDATE_TROUBLESHOOT_PRIMARY_STEPS: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot-primary-steps/${id}`,
    },
    UPLOAD_TROUBLESHOOT_PRIMARY_STEP_IMAGE: {
        url: (id: number) =>
            `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot-primary-steps/images/${id}`,
    },
    GET_TROUBLESHOOT_STEPS: {
        url: (primaryStepId: number) =>
            `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot-steps/${primaryStepId}`,
    },
    UPDATE_TROUBLESHOOT_STEPS: {
        url: (id: number) => `${process.env.REACT_APP_SERVER_URL}/remote-settings/troubleshoot-steps/${id}`,
    },
};
