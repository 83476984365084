import { referralOrderConstants } from './referral.order.constants';
import { IReferralOrderActions, IReferralOrderState } from './referral.order.interfaces';

const initialState: IReferralOrderState = {
    referralOrders: [],
    referralOrder: null,
    total: 0,
    loading: false,
    unusedVouchersCount: -1,
};

const referralOrderReducer = (state = initialState, action: IReferralOrderActions): IReferralOrderState => {
    switch (action.type) {
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_REQUEST:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_REQUEST:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_REQUEST:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_FAILURE:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_FAILURE:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_FAILURE:
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_SUCCESS:
            return {
                ...state,
                referralOrders: action.referralOrders.referralOrders,
                total: action.referralOrders.total,
                loading: false,
            };
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_SUCCESS:
            return {
                ...state,
                referralOrder: action.referralOrder,
                loading: false,
            };
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_SUCCESS:
            return {
                ...state,
                unusedVouchersCount: action.unusedVouchersCount,
                loading: false,
            };
        default:
            return state;
    }
};

export { referralOrderReducer };
