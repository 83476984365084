export const selectionConstants = {
    SELECTION_ACTION_TYPES: {
        GET_SELECTIONS_REQUEST: 'GET_SELECTIONS_REQUEST',
        GET_SELECTIONS_SUCCESS: 'GET_SELECTIONS_SUCCESS',
        GET_SELECTIONS_FAILURE: 'GET_SELECTIONS_FAILURE',
        GET_SELECTION_BY_ID_REQUEST: 'GET_SELECTION_BY_ID_REQUEST',
        GET_SELECTION_BY_ID_SUCCESS: 'GET_SELECTION_BY_ID_SUCCESS',
        GET_SELECTION_BY_ID_FAILURE: 'GET_SELECTION_BY_ID_FAILURE',
        ADD_SELECTION_REQUEST: 'ADD_SELECTION_REQUEST',
        ADD_SELECTION_SUCCESS: 'ADD_SELECTION_SUCCESS',
        ADD_SELECTION_FAILURE: 'ADD_SELECTION_FAILURE',
        DELETE_SELECTION_REQUEST: 'DELETE_SELECTION_REQUEST',
        DELETE_SELECTION_SUCCESS: 'DELETE_SELECTION_SUCCESS',
        DELETE_SELECTION_FAILURE: 'DELETE_SELECTION_FAILURE',
        UPDATE_SELECTION_REQUEST: 'UPDATE_SELECTION_REQUEST',
        UPDATE_SELECTION_SUCCESS: 'UPDATE_SELECTION_SUCCESS',
        UPDATE_SELECTION_FAILURE: 'UPDATE_SELECTION_FAILURE',
        GET_SELECTION_DISPLAYS_REQUEST: 'GET_SELECTION_DISPLAYS_REQUEST',
        GET_SELECTION_DISPLAYS_SUCCESS: 'GET_SELECTION_DISPLAYS_SUCCESS',
        GET_SELECTION_DISPLAYS_FAILURE: 'GET_SELECTION_DISPLAYS_FAILURE',
        GET_PINNED_SELECTION_RECIPES_REQUEST: 'GET_PINNED_SELECTION_RECIPES_REQUEST',
        GET_PINNED_SELECTION_RECIPES_SUCCESS: 'GET_PINNED_SELECTION_RECIPES_SUCCESS',
        GET_PINNED_SELECTION_RECIPES_FAILURE: 'GET_PINNED_SELECTION_RECIPES_FAILURE',
        ADD_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST: 'ADD_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST',
        ADD_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS: 'ADD_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS',
        ADD_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE: 'ADD_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE',
        GET_RECIPES_FOR_SELECTION_REQUEST: 'GET_RECIPES_FOR_SELECTION_REQUEST',
        GET_RECIPES_FOR_SELECTION_SUCCESS: 'GET_RECIPES_FOR_SELECTION_SUCCESS',
        GET_RECIPES_FOR_SELECTION_FAILURE: 'GET_RECIPES_FOR_SELECTION_FAILURE',
        GET_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST: 'GET_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST',
        GET_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS: 'GET_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS',
        GET_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE: 'GET_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE',
        UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST: 'UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST',
        UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS: 'UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS',
        UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE: 'UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE',
        UPDATE_RECIPE_SELECTION_DISPLAYS_REQUEST: 'UPDATE_RECIPE_SELECTION_DISPLAYS_REQUEST',
        UPDATE_RECIPE_SELECTION_DISPLAYS_SUCCESS: 'UPDATE_RECIPE_SELECTION_DISPLAYS_SUCCESS',
        UPDATE_RECIPE_SELECTION_DISPLAYS_FAILURE: 'UPDATE_RECIPE_SELECTION_DISPLAYS_FAILURE',
        ADD_RECIPE_SELECTION_DISPLAYS_REQUEST: 'ADD_RECIPE_SELECTION_DISPLAYS_REQUEST',
        ADD_RECIPE_SELECTION_DISPLAYS_SUCCESS: 'ADD_RECIPE_SELECTION_DISPLAYS_SUCCESS',
        ADD_RECIPE_SELECTION_DISPLAYS_FAILURE: 'ADD_RECIPE_SELECTION_DISPLAYS_FAILURE',
        UPDATE_RECIPE_SELECTION_PINNED_REQUEST: 'UPDATE_RECIPE_SELECTION_PINNED_REQUEST',
        UPDATE_RECIPE_SELECTION_PINNED_SUCCESS: 'UPDATE_RECIPE_SELECTION_PINNED_SUCCESS',
        UPDATE_RECIPE_SELECTION_PINNED_FAILURE: 'UPDATE_RECIPE_SELECTION_PINNED_FAILURE',
        ADD_RECIPE_SELECTION_PINNED_REQUEST: 'ADD_RECIPE_SELECTION_PINNED_REQUEST',
        ADD_RECIPE_SELECTION_PINNED_SUCCESS: 'ADD_RECIPE_SELECTION_PINNED_SUCCESS',
        ADD_RECIPE_SELECTION_PINNED_FAILURE: 'ADD_RECIPE_SELECTION_PINNED_FAILURE',
        GET_RECIPE_SELECTION_PRODUCTS_REQUEST: 'GET_RECIPE_SELECTION_PRODUCTS_REQUEST',
        GET_RECIPE_SELECTION_PRODUCTS_SUCCESS: 'GET_RECIPE_SELECTION_PRODUCTS_SUCCESS',
        GET_RECIPE_SELECTION_PRODUCTS_FAILURE: 'GET_RECIPE_SELECTION_PRODUCTS_FAILURE',
        ADD_RECIPE_SELECTION_PRODUCTS_REQUEST: 'ADD_RECIPE_SELECTION_PRODUCTS_REQUEST',
        ADD_RECIPE_SELECTION_PRODUCTS_SUCCESS: 'ADD_RECIPE_SELECTION_PRODUCTS_SUCCESS',
        ADD_RECIPE_SELECTION_PRODUCTS_FAILURE: 'ADD_RECIPE_SELECTION_PRODUCTS_FAILURE',
        UPDATE_RECIPE_SELECTION_PRODUCTS_REQUEST: 'UPDATE_RECIPE_SELECTION_PRODUCTS_REQUEST',
        UPDATE_RECIPE_SELECTION_PRODUCTS_SUCCESS: 'UPDATE_RECIPE_SELECTION_PRODUCTS_SUCCESS',
        UPDATE_RECIPE_SELECTION_PRODUCTS_FAILURE: 'UPDATE_RECIPE_SELECTION_PRODUCTS_FAILURE',
        ADD_RECIPE_SELECTION_VALUE_TAGS_REQUEST: 'ADD_RECIPE_SELECTION_VALUE_TAGS_REQUEST',
        ADD_RECIPE_SELECTION_VALUE_TAGS_SUCCESS: 'ADD_RECIPE_SELECTION_VALUE_TAGS_SUCCESS',
        ADD_RECIPE_SELECTION_VALUE_TAGS_FAILURE: 'ADD_RECIPE_SELECTION_VALUE_TAGS_FAILURE',
        UPDATE_RECIPE_SELECTION_VALUE_TAGS_REQUEST: 'UPDATE_RECIPE_SELECTION_VALUE_TAGS_REQUEST',
        UPDATE_RECIPE_SELECTION_VALUE_TAGS_SUCCESS: 'UPDATE_RECIPE_SELECTION_VALUE_TAGS_SUCCESS',
        UPDATE_RECIPE_SELECTION_VALUE_TAGS_FAILURE: 'UPDATE_RECIPE_SELECTION_VALUE_TAGS_FAILURE',
        GET_RECIPE_SELECTION_VALUE_TAGS_REQUEST: 'GET_RECIPE_SELECTION_VALUE_TAGS_REQUEST',
        GET_RECIPE_SELECTION_VALUE_TAGS_SUCCESS: 'GET_RECIPE_SELECTION_VALUE_TAGS_SUCCESS',
        GET_RECIPE_SELECTION_VALUE_TAGS_FAILURE: 'GET_RECIPE_SELECTION_VALUE_TAGS_FAILURE',
        GET_RECIPE_BY_VALUE_TAGS_REQUEST: 'GET_RECIPE_BY_VALUE_TAGS_REQUEST',
        GET_RECIPE_BY_VALUE_TAGS_SUCCESS: 'GET_RECIPE_BY_VALUE_TAGS_SUCCESS',
        GET_RECIPE_BY_VALUE_TAGS_FAILURE: 'GET_RECIPE_BY_VALUE_TAGS_FAILURE',
        GET_RECIPE_BY_ALL_TAGS_REQUEST: 'GET_RECIPE_BY_ALL_TAGS_REQUEST',
        GET_RECIPE_BY_ALL_TAGS_SUCCESS: 'GET_RECIPE_BY_ALL_TAGS_SUCCESS',
        GET_RECIPE_BY_ALL_TAGS_FAILURE: 'GET_RECIPE_BY_ALL_TAGS_FAILURE',
    },
    SELECTION_ROW_ELEMENTS: {
        ID: 1,
        NAME: 2,
        CREATED_AT: 4,
        MODIFIED_AT: 5,
    },
    SELECTION_ROUTES: {
        SELECTIONS: '/recipe-selections',
        SELECTIONS_WITH_PARAMS: '/recipe-selections/',
        ADD_SELECTION: '/recipe-selections/add',
        EDIT_SELECTION: '/recipe-selections/edit',
        RECIPE_SELECTION_WITH_PARAMS: '/recipe-selection/',
        DISPLAYS: '/displays',
        PINNED_RECIPES: '/pinned-recipes',
        RECIPE_SELECTION_CATEGORY_TAGS: '/recipe-selection-category-tags',
        RECIPE_SELECTION_DISPLAYS: '/recipe-selection-displays',
        PINNED: '/pinned',
        RECIPE_BY_CATEGORY_TAGS_WITH_PARAMS: '/selection/',
        RECIPE: '/recipes',
        RECIPE_SELECTION_PRODUCTS: '/recipe-selection-products',
        SELECTION_VALUE_TAGS: '/recipe-selection-value-tags',
        SELECTION_VALUE_TAGS_WITH_PARAM: '/recipe-selection-value-tags/',
        // RECIPES_BY_VALUE_TAGS: '/selection/:id/recipes-value-tags',
        PRE_RECIPES_BY_VALUE_TAGE: '/selection/',
        POST_RECIPES_BY_VALUE_TAGE: '/recipes-value-tags',
        GET_RECIPES_BY_ALL_TAGS: '/recipes-filtered-selection/',
    },

    SELECTION_SUB_ROUTES: {
        SELECTION: 'selection',
        SELECTION_CATEGORY_TAGS: 'category-tags',
        SELECTION_VALUE_TAGS: 'value-tags',
        SELECTION_PRODUCTS: 'products',
        SELECTION_RECIPE: 'recipe',
    },

    DISPLAY: ['HOME', 'RECIPE_OVERVIEW', 'NOT_DISPLAYED'],

    DRAG_AND_DROP: {
        CATEGORY_TAGS_SOURCE: 'Available category tags',
        CATEGORY_TAGS_DESTINATION: 'Selection category tags',
        PRODUCTS_SOURCE: 'Available products',
        PRODUCTS_DESTINATION: 'Selection products',
    },
};
