import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { IPagination } from '../../shared/components/interfaces';
import { InternalRatingReportDecision } from '../reviews/reviews.interfaces';
import {
    InternalUserReportDecision,
    IReportedUserResponse,
    IReportsForUserResponse,
    IUserReportActions,
    IUserReportReviewResponse,
} from './user.report.interfaces';
import { userReportConstants } from './user.report.constants';
import { userReportService } from './user.report.service';

const updateReviewStatus = (
    reviewId: number,
    read: boolean,
    internalDecision: InternalRatingReportDecision,
): ((dispatch: Dispatch) => Promise<AxiosResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return userReportService.updateReviewStatus(reviewId, read, internalDecision).then(
            (response) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data?.message?.toString() || 'Error updating review status'));
                dispatch(
                    alertActions.error(error.response.data?.message?.toString() || 'Error updating review status'),
                );
                return Promise.reject(error);
            },
        );
    };

    function request(): IUserReportActions {
        return { type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_REQUEST };
    }

    function success(): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_SUCCESS,
        };
    }

    function failure(error: string): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REVIEW_REPORT_FAILURE,
            error,
        };
    }
};

const getReviewReports = (reviewId: number): ((dispatch: Dispatch) => Promise<IUserReportReviewResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return userReportService.getReviewReports(reviewId).then(
            (response) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUserReportActions {
        return { type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_REQUEST };
    }

    function success(): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_SUCCESS,
        };
    }

    function failure(error: string): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REVIEW_REPORTS_FAILURE,
            error,
        };
    }
};

const getReportedUsers = (
    pagination: IPagination,
    searchText: string,
): ((dispatch: Dispatch) => Promise<IReportedUserResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return userReportService.getReportedUsers(pagination, searchText).then(
            (response) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUserReportActions {
        return { type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_REQUEST };
    }

    function success(): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_SUCCESS,
        };
    }

    function failure(error: string): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTED_USERS_FAILURE,
            error,
        };
    }
};

const updateReportedUser = (
    userId: number,
    read: boolean,
    internalDecision: InternalUserReportDecision,
): ((dispatch: Dispatch) => Promise<AxiosResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return userReportService.updateReportedUser(userId, read, internalDecision).then(
            (response) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUserReportActions {
        return { type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_REQUEST };
    }

    function success(): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_SUCCESS,
        };
    }

    function failure(error: string): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.UPDATE_REPORTED_USER_FAILURE,
            error,
        };
    }
};

const getReportsForUser = (userId: number): ((dispatch: Dispatch) => Promise<IReportsForUserResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return userReportService.getReportsForUser(userId).then(
            (response) => {
                dispatch(success());
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IUserReportActions {
        return { type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_REQUEST };
    }

    function success(): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_SUCCESS,
        };
    }

    function failure(error: string): IUserReportActions {
        return {
            type: userReportConstants.USER_REPORT_ACTION_TYPES.GET_REPORTS_FOR_USER_FAILURE,
            error,
        };
    }
};

export const userReportActions = {
    updateReviewStatus,
    getReviewReports,
    getReportedUsers,
    updateReportedUser,
    getReportsForUser,
};
