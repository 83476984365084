import { IProductForm } from '../../../upselling.interfaces';

const emptyNumber = '' as number | string as number;

const product: IProductForm = {
    titleEn: '',
    titleDe: '',
    picture: null,
    milieuPicture: null,
    randomProduct: false,
    shopifyDeAtProductId: emptyNumber,
    shopifyUsProductId: emptyNumber,
    shopifyDeAtVariantId: emptyNumber,
    shopifyUsVariantId: emptyNumber,
    categories: [],
};

export const formInitialValues = {
    product,
};
