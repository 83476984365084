import { genericHelpers } from './generics';
import { globalConstants } from '../constants/global.constants';
import filter from '../../modules/filters/pages/filter';
import { IFilters, IFilterTableState } from '../components/interfaces';

export const customRowIndexColumn = () => {
    return {
        name: '#',
        options: {
            filter: false,
            customBodyRender: (value, meta) => {
                return meta.rowIndex + 1;
            },
        },
    };
};

export const getColumnNameAndIndex = (changedColumn: string, tableColumns: any[]): IFilterTableState => {
    const filterIndex = tableColumns
        .map((column, index) => ({ name: column.name, columnIndex: index }))
        .filter((value, index, self) => self.findIndex((v) => v.name === changedColumn) === index);

    return filterIndex[globalConstants.KEYS.FIRST_ARRAY_INDEX];
};

export const generateRequestFilters = (
    filterState: IFilterTableState,
    filters: string[][],
    selectedFilters: Array<IFilters>,
): Array<IFilters> => {
    if (!genericHelpers.isNullOrUndefined(filters) && !genericHelpers.isNullOrUndefined(filterState)) {
        const filteredData = filters[filterState.columnIndex];
        const paramName = camelToSnakeCase(filterState.name).toString();
        const formattedFilterArray = [
            ...selectedFilters.filter((filter) => filter.filterParameterName !== paramName),
            ...filteredData.map((data) => ({
                filterParameterName: paramName,
                filterValue: parseFilterValue(data),
            })),
        ];

        return formattedFilterArray;
    }
};

function parseFilterValue(data: string): number {
    return data == 'true' ? 1 : data === 'false' ? 0 : Number(data);
}

function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
