import React from 'react';
import { Step, StepButton, Stepper, WithStyles, withStyles } from '@material-ui/core';
import { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { stepperStyles } from '../../../recipe/pages/addRecipe/styles';
import { troubleshootEndpoints } from '../../troubleshoot.endpoints';
import TroubleshootInfo from '../steps/TroubleshootInfo';
import TroubleshootSteps from '../steps/TroubleshootSteps';
import styles from './index.module.scss';
import TroubleshootPrimarySteps from '../steps/TroubleshootPrimarySteps';

type OwnProps = WithStyles<typeof stepperStyles>;

const steps: string[] = ['Preview and video', 'Primary Steps', 'Steps'];

const AddOrEditTroubleshoot = (props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { id, step } = useParams() as any;

    const totalSteps: number = steps.length;

    const history = useHistory();

    const subRoutes = troubleshootEndpoints.SUB_ROUTES;
    const defaultActiveStep = step ? Object.values(subRoutes).indexOf(step) : 0;

    const [entityId, setEntityId] = useState<number>(id);
    const [activeStep, setActiveStep] = useState<number | null>(defaultActiveStep);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});

    useEffect(() => {
        if (activeStep !== null) {
            pushToHistory(activeStep);
        }
    }, [entityId, activeStep]);

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const nextStep = (newEntityId?: number): void => {
        if (newEntityId) {
            setEntityId(newEntityId);
        }

        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const prevStep = (): void => {
        setActiveStep((prevStep: number) => prevStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const pushToHistory = (historyStep) => {
        const formatSubRoutes = Object.values(subRoutes);

        history.push(`${troubleshootEndpoints.ROUTES.EDIT_TROUBLESHOOT}/${formatSubRoutes[historyStep]}/${entityId}`);
    };

    const stepsComponents = [
        <TroubleshootInfo key={'troubleshoot-info'} nextStep={nextStep} />,
        <TroubleshootPrimarySteps key={'troubleshoot-primary-steps'} prevStep={prevStep} nextStep={nextStep} />,
        <TroubleshootSteps key={'troubleshoot-steps'} prevStep={prevStep} />,
    ];

    return (
        <div className={styles.addSelectionStepperStepper}>
            <Stepper activeStep={activeStep} className={props.classes.stepper} variant={'outlined'} nonLinear>
                {steps.map((label: string, index: number) => {
                    const subRoute = Object.values(subRoutes)[index];
                    return (
                        <Step key={label}>
                            <StepButton
                                to={`${troubleshootEndpoints.ROUTES.EDIT_TROUBLESHOOT}/${subRoute}/${id}`}
                                component={Link}
                                onClick={handleStep(index)}
                                completed={completed[index]}
                                disabled={!id}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {stepsComponents[activeStep] || stepsComponents[0]}
        </div>
    );
};

export default compose(withRouter, withStyles(stepperStyles), connect(null, null))(AddOrEditTroubleshoot);
