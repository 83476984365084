import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const categoryTagFormModel = {
    formId: uniqueId('form-'),
    formField: {
        nameEn: {
            name: 'name_en',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        nameDe: {
            name: 'name_de',
            label: de.name,
            requiredErrorMsg: de.name_required_error_msg,
        },
        tagTypeId: {
            name: 'tag_type_id',
            label: en.tag_type_label,
            requiredErrorMsg: en.tag_type_id_error_msg,
        },
    },
};

const formModels = {
    categoryTagFormModel,
};

export default formModels;
