import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';
import {
    IReferralOrderActions,
    IReferralOrderData,
    IReferralOrderResponse,
    IUploadReport,
    ReferralOrderStatus,
} from './referral.order.interfaces';
import { referralOrderService } from './referral.order.service';
import { referralOrderConstants } from './referral.order.constants';

function getReferralOrders(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IReferralOrderResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return referralOrderService.getReferralOrders(pagination, searchText, filters).then(
            (response) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReferralOrderActions {
        return { type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_REQUEST };
    }

    function success(response: IReferralOrderResponse): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_SUCCESS,
            referralOrders: response,
        };
    }

    function failure(error: string): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_FAILURE,
            error,
        };
    }
}

function updateReferralOrderStatus(
    referralOrderId: number,
    orderStatus: ReferralOrderStatus,
): (dispatch: Dispatch) => Promise<IReferralOrderData | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return referralOrderService.updateReferralOrderStatus(referralOrderId, orderStatus).then(
            (response) => {
                dispatch(success(response));
                dispatch(alertActions.success('Referral order status updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReferralOrderActions {
        return { type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_REQUEST };
    }

    function success(response: IReferralOrderData): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_SUCCESS,
            referralOrder: response,
        };
    }

    function failure(error: string): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.PATCH_REFERRAL_ORDER_FAILURE,
            error,
        };
    }
}

function uploadAlbersVouchersFile(voucherFile: File): (dispatch: Dispatch) => Promise<IUploadReport | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return referralOrderService.uploadAlbersVouchersFile(voucherFile).then(
            (response) => {
                dispatch(success());
                let uploadReport = `Successfully uploaded ${response.successfulInsertions} voucher pairs`;
                if (response.skippedVoucherPairs.length > 0) {
                    uploadReport += `\nSkipped ${response.skippedVoucherPairs.length} voucher pairs (already existing):`;
                    uploadReport += `\n${response.skippedVoucherPairs
                        .map((pair) => `[${pair.voucherCodeReferrer}|${pair.voucherCodeReferee}]`)
                        .join(', ')}`;
                }
                dispatch(alertActions.success(uploadReport));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.axiosError(error));
                return error;
            },
        );
    };

    function request(): IReferralOrderActions {
        return { type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_REQUEST };
    }

    function success(): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_SUCCESS,
        };
    }

    function failure(error: string): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.POST_VOUCHERS_FILE_FAILURE,
            error,
        };
    }
}

function getUnusedVouchersCount(): (dispatch: Dispatch) => Promise<number | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return referralOrderService.getUnusedVouchersCount().then(
            (response) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReferralOrderActions {
        return { type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_REQUEST };
    }

    function success(response: number): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_SUCCESS,
            unusedVouchersCount: response,
        };
    }

    function failure(error: string): IReferralOrderActions {
        return {
            type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_UNUSED_VOUCHERS_COUNT_FAILURE,
            error,
        };
    }
}

export const referralOrderActions = {
    getReferralOrders,
    updateReferralOrderStatus,
    getUnusedVouchersCount,
    uploadAlbersVouchersFile,
};
