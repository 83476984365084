import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import en from '../../../assets/language/en.json';
import { selectionService } from './selection.service';
import { selectionConstants } from './selection.constants';
import {
    IPinnedSelectionRecipesResponse,
    IRecipeSelectionCategoryTagsForm,
    IRecipeSelectionCategoryTagsResponse,
    IRecipeSelectionDisplaysResponse,
    ISelection,
    ISelectionAction,
    ISelectionForm,
    IAddRecipeForm,
    ISelectionResponse,
    ISelectionsDisplaysResponse,
    IValueTagsForm,
    IValueTagsData,
    IRecipeSelectionProductsResponse,
    IRecipeSelectionProductsForm,
} from './selection.interfaces';
import { IRecipeResponse, RecipeActions } from '../recipe/recipe.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { genericHelpers } from '../../shared/helpers/generics';
import { routeConstants } from '../../shared/routes/route.constants';
import { selectionHelpers } from '../helpers/selection.helpers';

function getSelections(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<ISelectionResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getSelections(pagination, searchText, filters).then(
            (response: ISelectionResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_REQUEST };
    }

    function success(response: ISelectionResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_SUCCESS,
            selectionsData: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTIONS_FAILURE, error };
    }
}

const addSelection = (selection: ISelectionForm): ((dispatch: Dispatch) => Promise<ISelection | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.addSelection(selection).then(
            (response: ISelection) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.selection_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_REQUEST };
    }

    function success(response: ISelection): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_SUCCESS,
            selection: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_SELECTION_FAILURE, error };
    }
};

const updateSelection = (
    selection: ISelectionForm,
    selectionId: number,
): ((dispatch: Dispatch) => Promise<ISelection | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.updateSelection(selection, selectionId).then(
            (response: ISelection) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.selection_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_REQUEST };
    }

    function success(response: ISelection): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_SUCCESS,
            selection: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_SELECTION_FAILURE, error };
    }
};

const deleteSelection = (
    selectionId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<ISelection | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.deleteSelection(selectionId).then(
            (response: ISelection) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.selection_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_REQUEST };
    }

    function success(response: ISelection): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_SUCCESS,
            selection: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.DELETE_SELECTION_FAILURE,
            error,
        };
    }
};

function getSelectionById(selectionId: number): (dispatch: Dispatch) => Promise<ISelection | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getSelectionById(selectionId).then(
            (response: ISelection) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_REQUEST };
    }

    function success(response: ISelection): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_SUCCESS,
            selection: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_BY_ID_FAILURE, error };
    }
}

function getSelectionDisplays(
    selectionId: number,
): (dispatch: Dispatch) => Promise<ISelectionsDisplaysResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getSelectionDisplays(selectionId).then(
            (response: ISelectionsDisplaysResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_DISPLAYS_REQUEST };
    }

    function success(response: ISelectionsDisplaysResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_DISPLAYS_SUCCESS,
            selectionDisplays: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_SELECTION_DISPLAYS_FAILURE, error };
    }
}

function getPinnedSelectionRecipes(
    selectionId: number,
): (dispatch: Dispatch) => Promise<IPinnedSelectionRecipesResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getPinnedSelectionRecipes(selectionId).then(
            (response: IPinnedSelectionRecipesResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_PINNED_SELECTION_RECIPES_REQUEST };
    }

    function success(response: IPinnedSelectionRecipesResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_PINNED_SELECTION_RECIPES_SUCCESS,
            pinnedSelectionRecipes: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_PINNED_SELECTION_RECIPES_FAILURE, error };
    }
}

const getRecipeSelectionCategoryTags = (
    recipeSelectionId: number,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getRecipeSelectionCategoryTags(recipeSelectionId).then(
            (response: IRecipeSelectionCategoryTagsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IRecipeSelectionCategoryTagsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS,
            recipeSelectionCategoryTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE, error };
    }
};

const addRecipeSelectionCategoryTags = (
    recipeSelectionCategoryTags: IRecipeSelectionCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.addRecipeSelectionCategoryTags(recipeSelectionCategoryTags).then(
            (response: IRecipeSelectionCategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_selection_category_tags_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IRecipeSelectionCategoryTagsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS,
            recipeSelectionCategoryTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE, error };
    }
};

const updateRecipeSelectionCategoryTags = (
    recipeSelectionCategoryTags: IRecipeSelectionCategoryTagsForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionCategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.updateRecipeSelectionCategoryTags(recipeSelectionCategoryTags).then(
            (response: IRecipeSelectionCategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_selection_category_tags_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_REQUEST };
    }

    function success(response: IRecipeSelectionCategoryTagsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_SUCCESS,
            recipeSelectionCategoryTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_CATEGORY_TAGS_FAILURE, error };
    }
};

const addRecipeSelectionDisplays = (
    recipeSelection: ISelectionForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionDisplaysResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.addSelectionDisplays(recipeSelection).then(
            (response: IRecipeSelectionDisplaysResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_selection_display_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_DISPLAYS_REQUEST };
    }

    function success(response: IRecipeSelectionDisplaysResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_DISPLAYS_SUCCESS,
            recipeSelectionDisplays: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_DISPLAYS_FAILURE, error };
    }
};

const updateRecipeSelectionDisplays = (
    recipeSelection: ISelectionForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionDisplaysResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.updateSelectionDisplays(recipeSelection).then(
            (response: IRecipeSelectionDisplaysResponse) => {
                dispatch(success(response));

                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_DISPLAYS_REQUEST };
    }

    function success(response: IRecipeSelectionDisplaysResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_DISPLAYS_SUCCESS,
            recipeSelectionDisplays: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_DISPLAYS_FAILURE, error };
    }
};

function updatePinnedSelectionRecipes(
    recipeSelection: IAddRecipeForm,
    blackListedRecipeIds: number[],
): (dispatch: Dispatch) => Promise<IPinnedSelectionRecipesResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return selectionService.updatePinnedSelectionRecipes(recipeSelection, blackListedRecipeIds).then(
            (response: IPinnedSelectionRecipesResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.selection_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_PINNED_REQUEST };
    }

    function success(response: IPinnedSelectionRecipesResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_PINNED_SUCCESS,
            pinnedSelectionRecipes: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_PINNED_FAILURE, error };
    }
}

function getRecipesForSelection(
    paging: IPagination,
    searchText: string,
    recipeSelectionId: number,
): (dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        return selectionService.getRecipesForSelection(paging, searchText, recipeSelectionId).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPES_FOR_SELECTION_REQUEST };
    }

    function success(response: IRecipeResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPES_FOR_SELECTION_SUCCESS,
            recipeData: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPES_FOR_SELECTION_FAILURE, error };
    }
}

const getRecipeSelectionProducts = (
    recipeSelectionId: number,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getRecipeSelectionProducts(recipeSelectionId).then(
            (response: IRecipeSelectionProductsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_REQUEST };
    }

    function success(response: IRecipeSelectionProductsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_SUCCESS,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_FAILURE, error };
    }
};

const addRecipeSelectionProducts = (
    recipeSelectionProducts: IRecipeSelectionProductsForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.addRecipeSelectionProducts(recipeSelectionProducts).then(
            (response: IRecipeSelectionProductsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_selection_products_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_REQUEST };
    }

    function success(response: IRecipeSelectionProductsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_SUCCESS,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_FAILURE, error };
    }
};

const updateRecipeSelectionProducts = (
    recipeSelectionProducts: IRecipeSelectionProductsForm,
): ((dispatch: Dispatch) => Promise<IRecipeSelectionProductsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.updateRecipeSelectionProducts(recipeSelectionProducts).then(
            (response: IRecipeSelectionProductsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.recipe_selection_products_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_REQUEST };
    }

    function success(response: IRecipeSelectionProductsResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_SUCCESS,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_PRODUCTS_FAILURE, error };
    }
};

const addValueTag = (
    valueTag: IValueTagsForm,
    selectionId: number,
): ((dispatch: Dispatch) => Promise<IValueTagsForm | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());
        valueTag.recipeSelectionId = selectionId;
        Object.keys(valueTag).forEach((key) => {
            if (!valueTag[key] || valueTag[key] == '') {
                valueTag[key] = null;
            }
        });
        return selectionService.addSelectionValueTag(selectionHelpers.valueTagsFormToData(valueTag)).then(
            (response: IValueTagsForm) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.value_tag_added_successfully));
                return selectionHelpers.valueTagsDataToForm(response);
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_VALUE_TAGS_REQUEST };
    }

    function success(response: IValueTagsForm): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_VALUE_TAGS_SUCCESS,
            valueTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.ADD_RECIPE_SELECTION_VALUE_TAGS_FAILURE, error };
    }
};

const updateValueTag = (
    valueTag: IValueTagsForm,
    selectionId: number,
): ((dispatch: Dispatch) => Promise<IValueTagsForm | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());
        valueTag.recipeSelectionId = selectionId;
        Object.keys(valueTag).forEach((key) => {
            if (genericHelpers.isNullOrUndefined(valueTag[key]) || valueTag[key] == '') {
                valueTag[key] = null;
            }
        });

        return selectionService.updateSelectionValueTag(selectionHelpers.valueTagsFormToData(valueTag)).then(
            (response: IValueTagsForm) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.value_tag_updated_successfully));
                return selectionHelpers.valueTagsDataToForm(response);
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_VALUE_TAGS_REQUEST };
    }

    function success(response: IValueTagsForm): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_VALUE_TAGS_SUCCESS,
            valueTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.UPDATE_RECIPE_SELECTION_VALUE_TAGS_FAILURE, error };
    }
};

const getValueTag = (selectionId: number): ((dispatch: Dispatch) => Promise<IValueTagsForm | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getSelectionValueTag(selectionId).then(
            (response: IValueTagsData) => {
                dispatch(success(response));
                return selectionHelpers.valueTagsDataToForm(response);
            },
            (error: AxiosError) => {
                if (error.response.data.errorCode == routeConstants.HTTP_STATUS_CODE.NOT_FOUND) {
                    return undefined;
                }
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_VALUE_TAGS_REQUEST };
    }

    function success(response: IValueTagsForm): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_VALUE_TAGS_SUCCESS,
            valueTags: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_SELECTION_VALUE_TAGS_FAILURE, error };
    }
};

const getRecipesByValueTag = (selectionId: number): ((dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getRecipesByValueTag(selectionId).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_VALUE_TAGS_REQUEST };
    }

    function success(response: IRecipeResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_VALUE_TAGS_SUCCESS,
            // pinnedSelectionRecipes: response,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_VALUE_TAGS_FAILURE, error };
    }
};

const getRecipesByAllTags = (selectionId: number): ((dispatch: Dispatch) => Promise<IRecipeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return selectionService.getRecipesByAllTags(selectionId).then(
            (response: IRecipeResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_ALL_TAGS_REQUEST };
    }

    function success(response: IRecipeResponse): ISelectionAction {
        return {
            type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_ALL_TAGS_SUCCESS,
        };
    }

    function failure(error: string): ISelectionAction {
        return { type: selectionConstants.SELECTION_ACTION_TYPES.GET_RECIPE_BY_ALL_TAGS_FAILURE, error };
    }
};

export const selectionActions = {
    getSelections,
    addSelection,
    updateSelection,
    deleteSelection,
    getSelectionById,
    getSelectionDisplays,
    getPinnedSelectionRecipes,
    getRecipeSelectionCategoryTags,
    addRecipeSelectionCategoryTags,
    updateRecipeSelectionCategoryTags,
    addRecipeSelectionDisplays,
    updateRecipeSelectionDisplays,
    updatePinnedSelectionRecipes,
    getRecipesForSelection,
    addValueTag,
    updateValueTag,
    getValueTag,
    getRecipesByValueTag,
    getRecipeSelectionProducts,
    addRecipeSelectionProducts,
    updateRecipeSelectionProducts,
    getRecipesByAllTags,
};
