import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import moment from 'moment';

const selectionColumns = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'name_en',
        label: en.name,
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'name_de',
        label: de.name,
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'createdAt',
        label: en.create_date_label,
        options: {
            filter: false,
            sort: true,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
    {
        name: 'modifiedAt',
        label: en.modify_date_label,
        options: {
            filter: false,
            sort: true,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

export const selectionDataTables = {
    selectionColumns,
};
