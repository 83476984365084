import { equipmentConstants } from './equipment.constants';
import { IEquipmentActions, IEquipmentState } from './equipment.interfaces';

const initialState: IEquipmentState = {
    equipmentList: [],
    equipment: null,
    loading: false,
};

const equipmentReducer = (state = initialState, action: IEquipmentActions): IEquipmentState => {
    switch (action.type) {
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipmentList: action.equipmentList.equipmentList,
                loading: false,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_FAILURE:
            return {
                loading: false,
            };

        case equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipment: action.equipment,
                loading: false,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                equipment: action.equipment,
                loading: false,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { equipmentReducer };
