import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { troubleshootEndpoints } from './troubleshoot.endpoints';
import {
    ITroubleshoot,
    ITroubleshootPrimaryStep,
    ITroubleshootResponse,
    ITroubleshootStep,
} from './troubleshoot.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getTroubleshoots = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<ITroubleshootResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.GET_TROUBLESHOOTS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getTroubleshoot = async (entityId: number): Promise<ITroubleshoot | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.GET_TROUBLESHOOT.url(entityId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const troubleshootToFormData = (data: ITroubleshoot) => {
    const formData = new FormData();

    for (const key in data) {
        if (!data.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, data[key]);
    }
    return formData;
};

const addTroubleshoot = async (data: ITroubleshoot): Promise<ITroubleshoot | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.ADD_TROUBLESHOOT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: troubleshootToFormData(data),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const updateTroubleshoot = async (data: ITroubleshoot, entityId: number): Promise<ITroubleshoot | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.UPDATE_TROUBLESHOOT.url(entityId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: troubleshootToFormData(data),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteTroubleshoot = async (entityId: number): Promise<AxiosResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.DELETE_TROUBLESHOOT.url(entityId),
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateTroubleshootPrimarySteps = async (
    data: ITroubleshootPrimaryStep[],
    entityId: number,
): Promise<ITroubleshootPrimaryStep[] | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.UPDATE_TROUBLESHOOT_PRIMARY_STEPS.url(entityId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data,
    };

    const response = await axios(requestOptions);
    const updatedSteps: ITroubleshootPrimaryStep[] = response.data.data;

    // Upload the steps' images
    await Promise.all(
        data.map(async (rawStep, index) => {
            if (rawStep.thumbnailEn instanceof File || rawStep.thumbnailDe instanceof File) {
                const formData = new FormData();
                formData.append('primaryStep_thumbnailEn', rawStep.thumbnailEn);
                formData.append('primaryStep_thumbnailDe', rawStep.thumbnailDe);
                // Upload the image
                await uploadTroubleshootPrimaryStepImage(formData, updatedSteps[index].id);
            }
        }),
    );

    return updatedSteps;
};

const uploadTroubleshootPrimaryStepImage = async (formData: FormData, entityId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.UPLOAD_TROUBLESHOOT_PRIMARY_STEP_IMAGE.url(entityId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getTroubleshootStepsByPrimaryStepId = async (
    primaryStepId: number,
): Promise<ITroubleshootStep[] | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.GET_TROUBLESHOOT_STEPS.url(primaryStepId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const updateTroubleshootSteps = async (
    data: ITroubleshootStep[],
    entityId: number,
): Promise<ITroubleshootStep[] | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: troubleshootEndpoints.UPDATE_TROUBLESHOOT_STEPS.url(entityId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const troubleshootService = {
    getTroubleshoots,
    getTroubleshoot,
    addTroubleshoot,
    updateTroubleshoot,
    deleteTroubleshoot,
    updateTroubleshootPrimarySteps,
    getTroubleshootStepsByPrimaryStepId,
    updateTroubleshootSteps,
};
