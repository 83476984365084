import { useHistory, useLocation } from 'react-router-dom';
import { influencerConstants } from '../../influencer.constants';
import { FormControl } from '@material-ui/core';
import styles from './index.module.scss';
import clsx from 'clsx';
import { ToggleButtonGroup } from '@material-ui/lab';
import { StyledToggleButton } from '../../../../shared/components/generics/styledComponents/StyledComponents';

type Props = {
    influencerId: number;
};

export default function EditFeedToggle({ influencerId }: Props) {
    const { pathname } = useLocation();
    const history = useHistory();

    let selectedTab;
    if (pathname.includes(influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED)) {
        selectedTab = influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED;
    } else if (pathname.includes(influencerConstants.INFLUENCER_ROUTES.ADD_INFLUENCER)) {
        return null;
    } else {
        selectedTab = influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER;
    }

    return (
        <ToggleButtonGroup
            className={clsx(styles.toggleButtonGroup)}
            value={selectedTab}
            exclusive
            onChange={(_, value) => {
                history.push(`${value}/${influencerId}`);
            }}
        >
            <StyledToggleButton
                key={influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER}
                value={influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER}
                name={influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER}
            >
                {'Edit Influencer'}
            </StyledToggleButton>
            <StyledToggleButton
                key={influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED}
                value={influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED}
                name={influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED}
            >
                {'Influencer Feed'}
            </StyledToggleButton>
        </ToggleButtonGroup>
    );
}
