export const ingredientsEndpoints = {
    INGREDIENTS_ROUTES: {
        INGREDIENTS: '/ingredients',
    },
    GET_INGREDIENTS: {
        url: process.env.REACT_APP_SERVER_URL + '/ingredients',
    },
    ADD_INGREDIENT: {
        url: process.env.REACT_APP_SERVER_URL + '/ingredients',
    },
    DELETE_INGREDIENT: {
        url: process.env.REACT_APP_SERVER_URL + '/ingredients/',
    },
    UPDATE_INGREDIENT: {
        url: process.env.REACT_APP_SERVER_URL + '/ingredients/',
    },
};
