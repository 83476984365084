import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';

const formModel = {
    formId: 'addOrEditAmbassadorForm',
    formField: {
        blog_name_en: {
            name: 'blog_name_en',
            label: en.blog_name,
            requiredErrorMsg: en.blog_name_error_msg,
        },
        blog_name_de: {
            name: 'blog_name_de',
            label: de.blog_name,
            requiredErrorMsg: de.blog_name_error_msg,
        },
        blogger_name: {
            name: 'blogger_name',
            label: en.blogger_name,
            requiredErrorMsg: en.blogger_name_error_msg,
        },
        header_picture: {
            name: 'header_picture',
            label: en.header_picture,
        },
        description_en: {
            name: 'description_en',
            label: en.description,
            requiredErrorMsg: en.description_error_msg,
        },
        description_de: {
            name: 'description_de',
            label: de.description,
            requiredErrorMsg: de.description_error_msg,
        },
        userAccountMail: {
            name: 'userAccountMail',
            label: 'User Account Mail',
        },
    },
};

const addEditInfluencerFeedModel = {
    formId: 'addOrEditInfluencerFeedForm',
    formField: {
        content: {
            name: 'content',
            label: 'Content',
        },
        titleDe: {
            name: 'titleDe',
            label: 'Title',
        },
        descriptionDe: {
            name: 'descriptionDe',
            label: 'Description',
        },
        titleEn: {
            name: 'titleEn',
            label: 'Title',
        },
        descriptionEn: {
            name: 'descriptionEn',
            label: 'Description',
        },
        publiclyVisible: {
            name: 'publiclyVisible',
            label: 'Publicly Visible',
        },
    },
};

const formModels = {
    ambassadorFormModel: formModel,
    influencerFeedFormModel: addEditInfluencerFeedModel,
};

export default formModels;
