import { authHeader } from './authHeader';

export const headers = {
    routeHeaders,
    formDataHeaders,
};

function routeHeaders() {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
}

function formDataHeaders() {
    return {
        ...authHeader(),
        'Content-Type': 'multipart/form-data;',
    };
}
