export const cookingMethodsConstants = {
    COOKING_METHODS_ACTION_TYPES: {
        GET_COOKING_METHOD_REQUEST: 'GET_COOKING_METHOD_REQUEST',
        GET_COOKING_METHOD_SUCCESS: 'GET_COOKING_METHOD_SUCCESS',
        GET_COOKING_METHOD_FAILURE: 'GET_COOKING_METHOD_FAILURE',
        GET_COOKING_METHOD_BY_ID: 'GET_COOKING_METHOD_BY_ID',
        ADD_COOKING_METHOD_REQUEST: 'ADD_COOKING_METHOD_REQUEST',
        ADD_COOKING_METHOD_SUCCESS: 'ADD_COOKING_METHOD_SUCCESS',
        ADD_COOKING_METHOD_FAILURE: 'ADD_COOKING_METHOD_FAILURE',
        UPDATE_COOKING_METHOD_REQUEST: 'UPDATE_COOKING_METHOD_REQUEST',
        UPDATE_COOKING_METHOD_SUCCESS: 'UPDATE_COOKING_METHOD_SUCCESS',
        UPDATE_COOKING_METHOD_FAILURE: 'UPDATE_COOKING_METHOD_FAILURE',
        DELETE_COOKING_METHOD: 'DELETE_COOKING_METHOD',
        UPDATE_COOKING_METHOD: 'UPDATE_COOKING_METHOD',
        DELETE_COOKING_METHOD_REQUEST: 'DELETE_COOKING_METHOD_REQUEST',
        DELETE_COOKING_METHOD_SUCCESS: 'DELETE_COOKING_METHOD_SUCCESS',
        DELETE_COOKING_METHOD_FAILURE: 'DELETE_COOKING_METHOD_FAILURE',
    },
    COOKING_METHOD_TYPES_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
    },
};
