import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const ingredientFormModel = {
    formId: uniqueId('form-'),
    formField: {
        nameEn: {
            name: 'name_en',
            label: en.name,
            requiredErrorMsg: en.title_error_msg,
        },
        nameDe: {
            name: 'name_de',
            label: de.name,
            requiredErrorMsg: de.title_error_msg,
        },
        imperialUnit: {
            name: 'imperialUnitId',
            label: en.imperial_label,
            requiredErrorMsg: en.unit_error_msg,
        },
        metricUnit: {
            name: 'metricUnitId',
            label: en.metric_label,
            requiredErrorMsg: en.unit_error_msg,
        },
        conversion: {
            name: 'conversion',
            label: en.conversion_label,
            requiredErrorMsg: en.unit_error_msg,
        },
    },
};

const formModels = {
    ingredientFormModel,
};

export default formModels;
