import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useIdParam(): number {
    const params = useParams<{
        id?: string;
    }>();

    return +params?.id;
}
