export const actionNamesConstants = {
    ACTION_NAMES_ACTION_TYPES: {
        GET_ACTION_NAMES_REQUEST: 'GET_ACTION_NAMES_REQUEST',
        GET_ACTION_NAMES_SUCCESS: 'GET_ACTION_NAMES_SUCCESS',
        GET_ACTION_NAMES_FAILURE: 'GET_ACTION_NAMES_FAILURE',
        GET_ACTION_NAME_BY_ID: 'GET_ACTION_NAME_BY_ID',
        ADD_ACTION_NAME_REQUEST: 'ADD_ACTION_NAME_REQUEST',
        ADD_ACTION_NAME_SUCCESS: 'ADD_ACTION_NAME_SUCCESS',
        ADD_ACTION_NAME_FAILURE: 'ADD_ACTION_NAME_FAILURE',
        DELETE_ACTION_NAME_REQUEST: 'DELETE_ACTION_NAME_REQUEST',
        DELETE_ACTION_NAME_SUCCESS: 'DELETE_ACTION_NAME_SUCCESS',
        DELETE_ACTION_NAME_FAILURE: 'DELETE_ACTION_NAME_FAILURE',
        UPDATE_ACTION_NAME_REQUEST: 'UPDATE_ACTION_NAME_REQUEST',
        UPDATE_ACTION_NAME_SUCCESS: 'UPDATE_ACTION_NAME_SUCCESS',
        UPDATE_ACTION_NAME_FAILURE: 'UPDATE_ACTION_NAME_FAILURE',
    },
    ACTION_NAME_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
    },

    ACTION_NAMES_ROUTES: {
        ACTION_NAMES: '/action-names',
        ACTION_NAMES_WITH_PARAMS: '/action-names/',
    },
};
