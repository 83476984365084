import { productConstants } from './upselling.constants';

export const upsellingEndpoints = {
    GET_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCTS,
    },
    GET_PRODUCT_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCTS_WITH_PARAMS,
    },
    ADD_PRODUCT: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCTS,
    },
    DELETE_PRODUCT: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCTS_WITH_PARAMS,
    },
    UPDATE_PRODUCT: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCTS_WITH_PARAMS,
    },
    GET_PRODUCT_CATEGORIES: {
        url: process.env.REACT_APP_SERVER_URL + productConstants.PRODUCT_ROUTES.PRODUCT_CATEGORIES,
    },
};
