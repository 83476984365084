export const globalSearchEndpoints = {
    ROUTES: {
        GLOBAL_SEARCH_APP_PAGES: '/remote-settings/global-search/app-pages',
    },
    SUB_ROUTES: {
        INFO: 'info',
        STEPS: 'steps',
    },
    GET_APP_PAGES: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/global-search/app-pages',
    },
    UPDATE_APP_PAGES: {
        url: process.env.REACT_APP_SERVER_URL + '/remote-settings/global-search/app-pages',
    },
};
