import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { influencerService } from '../../influencer.service';
import { IInfluencerFeed } from '../../influencer.interfaces';
import { alertActions } from '../../../../shared/actions/alert.actions';

export function useInfluencerFeedData(influencerId: number) {
    const client = useQueryClient();

    const getFeedQuery = useQuery({
        queryKey: ['getInfluencerFeedByInfluencerId', influencerId] as const,
        queryFn: ({ queryKey: [, influencerId] }) => influencerService.getInfluencerFeed(influencerId),
    });

    const deleteFeedEntryMutation = useMutation({
        mutationFn: (feedEntryId: number) => influencerService.deleteInfluencerFeedEntry(feedEntryId),
        onSettled: () =>
            client.invalidateQueries({
                queryKey: ['getInfluencerFeedByInfluencerId', influencerId],
            }),
        onError: (error) => {
            alertActions.zodError(error);
        },
    });

    const addFeedEntryMutation = useMutation({
        mutationFn: (feedEntry: IInfluencerFeed) => influencerService.addInfluencerFeedEntry(influencerId, feedEntry),
        onSettled: () =>
            client.invalidateQueries({
                queryKey: ['getInfluencerFeedByInfluencerId', influencerId],
            }),
        onError: (error) => {
            alertActions.zodError(error);
        },
    });

    const updateFeedEntryMutation = useMutation({
        mutationFn: (feedEntry: IInfluencerFeed) =>
            influencerService.updateInfluencerFeedEntry(feedEntry.id, feedEntry),
        onSettled: () =>
            client.invalidateQueries({
                queryKey: ['getInfluencerFeedByInfluencerId', influencerId],
            }),
        onError: (error) => {
            alertActions.zodError(error);
        },
    });

    return { getFeedQuery, addFeedEntryMutation, deleteFeedEntryMutation, updateFeedEntryMutation };
}
