import { connect } from 'react-redux';
import { AppState } from '../../shared/store';
import withStyles from '@material-ui/styles/withStyles';
import { muiStyles } from '../../shared/styles/mui.styles';
import { compose } from 'redux';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { DispatchPropsReturnType } from '../../shared/helpers/generics';
import ImportCsvFile from '../../shared/components/generics/import';
import { Button, ButtonGroup, TextField } from '@material-ui/core';
import clsx from 'clsx';
import generalStyles from '../../shared/styles/global.module.scss';
import { SendmailConfig } from './sendmail.config';
import styles from './index.module.scss';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { sendmailActions } from './sendmail.actions';

type OwnProps = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: AppState) => ({
    loading: state.userReports.loading,
});

const mapDispatchToProps = {
    sendMail: sendmailActions.requestSendingEmails,
};

const Sendmail = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    // Regex to match text inside square brackets
    const regex = /\[(.+?)\]/g;

    const [columns, setColumns] = useState<string[]>([]);
    const [invalidBodyPlaceholders, setInvalidBodyPlaceholders] = useState<string[]>([]);
    const [invalidSubjectPlaceholders, setInvalidSubjectPlaceholders] = useState<string[]>([]);
    const [template, setTemplate] = useState<string | null>(JSON.stringify(SendmailConfig.emailTemplate));
    const [file, setFile] = useState<File | null>(null);
    const [subject, setSubject] = useState<string>(SendmailConfig.defaultSubject);

    // all invalid placeholders
    const [allInvalidPlaceholders, setAllInvalidPlaceholders] = useState<string[]>([]);

    useEffect(() => {
        setAllInvalidPlaceholders([...invalidBodyPlaceholders, ...invalidSubjectPlaceholders]);
    }, [invalidBodyPlaceholders, invalidSubjectPlaceholders]);

    // Function to handle CSV file upload and extract columns
    const handleCsvUpload = async (file: File) => {
        setFile(file); // Update state with the uploaded file

        const text = await file.text();
        const rows = text.split('\n'); // Split file into rows
        if (rows.length > 0) {
            const header = rows[0].split(','); // Extract header (first row)
            setColumns(header.map((col) => col.trim().replaceAll('"', ''))); // Update state with trimmed column names
        }
    };

    const handleTemplateChange = (event: Draft.EditorState) => {
        const content = event.getCurrentContent();

        const rawContent = convertToRaw(content);

        if (!content.hasText()) {
            setTemplate(null);
            return;
        }

        setTemplate(JSON.stringify(content));

        const invalid = validateBodyPlaceholders(rawContent, columns);
        setInvalidBodyPlaceholders(invalid);
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subject = event.target.value;

        setSubject(subject);

        const invalid = validateSubjectPlaceholders(subject, columns);
        setInvalidSubjectPlaceholders(invalid);
    };

    const validateSubjectPlaceholders = (subject: string, validPlaceholders: string[]): string[] => {
        // Array to collect invalid placeholders
        const invalidPlaceholders: string[] = [];

        let match: RegExpExecArray | null;
        while ((match = regex.exec(subject)) !== null) {
            const placeholder = match[1]; // Extract text inside brackets

            // Check if the placeholder exists in the validPlaceholders array
            if (!validPlaceholders.includes(placeholder)) {
                invalidPlaceholders.push(placeholder);
            }
        }

        return invalidPlaceholders; // Return the array of invalid placeholders
    };

    const validateBodyPlaceholders = (
        rawContent: Draft.RawDraftContentState,
        validPlaceholders: string[],
    ): string[] => {
        // Extract all blocks from the raw content
        const blocks = rawContent.blocks;

        // Array to collect invalid placeholders
        const invalidPlaceholders: string[] = [];

        if (!Array.isArray(blocks)) {
            return invalidPlaceholders;
        }

        // Iterate over each block
        blocks.forEach((block: { text: string }) => {
            const { text } = block;

            // Find all matches of the regex in the block text
            let match: RegExpExecArray | null;
            while ((match = regex.exec(text)) !== null) {
                const placeholder = match[1]; // Extract text inside brackets

                // Check if the placeholder exists in the validPlaceholders array
                if (!validPlaceholders.includes(placeholder)) {
                    invalidPlaceholders.push(placeholder);
                }
            }
        });

        return invalidPlaceholders; // Return the array of invalid placeholders
    };

    return (
        <div className={clsx(styles.sendmail, 'mt16')}>
            <ButtonGroup className={clsx('mb4')}>
                <ImportCsvFile
                    uploadFile={handleCsvUpload}
                    allowedFileTypes={SendmailConfig.allowedFileExtensions}
                    buttonLabel="Choose a file"
                />
            </ButtonGroup>

            {columns.length > 0 && (
                <div>
                    <h4 className={clsx(styles.availableColumnsHeader)}>Available Columns:</h4>
                    <div className={clsx(styles.availableColumnsList)}>{columns.join(', ')}</div>
                </div>
            )}

            <div className={clsx(styles.topMargin)}>
                <h4>E-Mail Subject:</h4>
                <TextField className={clsx(styles.textField)} onChange={handleSubjectChange} defaultValue={subject} />
            </div>

            <div className={clsx(styles.topMargin)}>
                <h4>E-Mail Template:</h4>
                <MUIRichTextEditor
                    defaultValue={JSON.stringify(SendmailConfig.emailTemplate)}
                    onChange={handleTemplateChange}
                    onSave={(save) => console.log(save)}
                />
            </div>

            {allInvalidPlaceholders.length > 0 && file && (
                <div className={clsx(styles.invalidPlaceholderGroup, styles.topMargin)}>
                    <h6>These placeholders were not found in the CSV file header:</h6>
                    <div>{allInvalidPlaceholders.join(', ')}</div>
                </div>
            )}

            <ButtonGroup className={clsx('mb4', styles.footerButtonGroup, styles.topMargin)}>
                <Button
                    disabled={allInvalidPlaceholders.length > 0 || !file || !template || !subject}
                    className={generalStyles.formButton}
                    onClick={() => props.sendMail(file, subject, template)}
                >
                    {'Send E-Mails'}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export const SendmailComponent = compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(Sendmail);
