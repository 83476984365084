import { selectionConstants } from './selection.constants';

export const selectionEndpoints = {
    GET_SELECTIONS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS,
    },
    GET_SELECTION_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS_WITH_PARAMS,
    },
    ADD_SELECTION: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS,
    },
    DELETE_SELECTION: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS_WITH_PARAMS,
    },
    UPDATE_SELECTION: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS_WITH_PARAMS,
    },
    GET_SELECTION_DISPLAYS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_WITH_PARAMS,
    },
    GET_SELECTION_RECIPES: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS_WITH_PARAMS,
    },
    GET_RECIPE_SELECTION_CATEGORY_TAGS: {
        url:
            process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_CATEGORY_TAGS + '/',
    },

    GET_RECIPE_BY_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_BY_CATEGORY_TAGS_WITH_PARAMS,
    },

    ADD_RECIPE_SELECTION_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_CATEGORY_TAGS,
    },
    UPDATE_RECIPE_SELECTION_CATEGORY_TAGS: {
        url:
            process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_CATEGORY_TAGS + '/',
    },
    ADD_RECIPE_SELECTION_DISPLAYS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_DISPLAYS,
    },
    UPDATE_RECIPE_SELECTION_DISPLAYS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_WITH_PARAMS,
    },
    UPDATE_RECIPE_SELECTION_PINNED: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTIONS_WITH_PARAMS,
    },

    GET_RECIPE_SELECTION_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_PRODUCTS + '/',
    },
    ADD_RECIPE_SELECTION_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_PRODUCTS,
    },
    UPDATE_RECIPE_SELECTION_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.RECIPE_SELECTION_PRODUCTS + '/',
    },

    ADD_SELECTION_VALUE_TAG: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTION_VALUE_TAGS,
    },
    UPDATE_SELECTION_VALUE_TAG: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTION_VALUE_TAGS_WITH_PARAM,
    },
    GET_SELECTION_VALUE_TAG: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.SELECTION_VALUE_TAGS_WITH_PARAM,
    },
    GET_RECIPES_BY_VALUE_TAG: {
        pre_url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.PRE_RECIPES_BY_VALUE_TAGE,
        post_id_url: selectionConstants.SELECTION_ROUTES.POST_RECIPES_BY_VALUE_TAGE,
    },
    GET_RECIPES_BY_ALL_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + selectionConstants.SELECTION_ROUTES.GET_RECIPES_BY_ALL_TAGS,
    },
};
