import React from 'react';
import { CardMedia } from '@material-ui/core';
import { useState } from 'react';
import en from '../../../../../assets/language/en.json';
import { ReactComponent as UserPlaceholder } from '../../../../../assets/icons/user_placeholder.svg';

type Props = {
    pictureUrl: string;
    imageSize: string;
    fallback?: JSX.Element;
};

function RoundedImage(props: Props) {
    const { imageSize, pictureUrl, fallback } = props;

    const [hasError, setHasError] = useState(false);
    return hasError || !pictureUrl ? (
        fallback
    ) : (
        <CardMedia
            component="img"
            style={{
                width: imageSize,
                height: imageSize,
                objectFit: 'cover',
                borderRadius: '50%',
            }}
            title={en.profile_picture}
            src={pictureUrl || '../../../assets/icons/default_user.png'}
            onError={({ currentTarget }) => {
                setHasError(true);
                currentTarget.onerror = null; // prevents looping
            }}
            alt={en.profile_picture}
        />
    );
}
export default RoundedImage;
