import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { muiStyles } from '../../../styles/mui.styles';
import styles from './index.module.scss';

export interface CustomDatePickerProps {
    label: string;
    name: string;
    value: any;
    onChange: (newDate: any) => void;
    placeholder?: string;
    dateFormat: string;
}

type OwnProps = CustomDatePickerProps & KeyboardDatePickerProps;

const CustomDatePicker = (props: OwnProps) => {
    const { label, name, value, onChange, placeholder, dateFormat, ...otherProps } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={muiStyles.getMuiTheme(useTheme())}>
                <KeyboardDatePicker
                    {...otherProps}
                    className={styles.formInput}
                    label={props.label}
                    name={props.name}
                    clearable
                    value={props.value}
                    placeholder={props.placeholder || 'Date'}
                    disableToolbar={true}
                    orientation="landscape"
                    initialFocusedDate={''}
                    onChange={props.onChange}
                    format={props.dateFormat}
                    inputVariant="outlined"
                    okLabel={<p className={styles.datePickerLabel}>OK</p>}
                    cancelLabel={<p className={styles.datePickerLabel}>CANCEL</p>}
                    clearLabel={<p className={styles.datePickerLabel}>CLEAR</p>}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default CustomDatePicker;
