import { IActionNamesActions, IActionNameState } from './action.names.interfaces';
import { actionNamesConstants } from './action.names.constants';

const initialState: IActionNameState = { actionNames: [], actionName: null, loading: false };

const actionNamesReducer = (state = initialState, action: IActionNamesActions): IActionNameState => {
    switch (action.type) {
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_REQUEST:
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_REQUEST:
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_SUCCESS:
            return {
                ...state,
                actionNames: action.actionNamesData.actionNames,
                total: action.actionNamesData.total,
                loading: false,
            };
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_FAILURE:
            return {
                loading: false,
            };

        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_SUCCESS:
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_SUCCESS:
            return {
                ...state,
                actionName: action.actionName,
                loading: false,
            };
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_FAILURE:
        case actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export { actionNamesReducer };
