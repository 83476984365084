export const categoryTagsConstants = {
    CATEGORY_TAGS_ACTION_TYPES: {
        GET_CATEGORY_TAGS_REQUEST: 'GET_CATEGORY_TAGS_REQUEST',
        GET_CATEGORY_TAGS_SUCCESS: 'GET_CATEGORY_TAGS_SUCCESS',
        GET_CATEGORY_TAGS_FAILURE: 'GET_CATEGORY_TAGS_FAILURE',
        GET_CATEGORY_TAG_BY_ID: 'GET_CATEGORY_TAG_BY_ID',
        ADD_CATEGORY_TAG_REQUEST: 'ADD_CATEGORY_TAG_REQUEST',
        ADD_CATEGORY_TAG_SUCCESS: 'ADD_CATEGORY_TAG_SUCCESS',
        ADD_CATEGORY_TAG_FAILURE: 'ADD_CATEGORY_TAG_FAILURE',
        DELETE_CATEGORY_TAG_REQUEST: 'DELETE_CATEGORY_TAG_REQUEST',
        DELETE_CATEGORY_TAG_SUCCESS: 'DELETE_CATEGORY_TAG_SUCCESS',
        DELETE_CATEGORY_TAG_FAILURE: 'DELETE_CATEGORY_TAG_FAILURE',
        UPDATE_CATEGORY_TAG_REQUEST: 'UPDATE_CATEGORY_TAG_REQUEST',
        UPDATE_CATEGORY_TAG_SUCCESS: 'UPDATE_CATEGORY_TAG_SUCCESS',
        UPDATE_CATEGORY_TAG_FAILURE: 'UPDATE_CATEGORY_TAG_FAILURE',
    },
    CATEGORY_TAG_ROW_ELEMENTS: {
        ID: 1,
        TYPE_ID: 2,
        NAME_EN: 3,
        NAME_DE: 4,
    },
};
