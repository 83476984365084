import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { ICategoryTagTypeForm, ICategoryTagTypeResponse } from './category.tag.types.interfaces';
import { categoryTagTypesEndpoints } from './category.tag.types.endpoints';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getCategoryTagTypes = async (): Promise<ICategoryTagTypeResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagTypesEndpoints.GET_CATEGORY_TAG_TYPES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addCategoryTagType = async (categoryTagTypeData: ICategoryTagTypeForm): Promise<ICategoryTagTypeResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagTypesEndpoints.ADD_CATEGORY_TAG_TYPE.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: categoryTagTypeData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteCategoryTagType = async (categoryTagTypeId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagTypesEndpoints.DELETE_CATEGORY_TAG_TYPE.url + categoryTagTypeId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateCategoryTagType = async (
    categoryTagTypeData: ICategoryTagTypeForm,
    categoryTagTypeId: number,
): Promise<ICategoryTagTypeResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagTypesEndpoints.UPDATE_CATEGORY_TAG_TYPE.url + categoryTagTypeId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: categoryTagTypeData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const categoryTagTypesService = {
    getCategoryTagTypes,
    addCategoryTagType,
    deleteCategoryTagType,
    updateCategoryTagType,
};
