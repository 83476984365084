import React, { FunctionComponent, PropsWithChildren, ReactElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Step, StepButton, Stepper, WithStyles } from '@material-ui/core';
import styles from './index.module.scss';
import formModels from './formModel/form.model';
import { History } from 'history';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { stepperStyles } from '../../../recipe/pages/addRecipe/styles';
import en from '../../../../../assets/language/en.json';
import { IFullSelection, ISelectionForm } from '../../selection.interfaces';
import SelectionForm from './forms/Selection';
import AddCategoryTags from './forms/SelectionCategoryTags';
import AddProducts from './forms/SelectionProducts';
import AddRecipe from './forms/AddRecipe';
import { Link, useParams } from 'react-router-dom';
import { selectionConstants } from '../../selection.constants';
import { withRouter } from 'react-router-dom';
import ValueTagForm from './forms/SelectionValueTags';

interface IStepper extends WithStyles<typeof stepperStyles> {
    history: History;
    fullSelection: IFullSelection;
}

type Props = ISelectionForm & IStepper;

const { formId, formField } = formModels.selectionFormModel;
const valueTagFormModel = formModels.valueTagFormModel;
const steps: string[] = [
    en.selections_label,
    en.category_tags_label,
    en.value_tags_label,
    en.product_tags_label,
    en.add_recipe_label,
];

const SelectionStepper = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const { classes, fullSelection, history } = props;
    const { id, step } = useParams() as any;

    const totalSteps: number = steps.length;

    const subRoutes = selectionConstants.SELECTION_SUB_ROUTES;
    const defaultActiveStep = step ? Object.values(subRoutes).indexOf(step) : 0;

    const [activeStep, setActiveStep] = useState<number | null>(defaultActiveStep);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [selectionToUpdate, setSelectionToUpdate] = useState<IFullSelection | null>(fullSelection);

    const isLastStep = () => {
        return activeStep === totalSteps - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps;
    };

    const nextStep = (selection?: IFullSelection): void => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setSelectionToUpdate({ ...selectionToUpdate, ...selection });
        setActiveStep(newActiveStep);
        id && pushToHistory(newActiveStep);
    };

    const prevStep = (selection?: IFullSelection): void => {
        setSelectionToUpdate(selection);
        setActiveStep((prevStep: number) => prevStep - 1);
        id && pushToHistory(activeStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = (selection?: IFullSelection) => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        nextStep(selection);
        setCompleted(newCompleted);
    };

    const pushToHistory = (historyStep) => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);

        history.push(
            `${selectionConstants.SELECTION_ROUTES.EDIT_SELECTION}/${formatSubRoutes[historyStep]}/${selectionToUpdate.selection.id}`,
        );
    };

    const renderSteps = (step: number): JSX.Element => {
        switch (step) {
            case 0:
                return (
                    <SelectionForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        selectionData={selectionToUpdate}
                    />
                );
            case 1:
                return (
                    <AddCategoryTags
                        prevStep={prevStep}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        selectionData={selectionToUpdate}
                    />
                );
            case 2:
                return (
                    <ValueTagForm
                        prevStep={prevStep}
                        nextStep={nextStep}
                        formField={valueTagFormModel}
                        handleCompleteStep={handleComplete}
                        selectionData={selectionToUpdate}
                    />
                );
            case 3:
                return (
                    <AddProducts
                        prevStep={prevStep}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        selectionData={selectionToUpdate}
                    />
                );
            case 4:
                return (
                    <AddRecipe
                        formId={formModels.recipeFormModel.formId}
                        formField={formModels.recipeFormModel.formField}
                        prevStep={prevStep}
                        selectionData={selectionToUpdate}
                        handleCompleteStep={handleComplete}
                    />
                );
            default:
                return (
                    <SelectionForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        selectionData={selectionToUpdate}
                    />
                );
        }
    };
    return (
        <div className={styles.addSelectionStepperStepper}>
            <Stepper activeStep={activeStep} className={classes.stepper} variant={'outlined'} nonLinear>
                {steps.map((label: string, index: number) => {
                    const subRoute = Object.values(subRoutes)[index];
                    return (
                        <Step key={label}>
                            <StepButton
                                to={`${selectionConstants.SELECTION_ROUTES.EDIT_SELECTION}/${subRoute}/${id}`}
                                component={Link}
                                onClick={handleStep(index)}
                                completed={completed[index]}
                                disabled={!id}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {renderSteps(activeStep)}
        </div>
    );
};

export default compose(withRouter, withStyles(stepperStyles), connect(null, null))(SelectionStepper);
