import { userConstants } from '../constants/user.constants';
import { globalConstants } from '../constants/global.constants';
import { IUserState, UserActions } from '../actions/interfaces';
import { authConstants } from '../constants/auth.constants';

const initialState: IUserState = { user: null, loggedIn: false };

export function userReducer(state = initialState, action: UserActions): IUserState {
    switch (action.type) {
        case userConstants.PROFILE_REQUEST:
            return {
                ...state,
            };

        case userConstants.PROFILE_FAILURE:
            return {
                ...state,
            };
        case globalConstants.FETCH_STATE_STORAGE_SUCCESS:
            return {
                ...state,
                user: action.user,
                loggedIn: true,
            };

        case userConstants.PROFILE_SUCCESS:
            return {
                ...state,
                user: action.user,
                loggedIn: true,
            };

        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.authInfo.user,
                loggedIn: true,
            };
        default:
            return state;
    }
}
