import React from 'react';
import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { muiDataTablesConstants } from '../../../shared/constants/mui.datatables.constants';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { userReportDataTables } from '../../helpers/user.report.datatables';
import { reviewActions } from '../../reviews/reviews.actions';
import { userReportActions } from '../user.report.actions';
import { IReportsForUserResponse, IReportsFourUserProps } from '../user.report.interfaces';
import styles from './index.module.scss';

type OwnProps = IReportsFourUserProps;

const ReportsForUser = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history } = props;
    const { userId } = useParams() as any;
    const [reports, setReports] = useState<IReportsForUserResponse[]>([]);
    const [reportedUser, setReportedUser] = useState<IReportsForUserResponse['reportedUser']>(null);

    useEffect(() => {
        props.getReportsForUser(userId).then((response) => {
            setReports(response.data.reports);
            const ru = response.data.reportedUser as IReportsForUserResponse['reportedUser'];
            setReportedUser(ru);
            props.updateReportedUser(ru.id, true, ru.internalDecision);
        });
    }, []);

    const columns: MUIDataTableColumn[] = [
        ...userReportDataTables.reportsForUserColumns,
        {
            name: 'filterHelper',
            options: {
                display: false,
                filterList: reportedUser?.name ? [reportedUser.name] : [],
            },
        },
    ];

    const options: MUIDataTableOptions = {
        selectableRows: 'none',
        pagination: false,
        filterType: muiDataTablesConstants.FILTER_TYPE.MULTISELECT,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        serverSide: true,
    };

    return (
        <div className={clsx(styles.reports, 'mt16')}>
            <Button
                className={clsx(styles.blockButton, 'mb4')}
                onClick={async () => {
                    if (!reportedUser) return;
                    await props.blockUser(reportedUser.id, !reportedUser.blocked);
                    setReportedUser((old) => {
                        return { ...old, blocked: !old.blocked };
                    });
                }}
            >
                {reportedUser?.blocked ? 'UNBLOCK USER' : 'BLOCK USER'}
            </Button>
            <Paper>
                <MUIDataTable title={'Report'} options={options} columns={columns} data={reports} />
            </Paper>
        </div>
    );
};

const mapDispatchToProps = {
    getReportsForUser: userReportActions.getReportsForUser,
    blockUser: reviewActions.blockUser,
    updateReportedUser: userReportActions.updateReportedUser,
};

export default compose(withStyles(muiStyles.customStyles), connect(null, mapDispatchToProps))(ReportsForUser);
