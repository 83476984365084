import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const equipmentFormModel = {
    formId: uniqueId('form-'),
    formField: {
        nameEn: {
            name: 'nameEn',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        nameDe: {
            name: 'nameDe',
            label: de.name,
            requiredErrorMsg: en.type_required_error_msg,
        },
    },
};

const formModels = {
    equipmentFormModel: equipmentFormModel,
};

export default formModels;
