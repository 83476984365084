import formModels from './pages/addRecipe/FormModel/RecipeFormModel';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';

export const recipeConstants = {
    RECIPE_ROUTES: {
        RECIPES: '/recipes',
        RECIPES_WITH_PARAMS: '/recipes/',
        ADD_RECIPE: '/recipe/add',
        EDIT_RECIPE: '/recipe/edit',
        UPDATE_LINKED_RECIPES: (recipeId: number) => `/recipes/${recipeId}/linked-recipes`,
        EXPORT_RECIPES_FOR_COOKBOOK: (selectionId: number) => `/recipe-cookbook-export/${selectionId}`,
    },

    RECIPE_SUB_ROUTES: {
        RECIPE: 'recipe',
        CATEGORY_TAGS: 'category-tags',
        LINKED_RECIPES: 'linked-recipes',
        INGREDIENTS: 'ingredients',
        COOKING_STEPS: 'cooking-steps',
        EQUIPMENT: 'equipment',
        PRODUCTS: 'products',
    },

    RECIPE_ACTION_TYPES: {
        GET_RECIPES_REQUEST: 'GET_RECIPES_REQUEST',
        GET_RECIPES_SUCCESS: 'GET_RECIPES_SUCCESS',
        GET_RECIPES_FAILURE: 'GET_RECIPES_FAILURE',
        GET_RECIPE_BY_ID_REQUEST: 'GET_RECIPE_BY_ID_REQUEST',
        GET_RECIPE_BY_ID_SUCCESS: 'GET_RECIPE_BY_ID_SUCCESS',
        GET_RECIPE_BY_ID_FAILURE: 'GET_RECIPE_BY_ID_FAILURE',
        GET_RECIPE_BY_ID: 'GET_RECIPE_BY_ID',
        ADD_RECIPE_REQUEST: 'ADD_RECIPE_REQUEST',
        ADD_RECIPE: 'ADD_RECIPE',
        ADD_RECIPE_SUCCESS: 'ADD_RECIPE_SUCCESS',
        ADD_RECIPE_FAILURE: 'ADD_RECIPE_FAILURE',
        DELETE_RECIPE: 'DELETE_RECIPE',
        UPDATE_RECIPE_REQUEST: 'UPDATE_RECIPE_REQUEST',
        UPDATE_RECIPE_SUCCESS: 'UPDATE_RECIPE_SUCCESS',
        UPDATE_RECIPE_FAILURE: 'UPDATE_RECIPE_FAILURE',
        ADD_RECIPE_CATEGORY_TAGS_REQUEST: 'ADD_RECIPE_CATEGORY_TAGS_REQUEST',
        ADD_RECIPE_CATEGORY_TAGS_SUCCESS: 'ADD_RECIPE_CATEGORY_TAGS_SUCCESS',
        ADD_RECIPE_CATEGORY_TAGS_FAILURE: 'ADD_RECIPE_CATEGORY_TAGS_FAILURE',
        ADD_RECIPE_EQUIPMENT_REQUEST: 'ADD_RECIPE_EQUIPMENT_REQUEST',
        ADD_RECIPE_EQUIPMENT_SUCCESS: 'ADD_RECIPE_EQUIPMENT_SUCCESS',
        ADD_RECIPE_EQUIPMENT_FAILURE: 'ADD_RECIPE_EQUIPMENT_FAILURE',
        ADD_RECIPE_COOKING_STEPS_REQUEST: 'ADD_RECIPE_COOKING_STEPS_REQUEST',
        ADD_RECIPE_COOKING_STEPS_SUCCESS: 'ADD_RECIPE_COOKING_STEPS_SUCCESS',
        ADD_RECIPE_COOKING_STEPS_FAILURE: 'ADD_RECIPE_COOKING_STEPS_FAILURE',
        UPDATE_RECIPE_CATEGORY_TAGS_REQUEST: 'UPDATE_RECIPE_CATEGORY_TAGS_REQUEST',
        UPDATE_RECIPE_CATEGORY_TAGS_SUCCESS: 'UPDATE_RECIPE_CATEGORY_TAGS_SUCCESS',
        UPDATE_RECIPE_CATEGORY_TAGS_FAILURE: 'UPDATE_RECIPE_CATEGORY_TAGS_FAILURE',
        UPDATE_RECIPE_EQUIPMENT_REQUEST: 'UPDATE_RECIPE_EQUIPMENT_REQUEST',
        UPDATE_RECIPE_EQUIPMENT_SUCCESS: 'UPDATE_RECIPE_EQUIPMENT_SUCCESS',
        UPDATE_RECIPE_EQUIPMENT_FAILURE: 'UPDATE_RECIPE_EQUIPMENT_FAILURE',
        GET_RECIPE_CATEGORY_TAGS_REQUEST: 'GET_RECIPE_CATEGORY_TAGS_REQUEST',
        GET_RECIPE_CATEGORY_TAGS_SUCCESS: 'GET_RECIPE_CATEGORY_TAGS_SUCCESS',
        GET_RECIPE_CATEGORY_TAGS_FAILURE: 'GET_RECIPE_CATEGORY_TAGS_FAILURE',
        GET_RECIPE_EQUIPMENT_REQUEST: 'GET_RECIPE_EQUIPMENT_REQUEST',
        GET_RECIPE_EQUIPMENT_SUCCESS: 'GET_RECIPE_EQUIPMENT_SUCCESS',
        GET_RECIPE_EQUIPMENT_FAILURE: 'GET_RECIPE_EQUIPMENT_FAILURE',
        UPDATE_RECIPE_INGREDIENTS_REQUEST: 'UPDATE_RECIPE_INGREDIENTS_REQUEST',
        UPDATE_RECIPE_INGREDIENTS_SUCCESS: 'UPDATE_RECIPE_INGREDIENTS_SUCCESS',
        UPDATE_RECIPE_INGREDIENTS_FAILURE: 'UPDATE_RECIPE_INGREDIENTS_FAILURE',
        GET_RECIPE_INGREDIENTS_REQUEST: 'GET_RECIPE_INGREDIENTS_REQUEST',
        GET_RECIPE_INGREDIENTS_SUCCESS: 'GET_RECIPE_INGREDIENTS_SUCCESS',
        GET_RECIPE_INGREDIENTS_FAILURE: 'GET_RECIPE_INGREDIENTS_FAILURE',
        UPDATE_RECIPE_COOKING_STEPS_REQUEST: 'UPDATE_RECIPE_COOKING_STEPS_REQUEST',
        UPDATE_RECIPE_COOKING_STEPS_SUCCESS: 'UPDATE_RECIPE_COOKING_STEPS_SUCCESS',
        UPDATE_RECIPE_COOKING_STEPS_FAILURE: 'UPDATE_RECIPE_COOKING_STEPS_FAILURE',
        DELETE_RECIPE_INGREDIENT_REQUEST: 'DELETE_RECIPE_INGREDIENT_REQUEST',
        DELETE_RECIPE_INGREDIENT_SUCCESS: 'DELETE_RECIPE_INGREDIENT_SUCCESS',
        DELETE_RECIPE_INGREDIENT_FAILURE: 'DELETE_RECIPE_INGREDIENT_FAILURE',
        GET_RECIPE_COOKING_STEPS_REQUEST: 'GET_RECIPE_COOKING_STEPS_REQUEST',
        GET_RECIPE_COOKING_STEPS_SUCCESS: 'GET_RECIPE_COOKING_STEPS_SUCCESS',
        GET_RECIPE_COOKING_STEPS_FAILURE: 'GET_RECIPE_COOKING_STEPS_FAILURE',
        DELETE_RECIPE_REQUEST: 'DELETE_RECIPE_REQUEST',
        DELETE_RECIPE_SUCCESS: 'DELETE_RECIPE_SUCCESS',
        DELETE_RECIPE_FAILURE: 'DELETE_RECIPE_FAILURE',
        GET_RECIPE_COOKING_METHODS_REQUEST: 'GET_RECIPE_COOKING_METHODS_REQUEST',
        GET_RECIPE_COOKING_METHODS_SUCCESS: 'GET_RECIPE_COOKING_METHODS_SUCCESS',
        GET_RECIPE_COOKING_METHODS_FAILURE: 'GET_RECIPE_COOKING_METHODS_FAILURE',
        GET_RECIPE_PRODUCTS_REQUEST: 'GET_RECIPE_PRODUCTS_REQUEST',
        GET_RECIPE_PRODUCTS_SUCCESS: 'GET_RECIPE_PRODUCTS_SUCCESS',
        GET_RECIPE_PRODUCTS_FAILURE: 'GET_RECIPE_PRODUCTS_FAILURE',
        ADD_RECIPE_PRODUCTS_REQUEST: 'ADD_RECIPE_PRODUCTS_REQUEST',
        ADD_RECIPE_PRODUCTS_SUCCESS: 'ADD_RECIPE_PRODUCTS_SUCCESS',
        ADD_RECIPE_PRODUCTS_FAILURE: 'ADD_RECIPE_PRODUCTS_FAILURE',
        UPDATE_RECIPE_PRODUCTS_REQUEST: 'UPDATE_RECIPE_PRODUCTS_REQUEST',
        UPDATE_RECIPE_PRODUCTS_SUCCESS: 'UPDATE_RECIPE_PRODUCTS_SUCCESS',
        UPDATE_RECIPE_PRODUCTS_FAILURE: 'UPDATE_RECIPE_PRODUCTS_FAILURE',
        GET_RECIPE_GRILL_MODELS_REQUEST: 'GET_RECIPE_GRILL_MODELS_REQUEST',
        GET_RECIPE_GRILL_MODELS_SUCCESS: 'GET_RECIPE_GRILL_MODELS_SUCCESS',
        GET_RECIPE_GRILL_MODELS_FAILURE: 'GET_RECIPE_GRILL_MODELS_FAILURE',
        GET_GRILLS_REQUEST: 'GET_GRILLS_REQUEST',
        GET_GRILLS_SUCCESS: 'GET_GRILLS_SUCCESS',
        GET_GRILLS_FAILURE: 'GET_GRILLS_FAILURE',
        GET_RECIPE_TUTORIALS_REQUEST: 'GET_RECIPE_TUTORIALS_REQUEST',
        GET_RECIPE_TUTORIALS_SUCCESS: 'GET_RECIPE_TUTORIALS_SUCCESS',
        GET_RECIPE_TUTORIALS_FAILURE: 'GET_RECIPE_TUTORIALS_FAILURE',
        GET_ACTION_NAMES_REQUEST: 'GET_ACTION_NAMES_REQUEST',
        GET_ACTION_NAMES_SUCCESS: 'GET_ACTION_NAMES_SUCCESS',
        GET_ACTION_NAMES_FAILURE: 'GET_ACTION_NAMES_FAILURE',
        UPDATE_LINKED_RECIPES_REQUEST: 'UPDATE_LINKED_RECIPES_REQUEST',
        UPDATE_LINKED_RECIPES_SUCCESS: 'UPDATE_LINKED_RECIPES_SUCCESS',
        UPDATE_LINKED_RECIPES_FAILURE: 'UPDATE_LINKED_RECIPES_FAILURE',
    },
    YES: 'YES',
    NO: 'NO',
    COOKING_METHOD: {
        DIRECT: 'DIRECT',
        INDIRECT: 'INDIRECT',
    },
    DRAG_AND_DROP: {
        CATEGORY_TAGS_SOURCE: 'Available category tags',
        CATEGORY_TAGS_DESTINATION: 'Recipe category tags',
        EQUIPMENT_SOURCE: 'Available equipment',
        EQUIPMENT_DESTINATION: 'Recipe equipment',
        PRODUCTS_SOURCE: 'Available products',
        PRODUCTS_DESTINATION: 'Recipe products',
        KEYS: {
            SOURCE_COLUMN: 'SOURCE_COLUMN',
            DESTINATION_COLUMN: 'DESTINATION_COLUMN',
        },
    },
    RECIPE_MESSAGES: {
        RECIPE_CATEGORY_TAGS_SUCCESS: 'Category tags added successfully',
        RECIPE_INGREDIENTS_SUCCESS: 'Ingredients added successfully',
        RECIPE_COOKING_STEPS_SUCCESS: 'Cooking steps added successfully',
        RECIPE_EQUIPMENT_SUCCESS: 'Equipment added successfully',
    },
    RECIPE_COOKING_STEPS_PANELS: {
        LONG_DESCRIPTION_PANEL: 'LONG_DESCRIPTION_PANEL',
        LONG_DESCRIPTION_PANEL_LABEL: 'LONG DESCRIPTION',
        SHORT_DESCRIPTION_PANEL: 'SHORT_DESCRIPTION_PANEL',
        SHORT_DESCRIPTION_PANEL_LABEL: 'SHORT DESCRIPTION',
        GENERAL_PANEL: 'GENERAL_PANEL',
        GENERAL_PANEL_LABEL: 'GENERAL PANEL',
        TARGET_ZONES_PANEL: 'TARGET_ZONES_PANEL',
        TARGET_ZONES_PANEL_LABEL: 'TARGET ZONES',
        TARGET_PROBES_PANEL: 'TARGET_PROBES_PANEL',
        TARGET_PROBES_PANEL_LABEL: 'TARGET PROBES',
        TABLE_LABEL: 'Cooking Steps',
    },
    RECIPE_INGREDIENTS_ROW_ELEMENTS: {
        ID: 0,
        NAME: 1,
    },
    RECIPE_COOKING_STEPS_ROW_ELEMENTS: {
        STEP_NUMBER: 1,
    },
    RECIPE_LABEL: 'Recipe',
    descriptionsFriendlyRequiredMessage: {
        long_description_en:
            formModels.cookingStepsFormModel.cookingStepsFormField.long_description_en.requiredErrorMsg,
        long_description_de:
            formModels.cookingStepsFormModel.cookingStepsFormField.long_description_de.requiredErrorMsg,
        short_description_en:
            formModels.cookingStepsFormModel.cookingStepsFormField.short_description_en.requiredErrorMsg,
        short_description_de:
            formModels.cookingStepsFormModel.cookingStepsFormField.short_description_en.requiredErrorMsg,
    },
    descriptionsFriendlySavedMessage: {
        long_description_en: en.long_descriptions_saved_successfully,
        long_description_de: de.long_descriptions_saved_successfully,
        short_description_en: en.short_descriptions_saved_successfully,
        short_description_de: de.short_descriptions_saved_successfully,
    },
    RECIPE_PICTURES: {
        THUMBNAIL: 0,
        FULL: 1,
    },
    GRILL_ROUTES: {
        GRILLS: '/grills',
    },
    GRILL_CONSTANTS: {
        NO_GRILL: 'NO GRILL',
        NO_GRILL_VALUE: 0,
    },
    ACTION_NAMES_ROUTES: {
        ACTION_NAMES: '/action-names',
    },
    RECIPE_EQUIPMENT_ROUTES: {
        RECIPE_EQUIPMENT: '/recipe-equipment/',
    },
};
