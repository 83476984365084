import { unitConstants } from './unit.constants';

export const unitEndpoints = {
    GET_UNITS: {
        url: process.env.REACT_APP_SERVER_URL + unitConstants.UNIT_ROUTES.UNITS,
    },
    ADD_UNIT: {
        url: process.env.REACT_APP_SERVER_URL + unitConstants.UNIT_ROUTES.UNITS_WITH_PARAMS,
    },
    UPDATE_UNIT: {
        url: process.env.REACT_APP_SERVER_URL + unitConstants.UNIT_ROUTES.UNITS_WITH_PARAMS,
    },
    DELETE_UNIT: {
        url: process.env.REACT_APP_SERVER_URL + unitConstants.UNIT_ROUTES.UNITS_WITH_PARAMS,
    },
    FIXED_CONVERSION_RATES: {
        url: process.env.REACT_APP_SERVER_URL + unitConstants.UNIT_ROUTES.FIXED_CONVERSION_RATES,
    },
};
