import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { recipeAction } from '../../../recipe.actions';
import { IRecipeEquipment, IRecipeEquipmentForm, IRecipeEquipmentProps } from '../../../recipe.interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import { recipeConstants } from '../../../recipe.constants';
import clsx from 'clsx';
import { equipmentActions } from '../../../../equipment/equipment.actions';
import { IEquipment } from '../../../../equipment/equipment.interfaces';

const AddEquipment = (
    props: PropsWithChildren<IRecipeEquipmentProps>,
): ReactElement<FunctionComponent<IRecipeEquipmentProps>> => {
    const [equipment, setEquipment] = useState<Array<IEquipment>>([]);
    const [recipeEquipments, setRecipeEquipments] = useState<Array<IRecipeEquipment>>([]);
    const [changedEquipment, setChangedEquipment] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const { recipe } = props;

    useEffect(() => {
        const isMounted = true;
        const isFirstMount = equipment.length + recipeEquipments.length === 0;

        isFirstMount &&
            props.getEquipment({ ...globalConstants.DEFAULT_PAGINATION }).then((response) => {
                const equipmentList = response.equipmentList;

                if (isMounted) {
                    props.getRecipeEquipment(props.recipe.id).then((response) => {
                        const recipeEquipmentList: Array<IRecipeEquipment> = response.recipeEquipments;

                        if (recipeEquipmentList.length) {
                            const addedRecipeEquipment = recipeEquipmentList.filter(
                                (recipeEquip) =>
                                    !recipeEquipments.find((equipment) => {
                                        return equipment.id === recipeEquip.id;
                                    }),
                            );
                            const updatedRecipeEquipment = recipeEquipments.concat(addedRecipeEquipment);

                            setIsUpdating(true);
                            setRecipeEquipments(updatedRecipeEquipment);

                            const equipmentToDisplay = equipmentList.filter(
                                (equipment) =>
                                    !recipeEquipmentList.find((recipeEquip) => {
                                        return recipeEquip.id === equipment.id;
                                    }),
                            );

                            setEquipment(equipmentToDisplay);
                        } else {
                            setEquipment(equipmentList);
                        }
                    });
                }
            });
    }, []);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedEquipment) : handleInsert(changedEquipment);
    };

    const handleUpdate = (equipmentIds: Array<number>): void => {
        const equipmentToAdd = { recipe_id: recipe.id, equipment_ids: equipmentIds };

        props.updateRecipeEquipment(props.recipe.id, equipmentToAdd).then(() => {
            props.nextStep(recipe);
        });
    };

    const handleInsert = (equipment_ids: Array<number>): void => {
        const equipmentToSave: IRecipeEquipmentForm = {
            recipe_id: recipe.id,
            equipment_ids,
        };

        props.addRecipeEquipment(equipmentToSave).then(() => {
            props.nextStep(recipe);
        });
    };

    return (
        <>
            {equipment.length || recipeEquipments.length ? (
                <DragAndDrop
                    destinationColumnName={recipeConstants.DRAG_AND_DROP.EQUIPMENT_DESTINATION}
                    sourceColumnName={recipeConstants.DRAG_AND_DROP.EQUIPMENT_SOURCE}
                    items={recipeEquipments}
                    availableItems={equipment}
                    handleItemsChange={setChangedEquipment}
                    endScroll={[setEndScroll, endScroll]}
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.nextStep(props.recipe)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.prevStep(props.recipe)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handleSubmit}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                </Button>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    getEquipment: equipmentActions.getEquipment,
    addRecipeEquipment: recipeAction.addRecipeEquipment,
    getRecipeEquipment: recipeAction.getRecipeEquipment,
    updateRecipeEquipment: recipeAction.updateRecipeEquipment,
};

export default compose(connect(null, mapDispatchToProps))(AddEquipment);
