import { influencerConstants } from './influencer.constants';

export const influencerEndpoints = {
    GET_INFLUENCERS: {
        url: process.env.REACT_APP_SERVER_URL + influencerConstants.INFLUENCER_API_URLS.INFLUENCERS,
    },
    GET_INFLUENCER_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + influencerConstants.INFLUENCER_API_URLS.INFLUENCERS_WITH_PARAMS,
    },
    ADD_INFLUENCER: {
        url: process.env.REACT_APP_SERVER_URL + influencerConstants.INFLUENCER_API_URLS.INFLUENCERS,
    },
    DELETE_INFLUENCER: {
        url: process.env.REACT_APP_SERVER_URL + influencerConstants.INFLUENCER_API_URLS.INFLUENCERS_WITH_PARAMS,
    },
    UPDATE_INFLUENCER: {
        url: process.env.REACT_APP_SERVER_URL + influencerConstants.INFLUENCER_API_URLS.INFLUENCERS_WITH_PARAMS,
    },
    GET_INFLUNECER_FEED: {
        url: (influencerId: number) =>
            process.env.REACT_APP_SERVER_URL +
            influencerConstants.INFLUENCER_API_URLS.INFLUENCER_FEED_WITH_PARAMS(influencerId),
    },
    ADD_INFLUNECER_FEED_ENTRY: {
        url: (influencerId: number) =>
            process.env.REACT_APP_SERVER_URL +
            influencerConstants.INFLUENCER_API_URLS.INFLUENCER_FEED_WITH_PARAMS(influencerId),
    },
    UPDATE_INFLUNECER_FEED_ENTRY: {
        url: (feedEntryId: number) =>
            process.env.REACT_APP_SERVER_URL +
            influencerConstants.INFLUENCER_API_URLS.INFLUENCER_FEED_WITH_PARAMS(feedEntryId),
    },
    DELETE_INFLUNECER_FEED_ENTRY: {
        url: (feedEntryId: number) =>
            process.env.REACT_APP_SERVER_URL +
            influencerConstants.INFLUENCER_API_URLS.INFLUENCER_FEED_WITH_PARAMS(feedEntryId),
    },
};
