import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { categoryTagsEndpoints } from './category.tags.endpoints';
import { authHeader } from '../../shared/helpers/authHeader';
import { ICategoryTagForm, ICategoryTagsResponse } from './category.tags.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getCategoryTags = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<ICategoryTagsResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagsEndpoints.GET_CATEGORY_TAGS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: camelToSnakeCase(paging.orderBy),
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addCategoryTag = async (categoryTagData: ICategoryTagForm): Promise<ICategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagsEndpoints.ADD_CATEGORY_TAG.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: categoryTagData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteCategoryTag = async (categoryTagId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagsEndpoints.DELETE_CATEGORY_TAG.url + categoryTagId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateCategoryTag = async (
    categoryTagData: ICategoryTagForm,
    categoryTagId: number,
): Promise<ICategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: categoryTagsEndpoints.UPDATE_CATEGORY_TAG.url + categoryTagId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: categoryTagData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const categoryTagsService = {
    getCategoryTags,
    addCategoryTag,
    deleteCategoryTag,
    updateCategoryTag,
};
