import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnalyticsState, QueryRequest } from './analytics.interfaces';

const initialState: AnalyticsState = {
    globalSelectedFilters: {},
    querySpecificFilters: {},
};

export type SetFilterPayload = {
    filterKey: string;
    filterValue: QueryRequest[string] | null;
};

export type SetQuerySpecificFiltersPayload = SetFilterPayload & {
    queryKey: string;
};

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setGlobalSelectedFilters: (state, action: PayloadAction<SetFilterPayload>) => {
            const { filterKey, filterValue } = action.payload;
            if (filterValue === null) {
                if (filterKey in state.globalSelectedFilters) {
                    delete state.globalSelectedFilters[filterKey];
                }
                return;
            }
            state.globalSelectedFilters[filterKey] = filterValue;
        },
        setQuerySpecificFilters: (state, action: PayloadAction<SetQuerySpecificFiltersPayload>) => {
            const { filterKey, filterValue, queryKey } = action.payload;
            if (filterValue === null) {
                if (queryKey in state.querySpecificFilters && filterKey in state.querySpecificFilters[queryKey]) {
                    delete state.querySpecificFilters[queryKey][filterKey];
                }
                return;
            }
            if (!(queryKey in state.querySpecificFilters)) {
                state.querySpecificFilters[queryKey] = {};
            }
            state.querySpecificFilters[queryKey][filterKey] = filterValue;
        },
    },
});

export const analyticsActions = analyticsSlice.actions;
