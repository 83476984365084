const ingredient = {
    name_en: '',
    name_de: '',
    imperialUnitId: null,
    metricUnitId: null,
    conversion: null,
};

export const formInitialValues = {
    ingredient,
};
