import {
    IFullSelection,
    IPinnedSelectionRecipesResponse,
    ISelection,
    ISelectionsDisplaysResponse,
    IValueTagsData,
    IValueTagsForm,
} from '../selection/selection.interfaces';
import { IGrillModel, IRecipeData } from '../recipe/recipe.interfaces';
import { ICookingMethodData } from '../cookingMethods/cooking.methods.interfaces';
import { IngredientData } from '../ingredients/ingredients.interfaces';
import { IInfluencer } from '../influencers/influencer.interfaces';
import { genericHelpers } from '../../shared/helpers/generics';

const createFullSelectionObject = (
    selectionResponse: ISelection,
    selectionDisplaysResponse: ISelectionsDisplaysResponse,
    cookingMethods: Array<ICookingMethodData>,
    grillModels: Array<IGrillModel>,
    ingredients: Array<IngredientData>,
    ambassadors: Array<IInfluencer>,
    valueTags: IValueTagsForm,
): IFullSelection => {
    return {
        selection: {
            id: selectionResponse.id,
            displays: selectionDisplaysResponse.recipeSelectionDisplays.map((d) => d.display),
            name_en: selectionResponse.name_en,
            name_de: selectionResponse.name_de,
        },
        cookingMethods,
        grillModels,
        ingredients,
        ambassadors,
        valueTags,
    };
};

const valueTagsFormToData = (form: IValueTagsForm): IValueTagsData => {
    return {
        id: form.id,
        recipeSelectionId: form.recipeSelectionId,
        preparationTime: form.preparationTime,
        restingTime: form.restingTime,
        ambassadorId: form.ambassadorId,
        totalTime: form.totalTime,
        cookingTimeFrom: form.cookingTimeFrom,
        cookingTimeTo: form.cookingTimeTo,
        rating: form.rating,
        servings: form.servings,
        zoneTemperatureFrom: form.zoneTemperatureFrom,
        zoneTemperatureTo: form.zoneTemperatureTo,
        difficulty: form.difficulty,
        cookingMethods: form.cookingMethodIds
            ? form.cookingMethodIds.map((id) => {
                  return { id };
              })
            : [],
        grillModels: form.grillModelIds
            ? form.grillModelIds.map((id) => {
                  return { id };
              })
            : [],
        ingredients: form.ingredientIds ? form.ingredientIds : [],
    };
};

const valueTagsDataToForm = (data: IValueTagsData): any => {
    return {
        id: data.id,
        recipeSelectionId: genericHelpers.isNullOrUndefined(data.recipeSelectionId) ? '' : data.recipeSelectionId,
        preparationTime: data.preparationTime || '',
        restingTime: data.restingTime || '',
        ambassadorId: data.ambassadorId || '',
        totalTime: data.totalTime || '',
        cookingTimeFrom: data.cookingTimeFrom || '',
        cookingTimeTo: data.cookingTimeTo || '',
        rating: data.rating || '',
        servings: data.servings || '',
        zoneTemperatureFrom: data.zoneTemperatureFrom || '',
        zoneTemperatureTo: data.zoneTemperatureTo || '',
        difficulty: data.difficulty || '',
        cookingMethodIds: data.cookingMethods ? data.cookingMethods.map((cm) => cm.id) : [],
        grillModelIds: data.grillModels ? data.grillModels.map((gm) => gm.id) : [],
        ingredientIds: data.ingredients
            ? data.ingredients.map((ingredient: any) => {
                  return {
                      ...ingredient,
                      nameDe: ingredient.name_de,
                      nameEn: ingredient.name_en,
                  };
              })
            : [],
    };
};

export const selectionHelpers = {
    createFullSelectionObject,
    valueTagsDataToForm,
    valueTagsFormToData,
};
