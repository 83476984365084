import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../../shared/styles/global.module.scss';
import en from '../../../../assets/language/en.json';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, ISort, IRowsDeleted } from '../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../shared/constants/mui.datatables.constants';
import clsx from 'clsx';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { IEquipment, IEquipmentProps } from '../equipment.interfaces';
import { equipmentActions as equipmentActions } from '../equipment.actions';
import { equipmentDataTables } from '../../helpers/equipment.datatables';
import AddOrEditEquipment from './addOrEditEquipment';
import formModels from './addOrEditEquipment/formModel/equipment.form.model';
import { equipmentConstants } from '../equipment.constants';

const Equipment = (props: PropsWithChildren<IEquipmentProps>): ReactElement<IEquipmentProps> => {
    const [equipment, setEquipment] = useState<Array<IEquipment>>([]);
    const [filters, setFilters] = useState<IFilters | null>();
    const [sort, setSort] = useState<ISort | null>();
    const [pagination, setPagination] = useState<IPagination | null>();
    const componentIsMounted = useRef(true);

    const { formId, formField } = formModels.equipmentFormModel;

    const tableColumns = [
        ...equipmentDataTables.equipmentColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: IEquipment = {
                        id: tableMeta.rowData[equipmentConstants.EQUIPMENT_ROW_ELEMENTS.ID],
                        nameEn: tableMeta.rowData[equipmentConstants.EQUIPMENT_ROW_ELEMENTS.NAME_EN],
                        nameDe: tableMeta.rowData[equipmentConstants.EQUIPMENT_ROW_ELEMENTS.NAME_DE],
                    };
                    return (
                        <AddOrEditEquipment
                            formId={formId}
                            formField={formField}
                            equipment={row}
                            refreshData={_refreshEquipment}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        setRowProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        setCellProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const equipmentToRemove = rowsDeleted.data.map(({ index }) => index);
            const equipmentToRemoveLength = equipmentToRemove.length;

            equipmentToRemove.map(async (equipmentIndex, index) => {
                const equipmentId = equipment[equipmentIndex].id;
                const completed = equipmentToRemoveLength === index + 1;

                await props.deleteEquipment(equipmentId, completed);
                completed && (await _refreshEquipment());
            });
        },
    };

    useEffect(() => {
        props.getEquipment(filters, sort, pagination).then((response) => {
            if (componentIsMounted.current) {
                setEquipment(response.equipmentList);
                componentIsMounted.current = false;
            }
        });
    }, []);

    const _refreshEquipment = (): void => {
        props.getEquipment(filters, sort, pagination).then((response) => {
            setEquipment(response.equipmentList);
        });
    };

    return (
        <div className={styles.generalContainer}>
            <AddOrEditEquipment formId={formId} formField={formField} refreshData={_refreshEquipment} />
            <MUIDataTable title={en.equipment_label} options={options} columns={tableColumns} data={equipment} />
        </div>
    );
};

const mapDispatchToProps = {
    getEquipment: equipmentActions.getEquipment,
    deleteEquipment: equipmentActions.deleteEquipment,
    updateEquipment: equipmentActions.updateEquipment,
};

export default compose(withStyles(muiStyles.customStyles), connect(null, mapDispatchToProps))(Equipment);
