import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PublicLayout from './app/shared/components/layouts/PublicLayout';
import PrivateLayout from './app/shared/components/layouts/PrivateLayout';
import ProtectedLayout from './app/shared/components/layouts/ProtectedLayout';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

toast.configure();
const queryClient = new QueryClient({
    defaultOptions: {
        //,
    },
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path="/app" component={PublicLayout} />
                        <Route path="/ext" component={ProtectedLayout} />
                        <Route path="/" component={PrivateLayout} />
                    </Switch>
                </BrowserRouter>
                <ToastContainer />
            </div>
        </QueryClientProvider>
    );
}

export default App;
