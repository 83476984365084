import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';

export const tutorialConstants = {
    TUTORIAL_ACTION_TYPES: {
        GET_TUTORIALS_REQUEST: 'GET_TUTORIALS_REQUEST',
        GET_TUTORIALS_SUCCESS: 'GET_TUTORIALS_SUCCESS',
        GET_TUTORIALS_FAILURE: 'GET_TUTORIALS_FAILURE',
        GET_TUTORIAL_BY_ID_REQUEST: 'GET_TUTORIAL_BY_ID_REQUEST',
        GET_TUTORIAL_BY_ID_SUCCESS: 'GET_TUTORIAL_BY_ID_SUCCESS',
        GET_TUTORIAL_BY_ID_FAILURE: 'GET_TUTORIAL_BY_ID_FAILURE',
        ADD_TUTORIAL_REQUEST: 'ADD_TUTORIAL_REQUEST',
        ADD_TUTORIAL_SUCCESS: 'ADD_TUTORIAL_SUCCESS',
        ADD_TUTORIAL_FAILURE: 'ADD_TUTORIAL_FAILURE',
        DELETE_TUTORIAL_REQUEST: 'DELETE_TUTORIAL_REQUEST',
        DELETE_TUTORIAL_SUCCESS: 'DELETE_TUTORIAL_SUCCESS',
        DELETE_TUTORIAL_FAILURE: 'DELETE_TUTORIAL_FAILURE',
        UPDATE_TUTORIAL_REQUEST: 'UPDATE_TUTORIAL_REQUEST',
        UPDATE_TUTORIAL_SUCCESS: 'UPDATE_TUTORIAL_SUCCESS',
        UPDATE_TUTORIAL_FAILURE: 'UPDATE_TUTORIAL_FAILURE',
        ADD_TUTORIAL_STEP_REQUEST: 'ADD_TUTORIAL_STEP_REQUEST',
        ADD_TUTORIAL_STEP_SUCCESS: 'ADD_TUTORIAL_STEP_SUCCESS',
        ADD_TUTORIAL_STEP_FAILURE: 'ADD_TUTORIAL_STEP_FAILURE',
        GET_TUTORIAL_STEPS_REQUEST: 'GET_TUTORIAL_STEPS_REQUEST',
        GET_TUTORIAL_STEPS_SUCCESS: 'GET_TUTORIAL_STEPS_SUCCESS',
        GET_TUTORIAL_STEPS_FAILURE: 'GET_TUTORIAL_STEPS_FAILURE',
        UPDATE_TUTORIAL_STEP_REQUEST: 'UPDATE_TUTORIAL_STEP_REQUEST',
        UPDATE_TUTORIAL_STEP_SUCCESS: 'UPDATE_TUTORIAL_STEP_SUCCESS',
        UPDATE_TUTORIAL_STEP_FAILURE: 'UPDATE_TUTORIAL_STEP_FAILURE',
        DELETE_TUTORIAL_STEP_REQUEST: 'DELETE_TUTORIAL_STEP_REQUEST',
        DELETE_TUTORIAL_STEP_SUCCESS: 'DELETE_TUTORIAL_STEP_SUCCESS',
        DELETE_TUTORIAL_STEP_FAILURE: 'DELETE_TUTORIAL_STEP_FAILURE',
        GET_TUTORIAL_PRODUCTS_REQUEST: 'GET_TUTORIAL_PRODUCTS_REQUEST',
        GET_TUTORIAL_PRODUCTS_SUCCESS: 'GET_TUTORIAL_PRODUCTS_SUCCESS',
        GET_TUTORIAL_PRODUCTS_FAILURE: 'GET_TUTORIAL_PRODUCTS_FAILURE',
        ADD_TUTORIAL_PRODUCTS_REQUEST: 'ADD_TUTORIAL_PRODUCTS_REQUEST',
        ADD_TUTORIAL_PRODUCTS_SUCCESS: 'ADD_TUTORIAL_PRODUCTS_SUCCESS',
        ADD_TUTORIAL_PRODUCTS_FAILURE: 'ADD_TUTORIAL_PRODUCTS_FAILURE',
        UPDATE_TUTORIAL_PRODUCTS_REQUEST: 'UPDATE_TUTORIAL_PRODUCTS_REQUEST',
        UPDATE_TUTORIAL_PRODUCTS_SUCCESS: 'UPDATE_TUTORIAL_PRODUCTS_SUCCESS',
        UPDATE_TUTORIAL_PRODUCTS_FAILURE: 'UPDATE_TUTORIAL_PRODUCTS_FAILURE',
        IMPORT_FILE_TUTORIAL_STEP: 'IMPORT_FILE_TUTORIAL_STEP',
    },
    TUTORIAL_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
        HEADER_PICTURE: 4,
    },
    descriptionsFriendlySavedMessage: {
        text_en: en.step_description_saved_successfully,
        text_de: de.step_description_saved_successfully,
    },
    TUTORIALS_ROUTES: {
        TUTORIALS: '/tutorials',
        TUTORIALS_WITH_PARAMS: '/tutorials/',
        ADD_TUTORIAL: '/tutorials/add',
        EDIT_TUTORIAL: '/tutorials/edit',
        STEPS: '/steps',
        TUTORIAL_STEPS: '/tutorial-steps/',
    },

    TUTORIALS_SUB_ROUTES: {
        TUTORIAL: 'tutorial',
        TUTORIAL_STEP: 'step',
        TUTORIAL_PRODUCTS: 'products',
    },

    DRAG_AND_DROP: {
        PRODUCTS_SOURCE: 'Available products',
        PRODUCTS_DESTINATION: 'Tutorial products',
    },
};
