import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import ImageModal from '../../shared/components/generics/imageModal/ImageModal';
import moment from 'moment';

const quizQuestionColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'questionImage',
        label: en.picture_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string) {
                if (pictureUrl) {
                    return (
                        <ImageModal
                            baseStyle={{
                                width: '96px',
                                height: '96px',
                                borderRadius: '8px',
                            }}
                            title={'Quiz Question Image'}
                            image={pictureUrl}
                            alt={'Quiz Question Image'}
                        />
                    );
                }
                return <></>;
            },
        },
    },
    {
        name: 'questionEn',
        label: 'Question',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'correctAnswerEn',
        label: 'Correct Answer',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'wrongAnswerEn',
        label: 'Wrong Answer',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'answerExplanationEn',
        label: 'Answer Explanation',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
];

const quizRoundColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'quizName',
        label: 'Quiz Name',
        options: {
            display: true,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'startDate',
        label: 'Start Date',
        options: {
            display: true,
            sort: false,
            filter: false,
            customBodyRender(value: unknown): string {
                return moment(value).utc().calendar();
            },
        },
    },
];

export const quizQuestionTables = {
    quizQuestionColumns,
    quizRoundColumns,
};
