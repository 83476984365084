import { unitConstants } from './unit.constants';
import { IUnitActions, IUnitState } from './unit.interfaces';

const initialState: IUnitState = {
    conversionRates: [],
    units: [],
    unit: null,
    loading: false,
};

const unitReducer = (state = initialState, action: IUnitActions): IUnitState => {
    switch (action.type) {
        case unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_SUCCESS:
            return {
                ...state,
                conversionRates: action.conversionRates,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.GET_UNITS_SUCCESS:
            return {
                ...state,
                units: action.unitsData.units,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_REQUEST:
        case unitConstants.UNIT_ACTION_TYPES.GET_UNITS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case unitConstants.UNIT_ACTION_TYPES.GET_CONVERSION_RATES_FAILURE:
        case unitConstants.UNIT_ACTION_TYPES.GET_UNITS_FAILURE:
            return {
                loading: false,
            };
        default:
            return state;

        case unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_SUCCESS:
            return {
                ...state,
                unit: action.unitData,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.ADD_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                unit: action.unitData,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.UPDATE_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case unitConstants.UNIT_ACTION_TYPES.DELETE_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
            };
    }
};

export { unitReducer };
