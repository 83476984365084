import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { IEquipmentActions, IEquipmentResponse, IEquipment } from './equipment.interfaces';
import equipmentService from './equipment.service';
import { equipmentConstants } from './equipment.constants';
import en from '../../../assets/language/en.json';

function getEquipment(): (dispatch: Dispatch) => Promise<IEquipmentResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return equipmentService.getEquipment().then(
            (response: IEquipmentResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_REQUEST };
    }

    function success(response: IEquipmentResponse): IEquipmentActions {
        return {
            type: equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_SUCCESS,
            equipmentList: response,
        };
    }

    function failure(error: string): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.GET_EQUIPMENT_FAILURE, error };
    }
}

const addEquipment = (equipment: IEquipment): ((dispatch: Dispatch) => Promise<IEquipmentResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return equipmentService.addEquipment(equipment).then(
            (response: IEquipmentResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.equipment_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_REQUEST };
    }

    function success(response: IEquipmentResponse): IEquipmentActions {
        return {
            type: equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_SUCCESS,
            equipment: response.data,
        };
    }

    function failure(error: string): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.ADD_EQUIPMENT_FAILURE, error };
    }
};

const updateEquipment = (
    equipment: IEquipment,
    id: number,
): ((dispatch: Dispatch) => Promise<IEquipmentResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return equipmentService.updateEquipment(equipment, id).then(
            (response: IEquipmentResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.equipment_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_REQUEST };
    }

    function success(response: IEquipmentResponse): IEquipmentActions {
        return {
            type: equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_SUCCESS,
            equipment: response.data,
        };
    }

    function failure(error: string): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.UPDATE_EQUIPMENT_FAILURE, error };
    }
};

const deleteEquipment = (
    id: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return equipmentService.deleteEquipment(id).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.equipment_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IEquipmentActions {
        return { type: equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_REQUEST };
    }

    function success(response: AxiosResponse): IEquipmentActions {
        return {
            type: equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_SUCCESS,
        };
    }

    function failure(error: string): IEquipmentActions {
        return {
            type: equipmentConstants.EQUIPMENT_ACTION_TYPES.DELETE_EQUIPMENT_FAILURE,
            error,
        };
    }
};

export const equipmentActions = {
    getEquipment,
    addEquipment,
    updateEquipment,
    deleteEquipment,
};
