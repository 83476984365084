import { Theme, createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const stepperStyles = (theme: Theme): StyleRules =>
    createStyles({
        stepper: {
            marginTop: '3rem',
            padding: theme.spacing(3, 0, 5),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
        modalStepper: {
            padding: theme.spacing(3, 0, 5),
        },
    });

export { stepperStyles };
