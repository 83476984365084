import { userConstants } from '../../shared/constants/user.constants';
import { reviewConstants } from './reviews.constants';

export const reviewEndpoints = {
    GET_REVIEWS: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.REVIEWS,
    },
    DELETE_REVIEW: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.REVIEW_WITH_PARAMS,
    },
    BLOCK_USER: {
        url: process.env.REACT_APP_SERVER_URL + userConstants.USER_ROUTES.BLOCK_USER,
    },
    TOGGLE_READ_STATUS: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.TOGGLE_READ_STATUS,
    },
    UNREAD_REVIEWS_COUNT: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.UNREAD_REVIEWS_COUNT,
    },
    GET_BLACKLIST: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.BLACKLIST,
    },
    ADD_BLACKLIST: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.BLACKLIST_WITH_PARAMS,
    },
    UPDATE_BLACKLIST: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.BLACKLIST_WITH_PARAMS,
    },
    DELETE_BLACKLIST: {
        url: process.env.REACT_APP_SERVER_URL + reviewConstants.REVIEW_ROUTES.BLACKLIST_WITH_PARAMS,
    },
};
