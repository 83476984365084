import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const categoryTagTypeFormModel = {
    formId: uniqueId('form-'),
    formField: {
        name_en: {
            name: 'name_en',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        name_de: {
            name: 'name_de',
            label: de.name,
            requiredErrorMsg: de.name_required_error_msg,
        },
    },
};

const formModels = {
    categoryTagTypeFormModel,
};

export default formModels;
