import React, { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import formModels from './formModel/form.model';
import FilterStepper from './stepper';
import { IAddOrEditFilter, IFilter } from '../../filter.interfaces';
import { useParams } from 'react-router-dom';
import { filterAction } from '../../filter.actions';
import { AppState } from '../../../../shared/store';
import filter from '../filter';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type FilterProps = typeof formModels.filterFormModel & IAddOrEditFilter & ReturnType<typeof mapStateToProps>;

const AddOrEditFilter = (props: PropsWithChildren<FilterProps>): JSX.Element => {
    const id = useIdParam();

    useEffect(() => {
        async function getData() {
            await props.getFilterById(id).then();
        }

        id && getData();
    }, []);

    return <FilterStepper key={id ? props.filter?.id : null} />;
};

const mapStateToProps = (state: AppState) => {
    return {
        filter: state.filters.filter,
    };
};

const mapDispatchToProps = {
    getFilterById: filterAction.getFilterById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditFilter);
