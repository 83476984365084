import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import styles from '../../../ingredients/pages/ingredient/index.module.scss';
import en from '../../../../../assets/language/en.json';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted, ISort } from '../../../../shared/components/interfaces';
import { categoryTagTypesDataTables } from '../../../helpers/category.tag.types.datatables';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import clsx from 'clsx';
import { ICategoryTagTypeData, ICategoryTagTypeForm, ICategoryTagTypeProps } from '../../category.tag.types.interfaces';
import { categoryTagTypesActions } from '../../category.tag.types.actions';
import { compose } from 'recompose';
import { categoryTagTypeConstants } from '../../category.tag.types.constants';
import AddOrEditCategoryTagType from '../addOrEditCategoryTagTypes';
import formModels from '../addOrEditCategoryTagTypes/formModel/category.tag.type.form.model';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';

const CategoryTagTypes = (props: PropsWithChildren<ICategoryTagTypeProps>): ReactElement<ICategoryTagTypeProps> => {
    const [categoryTagTypes, setCategoryTagTypes] = useState<Array<ICategoryTagTypeData>>([]);
    const [filters, setFilters] = useState<IFilters | null>();
    const [sort, setSort] = useState<ISort | null>();
    const [pagination, setPagination] = useState<IPagination | null>();
    const componentIsMounted = useRef(true);
    const { formId, formField } = formModels.categoryTagTypeFormModel;
    const tableColumns = [
        ...categoryTagTypesDataTables.categoryTagTypesColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: ICategoryTagTypeForm = {
                        id: tableMeta.rowData[categoryTagTypeConstants.CATEGORY_TAG_TYPES_ROW_ELEMENTS.ID],
                        name_en: tableMeta.rowData[categoryTagTypeConstants.CATEGORY_TAG_TYPES_ROW_ELEMENTS.NAME_EN],
                        name_de: tableMeta.rowData[categoryTagTypeConstants.CATEGORY_TAG_TYPES_ROW_ELEMENTS.NAME_DE],
                    };
                    return (
                        <AddOrEditCategoryTagType
                            formId={formId}
                            formField={formField}
                            categoryTagTypeData={row}
                            refreshData={_refreshCategoryTagTypes}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        setRowProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        setCellProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const categoryTagTypesToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const categoryTagTypesLength = categoryTagTypesToRemoveIndex.length;

            categoryTagTypesToRemoveIndex.map(async (categoryTagTypeIndex, index) => {
                const categoryTagTypeId = categoryTagTypes[categoryTagTypeIndex].id;
                const completed = categoryTagTypesLength === index + 1;
                await props.deleteCategoryTagType(categoryTagTypeId, completed);
                completed && (await _refreshCategoryTagTypes());
            });
        },
    };

    useEffect(() => {
        props.getCategoryTagTypes(filters, sort, pagination).then((response) => {
            if (componentIsMounted.current) {
                setCategoryTagTypes(response.categoryTagTypes);
                componentIsMounted.current = false;
            }
        });
    }, []);

    const _refreshCategoryTagTypes = (): void => {
        props.getCategoryTagTypes(filters, sort, pagination).then((response) => {
            setCategoryTagTypes(response.categoryTagTypes);
        });
    };

    return (
        <div className={clsx(styles.ingredient, 'mt16')}>
            <AddOrEditCategoryTagType formId={formId} formField={formField} refreshData={_refreshCategoryTagTypes} />
            <MUIDataTable
                title={en.category_tag_types}
                options={options}
                columns={tableColumns}
                data={categoryTagTypes}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    getCategoryTagTypes: categoryTagTypesActions.getCategoryTagTypes,
    deleteCategoryTagType: categoryTagTypesActions.deleteCategoryTagType,
};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(CategoryTagTypes);
