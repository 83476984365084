import en from '../../../../../../../assets/language/en.json';
import de from '../../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const blacklistFormModel = {
    formId: uniqueId('form-'),
    formField: {
        wordEn: {
            name: 'wordEn',
            label: en.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
        wordDe: {
            name: 'wordDe',
            label: de.name,
            requiredErrorMsg: en.name_required_error_msg,
        },
    },
};

const formModels = {
    blacklistFormModel,
};

export default formModels;
