import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Step,
    StepButton,
    Stepper,
    TextField,
    WithStyles,
    useTheme,
    withStyles,
} from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import clsx from 'clsx';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { CustomIcons } from '../../../../../assets/icons';
import en from '../../../../../assets/language/en.json';
import DragDropTable from '../../../../shared/components/generics/dragDropTable/DragDropTable';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType, genericHelpers } from '../../../../shared/helpers/generics';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { AppState } from '../../../../shared/store';
import { troubleshootTables } from '../../../helpers/troubleshoot.datatables';
import { stepperStyles } from '../../../recipe/pages/addRecipe/styles';
import { troubleshotActions } from '../../troubleshoot.actions';
import { troubleshootEndpoints } from '../../troubleshoot.endpoints';
import { ITroubleshootLocalization, ITroubleshootPrimaryStep, ITroubleshootStep } from '../../troubleshoot.interfaces';
import { formInitialValues } from '../addOrEditTroubleshoot/formModel/form.initial.values';
import validationSchemas from '../addOrEditTroubleshoot/formModel/validation.schema';
import styles from './index.module.scss';
import CustomCheckbox from '../../../../shared/components/generics/checkbox/Checkbox';

type OwnProps = {
    prevStep: () => void;
} & DispatchPropsReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

enum DialogState {
    CHOOSE_STEP_TYPE,
    BASIC_STEP,
    PLATFORM_SPECIFIC_STEP,
}

const TroubleshootSteps = (props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { getTroubleshoot, getTroubleshootStepsByPrimaryStepId, updateTroubleshootSteps, prevStep } = props;

    const troubleshootId = useIdParam();

    const [troubleshootPrimarySteps, setTroubleshootPrimarySteps] = useState<ITroubleshootPrimaryStep[]>([]);
    const [selectedPrimaryStepIdx, setSelectedPrimaryStepIdx] = useState<number>(null);
    const [updatingTroubleshootSteps, setUpdatingTroubleshootSteps] = useState<ITroubleshootStep[]>([]);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(null);
    const [updatingTroubleshootStepIdx, setUpdatingTroubleshootStepIdx] = useState<number | null>(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [currentlySelectedLanguage, setCurrentlySelectedLanguage] = useState<'enEnTranslation' | 'deDeTranslation'>(
        'enEnTranslation',
    );

    useEffect(() => {
        const fetchData = async () => {
            if (troubleshootId) {
                const troubleshoot = await getTroubleshoot(troubleshootId);
                if (troubleshoot.troubleshootingPrimarySteps && troubleshoot.troubleshootingPrimarySteps.length > 0) {
                    setTroubleshootPrimarySteps(troubleshoot.troubleshootingPrimarySteps);
                    setSelectedPrimaryStepIdx(0);
                }
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (troubleshootPrimarySteps?.[selectedPrimaryStepIdx]?.id) {
                const steps = await getTroubleshootStepsByPrimaryStepId(
                    troubleshootPrimarySteps[selectedPrimaryStepIdx].id,
                );
                if (!(steps instanceof Error)) {
                    setUpdatingTroubleshootSteps(steps);
                }
            }
        };
        fetchData();
    }, [selectedPrimaryStepIdx]);

    useEffect(() => {
        const updatingStep = updatingTroubleshootSteps[updatingTroubleshootStepIdx];
        if (updatingStep?.defaultDescription) {
            setDialogState(DialogState.BASIC_STEP);
            return;
        }
        if (
            updatingStep?.semanticFirmwareIOS ||
            updatingStep?.semanticFirmwareAndroid ||
            updatingStep?.oldFirmwareIOS ||
            updatingStep?.oldFirmwareAndroid
        ) {
            setDialogState(DialogState.PLATFORM_SPECIFIC_STEP);
            return;
        }
        setDialogState(null);
    }, [updatingTroubleshootStepIdx]);

    useEffect(() => {
        zodUtils
            .zodFormikValidator(validationSchemas.troubleshootStepsSchema)(updatingTroubleshootSteps)
            .then((errors) => {
                setValidationErrors(errors);
            });
    }, [updatingTroubleshootSteps]);

    const handleSubmit = async (values: ITroubleshootStep[]): Promise<void> => {
        const saveResult = await updateTroubleshootSteps(values, troubleshootPrimarySteps[selectedPrimaryStepIdx].id);
        if (saveResult instanceof Error || !saveResult) {
            return;
        }
        setIsChanged(false);
    };

    const history = useHistory();
    const handleFinish = () => {
        history.push(troubleshootEndpoints.ROUTES.TROUBLESHOOTS);
    };

    const saveStep = (values: ITroubleshootStep) => {
        if (updatingTroubleshootStepIdx !== null) {
            setUpdatingTroubleshootSteps((prev) => {
                const newState = [...prev];
                newState[updatingTroubleshootStepIdx] = { ...values, stepNumber: updatingTroubleshootStepIdx };
                return newState;
            });
        } else {
            setUpdatingTroubleshootSteps((prev) => {
                return [
                    ...prev,
                    {
                        ...values,
                        stepNumber: prev.length,
                    },
                ];
            });
        }
        setUpdatingTroubleshootStepIdx(null);
        setIsChanged(true);
    };

    const dialogClose = () => {
        setDialogState(null);
        setUpdatingTroubleshootStepIdx(null);
    };

    const tableColumns: MUIDataTableColumnDef[] = [
        {
            name: 'stepNumber',
            label: en.step_number,
            options: {
                filter: false,
                sort: false,
                customBodyRender: function stepNumberRenderer(value: string | number, meta) {
                    return +value + 1;
                },
            },
        },
        {
            name: 'isResolvingStep',
            label: 'Resolving Step',
            options: {
                filter: false,
                sort: false,
                customBodyRender: function stepNumberRenderer(value: boolean, meta) {
                    return (
                        <CustomCheckbox
                            checked={value}
                            onChange={(event) => {
                                setUpdatingTroubleshootSteps((prev) => {
                                    const newState = [...prev];
                                    newState[meta.rowIndex] = {
                                        ...newState[meta.rowIndex],
                                        isResolvingStep: event.target.checked,
                                    };
                                    return newState;
                                });
                                setIsChanged(true);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    );
                },
            },
        },
        ...troubleshootTables.troubleshootStepsColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender: function EditButton(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={clsx(styles.redButton, 'mr2')}
                            onClick={() => setUpdatingTroubleshootStepIdx(tableMeta.rowIndex)}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const tableOptions: MUIDataTableOptions = {
        filter: false,
        responsive: muiDataTablesConstants.RESPONSIVE.STANDARD,
        pagination: false,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.SINGLE,
        onRowsDelete: (rowsDeleted: IRowsDeleted) => {
            const stepsToRemoveIndex = rowsDeleted.data.map((row) => row.index);
            setUpdatingTroubleshootSteps((prev) => {
                const newState = prev
                    // Filter out the deleted steps
                    .filter((_, index) => !stepsToRemoveIndex.includes(index))
                    // Map the new index to correct step_number
                    .map((entity, index) => ({
                        ...entity,
                        stepNumber: index,
                    }));
                return newState;
            });
            setIsChanged(true);
        },
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = genericHelpers
            .reorder(updatingTroubleshootSteps, result.source.index, result.destination.index)
            .map((item, index) => ({
                ...item,
                stepNumber: index,
            }));

        setIsChanged(true);
        setUpdatingTroubleshootSteps(items);
    };

    return (
        <Paper className={clsx(styles.paper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.container, 'p4')}>
                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                    <TextField
                        className={clsx(styles.formInput, styles.primaryStepSelect)}
                        label={'Primary Step'}
                        type="text"
                        required
                        key={selectedPrimaryStepIdx}
                        disabled={troubleshootPrimarySteps.length === 0 || isChanged}
                        onChange={(event) => {
                            setSelectedPrimaryStepIdx(+event.target.value);
                        }}
                        value={selectedPrimaryStepIdx}
                        variant="outlined"
                        select
                        SelectProps={{
                            multiple: false,
                        }}
                    >
                        {troubleshootPrimarySteps.map((primaryStep, index) => {
                            return (
                                <MenuItem key={primaryStep.id} value={index}>
                                    {`${primaryStep.stepNumber + 1}. ${primaryStep.titleEn} [${primaryStep.titleDe}]`}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <FormLabel
                        className={clsx(styles.primaryStepSelect, styles.primaryStepSelectLabel)}
                        required={false}
                    >
                        {isChanged ? 'Save the steps before changing the primary step' : ''}
                    </FormLabel>
                </FormControl>
                <Grid item xs={12} className={styles.topLeftNavigation}>
                    <Button
                        className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                        variant={'outlined'}
                        onClick={() => {
                            setDialogState(DialogState.CHOOSE_STEP_TYPE);
                        }}
                    >
                        ADD NEW STEP
                    </Button>
                    <Button
                        className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                        style={{
                            marginLeft: 'auto',
                        }}
                        variant={'outlined'}
                        onClick={() => handleSubmit(updatingTroubleshootSteps)}
                        disabled={Object.keys(validationErrors).length > 0 || !isChanged}
                    >
                        Save Steps
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DragDropTable
                        title={'Troubleshoot Steps'}
                        data={updatingTroubleshootSteps}
                        columns={tableColumns}
                        options={tableOptions}
                        onDragEnd={onDragEnd}
                    />
                </Grid>
                <AddEditDialog
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    saveStep={saveStep}
                    updatingStep={updatingTroubleshootSteps?.[updatingTroubleshootStepIdx]}
                    onClose={dialogClose}
                    currentlySelectedLanguage={currentlySelectedLanguage}
                    setCurrentlySelectedLanguage={setCurrentlySelectedLanguage}
                />
                <div className={clsx(styles.bottomNavigation, 'mt4', 'mb2')}>
                    <TroubleShootStepErrors validationErrors={validationErrors} />
                    <Button
                        className={clsx(styles.redButton)}
                        variant={'outlined'}
                        onClick={async () => {
                            if (isChanged) {
                                await handleSubmit(updatingTroubleshootSteps);
                            }
                            handleFinish();
                        }}
                        disabled={Object.keys(validationErrors).length > 0 || !isChanged}
                    >
                        Finish
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    getTroubleshoot: troubleshotActions.getTroubleshoot,
    getTroubleshootStepsByPrimaryStepId: troubleshotActions.getTroubleshootStepsByPrimaryStepId,
    updateTroubleshootSteps: troubleshotActions.updateTroubleshootSteps,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TroubleshootSteps);

type DialogProps = {
    dialogState: DialogState;
    setDialogState: (newState: DialogState) => void;
    saveStep: (step: ITroubleshootStep) => void;
    updatingStep?: ITroubleshootStep;
    onClose: () => void;
    currentlySelectedLanguage: 'enEnTranslation' | 'deDeTranslation';
    setCurrentlySelectedLanguage: (newLanguage: 'enEnTranslation' | 'deDeTranslation') => void;
};

const AddEditDialog = (props: DialogProps): JSX.Element => {
    const {
        dialogState,
        setDialogState,
        saveStep,
        updatingStep,
        onClose,
        currentlySelectedLanguage,
        setCurrentlySelectedLanguage,
    } = props;
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={dialogState !== null}
            maxWidth={
                dialogState === DialogState.BASIC_STEP || dialogState === DialogState.PLATFORM_SPECIFIC_STEP
                    ? 'lg'
                    : 'md'
            }
            fullWidth={true}
            className={styles.dialog}
        >
            {renderDialogContent()}
        </Dialog>
    );

    function dialogSaveStep(step: ITroubleshootStep) {
        saveStep(step);
        setDialogState(null);
    }

    function renderDialogContent() {
        switch (dialogState) {
            case DialogState.BASIC_STEP:
                return (
                    <BasicStepDialog
                        key={'BasicStepDialog'}
                        saveStep={dialogSaveStep}
                        updatingStep={updatingStep}
                        currentlySelectedLanguage={currentlySelectedLanguage}
                        setCurrentlySelectedLanguage={setCurrentlySelectedLanguage}
                    />
                );
            case DialogState.PLATFORM_SPECIFIC_STEP:
                return (
                    <PlatformSpecificDialog
                        key={'PlatformSpecificDialog'}
                        saveStep={dialogSaveStep}
                        updatingStep={updatingStep}
                        currentlySelectedLanguage={currentlySelectedLanguage}
                        setCurrentlySelectedLanguage={setCurrentlySelectedLanguage}
                    />
                );
            default:
                return <ChooseStepTypeDialog key={'ChooseStepTypeDialog'} setDialogState={setDialogState} />;
        }
    }
};

type ChooseStepTypeDialogProps = {
    setDialogState: (newState: DialogState) => void;
};

const ChooseStepTypeDialog = (props: ChooseStepTypeDialogProps): JSX.Element => {
    const { setDialogState } = props;

    return (
        <>
            <DialogTitle id="customized-dialog-title">{'Choose Step Type'}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className={styles.chooseStepCardWrapper}>
                            <div
                                className={styles.chooseStepCard}
                                onClick={() => {
                                    setDialogState(DialogState.BASIC_STEP);
                                }}
                            >
                                <CustomIcons.TroubleshootBasicIcon className={styles.icon} />
                                <p className={styles.label}>Basic Troubleshoot</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={styles.chooseStepCardWrapper}>
                            <div
                                className={styles.chooseStepCard}
                                onClick={() => {
                                    setDialogState(DialogState.PLATFORM_SPECIFIC_STEP);
                                }}
                            >
                                <CustomIcons.TroubleshootPlatformSpecificIcon className={styles.icon} />
                                <p className={styles.label}>Platform Specific Troubleshoot</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

type BasicStepDialogProps = {
    saveStep: (step: ITroubleshootStep) => void;
    updatingStep?: ITroubleshootStep;
    currentlySelectedLanguage: 'enEnTranslation' | 'deDeTranslation';
    setCurrentlySelectedLanguage: (newLanguage: 'enEnTranslation' | 'deDeTranslation') => void;
};

const BasicStepDialog = (props: BasicStepDialogProps): JSX.Element => {
    const { saveStep, updatingStep, currentlySelectedLanguage, setCurrentlySelectedLanguage } = props;

    const [step, setStep] = useState<ITroubleshootStep>(updatingStep || formInitialValues.basicTroubleshootStep);

    return (
        <div className={styles.dialogContents}>
            <TroubleshootMarkdownEditor
                name="defaultDescription"
                value={step.defaultDescription as ITroubleshootLocalization}
                onChange={(newValue) =>
                    setStep((prev) => {
                        return {
                            ...prev,
                            defaultDescription: newValue,
                        };
                    })
                }
                currentlySelectedLanguage={currentlySelectedLanguage}
            />
            <TextField
                className={styles.languageSelect}
                select
                value={currentlySelectedLanguage}
                onChange={(e) => {
                    setCurrentlySelectedLanguage(e.target.value as 'enEnTranslation' | 'deDeTranslation');
                }}
                variant={'outlined'}
                SelectProps={{
                    multiple: false,
                }}
            >
                <MenuItem value="enEnTranslation">
                    <span className={styles.languageWrapper}>
                        <span className="mx-2">English</span>
                        <LanguageFlag country="us" />
                    </span>
                </MenuItem>
                <MenuItem value="deDeTranslation">
                    <span className={styles.languageWrapper}>
                        <span className="mx-2">Deutsch</span>
                        <LanguageFlag country="de" />
                    </span>
                </MenuItem>
            </TextField>
            <div className={clsx(styles.bottomNavigation)}>
                <Button
                    className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                    variant={'outlined'}
                    onClick={() => {
                        saveStep(step);
                    }}
                >
                    Save Step
                </Button>
            </div>
        </div>
    );
};

type PlatformSpecificDialogProps = {
    saveStep: (step: ITroubleshootStep) => void;
    updatingStep?: ITroubleshootStep;
    currentlySelectedLanguage: 'enEnTranslation' | 'deDeTranslation';
    setCurrentlySelectedLanguage: (newLanguage: 'enEnTranslation' | 'deDeTranslation') => void;
} & WithStyles<typeof stepperStyles>;

const stepperSteps: string[] = [
    'Old Firmware iOS',
    'Old Firmware Android',
    'Semantic Firmware iOS',
    'Semantic Firmware Android',
];
const PlatformSpecificDialog = compose(withStyles(stepperStyles))((props: PlatformSpecificDialogProps): JSX.Element => {
    const { saveStep, updatingStep, currentlySelectedLanguage, setCurrentlySelectedLanguage } = props;

    const [stepperActiveStep, setStepperActiveStep] = useState(0);
    const [troubleshootStep, setTroubleshootStep] = useState<ITroubleshootStep>(
        updatingStep || formInitialValues.platformSpecificTroubleshootStep,
    );

    const stepperStepComponents = [
        <TroubleshootMarkdownEditor
            key={'old-firmware-ios'}
            name={'old-firmware-ios'}
            value={troubleshootStep.oldFirmwareIOS as ITroubleshootLocalization}
            onChange={(newValue) =>
                setTroubleshootStep((prev) => {
                    return {
                        ...prev,
                        oldFirmwareIOS: newValue,
                    };
                })
            }
            currentlySelectedLanguage={currentlySelectedLanguage}
        />,
        <TroubleshootMarkdownEditor
            key={'old-firmware-android'}
            name={'old-firmware-android'}
            value={troubleshootStep.oldFirmwareAndroid as ITroubleshootLocalization}
            onChange={(newValue) =>
                setTroubleshootStep((prev) => {
                    return {
                        ...prev,
                        oldFirmwareAndroid: newValue,
                    };
                })
            }
            currentlySelectedLanguage={currentlySelectedLanguage}
        />,
        <TroubleshootMarkdownEditor
            key={'semantic-firmware-ios'}
            name={'semantic-firmware-ios'}
            value={troubleshootStep.semanticFirmwareIOS as ITroubleshootLocalization}
            onChange={(newValue) =>
                setTroubleshootStep((prev) => {
                    return {
                        ...prev,
                        semanticFirmwareIOS: newValue,
                    };
                })
            }
            currentlySelectedLanguage={currentlySelectedLanguage}
        />,
        <TroubleshootMarkdownEditor
            key={'semantic-firmware-android'}
            name={'semantic-firmware-android'}
            value={troubleshootStep.semanticFirmwareAndroid as ITroubleshootLocalization}
            onChange={(newValue) =>
                setTroubleshootStep((prev) => {
                    return {
                        ...prev,
                        semanticFirmwareAndroid: newValue,
                    };
                })
            }
            currentlySelectedLanguage={currentlySelectedLanguage}
        />,
    ];

    function checkCompleted(index: number): boolean {
        switch (index) {
            case 0:
                return (
                    troubleshootStep.oldFirmwareIOS?.enEnTranslation?.length > 0 &&
                    troubleshootStep.oldFirmwareIOS?.deDeTranslation?.length > 0
                );
            case 1:
                return (
                    troubleshootStep.oldFirmwareAndroid?.enEnTranslation?.length > 0 &&
                    troubleshootStep.oldFirmwareAndroid?.deDeTranslation?.length > 0
                );
            case 2:
                return (
                    troubleshootStep.semanticFirmwareIOS?.enEnTranslation?.length > 0 &&
                    troubleshootStep.semanticFirmwareIOS?.deDeTranslation?.length > 0
                );
            case 3:
                return (
                    troubleshootStep.semanticFirmwareAndroid?.enEnTranslation?.length > 0 &&
                    troubleshootStep.semanticFirmwareAndroid?.deDeTranslation?.length > 0
                );
            default:
                return false;
        }
    }

    return (
        <div className={styles.dialogContents}>
            <Stepper activeStep={stepperActiveStep} className={props.classes.stepper} variant={'outlined'} nonLinear>
                {stepperSteps.map((label: string, index: number) => {
                    return (
                        <Step key={label}>
                            <StepButton
                                onClick={() => {
                                    setStepperActiveStep(index);
                                }}
                                completed={checkCompleted(index)}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {stepperStepComponents[stepperActiveStep] || stepperStepComponents[0]}
            <TextField
                className={styles.languageSelect}
                select
                value={currentlySelectedLanguage}
                onChange={(e) => {
                    setCurrentlySelectedLanguage(e.target.value as 'enEnTranslation' | 'deDeTranslation');
                }}
                variant={'outlined'}
                SelectProps={{
                    multiple: false,
                }}
            >
                <MenuItem value="enEnTranslation">
                    <span className={styles.languageWrapper}>
                        <span className="mx-2">English</span>
                        <LanguageFlag country="us" />
                    </span>
                </MenuItem>
                <MenuItem value="deDeTranslation">
                    <span className={styles.languageWrapper}>
                        <span className="mx-2">Deutsch</span>
                        <LanguageFlag country="de" />
                    </span>
                </MenuItem>
            </TextField>
            <div className={clsx(styles.bottomNavigation)}>
                <Button
                    className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                    variant={'outlined'}
                    onClick={() => {
                        saveStep(troubleshootStep);
                    }}
                >
                    Save Step
                </Button>
            </div>
        </div>
    );
});

type TroubleshootMarkdownEditorProps = {
    value: ITroubleshootLocalization;
    name: string;
    onChange: (newValue: ITroubleshootLocalization) => void;
    currentlySelectedLanguage: 'enEnTranslation' | 'deDeTranslation';
};

const TroubleshootMarkdownEditor = (props: TroubleshootMarkdownEditorProps): JSX.Element => {
    const { value, onChange, name, currentlySelectedLanguage } = props;

    return (
        <div data-color-mode={useTheme().palette.type}>
            <MDEditor
                key={`MDEditor-${currentlySelectedLanguage}-${name}`}
                autoFocus
                value={value?.[currentlySelectedLanguage] ?? ''}
                onChange={(newValue) => {
                    if (!newValue && value && typeof value === 'object' && !anyOtherLanguageHasValue(value)) {
                        onChange(null);
                        return;
                    }
                    onChange({
                        ...value,
                        [currentlySelectedLanguage]: newValue,
                    });
                }}
                height={500}
            />
        </div>
    );

    function anyOtherLanguageHasValue(localizationObject: ITroubleshootLocalization) {
        return Object.keys(localizationObject)
            .map((key) => {
                if (key !== currentlySelectedLanguage) {
                    return localizationObject[key];
                }
                return null;
            })
            .find((val) => !!val);
    }
};

function TroubleShootStepErrors(props: { validationErrors: Record<string, unknown> }) {
    const { validationErrors } = props;
    const errorKeys = Object.keys(validationErrors);
    if (errorKeys?.length > 0) {
        return (
            <FormHelperText className={clsx(styles.error_span, 'mt2', 'mr4')}>
                {errorKeys.map((key) => {
                    return (
                        <>
                            <span key={key}>{`${key}: ${validationErrors[key]}`}</span>
                            <br />
                        </>
                    );
                })}
            </FormHelperText>
        );
    }
    return null;
}
