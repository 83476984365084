import { convertToRaw, EditorState } from 'draft-js';
import { ICookingStepsForm, IRecipeForm, IRecipeIngredientsForm } from '../../../recipe.interfaces';

const recipe: IRecipeForm = {
    name_en: '',
    name_de: '',
    thumbnail_picture: null,
    full_picture: null,
    difficulty_en: '',
    resting_time: 0,
    preparation_time: 0,
    cooking_time: 0,
    overnightTime: false,
    serving: 1,
    minimumServing: 1,
    servingSteps: 1,
    cooking_method_ids: [],
    ambassador_id: undefined as number,
    grillModelIds: [],
    shouldTimeScale: false,
    tutorialIds: [],
    proRecipe: false,
    adminRecipe: false,
    hintTitleDe: '',
    hintTitleEn: '',
    hintDescriptionDe: '',
    hintDescriptionEn: '',
    linkedRecipes: undefined,
};

const recipeCookingMethods = {
    cooking_method_ids: [],
};

const normalCookingStep: ICookingStepsForm = {
    id: null,
    step_number: null as unknown as number,
    duration: null as unknown as number,
    relevant_for_dashboard: false,
    is_interval: false,
    short_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    short_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    first_zone: null as unknown as number,
    second_zone: null as unknown as number,
    third_zone: null as unknown as number,
    fourth_zone: null as unknown as number,
    master_probe: null as unknown as number,
    actions: [],
};

const dashboardRelevantCookingStep: ICookingStepsForm = {
    id: null,
    step_number: null as unknown as number,
    duration: null as unknown as number,
    relevant_for_dashboard: true,
    is_interval: false,
    short_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    short_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    first_zone: null as unknown as number,
    second_zone: null as unknown as number,
    third_zone: null as unknown as number,
    fourth_zone: null as unknown as number,
    master_probe: null as unknown as number,
    actions: [],
    cooking_step_method: null,
};

const linkedRecipeCookingStep: ICookingStepsForm = {
    id: null,
    step_number: null as unknown as number,
    duration: null as unknown as number,
    relevant_for_dashboard: false,
    is_interval: false,
    short_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    short_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    long_description_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    first_zone: null as unknown as number,
    second_zone: null as unknown as number,
    third_zone: null as unknown as number,
    fourth_zone: null as unknown as number,
    master_probe: null as unknown as number,
    actions: [],
    cooking_step_method: null,
};

const ingredientsInitialValues: IRecipeIngredientsForm = {
    ingredient: null,
    metricQuantity: null,
    imperialQuantity: null,
    isMeasurementUnitsConversionOn: true,
};

const formInitialValues = {
    recipeInitialValues: recipe,
    normalCookingStep,
    dashboardRelevantCookingStep,
    linkedRecipeCookingStep,
    ingredientsInitialValues,
    recipeCookingMethodsInitialValues: recipeCookingMethods,
};

export default formInitialValues;
