import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { formInitialValues } from '../formModel/form.initial.values';
import { Form, Formik, FormikProps } from 'formik';
import { ITutorialFormComponent, ITutorialData, ITutorialForm } from '../../../tutorial.interfaces';
import validationSchemas from '../formModel/validation.schema';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import styles from '../index.module.scss';
import formModels from '../formModel/form.model';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import en from '../../../../../../assets/language/en.json';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { tutorialActions } from '../../../tutorial.actions';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import ImportFile from '../../../../import/pages/import.file';
import clsx from 'clsx';
import LanguageFlag from '../../../../../shared/components/generics/languageFlag/LanguageFlag';
import { Link, useParams } from 'react-router-dom';
import { tutorialConstants } from '../../../tutorial.constants';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';

type Props = ITutorialFormComponent & typeof formModels.tutorialFormModel;

const TutorialForm = (props: PropsWithChildren<Props>): ReactElement => {
    const {
        formField: { title_en, title_de, header_picture },
        tutorialData,
    } = props;
    const formInitials = tutorialData ? tutorialData : formInitialValues.tutorial;
    const hiddenHeaderPictureInput = React.useRef(null);

    const id = useIdParam();

    const handleSubmit = (values: ITutorialForm): void => {
        tutorialData ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: ITutorialForm): void => {
        props.addTutorial(values).then((response) => {
            props.handleCompleteStep(response.data, 'tutorial-step');
        });
    };
    const handleUpdate = (values: ITutorialForm): void => {
        props.updateTutorial(genericHelpers.removeIdFromObject(values), values.id).then((response) => {
            props.handleCompleteStep(response.data, 'tutorial-step');
        });
    };

    const handleUploadClick = (): void => {
        hiddenHeaderPictureInput.current.click();
    };

    const nextStep = (): void => {
        props.nextStep(tutorialData);
    };

    return (
        <div className={clsx(styles.tutorial, 'mt8')}>
            <Formik
                initialValues={formInitials}
                onSubmit={(values: ITutorialForm, { setSubmitting }) => {
                    handleSubmit(values);
                    setSubmitting(false);
                }}
                validationSchema={validationSchemas.tutorialValidationSchema}
                enableReinitialize={true}
            >
                {(props: FormikProps<ITutorialForm>) => (
                    <Form className={styles.addTutorialForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={title_en.label}
                                            name={title_en.name}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.title_en}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.title_en && props.touched.title_en
                                                ? props.errors.title_en
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={title_de.label}
                                            name={title_de.name}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.title_de}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.title_de && props.touched.title_de
                                                ? props.errors.title_de
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={styles.formControl}>
                                        <Card>
                                            <CardActionArea className={styles.actionArea}>
                                                <ImportFile
                                                    file={props.values.header_picture}
                                                    title={en.header_picture}
                                                    style={styles.imgCard}
                                                    remove={() => props.setFieldValue('header_picture', null)}
                                                />
                                            </CardActionArea>
                                            <CardActions>
                                                <input
                                                    accept="file/"
                                                    id="importFile"
                                                    type="file"
                                                    className={styles.fileInput}
                                                    ref={hiddenHeaderPictureInput}
                                                    onChange={(event) => {
                                                        props.setFieldValue(
                                                            header_picture.name,
                                                            event.currentTarget.files[
                                                                globalConstants.KEYS.FIRST_FILE_INDEX
                                                            ],
                                                        );
                                                    }}
                                                />
                                                <Button
                                                    className={styles.uploadImg}
                                                    onClick={handleUploadClick}
                                                    startIcon={<CloudUploadIcon />}
                                                >
                                                    {en.upload_header_picture}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <div className={styles.bottomNavigation}>
                            <Button
                                className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                onClick={nextStep}
                                disabled={!tutorialData}
                            >
                                {en.next}
                            </Button>
                            <Button
                                type="submit"
                                className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                            >
                                {en.save_button_label}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const mapDispatchToProps = {
    addTutorial: tutorialActions.addTutorial,
    updateTutorial: tutorialActions.updateTutorial,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(TutorialForm);
