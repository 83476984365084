import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { categoryTagsAction } from '../../../../categoryTags/category.tags.actions';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import { ICategoryTagsData } from '../../../../categoryTags/category.tags.interfaces';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import {
    IFilterCategoryTag,
    IFilterCategoryTagsForm,
    IFilterCategoryTagsFormComponent,
} from '../../../filter.interfaces';
import { filterConstants } from '../../../filter.constants';
import { filterAction } from '../../../filter.actions';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type Props = IFilterCategoryTagsFormComponent & ReturnType<typeof mapStateToProps>;

const AddCategoryTags = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [categoryTags, setCategoryTags] = useState<Array<ICategoryTagsData>>([]);
    const [filterCategoryTags, setFilterCategoryTags] = useState<Array<IFilterCategoryTag>>([]);
    const [changedCategories, setChangedCategories] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const { filter, history, categoryTagsTotal } = props;

    useEffect(() => {
        const isMounted = true;
        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            categoryTagsTotal,
            categoryTags.length + filterCategoryTags.length,
            page,
        );
        const isFirstMount = categoryTags.length + filterCategoryTags.length === 0;

        (hasPagination || isFirstMount) &&
            props.getCategoryTags({ ...globalConstants.DEFAULT_PAGINATION, page: nextPage }).then((response) => {
                const categoryTagsResponse = response.categoryTags;

                if (isMounted) {
                    props.getFilterCategoryTags(filter.id).then((response) => {
                        const filterCategoryTagsResponse: Array<IFilterCategoryTag> = response.filterCategoryTags;

                        if (Array.isArray(filterCategoryTagsResponse)) {
                            const newFilterCategoryTagsResponse = filterCategoryTagsResponse.filter(
                                (filterCategoryTagResponse) =>
                                    !filterCategoryTags.find((categoryTag) => {
                                        return categoryTag.id === filterCategoryTagResponse.id;
                                    }),
                            );
                            const updatedRecipeCategoryTags = filterCategoryTags.concat(newFilterCategoryTagsResponse);
                            setIsUpdating(true);
                            setFilterCategoryTags(updatedRecipeCategoryTags);

                            const categoryTagsToDisplay = categoryTagsResponse.filter(
                                (categoryTag) =>
                                    !filterCategoryTagsResponse.find((filterCategoryTag) => {
                                        return filterCategoryTag.id === categoryTag.id;
                                    }),
                            );

                            const updatedCategoryTags = categoryTags.concat(categoryTagsToDisplay);

                            setCategoryTags(updatedCategoryTags);
                            hasPagination && setPage(nextPage);
                        } else {
                            const updatedCategoryTags = categoryTags.concat(categoryTagsResponse);
                            setCategoryTags(updatedCategoryTags);
                            hasPagination && setPage(nextPage);
                        }
                    });
                }
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedCategories) : handleInsert(changedCategories);
    };

    const handleUpdate = (categoryTagIds: Array<number>): void => {
        const categoryTagsToSave = { category_tag_ids: categoryTagIds, filter_id: filter.id };

        props.updateFilterCategoryTags(categoryTagsToSave).then(() => {
            history.push(filterConstants.FILTERS_ROUTES.FILTERS);
        });
    };

    const handleInsert = (category_tag_ids: Array<number>): void => {
        const categoryTagsToSave: IFilterCategoryTagsForm = {
            filter_id: filter.id,
            category_tag_ids,
        };

        props.addFilterCategoryTags(categoryTagsToSave).then(() => {
            history.push(filterConstants.FILTERS_ROUTES.FILTERS);
        });
    };

    return (
        <>
            {Array.isArray(categoryTags) || Array.isArray(filterCategoryTags) ? (
                <DragAndDrop
                    destinationColumnName={filterConstants.DRAG_AND_DROP.CATEGORY_TAGS_DESTINATION}
                    sourceColumnName={filterConstants.DRAG_AND_DROP.CATEGORY_TAGS_SOURCE}
                    items={filterCategoryTags}
                    availableItems={categoryTags}
                    handleItemsChange={setChangedCategories}
                    endScroll={[setEndScroll, endScroll]}
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.nextStep(filter)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handleSubmit}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.prevStep(filter)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    categoryTagsTotal: state.categoryTags.total,
});

const mapDispatchToProps = {
    getCategoryTags: categoryTagsAction.getCategoryTags,
    addFilterCategoryTags: filterAction.addFilterCategoryTags,
    getFilterCategoryTags: filterAction.getFilterCategoryTags,
    updateFilterCategoryTags: filterAction.updateFilterCategoryTags,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddCategoryTags);
