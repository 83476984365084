interface IPagination {
    hasPagination: boolean;
    nextPage: number;
}

const paginate = (endScroll: boolean, totalItems: number, receivedItems: number, page: number): IPagination => {
    const hasPagination = endScroll && totalItems > receivedItems;
    const nextPage = hasPagination ? page + 1 : page;

    return { hasPagination, nextPage };
};

export const paginationHelper = {
    paginate,
};
