import React from 'react';
import moment from 'moment';
import en from '../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { ILocalization } from '../../shared/helpers/zodUtils';
import ImageModal from '../../shared/components/generics/imageModal/ImageModal';
import { Check, Clear } from '@material-ui/icons';

const indexColumn = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
];

const objectTimestamps = [
    {
        name: 'createdAt',
        label: en.create_date_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
    {
        name: 'modifiedAt',
        label: en.modify_date_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

export const commonOptions = {
    indexColumn,
    objectTimestamps,
};

const labelWithSubLabel = (label: string, subLabel: string) =>
    function LabelWithSubLabel(options) {
        return (
            <>
                <p
                    style={{
                        margin: 0,
                    }}
                >
                    {label}
                </p>
                <p
                    style={{
                        fontSize: '12px',
                        color: '#9e9e9e',
                        fontWeight: 400,
                        margin: 0,
                    }}
                >
                    {subLabel}
                </p>
            </>
        );
    };

const imageModalRenderer = (picture: string | Blob) => {
    let pictureUrl: string;
    if (picture instanceof Blob) {
        pictureUrl = URL.createObjectURL(picture);
    } else {
        pictureUrl = picture;
    }

    if (picture) {
        return (
            <ImageModal
                baseStyle={{
                    height: '96px',
                    borderRadius: '8px',
                    imageRendering: 'pixelated',
                }}
                title={'Thumbnail (EN)'}
                image={pictureUrl}
                alt={'Thumbnail (EN)'}
            />
        );
    }
    return <></>;
};

const localizationBodyRenderer =
    (language: keyof ILocalization = 'enEnTranslation', maxLength?: number) =>
    (value: ILocalization | null) => {
        const rawValue = value?.[language] || null;
        const shortened =
            maxLength && rawValue?.length > maxLength ? rawValue?.substring(0, maxLength) + '...' : rawValue;
        return shortened;
    };

const booleanRenderer = (value: any, tableMeta: MUIDataTableMeta, updateValue: (value: string) => void) => {
    let newValue = false;
    if (typeof value === 'boolean') {
        newValue = value;
    } else if (typeof value === 'string') {
        newValue = value?.toLowerCase() === 'true';
    }
    return newValue ? <Check /> : <Clear />;
};

export const commonDatatableHelpers = {
    labelWithSubLabel,
    localizationBodyRenderer,
    imageModalRenderer,
    booleanRenderer,
};
