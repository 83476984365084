export const categoryTagsEndpoints = {
    CATEGORY_TAGS_ROUTES: {
        CATEGORY_TAGS: '/category-tags',
    },
    GET_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tags',
    },
    ADD_CATEGORY_TAG: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tags',
    },
    DELETE_CATEGORY_TAG: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tags/',
    },
    UPDATE_CATEGORY_TAG: {
        url: process.env.REACT_APP_SERVER_URL + '/category-tags/',
    },
};
