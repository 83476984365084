import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { upsellingEndpoints } from './upselling.endpoints';
import { IProduct, IProductCategoriesResponse, IProductForm, IProductResponse } from './upselling.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getProducts = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IProductResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.GET_PRODUCTS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: camelToSnakeCase(pagination.orderBy),
            orderDirection: pagination.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getProductById = async (productId: number): Promise<IProduct | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.GET_PRODUCT_BY_ID.url + productId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

function productToFormData(productData: IProductForm) {
    const formData = new FormData();

    for (const key in productData) {
        if (!productData.hasOwnProperty(key)) {
            continue;
        }
        if (typeof productData[key] === 'boolean') {
            formData.append(key, productData[key] === true ? 'true' : 'false');
            continue;
        }
        formData.append(key, productData[key]);
    }
    return formData;
}

const addProduct = async (productData: IProductForm): Promise<AxiosResponse> => {
    const formData = productToFormData(productData);

    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.ADD_PRODUCT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateProduct = async (productData: IProductForm, productId: number): Promise<AxiosResponse> => {
    const formData = productToFormData(productData);

    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.UPDATE_PRODUCT.url + productId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteProduct = async (productId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.DELETE_PRODUCT.url + productId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getProductCategories = async (): Promise<IProductCategoriesResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: upsellingEndpoints.GET_PRODUCT_CATEGORIES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

export const upsellingService = {
    getProducts,
    getProductById,
    addProduct,
    deleteProduct,
    updateProduct,
    getProductCategories,
};
