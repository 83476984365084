import { loginConstants } from './login.constants';
import { loginService } from './login.service';
import { authConstants } from '../../shared/constants/auth.constants';
import { alertActions } from '../../shared/actions/alert.actions';
import { IAuthCode, ILoginResponse, IRegisterResponse, LoginActions } from './login.interfaces';
import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { loginHelpers } from './helpers/actionHelpers';

export const loginActions = {
    login,
    register,
    forgotPassword,
    changePassword,
};

function login(code: IAuthCode) {
    return (dispatch: Dispatch) => {
        dispatch(request());

        return loginService.login(code).then(
            (response: ILoginResponse) => {
                dispatch(success(response));
                loginHelpers.setAuthenticationData(response);
            },
            (error: AxiosError) => {
                dispatch(failure(error?.response?.data?.message?.toString()));
                dispatch(alertActions.error(error?.response?.data?.message?.toString()));
            },
        );
    };

    function request(): LoginActions {
        return { type: loginConstants.LOGIN_REQUEST };
    }
    function success(body): LoginActions {
        return { type: authConstants.LOGIN_SUCCESS, authInfo: body };
    }
    function failure(error): LoginActions {
        return { type: loginConstants.LOGIN_FAILURE, error };
    }
}

function register(code: IAuthCode) {
    return (dispatch: Dispatch) => {
        return loginService.register(code).then(
            (response: IRegisterResponse) => {
                return response?.data;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error?.response?.data?.message?.toString()));
            },
        );
    };
}
// Infer Param type of forgotPassword
function forgotPassword(data: { email: string }) {
    return (dispatch: Dispatch) => {
        return loginService.forgotPassword(data).then(
            (response: AxiosResponse) => {
                dispatch(alertActions.success("We've sent you an email with a link to reset your password"));
                return response?.data;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error?.response?.data?.message?.toString()));
            },
        );
    };
}

function changePassword(data: { newPassword: string; forgotPasswordToken: string }) {
    return (dispatch: Dispatch) => {
        return loginService.changePassword(data).then(
            (response: boolean) => {
                if (response === true) {
                    dispatch(alertActions.success("You've successfully changed your password"));
                }
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error?.response?.data?.message?.toString()));
            },
        );
    };
}
