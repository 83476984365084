import React, { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import styles from '../../../../shared/styles/global.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { compose } from 'recompose';
import { cookingMethodsConstants } from '../../cooking.methods.contants';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { ICookingMethodForm, ICookingMethodProps } from '../../cooking.methods.interfaces';
import formModels from '../addOrEditCookingMethods/formModel/form.model';
import { cookingMethodActions } from '../../cooking.method.actions';
import AddOrEditCookingMethod from '../addOrEditCookingMethods';
import { cookingMethodDataTables } from '../../../helpers/cooking.methods.datatables';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';

type OwnProps = ICookingMethodProps & ReturnType<typeof mapStateToProps>;

const CookingMethods = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { formId, formField } = formModels.cookingMethodFormModel;
    const { cookingMethods, total, loading } = props;
    const tableColumns = [
        ...cookingMethodDataTables.cookingMethodColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: ICookingMethodForm = {
                        id: tableMeta.rowData[cookingMethodsConstants.COOKING_METHOD_TYPES_ROW_ELEMENTS.ID],
                        name_en: tableMeta.rowData[cookingMethodsConstants.COOKING_METHOD_TYPES_ROW_ELEMENTS.NAME_EN],
                        name_de: tableMeta.rowData[cookingMethodsConstants.COOKING_METHOD_TYPES_ROW_ELEMENTS.NAME_DE],
                    };
                    return (
                        <AddOrEditCookingMethod
                            formId={formId}
                            formField={formField}
                            cookingMethodData={row}
                            refreshData={_refreshCookingMethods}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const cookingMethodToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const cookingMethodsToRemoveLength = cookingMethodToRemoveIndex.length;

            cookingMethodToRemoveIndex.map(async (cookingMethodIndex, index) => {
                const cookingMethodId = cookingMethods[cookingMethodIndex].id;
                const completed = cookingMethodsToRemoveLength === index + 1;
                await props.deleteCookingMethod(cookingMethodId, completed);
                completed && (await _refreshCookingMethods(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    const _refreshCookingMethods = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getCookingMethods(pagination, searchText, filters);
    };

    return (
        <div className={styles.generalContainer}>
            <AddOrEditCookingMethod formId={formId} formField={formField} refreshData={_refreshCookingMethods} />
            <DynamicTable
                title={en.cooking_methods}
                options={options}
                columns={tableColumns}
                data={cookingMethods}
                total={total}
                loading={loading}
                refreshData={_refreshCookingMethods}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    cookingMethods: state.cookingMethods.cookingMethods,
    total: state.cookingMethods.total,
    loading: state.cookingMethods.loading,
});

const mapDispatchToProps = {
    getCookingMethods: cookingMethodActions.getCookingMethods,
    deleteCookingMethod: cookingMethodActions.deleteCookingMethod,
};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(CookingMethods);
