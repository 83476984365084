import { MenuItem, TextField, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import en from '../../../../assets/language/en.json';
import ImportCsvFile from '../../../shared/components/generics/import';
import DynamicTable from '../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination } from '../../../shared/components/interfaces';
import { globalConstants } from '../../../shared/constants/global.constants';
import { useQuery } from '../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType } from '../../../shared/helpers/generics';
import { AppState, useAppDispatch } from '../../../shared/store';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { referralOrderDataTables } from '../../helpers/referral.order.datatables';
import { referralOrderActions } from '../referral.order.actions';
import { referralOrderConstants } from '../referral.order.constants';
import { ReferralOrderStatus } from '../referral.order.interfaces';
import styles from './index.module.scss';

type Props = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

const ReferralOrders = (props: Props) => {
    const {
        referralOrders,
        total,
        loading,
        getReferralOrders,
        updateReferralOrderStatus,
        uploadAlbersVouchersFile,
        unusedVouchersCount,
        getUnusedVouchersCount,
    } = props;
    const dispatch = useAppDispatch();

    const searchTextQuery = useQuery()?.get('searchText');

    useEffect(() => {
        getUnusedVouchersCount();
    }, []);

    useEffect(() => {
        if (searchTextQuery) {
            _refreshReferralOrders(globalConstants.DEFAULT_PAGINATION, searchTextQuery, null);
        }
    }, [searchTextQuery]);

    const options: MUIDataTableOptions = {
        searchPlaceholder: searchTextQuery || '',
        selectableRows: 'none',
        setRowProps(row, dataIndex, rowIndex) {
            if (
                referralOrders[rowIndex + 1] &&
                referralOrders[rowIndex].referralCode !== referralOrders[rowIndex + 1].referralCode
            ) {
                return {
                    style: {
                        borderBottom: '2px solid rgba(34, 34, 34, 0.69)',
                    },
                };
            }
        },
    };

    async function _refreshReferralOrders(
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> {
        await getReferralOrders(pagination, searchText, filters);
    }

    const tableColumns = [...referralOrderDataTables.referralOrderColumns];

    tableColumns.splice(5, 0, {
        name: 'orderStatus',
        label: 'Order status',
        options: {
            filter: false,
            sort: false,
            customBodyRender: renderOrderStatus,
        },
    });

    return (
        <div className={clsx(styles.orders, 'mt16')}>
            <div className={clsx(styles.voucherDiv)}>
                {unusedVouchersCount > -1 && unusedVouchersCount < 10 ? (
                    <b className={styles.voucherWarning}>WARNING!</b>
                ) : null}
                <p className={styles.vouhcersLeftText}>
                    There are <b>{unusedVouchersCount === -1 ? 'N/A' : unusedVouchersCount}</b> unused voucher pairs
                    remaining.
                </p>
                <ImportCsvFile
                    allowedFileTypes={['csv']}
                    buttonLabel="Import albers vouchers"
                    uploadFile={uploadAlbersVouchersFile}
                    callback={() => getUnusedVouchersCount()}
                />
            </div>
            <DynamicTable
                title={en.referral_orders_label}
                options={options}
                columns={tableColumns}
                data={referralOrders}
                total={total}
                loading={loading}
                refreshData={_refreshReferralOrders}
            />
        </div>
    );

    function renderOrderStatus(_orderStatus: ReferralOrderStatus, meta: MUIDataTableMeta) {
        return (
            <TextField
                name={'orderStatus'}
                select
                type={'text'}
                onChange={(event) => {
                    const newOrderStatus = event.target.value as ReferralOrderStatus;

                    const newState = [...referralOrders];
                    newState[meta.rowIndex].orderStatus = newOrderStatus;
                    dispatch({
                        type: referralOrderConstants.REFERRAL_ORDER_ACTION_TYPES.GET_REFERRAL_ORDERS_SUCCESS,
                        referralOrders: {
                            referralOrders: newState,
                            total: total,
                        },
                    });

                    updateReferralOrderStatus(referralOrders[meta.rowIndex].id, newOrderStatus);
                }}
                value={referralOrders[meta.rowIndex].orderStatus}
                variant={'outlined'}
            >
                <MenuItem className={clsx('py4')} key={'orderStatus'} value={'Order Status'} disabled>
                    {'Order Status'}
                </MenuItem>
                {Object.keys(ReferralOrderStatus).map((key) => (
                    <MenuItem key={key} value={ReferralOrderStatus[key]}>
                        {ReferralOrderStatus[key].charAt(0).toUpperCase() +
                            ReferralOrderStatus[key].slice(1).toLowerCase()}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
};

const mapStateToProps = (state: AppState) => ({
    referralOrders: state.referralOrders.referralOrders,
    total: state.referralOrders.total,
    loading: state.referralOrders.loading,
    unusedVouchersCount: state.referralOrders.unusedVouchersCount,
});

const mapDispatchToProps = {
    getReferralOrders: referralOrderActions.getReferralOrders,
    updateReferralOrderStatus: referralOrderActions.updateReferralOrderStatus,
    uploadAlbersVouchersFile: referralOrderActions.uploadAlbersVouchersFile,
    getUnusedVouchersCount: referralOrderActions.getUnusedVouchersCount,
};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(ReferralOrders);
