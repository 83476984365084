import en from '../../../assets/language/en.json';
import { commonOptions } from './common.datatable.options';

const filterColumns = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'name_en',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'name_de',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    ...commonOptions.objectTimestamps,
];

export const filterDataTables = {
    filterColumns,
};
