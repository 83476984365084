import { omit, pick } from 'lodash';
import { globalConstants } from '../constants/global.constants';

const getFileExtension = (file: string): string => {
    if (validString(file)) return file.split('.').pop().toLowerCase();
};

const validString = (value: unknown): boolean => {
    if (value === undefined || value === null) {
        return false;
    }

    return typeof value === 'string' && value.length >= 0;
};

const roundNumber = (decimal: number, precision: number): number => {
    const factor = Math.pow(10, precision);
    return Math.round(decimal * factor) / factor;
};

const isNullOrUndefined = (object: unknown): boolean => {
    return typeof object === 'undefined' || object === null;
};

const removeIdFromObject = <T>(object?: T): T => {
    return omit(object, [globalConstants.KEYS.ID]);
};

const getIdFromObject = <T>(object?: T): T => {
    return pick(object, [globalConstants.KEYS.ID]);
};

const sleep = (delay = 0): Promise<unknown> => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

/**
 * Method to make an array distinct. Only keeps the first occurrence of an object with same key
 * @param object The array of objects
 * @param key The object's key used to filter the array
 * @returns a new array where each object with the specified key only occurs once
 */
const toDistinctArrByKey = <T>(object: T[], key: string): T[] => {
    const result: T[] = [];
    const alreadyInArr = new Map<any, boolean>();
    for (const item of object) {
        if (alreadyInArr.get(item[key])) continue;
        alreadyInArr.set(item[key], true);
        result.push(item);
    }
    return result;
};

const CREATED_AT = 'ts_created';
const LAST_MODIFIED_AT = 'ts_last_modified';

const mapOrderBy = (orderBy: string) => {
    switch (orderBy) {
        case 'createdAt':
            return CREATED_AT;
        case 'modifiedAt':
            return LAST_MODIFIED_AT;
        default:
            return orderBy;
    }
};

const reorder = <E>(list: Iterable<E>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export type FunctionReturnType<T> = {
    [K in keyof T]: T[K] extends (...args: any) => any ? ReturnType<T[K]> : never;
};

type DispatchReturnType<F> = F extends (...args: infer Args) => infer R
    ? (...args: Args) => R extends (...innerArgs: any) => infer InnerR ? InnerR : never
    : never;

export type DispatchPropsReturnType<T> = {
    [K in keyof T]: DispatchReturnType<T[K]>;
};

export type SuccessResponse<T> = {
    data: T;
};

export type SuccessResponsePaginated<T> = {
    page: T[];
    total: number;
};

export const genericHelpers = {
    getFileExtension,
    validString,
    isNullOrUndefined,
    removeIdFromObject,
    sleep,
    getIdFromObject,
    toDistinctArrByKey,
    mapOrderBy,
    reorder,
    roundNumber,
};
