import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import de from '../../../../../assets/language/de.json';
import en from '../../../../../assets/language/en.json';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import DialogTextEditor from '../../../../shared/components/generics/textEditorModal/TextEditor';
import TextEditorValueRender from '../../../../shared/components/generics/textEditorValuesRender/Box';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { useAppDispatch, useAppSelector } from '../../../../shared/store';
import ImportFile from '../../../import/pages/import.file';
import { influencerConstants } from '../../influencer.constants';
import { IInfluencer, IInfluencerForm, IInfluencerFormComponent } from '../../influencer.interfaces';
import { influencerService } from '../../influencer.service';
import { influencerThunks } from '../../influencer.thunks';
import { formInitialValues } from './formModel/form.initial.values';
import formModels from './formModel/form.model';
import validationSchemas from './formModel/validation.schema';
import styles from './index.module.scss';
import EditFeedToggle from './EditFeedToggle';

type Props = IInfluencerFormComponent & typeof formModels.ambassadorFormModel;

function useInfluencerState() {
    const influencer = useAppSelector((state) => state.influencers.influencer);
    const loading = useAppSelector((state) => state.influencers.influencerLoading);
    const dispatch = useAppDispatch();
    return { influencer, loading, dispatch };
}

const AmbassadorForm = (props: PropsWithChildren<Props>): ReactElement => {
    const id = useIdParam();
    const {
        formField: {
            blogger_name,
            blog_name_en,
            blog_name_de,
            header_picture,
            description_en,
            description_de,
            userAccountMail,
        },
    } = formModels.ambassadorFormModel;
    const { dispatch, influencer, loading } = useInfluencerState();

    const hiddenHeaderPictureInput = React.useRef(null);

    const [updatingAmbassador, setUpdatingAmbassador] = useState<IInfluencer | null>(influencer);
    const [open, setOpen] = useState<string | boolean>(false);

    const formInitials = updatingAmbassador ? updatingAmbassador : formInitialValues.ambassador;

    useEffect(() => {
        async function getAmbasador() {
            const response = await influencerService.getInfluencerById(id);
            setUpdatingAmbassador(response);
        }

        id && getAmbasador();
    }, []);

    const handleSubmit = async (values: IInfluencerForm): Promise<void> => {
        updatingAmbassador ? await handleUpdate(values) : await handleInsert(values);
    };

    const handleInsert = async (values: IInfluencerForm): Promise<void> => {
        await dispatch(influencerThunks.addInfluencer(values)).unwrap();

        props.history.push(influencerConstants.INFLUENCER_ROUTES.INFLUENCERS);
    };
    const handleUpdate = async (values: IInfluencerForm): Promise<void> => {
        await dispatch(influencerThunks.updateInfluencer({ influencer: values, influencerId: id })).unwrap();

        props.history.push(influencerConstants.INFLUENCER_ROUTES.INFLUENCERS);
    };

    const handleUploadClick = (): void => {
        hiddenHeaderPictureInput.current.click();
    };

    const handleDialogClick = (type?: string): void => {
        const boxClicked = (event.target as HTMLInputElement).className;

        if (boxClicked) {
            setOpen(type ? type : false);
        }
    };

    return (
        <Paper className={clsx(styles.ambassadorPaper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.ambassador, 'p4')}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={async (values: IInfluencerForm, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.ambassadorValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IInfluencerForm>) => (
                        <Form className={styles.addAmbassadorForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <EditFeedToggle influencerId={id} />
                                    <Typography variant="h3" gutterBottom className={clsx(styles.typography, 'mt8')}>
                                        {en.ambassador_label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={blog_name_en.label}
                                                name={blog_name_en.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.blog_name_en}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.blog_name_en && props.touched.blog_name_en
                                                    ? props.errors.blog_name_en
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={blog_name_de.label}
                                                name={blog_name_de.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.blog_name_de}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.blog_name_de && props.touched.blog_name_de
                                                    ? props.errors.blog_name_de
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={blogger_name.label}
                                                name={blogger_name.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.blogger_name}
                                                variant="outlined"
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.blogger_name && props.touched.blogger_name
                                                    ? props.errors.blogger_name
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={userAccountMail.label}
                                                name={userAccountMail.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.userAccountMail}
                                                variant="outlined"
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.userAccountMail && props.touched.userAccountMail
                                                    ? props.errors.userAccountMail
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={props.values.header_picture}
                                                        title={en.header_picture}
                                                        style={styles.imgCard}
                                                        remove={() => props.setFieldValue('header_picture', null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id="importFile"
                                                        type="file"
                                                        name={header_picture.name}
                                                        className={styles.fileInput}
                                                        ref={hiddenHeaderPictureInput}
                                                        onChange={(event) => {
                                                            props.setFieldValue(
                                                                header_picture.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={handleUploadClick}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_header_picture}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <DialogTextEditor
                                        onSave={props.setFieldValue}
                                        value={props.values.description_en}
                                        type={description_en}
                                        openDialog={open === description_en.name}
                                        handleDialogClick={handleDialogClick}
                                        alert={en.description_saved_successfully}
                                    />
                                    <TextEditorValueRender
                                        values={props.values.description_en}
                                        label={description_en.label}
                                        handleDialogClick={() => handleDialogClick(description_en.name)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DialogTextEditor
                                        onSave={props.setFieldValue}
                                        value={props.values.description_de}
                                        type={description_de}
                                        openDialog={open === description_de.name}
                                        handleDialogClick={handleDialogClick}
                                        alert={de.description_saved_successfully}
                                    />
                                    <TextEditorValueRender
                                        values={props.values.description_de}
                                        label={description_de.label}
                                        handleDialogClick={() => handleDialogClick(description_de.name)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={clsx(styles.bottomNavigation, 'mt12')}>
                                    <Button
                                        type="submit"
                                        className={clsx(styles.saveAmbassador, 'mr2', 'mt4')}
                                        disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                    >
                                        {en.save_button_label}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Paper>
    );
};

export default compose(withRouter, connect(null, null))(AmbassadorForm);
