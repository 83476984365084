import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { ICookingMethodForm } from '../../../cooking.methods.interfaces';

const {
    formField: { nameEn, nameDe },
} = formModels.cookingMethodFormModel;

const cookingMethodValidationSchema: ObjectSchema<ICookingMethodForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${nameEn.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${nameDe.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    cookingMethodValidationSchema,
};

export default validationSchemas;
