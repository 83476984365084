import { AxiosError, AxiosResponse } from 'axios';
import { sendmailService } from './sendmail.service';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { ISendmailAction, ISendmailRespone } from './sendmail.interfaces';
import { sendmailConstants } from './sendmail.constants';

function requestSendingEmails(
    csvFile: File,
    subject: string,
    template: string,
): (dispatch: Dispatch) => Promise<AxiosError | ISendmailRespone> {
    return (dispatch) => {
        dispatch(request());
        return sendmailService.requestSendingEmails(csvFile, subject, template).then(
            (response: AxiosResponse) => {
                dispatch(success());

                const data = response.data.data as ISendmailRespone;

                const { sent, failed } = data;

                dispatch(alertActions.success(`Sent ${sent} emails, failed: ${failed}`));

                return data;
            },
            (error: AxiosError) => {
                dispatch(failure());
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ISendmailAction {
        return { type: sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_REQUEST };
    }

    function success(): ISendmailAction {
        return { type: sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_SUCCESS };
    }

    function failure(): ISendmailAction {
        return { type: sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_FAILURE };
    }
}

export const sendmailActions = {
    requestSendingEmails,
};
