import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
    InputAdornment,
    MenuItem,
    Radio,
    Step,
    StepButton,
    Stepper,
    TextField,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import en from '../../../../../../../assets/language/en.json';
import BilingualTextEditor, {
    BilingualTextEditorRef,
} from '../../../../../../shared/components/generics/textEditor/BilingualTextEditor';
import { globalConstants } from '../../../../../../shared/constants/global.constants';
import { AppState } from '../../../../../../shared/store';
import { ICookingStepsFormData } from '../../../../../cookingSteps/cooking.steps.interfaces';
import { recipeHelpers } from '../../../../../helpers/recipe.helpers';
import { recipeAction } from '../../../../recipe.actions';
import { IActionNamesResponse, ICookingStepsForm, RecipeCookingStepMethod } from '../../../../recipe.interfaces';
import formInitialValues from '../../FormModel/FormInitialValues';
import recipeFormModel from '../../FormModel/RecipeFormModel';
import validationSchemas from '../../FormModel/ValidationSchema';
import { stepperStyles } from '../../styles';
import styles from './index.module.scss';
import NotCompleteDialog from './NotCompleteDialog';

interface DashboardStepProps extends WithStyles<typeof stepperStyles> {
    handleNewCookingStep: (values: ICookingStepsForm) => void;
    cookingStepToEdit?: ICookingStepsForm;
    backToSelectStepType: () => void;
    getActionNames: () => Promise<IActionNamesResponse>;
}

type OwnProps = DashboardStepProps & ReturnType<typeof mapStateToProps>;

enum DashboardSettingsType {
    TEMPERATURE,
    TIMER,
}

function AddDashboardCookingStep(props: OwnProps) {
    const form = recipeFormModel.cookingStepsFormModel.cookingStepsFormField;

    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
    const [activeStep, setActiveStep] = useState<number>(0);
    const [dashboardSettingsType, setDashboardSettingsType] = useState(
        props.cookingStepToEdit?.duration ? DashboardSettingsType.TIMER : DashboardSettingsType.TEMPERATURE,
    );
    const [nextClicked, setNextClicked] = useState(false);

    const longDescRef = useRef<BilingualTextEditorRef>();
    const shortDescRef = useRef<BilingualTextEditorRef>();

    useEffect(() => {
        // Load action names
        props.getActionNames();
    }, []);

    const steps: string[] = [
        en.long_description.toUpperCase(),
        en.short_description.toUpperCase(),
        en.dashboard_settings.toUpperCase(),
    ];

    const toggleDashboardSettingsType = (type: DashboardSettingsType) => () => {
        setDashboardSettingsType(type);
    };

    const handleFormSubmit = (values: ICookingStepsForm) => {
        if (values.actions.length) {
            values.is_interval = true;
        } else {
            values.is_interval = false;
        }
        props.handleNewCookingStep(values);
    };

    const saveDescriptionFieldValue = (
        typeName: string,
        data: string,
        formProps: FormikProps<ICookingStepsForm>,
    ): void => {
        const dataObj: ICookingStepsFormData = JSON.parse(data);
        dataObj.blocks.map((block) => {
            block.text = block.text.replace(/ +/g, ' ').trim();
        });
        formProps.setFieldValue(typeName, JSON.stringify(dataObj));
    };

    const setRecipeCookingStepMethod =
        (formProps: FormikProps<ICookingStepsForm>, recipeCookingStepMethod: RecipeCookingStepMethod) => () =>
            formProps.setFieldValue(form.cooking_step_method.name, recipeCookingStepMethod);

    return (
        <>
            <Formik
                initialValues={props.cookingStepToEdit || formInitialValues.dashboardRelevantCookingStep}
                enableReinitialize={true}
                onSubmit={(values: ICookingStepsForm, { setSubmitting, resetForm }) => {
                    handleFormSubmit(values);
                    setSubmitting(false);
                    resetForm();
                }}
                validationSchema={validationSchemas.cookingStepsSchema}
            >
                {(formProps: FormikProps<ICookingStepsForm>) => {
                    const [longDescComplete, setLongDescComplete] = useState(
                        recipeHelpers.checkDescriptionsFilled(
                            formProps.values.long_description_en,
                            formProps.values.long_description_de,
                        ),
                    );

                    const [shortDescComplete, setShortDescComplete] = useState(
                        recipeHelpers.checkDescriptionsFilled(
                            formProps.values.short_description_en,
                            formProps.values.short_description_de,
                        ),
                    );

                    useEffect(() => {
                        if (dashboardSettingsType === DashboardSettingsType.TEMPERATURE) {
                            formProps.setFieldValue(form.countdown.name, null);
                            return;
                        }
                        if (dashboardSettingsType === DashboardSettingsType.TIMER) {
                            formProps.setFieldValue(form.master_probe.name, null);
                        }
                    }, [dashboardSettingsType]);

                    useEffect(() => {
                        setLongDescComplete(
                            recipeHelpers.checkDescriptionsFilled(
                                formProps.values.long_description_en,
                                formProps.values.long_description_de,
                            ),
                        );
                        setShortDescComplete(
                            recipeHelpers.checkDescriptionsFilled(
                                formProps.values.short_description_en,
                                formProps.values.short_description_de,
                            ),
                        );
                    }, [formProps.values]);

                    useEffect(() => {
                        if (nextClicked && longDescComplete && activeStep === 0) {
                            handleNextStep();
                            setNextClicked(false);
                        }
                    }, [nextClicked, longDescComplete]);

                    useEffect(() => {
                        if (nextClicked && shortDescComplete && activeStep === 1) {
                            handleNextStep();
                            setNextClicked(false);
                        }
                    }, [nextClicked, shortDescComplete]);

                    const lastStep = 2;
                    const handleNext = () => {
                        setNextClicked(true);
                        switch (activeStep) {
                            case 0:
                                longDescRef.current.saveText();
                                break;
                            case 1:
                                shortDescRef.current.saveText();
                                break;
                            default:
                                formProps.submitForm();
                                break;
                        }
                    };

                    const handleNextStep = () => {
                        switch (activeStep) {
                            case 0:
                                setActiveStep((prev) => prev + 1);
                                break;
                            case 1:
                                setActiveStep((prev) => prev + 1);
                                break;
                            default:
                                formProps.submitForm();
                                break;
                        }
                    };

                    const handlePrev = () => {
                        if (activeStep <= 0) {
                            props.backToSelectStepType();
                            return;
                        }
                        setActiveStep((prev) => prev - 1);
                    };

                    const longDescriptionProps = {
                        en: {
                            value: formProps.values.long_description_en,
                            label: form.long_description_en.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.long_description_en.name, data, formProps);
                            },
                        },
                        de: {
                            value: formProps.values.long_description_de,
                            label: form.long_description_de.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.long_description_de.name, data, formProps);
                            },
                        },
                    };
                    const shortDescriptionProps = {
                        en: {
                            value: formProps.values.short_description_en,
                            label: form.short_description_en.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.short_description_en.name, data, formProps);
                            },
                        },
                        de: {
                            value: formProps.values.short_description_de,
                            label: form.short_description_de.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.short_description_de.name, data, formProps);
                            },
                        },
                    };

                    return (
                        <>
                            <Stepper
                                activeStep={activeStep}
                                className={clsx(props.classes.stepper, 'my-0', styles.descStepper)}
                                variant={'outlined'}
                                nonLinear
                            >
                                {steps.map((label: string, index: number) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton
                                                onClick={() => setActiveStep(index)}
                                                completed={completed[index]}
                                            >
                                                {label}
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {renderDialogContent()}
                        </>
                    );

                    function renderDialogContent() {
                        if (nextClicked && !longDescComplete) {
                            return (
                                <NotCompleteDialog
                                    onContinue={() => setLongDescComplete(true)}
                                    onAddDescription={() => setNextClicked(false)}
                                ></NotCompleteDialog>
                            );
                        }
                        if (nextClicked && !shortDescComplete) {
                            return (
                                <NotCompleteDialog
                                    onContinue={() => setShortDescComplete(true)}
                                    onAddDescription={() => setNextClicked(false)}
                                ></NotCompleteDialog>
                            );
                        }
                        return (
                            <>
                                <Form className={clsx(styles.addCookingStepsForm)}>{renderSteps(activeStep)}</Form>
                                <div className={styles.buttonWrapper}>
                                    <Button className={clsx(styles.buttonWhite, 'mx-2')} onClick={() => handlePrev()}>
                                        {en.back}
                                    </Button>
                                    <Button
                                        className={styles.buttonRed}
                                        disabled={activeStep === lastStep && !formProps.isValid}
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        {en.next}
                                    </Button>
                                </div>
                            </>
                        );
                    }

                    function renderSteps(step: number): JSX.Element {
                        switch (step) {
                            case 1:
                                return (
                                    <div className={styles.dashboardTextEditorWrapper}>
                                        <BilingualTextEditor {...shortDescriptionProps} ref={shortDescRef} />
                                    </div>
                                );
                            case 2:
                                return temperatureOrTimerForm(formProps);
                            default:
                                return (
                                    <div className={styles.dashboardTextEditorWrapper}>
                                        <BilingualTextEditor {...longDescriptionProps} ref={longDescRef} />
                                    </div>
                                );
                        }
                    }
                }}
            </Formik>
        </>
    );

    function temperatureOrTimerForm(formProps: FormikProps<ICookingStepsForm>): JSX.Element {
        const minInputValue = formProps.values.relevant_for_dashboard ? 1 : 0;

        handleActionTimerForm(formProps);

        return (
            <>
                <Grid container>
                    <Grid item xs={6} onClick={toggleDashboardSettingsType(DashboardSettingsType.TEMPERATURE)}>
                        <div
                            className={clsx(
                                styles.timerTempToggleBar,
                                dashboardSettingsType === DashboardSettingsType.TEMPERATURE
                                    ? styles.selectedSetting
                                    : '',
                            )}
                        >
                            {en.temperature}
                        </div>
                    </Grid>
                    <Grid item xs={6} onClick={toggleDashboardSettingsType(DashboardSettingsType.TIMER)}>
                        <div
                            className={clsx(
                                styles.timerTempToggleBar,
                                dashboardSettingsType === DashboardSettingsType.TIMER ? styles.selectedSetting : '',
                            )}
                        >
                            {en.timer}
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            className={styles.formInput}
                            label={form.first_zone.label}
                            name={form.first_zone.name}
                            type="number"
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            value={formProps.values.first_zone}
                            variant="outlined"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={
                                            formProps.values.first_zone > 0
                                                ? styles.adornmentTouched
                                                : styles.adornmentUntouched
                                        }
                                    >
                                        <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={styles.formInput}
                            label={form.second_zone.label}
                            name={form.second_zone.name}
                            type="number"
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            value={formProps.values.second_zone}
                            variant="outlined"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={
                                            formProps.values.second_zone > 0
                                                ? styles.adornmentTouched
                                                : styles.adornmentUntouched
                                        }
                                    >
                                        <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={styles.formInput}
                            label={form.third_zone.label}
                            name={form.third_zone.name}
                            type="number"
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            value={formProps.values.third_zone}
                            variant="outlined"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={
                                            formProps.values.third_zone > 0
                                                ? styles.adornmentTouched
                                                : styles.adornmentUntouched
                                        }
                                    >
                                        <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={styles.formInput}
                            label={form.fourth_zone.label}
                            name={form.fourth_zone.name}
                            type="number"
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            value={formProps.values.fourth_zone}
                            variant="outlined"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={
                                            formProps.values.fourth_zone > 0
                                                ? styles.adornmentTouched
                                                : styles.adornmentUntouched
                                        }
                                    >
                                        <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {dashboardSettingsType === DashboardSettingsType.TEMPERATURE ? (
                        // Dashboard settings with master probe temperature
                        <Grid item xs={12}>
                            <TextField
                                className={styles.formInput}
                                label={form.master_probe.label}
                                name={form.master_probe.name}
                                type="number"
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values.master_probe}
                                helperText={(formProps.errors as any).meatProbeOrCountdown}
                                variant="outlined"
                                InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className={
                                                formProps.values.master_probe > 0
                                                    ? styles.adornmentTouched
                                                    : styles.adornmentUntouched
                                            }
                                        >
                                            <span>{globalConstants.ADORNMENTS.CELSIUS}</span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    ) : (
                        // Dashboard settings with timer
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    className={styles.formInput}
                                    label={form.countdown.label}
                                    name={form.countdown.name}
                                    type="number"
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                    value={formProps.values.duration}
                                    variant="outlined"
                                    helperText={(formProps.errors as any).meatProbeOrCountdown}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                className={
                                                    formProps.values.duration > 0
                                                        ? styles.adornmentTouched
                                                        : styles.adornmentUntouched
                                                }
                                            >
                                                <AccessTime />
                                                <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    {/* Action timers */}
                    {formProps.values.actions.map((action, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={6}>
                                    <TextField
                                        className={styles.formInput}
                                        name={form.actions.name}
                                        label={form.actions.timerLabel}
                                        type="number"
                                        onChange={(event) => {
                                            const newValue = event.target.value;
                                            formProps.values.actions[index].actionTimer = +newValue;
                                            formProps.setFieldValue(form.actions.name, formProps.values.actions);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        value={action.actionTimer || ''}
                                        InputProps={{
                                            inputProps: { min: minInputValue },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AccessTime />
                                                    <span>{globalConstants.ADORNMENTS.MINUTES}</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant={'outlined'}
                                        FormHelperTextProps={{
                                            className: styles.error_span,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={styles.formInput}
                                        label={form.actions.nameLabel}
                                        select
                                        name={form.actions.name}
                                        value={action.actionId || ''}
                                        onChange={(event) => {
                                            const newValue = event.target.value;
                                            formProps.values.actions[index].actionId = +newValue;
                                            formProps.setFieldValue(form.actions.name, formProps.values.actions);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        helperText={
                                            formProps.errors.actions && formProps.touched.actions
                                                ? (formProps.errors.actions as React.ReactNode)
                                                : ''
                                        }
                                        variant={'outlined'}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{form.actions.nameLabel}</em>
                                        </MenuItem>
                                        <MenuItem key={'none'} value={''} className={clsx('py4')} />
                                        {props.actionNames
                                            ? props.actionNames
                                                  .filter(
                                                      (actionOption) =>
                                                          actionOption.id === action.actionId ||
                                                          !formProps.values.actions
                                                              .map(
                                                                  (alreadySelectedAction) =>
                                                                      alreadySelectedAction.actionId,
                                                              )
                                                              .includes(actionOption.id),
                                                  )
                                                  .map((actionName, index) => (
                                                      <MenuItem key={index} value={actionName.id}>
                                                          {actionName.nameEn} ({actionName.nameDe})
                                                      </MenuItem>
                                                  ))
                                            : null}
                                    </TextField>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                    {/* Cooking step method */}
                    <Grid item xs={6} style={{ marginBottom: '5rem' }}>
                        <TextField
                            className={clsx(styles.formInput, styles.pointerInput)}
                            label={form.cooking_step_method.label}
                            name={form.cooking_step_method.name}
                            value={en.direct_step_label}
                            variant={'outlined'}
                            focused={formProps.values.cooking_step_method === RecipeCookingStepMethod.DIRECT}
                            helperText={(formProps.errors as any).directOrIndirect}
                            onClick={setRecipeCookingStepMethod(formProps, RecipeCookingStepMethod.DIRECT)}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Radio
                                            checked={
                                                formProps.values.cooking_step_method === RecipeCookingStepMethod.DIRECT
                                            }
                                            onClick={setRecipeCookingStepMethod(
                                                formProps,
                                                RecipeCookingStepMethod.DIRECT,
                                            )}
                                            classes={{
                                                checked: styles.radioButton,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={clsx(styles.formInput, styles.pointerInput)}
                            label={form.cooking_step_method.label}
                            name={form.cooking_step_method.name}
                            value={en.indirect_step_label}
                            variant={'outlined'}
                            focused={formProps.values.cooking_step_method === RecipeCookingStepMethod.INDIRECT}
                            onClick={setRecipeCookingStepMethod(formProps, RecipeCookingStepMethod.INDIRECT)}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Radio
                                            checked={
                                                formProps.values.cooking_step_method ===
                                                RecipeCookingStepMethod.INDIRECT
                                            }
                                            onClick={setRecipeCookingStepMethod(
                                                formProps,
                                                RecipeCookingStepMethod.INDIRECT,
                                            )}
                                            classes={{
                                                checked: styles.radioButton,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
    function handleActionTimerForm(formProps: FormikProps<ICookingStepsForm>) {
        if (formProps.values.actions.length === 0) {
            formProps.setFieldValue(form.actions.name, [
                {
                    actionId: null,
                    actionTimer: null,
                },
            ]);
            return;
        }

        const actionsCopy = [...formProps.values.actions];
        let changed = false;

        actionsCopy.forEach((action, idx) => {
            if (idx === actionsCopy.length - 1) {
                if (action.actionId && action.actionTimer) {
                    formProps.values.actions.push({
                        actionId: null,
                        actionTimer: null,
                    });
                    changed = true;
                }
                return;
            }
            if (!(action.actionId || action.actionTimer)) {
                formProps.values.actions.splice(idx, 1);
                changed = true;
            }
        });

        if (changed) formProps.setFieldValue(form.actions.name, formProps.values.actions);
    }
}

const mapStateToProps = (state: AppState) => ({
    actionNames: state.actionNames.actionNames,
});

const mapDispatchToProps = {
    getActionNames: recipeAction.getActionNames,
};

export default compose(
    withStyles(stepperStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(AddDashboardCookingStep);
