import React from 'react';
import { Button, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { quizQuestionEndpoints } from '../../quiz.questions.endpoints';
import styles from './index.module.scss';
import { QuestionOverview } from './QuestionOverview';
import { ToggleButtonGroup } from '@material-ui/lab';
import { StyledToggleButton } from '../../../../shared/components/generics/styledComponents/StyledComponents';
import { RoundOverview } from './RoundOverview';

type OwnProps = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

const QuizQuestionOverview = (props: PropsWithChildren<OwnProps>) => {
    // Get the current url from the router
    const { pathname } = useLocation();
    const history = useHistory();

    const selectedTab = pathname.includes(quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS)
        ? quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS
        : quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS;

    return (
        <div className={clsx(styles.container, 'mt16')}>
            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                <ToggleButtonGroup
                    className={clsx(styles.toggleButtonGroup)}
                    value={selectedTab}
                    exclusive
                    onChange={(_, value) => {
                        history.push(value);
                    }}
                    aria-label="Platform"
                >
                    <StyledToggleButton
                        key={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS}
                        value={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS}
                        name={'Argus'}
                    >
                        {'Quiz Rounds'}
                    </StyledToggleButton>
                    <StyledToggleButton
                        key={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS}
                        value={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS}
                        name={'Argus'}
                    >
                        {'Quiz Questions'}
                    </StyledToggleButton>
                </ToggleButtonGroup>
            </FormControl>
            {selectedTab === quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS ? (
                <QuestionOverview />
            ) : (
                <RoundOverview />
            )}
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {};

export default compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(QuizQuestionOverview);
