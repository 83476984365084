import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import { unitConstants } from '../unitManagement/unit.constants';

const unitColumns = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
        },
    },
    {
        name: 'name',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'nameDe',
        label: en.name_de,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'type',
        label: en.type_label,
        options: {
            filter: true,
            sort: false,
            customBodyRender(value: string): string {
                return unitConstants.TYPE[value].label;
            },
        },
    },
];

export const unitDataTables = {
    unitColumns,
};
