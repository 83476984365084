import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import LanguageFlag from '../../../shared/components/generics/languageFlag/LanguageFlag';
import { DispatchPropsReturnType } from '../../../shared/helpers/generics';
import { AppState } from '../../../shared/store';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { IGlobalSearchPageItem } from '../global.search.interfaces';
import { formInitialValues } from './addOrEditGlobalSearch/formModel/form.initial.values';
import formModels from './addOrEditGlobalSearch/formModel/global.search.form.model';
import { useTreeData } from './hooks';
import styles from './index.module.scss';
import { globalSearchActions } from '../global.search.actions';
import { zodUtils } from '../../../shared/helpers/zodUtils';
import validationSchemas from './addOrEditGlobalSearch/formModel/validation.schema';
import CustomCheckbox from '../../../shared/components/generics/checkbox/Checkbox';

type OwnProps = ReturnType<typeof mapStateToProps> & DispatchPropsReturnType<typeof mapDispatchToProps>;

const GlobalSearchAppPagesComponent = (props: PropsWithChildren<OwnProps>) => {
    const { getGlobalSearchAppPages, updateGlobalSearchAppPages } = props;
    const { treeData, deleteTreeNode, addNewChild, updateNode, isChanged, saveTreeData } = useTreeData(
        getGlobalSearchAppPages,
        updateGlobalSearchAppPages,
    );
    const [editingNode, setEditingNode] = React.useState<{
        node: IGlobalSearchPageItem;
        saveCallback: (newNode: IGlobalSearchPageItem) => void;
    } | null>(null);

    const form = formModels.appPageFormModel.formField;

    const renderTree = (node: IGlobalSearchPageItem) => (
        <TreeItem
            className={clsx(styles.treeItem, !node.parentId && styles.treeItemRoot)}
            key={node.id}
            nodeId={String(node.id)}
            label={
                <div className={styles.treeItemContent}>
                    {node.nameEn}
                    <Button
                        className={clsx(styles.redButton, styles.treeItemButton)}
                        onClick={(event) => {
                            event.stopPropagation();
                            return setEditingNode({
                                node,
                                saveCallback: (newNode) => {
                                    updateNode(newNode);
                                    setEditingNode(null);
                                },
                            });
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        className={clsx(styles.redButton, styles.treeItemButton)}
                        onClick={() =>
                            setEditingNode({
                                node: null,
                                saveCallback: (newNode) => {
                                    addNewChild({ ...newNode, parentId: node.id });
                                    setEditingNode(null);
                                },
                            })
                        }
                    >
                        Add sub route
                    </Button>
                    <Button
                        className={clsx(styles.redButton, styles.treeItemButton)}
                        onClick={() => deleteTreeNode(node.id)}
                    >
                        Delete
                    </Button>
                </div>
            }
        >
            {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <div className={clsx(styles.mainContainer, 'mt16')}>
            <h1>Edit App Pages for Global Search</h1>
            <div className={styles.buttonRow}>
                <Button
                    className={styles.redButton}
                    onClick={() =>
                        setEditingNode({
                            node: null,
                            saveCallback: (newNode) => {
                                addNewChild(newNode);
                                setEditingNode(null);
                            },
                        })
                    }
                >
                    Add top level page
                </Button>
                <Button className={clsx(styles.redButton)} disabled={!isChanged} onClick={saveTreeData}>
                    {'Save'}
                </Button>
            </div>

            {isChanged && <p className={styles.saveWarning}>{"Don't forget to save!"}</p>}
            <TreeView
                className={styles.treeView}
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRight />}
            >
                {treeData.map((rootNode) => renderTree(rootNode))}
            </TreeView>
            <Dialog
                key={editingNode?.node?.id ?? 'closed'}
                open={!!editingNode}
                maxWidth="md"
                onClose={() => setEditingNode(null)}
                className={styles.dialog}
            >
                <Formik
                    initialValues={editingNode?.node ?? formInitialValues.appPageItem}
                    onSubmit={(values) => {
                        editingNode.saveCallback(values);
                    }}
                    validate={zodUtils.zodFormikValidator(validationSchemas.baseGlobalSearchPageItemSchema)}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<IGlobalSearchPageItem>) => (
                        <Form className={styles.addForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.nameEn.label}
                                            name={form.nameEn.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.nameEn}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.nameEn && formProps.touched.nameEn
                                                ? formProps.errors.nameEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.tagsEn.label}
                                            name={form.tagsEn.name}
                                            type="text"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.tagsEn}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.tagsEn && formProps.touched.tagsEn
                                                ? formProps.errors.tagsEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.nameDe.label}
                                            name={form.nameDe.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.nameDe}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.nameDe && formProps.touched.nameDe
                                                ? formProps.errors.nameDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.tagsDe.label}
                                            name={form.tagsDe.name}
                                            type="text"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.tagsDe}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.tagsDe && formProps.touched.tagsDe
                                                ? formProps.errors.tagsDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.link.label}
                                            name={form.link.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.link}
                                            variant="outlined"
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.link && formProps.touched.link
                                                ? formProps.errors.link
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControlLabel
                                        className={clsx(styles.formControl, 'mb4')}
                                        control={
                                            <CustomCheckbox
                                                checked={formProps.values.isGrillRelated}
                                                onChange={(event) => {
                                                    formProps.setFieldValue(
                                                        form.isGrillRelated.name,
                                                        event.target.checked,
                                                    );
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={form.isGrillRelated.label}
                                    />
                                    <div>
                                        <Button
                                            className={clsx(styles.redButton, styles.dialogSaveButton)}
                                            type="submit"
                                            disabled={!formProps.isValid}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    getGlobalSearchAppPages: globalSearchActions.getGlobalSearchAppPages,
    updateGlobalSearchAppPages: globalSearchActions.updateGlobalSearchAppPages,
};

export const GlobalSearchAppPages = compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(GlobalSearchAppPagesComponent);
