import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { reviewEndpoints } from './reviews.endpoints';
import {
    IBlacklist,
    IBlacklistResponse,
    IBlockUserResponse,
    ICountUnreadReviewsResponse,
    InternalRatingReportDecision,
    IReview,
    IReviewResponse,
} from './reviews.interfaces';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';
import { userReportEndpoints } from '../userReports/user.report.endpoints';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getReviews = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters[],
): Promise<IReviewResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.GET_REVIEWS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: camelToSnakeCase(pagination.orderBy),
            orderDirection: pagination.orderDirection,
            searchText,
            filters: JSON.stringify(filters),
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const deleteReview = async (reviewId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.DELETE_REVIEW.url + reviewId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const blockUser = async (userId: number, blocked: boolean): Promise<IBlockUserResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.BLOCK_USER.url + userId,
        method: routeConstants.METHODS.PATCH,
        headers: authHeader(),
        data: {
            blocked,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const unreadReviewsCount = async (): Promise<ICountUnreadReviewsResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.UNREAD_REVIEWS_COUNT.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getBlacklist = async (
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters[],
): Promise<IBlacklistResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.GET_BLACKLIST.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: pagination.page,
            paging: pagination.paging,
            size: pagination.size,
            orderBy: camelToSnakeCase(pagination.orderBy),
            orderDirection: pagination.orderDirection,
            searchText,
            filters: JSON.stringify(filters),
        },
    };
    const response = await axios(requestOptions);

    return response.data.data;
};

const addBlacklist = async (blacklist: IBlacklist): Promise<IBlacklistResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.ADD_BLACKLIST.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: blacklist,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateBlacklist = async (blacklist: IBlacklist, id: number): Promise<IBlacklistResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.UPDATE_BLACKLIST.url + id,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: blacklist,
    };
    const response = await axios(requestOptions);

    return response.data;
};

const deleteBlacklist = async (id: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: reviewEndpoints.DELETE_BLACKLIST.url + id,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateReviewStatus = async (
    reviewId: number,
    read: boolean,
    internalDecision: InternalRatingReportDecision,
): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: userReportEndpoints.UPDATE_REVIEW_REPORT.url(reviewId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: {
            read,
            internalDecision,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const reviewService = {
    getReviews,
    deleteReview,
    blockUser,
    unreadReviewsCount,
    getBlacklist,
    addBlacklist,
    updateBlacklist,
    deleteBlacklist,
    updateReviewStatus,
};
