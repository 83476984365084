export const influencerConstants = {
    INFLUENCER_ROW_ELEMENTS: {
        ID: 1,
        BLOG_NAME: 2,
        BLOGGER_NAME: 3,
        DESCRIPTION: 4,
        HEADER_PICTURE: 5,
    },
    INFLUENCER_API_URLS: {
        INFLUENCERS: '/ambassadors',
        INFLUENCERS_WITH_PARAMS: '/ambassadors/',
        INFLUENCER_FEED: '/influencers/feed',
        INFLUENCER_FEED_WITH_PARAMS: (param: number) => `/influencers/feed/${param}`,
    },
    INFLUENCER_ROUTES: {
        INFLUENCERS: '/influencers',
        ADD_INFLUENCER: '/influencers/add',
        EDIT_INFLUENCER: '/influencers/edit',
        INFLUENCER_FEED: '/influencers/feed',
    },
};
