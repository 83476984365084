import { recipeConstants } from '../recipe/recipe.constants';
import { Fraction, fraction } from 'mathjs';

import en from '../../../assets/language/en.json';

import { commonOptions } from './common.datatable.options';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { richTextEditorHelpers } from '../../shared/helpers/rich.text.editor.helpers';

const recipeColumns: MUIDataTableColumn[] = [
    ...commonOptions.indexColumn,
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            filter: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: 'preparationTime',
        label: en.preparation_time_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: unknown): string => {
                return !value ? '-' : (value as string);
            },
        },
    },
    {
        name: 'cookingTime',
        label: en.cooking_time_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: unknown): string => {
                return !value ? '-' : (value as string);
            },
        },
    },
    {
        name: 'restingTime',
        label: en.resting_time_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: unknown): string => {
                return !value ? '-' : (value as string);
            },
        },
    },
    {
        name: 'serving',
        label: en.servings_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: unknown): string => {
                return !value ? '-' : (value as string);
            },
        },
    },
    {
        name: 'totalCookingTime',
        label: en.total_cooking_time,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: unknown): string => {
                return !value ? '-' : (value as string);
            },
        },
    },
    {
        name: 'isFavorite',
        label: en.favorite,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: boolean): string => {
                return value ? recipeConstants.YES : recipeConstants.NO;
            },
        },
    },
    ...commonOptions.objectTimestamps,
];

const recipeCookingStepsColumns: MUIDataTableColumn[] = [
    {
        name: 'long_description_en',
        label: en.long_description,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string): string => {
                return richTextEditorHelpers.parseRichText(value);
            },
        },
    },
    {
        name: 'short_description_en',
        label: en.short_description,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string): string => {
                return richTextEditorHelpers.parseRichText(value);
            },
        },
    },

    {
        name: 'first_zone',
        label: en.first_zone,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'second_zone',
        label: en.second_zone,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'third_zone',
        label: en.third_zone,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'fourth_zone',
        label: en.fourth_zone,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'master_probe',
        label: en.master_probe,
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'duration',
        label: en.countdown,
        options: {
            filter: false,
            sort: true,
        },
    },
];

const recipeIngredientsColumns: MUIDataTableColumn[] = [
    {
        name: 'orderNumber',
        label: '#',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: number, meta: MUIDataTableMeta) {
                return value + 1;
            },
        },
    },
    {
        name: 'name_en',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'metricQuantity',
        label: en.metric_quantity_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return value ? value : '-';
            },
        },
    },
    {
        name: 'metricUnit',
        label: en.metric_unit_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return value ? value : '-';
            },
        },
    },
    {
        name: 'imperialQuantity',
        label: en.imperial_quantity_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                const { n, d } = fraction((value || 0).toFixed(3)) as Fraction;
                return n ? `${n} / ${d}` : '-';
            },
        },
    },

    {
        name: 'imperialUnit',
        label: en.imperial_unit_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return value ? value : '-';
            },
        },
    },
];

const linkedRecipeColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            filter: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
];

export const recipeCookingStepsDataTable = {
    recipeCookingStepsColumns,
};

export const recipeDataTable = {
    recipeColumns,
    recipeIngredientsColumns,
    linkedRecipeColumns,
};
