import * as Yup from 'yup';
import formModels from './category.tag.form.model';
import { ObjectSchema } from 'yup';
import { ICategoryTagForm } from '../../../category.tags.interfaces';

const {
    formField: { nameEn, nameDe, tagTypeId },
} = formModels.categoryTagFormModel;

const categoryTagValidationSchema: ObjectSchema<ICategoryTagForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${nameEn.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${nameDe.requiredErrorMsg}`).defined(),
        tag_type_id: Yup.number().required(`${tagTypeId.requiredErrorMsg}`).defined(),
    });

const validationSchemas = {
    categoryTagValidationSchema,
};

export default validationSchemas;
