export const LOGIN_ENDPOINTS = {
    LOGIN: {
        url: process.env.REACT_APP_SERVER_URL + '/login',
    },
    REGISTER: {
        url: process.env.REACT_APP_SERVER_URL + '/register',
    },
    FORGOT_PASSWORD: {
        url: process.env.REACT_APP_SERVER_URL + '/profile/forgot-password',
    },
    CHANGE_PASSWORD: {
        url: process.env.REACT_APP_SERVER_URL + '/profile/change-password',
    },
};
