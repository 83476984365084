import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { IProduct } from '../upselling/upselling.interfaces';
import { promotionCampaignEndpoints } from './promotion.campaign.endpoints';
import {
    IPromotionCampaignResponse,
    IPromotionCampaignData,
    IPromotionCampaignCouponResult,
    ISegmentAudience,
} from './promotion.campaign.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getPromotionCampaigns = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IPromotionCampaignResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.GET_PROMOTION_CAMPAIGNS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getPromotionCampaign = async (promotionCampaignId: number): Promise<IPromotionCampaignData> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.GET_PROMOTION_CAMPAIGN.url + promotionCampaignId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const jsonKeys = ['regions', 'targetUsers', 'coupon', 'segmentAudiences'];
const promotionCampaignToFormData = (promotionCampaign: IPromotionCampaignData) => {
    const formData = new FormData();

    for (const key in promotionCampaign) {
        if (!promotionCampaign.hasOwnProperty(key)) {
            continue;
        }
        if (jsonKeys.includes(key)) {
            formData.append(`${key}`, JSON.stringify(promotionCampaign[key]));
            continue;
        }
        formData.append(`${key}`, promotionCampaign[key]);
    }
    return formData;
};

const addPromotionCampaign = async (promotionCampaign: IPromotionCampaignData): Promise<IPromotionCampaignData> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.ADD_PROMOTION_CAMPAIGN.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: promotionCampaignToFormData(promotionCampaign),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updatePromotionCampaign = async (
    promotionCampaignData: IPromotionCampaignData,
    promotionCampaignId: number,
): Promise<IPromotionCampaignData> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.UPDATE_PROMOTION_CAMPAIGN.url + promotionCampaignId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: promotionCampaignToFormData(promotionCampaignData),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deletePromotionCampaign = async (promotionCampaignId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.DELETE_PROMOTION_CAMPAIGN.url + promotionCampaignId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getAvailableCouponProducts = async (selectedRegions: string[]): Promise<IProduct[]> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.GET_AVAILABLE_COUPON_PRODUCTS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            selectedRegions: JSON.stringify(selectedRegions),
        },
    };

    const response = await axios(requestOptions);
    return response.data.data;
};

const getSegmentAudiences = async (): Promise<ISegmentAudience[]> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.GET_SEGMENT_AUDIENCES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);
    return response.data.data;
};

const testPromotionCampaignCoupon = async (
    promotionCampaign: IPromotionCampaignData,
): Promise<AxiosResponse<IPromotionCampaignCouponResult[]>> => {
    const requestOptions: AxiosRequestConfig = {
        url: promotionCampaignEndpoints.TEST_PROMOTION_CAMPAIGN_COUPON.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: promotionCampaign,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const promotionCampaignService = {
    getPromotionCampaigns,
    getPromotionCampaign,
    getSegmentAudiences,
    addPromotionCampaign,
    deletePromotionCampaign,
    updatePromotionCampaign,
    getAvailableCouponProducts,
    testPromotionCampaignCoupon,
};
