import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import en from '../../../../../../../assets/language/en.json';
import BilingualTextEditor, {
    BilingualTextEditorRef,
} from '../../../../../../shared/components/generics/textEditor/BilingualTextEditor';
import { ICookingStepsFormData } from '../../../../../cookingSteps/cooking.steps.interfaces';
import { recipeHelpers } from '../../../../../helpers/recipe.helpers';
import { ICookingStepsForm } from '../../../../recipe.interfaces';
import formInitialValues from '../../FormModel/FormInitialValues';
import recipeFormModel from '../../FormModel/RecipeFormModel';
import validationSchemas from '../../FormModel/ValidationSchema';
import styles from './index.module.scss';
import NotCompleteDialog from './NotCompleteDialog';

type OwnProps = {
    handleNewCookingStep: (values: ICookingStepsForm) => void;
    cookingStepToEdit?: ICookingStepsForm;
    backToSelectStepType: () => void;
};

function AddNormalCookingStep(props: OwnProps) {
    const [longDescSaved, setLongDescSaved] = useState(false);
    const form = recipeFormModel.cookingStepsFormModel.cookingStepsFormField;

    const longDescField = useRef<BilingualTextEditorRef>();

    const handleFormSubmit = (values: ICookingStepsForm) => {
        props.handleNewCookingStep(values);
    };

    const saveDescriptionFieldValue = (
        typeName: string,
        data: string,
        formProps: FormikProps<ICookingStepsForm>,
    ): void => {
        const dataObj: ICookingStepsFormData = JSON.parse(data);
        dataObj.blocks.map((block) => {
            block.text = block.text.replace(/ +/g, ' ').trim();
        });
        formProps.setFieldValue(typeName, JSON.stringify(dataObj));
    };

    return (
        <>
            <Formik
                initialValues={props.cookingStepToEdit || formInitialValues.normalCookingStep}
                enableReinitialize={true}
                onSubmit={(values: ICookingStepsForm, { setSubmitting, resetForm }) => {
                    handleFormSubmit(values);
                    setSubmitting(false);
                    resetForm();
                }}
                validationSchema={validationSchemas.cookingStepsSchema}
            >
                {(formProps: FormikProps<ICookingStepsForm>) => {
                    const [longDescComplete, setLongDescComplete] = useState(
                        recipeHelpers.checkDescriptionsFilled(
                            formProps.values.long_description_en,
                            formProps.values.long_description_de,
                        ),
                    );

                    useEffect(() => {
                        setLongDescComplete(
                            recipeHelpers.checkDescriptionsFilled(
                                formProps.values.long_description_en,
                                formProps.values.long_description_de,
                            ),
                        );
                    }, [formProps.values]);

                    useEffect(() => {
                        if (longDescSaved && longDescComplete) {
                            formProps.submitForm();
                        }
                    }, [longDescSaved, longDescComplete]);

                    const handleNext = () => {
                        setLongDescSaved(true);
                    };

                    const longDescriptionProps = {
                        en: {
                            value: formProps.values.long_description_en,
                            label: form.long_description_en.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.long_description_en.name, data, formProps);
                            },
                        },
                        de: {
                            value: formProps.values.long_description_de,
                            label: form.long_description_de.label,
                            alert: en.long_descriptions_saved_successfully,
                            onSave: (data) => {
                                saveDescriptionFieldValue(form.long_description_de.name, data, formProps);
                            },
                        },
                    };
                    return (
                        <>
                            {longDescSaved && !longDescComplete ? (
                                <NotCompleteDialog
                                    onContinue={() => setLongDescComplete(true)}
                                    onAddDescription={() => setLongDescSaved(false)}
                                ></NotCompleteDialog>
                            ) : (
                                <>
                                    <Form className={clsx(styles.addCookingStepsForm)}>
                                        <div className={styles.normalTextEditorWrapper}>
                                            <BilingualTextEditor
                                                {...longDescriptionProps}
                                                ref={longDescField}
                                                displayTitle
                                            />
                                        </div>
                                    </Form>
                                    <div className={styles.buttonWrapper}>
                                        <Button
                                            className={clsx(styles.buttonWhite, 'mx-2')}
                                            onClick={props.backToSelectStepType}
                                        >
                                            BACK
                                        </Button>
                                        <Button
                                            className={styles.buttonRed}
                                            onClick={() => {
                                                longDescField.current.saveText();
                                                handleNext();
                                            }}
                                        >
                                            NEXT
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    );
                }}
            </Formik>
        </>
    );
}

export default AddNormalCookingStep;
