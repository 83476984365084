import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';
import { troubleshootConstants } from './troubleshoot.constants';
import {
    ITroubleshoot,
    ITroubleshootActions,
    ITroubleshootPrimaryStep,
    ITroubleshootResponse,
    ITroubleshootStep,
} from './troubleshoot.interfaces';
import { troubleshootService } from './troubleshoot.service';

function getTroubleshoots(
    paging?: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<ITroubleshootResponse> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return troubleshootService.getTroubleshoots(pagination, searchText, filters).then(
            (response: ITroubleshootResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return [];
            },
        ) as Promise<ITroubleshootResponse>;
    };

    function request(): ITroubleshootActions {
        return { type: troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_REQUEST };
    }

    function success(response: ITroubleshootResponse): ITroubleshootActions {
        return {
            type: troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_SUCCESS,
            troubleshootData: response,
        };
    }

    function failure(error: string): ITroubleshootActions {
        return {
            type: troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_FAILURE,
            error,
        };
    }
}

function getTroubleshoot(entityId: number): (dispatch: Dispatch) => Promise<ITroubleshoot> {
    return (dispatch) => {
        return troubleshootService.getTroubleshoot(entityId).then(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        ) as Promise<ITroubleshoot>;
    };
}

const addTroubleshoot = (data: ITroubleshoot): ((dispatch: Dispatch) => Promise<ITroubleshoot | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.addTroubleshoot(data).then(
            (response: ITroubleshoot) => {
                dispatch(alertActions.success('Troubleshoot added successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const updateTroubleshoot = (
    data: ITroubleshoot,
    entityId: number,
): ((dispatch: Dispatch) => Promise<ITroubleshoot | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.updateTroubleshoot(data, entityId).then(
            (response: ITroubleshoot) => {
                dispatch(alertActions.success('Troubleshoot updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const deleteTroubleshoot = (
    entityId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.deleteTroubleshoot(entityId).then(
            (response: AxiosResponse) => {
                completed && dispatch(alertActions.success('Troubleshoot deleted successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };
};

const updateTroubleshootPrimarySteps = (
    data: ITroubleshootPrimaryStep[],
    entityId: number,
): ((dispatch: Dispatch) => Promise<ITroubleshootPrimaryStep[] | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.updateTroubleshootPrimarySteps(data, entityId).then(
            (response: ITroubleshootPrimaryStep[]) => {
                dispatch(alertActions.success("Troubleshoot's primary steps updated successfully"));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return null;
            },
        );
    };
};

const getTroubleshootStepsByPrimaryStepId = (
    primaryStepId: number,
): ((dispatch: Dispatch) => Promise<ITroubleshootStep[] | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.getTroubleshootStepsByPrimaryStepId(primaryStepId).then(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };
};

const updateTroubleshootSteps = (
    data: ITroubleshootStep[],
    entityId: number,
): ((dispatch: Dispatch) => Promise<ITroubleshootStep[] | AxiosError>) => {
    return (dispatch) => {
        return troubleshootService.updateTroubleshootSteps(data, entityId).then(
            (response) => {
                dispatch(alertActions.success("Troubleshoot's steps updated successfully"));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return null;
            },
        );
    };
};

export const troubleshotActions = {
    getTroubleshoots,
    getTroubleshoot,
    addTroubleshoot,
    updateTroubleshoot,
    deleteTroubleshoot,
    updateTroubleshootSteps,
    getTroubleshootStepsByPrimaryStepId,
    updateTroubleshootPrimarySteps,
};
