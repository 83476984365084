import React from 'react';
import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import { styleConstants } from '../../../constants/style.constants';

type OwnProps = CheckboxProps;

const StyledCheckbox = withStyles({
    root: {
        color: styleConstants.COLORS.BLACK_LIGHT,
        '&$checked': {
            color: styleConstants.COLORS.RED,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CustomCheckbox(props: OwnProps): JSX.Element {
    return <StyledCheckbox {...props} />;
}
