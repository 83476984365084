import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const formModel = {
    formId: uniqueId('form-'),
    formField: {
        name_en: {
            name: 'name_en',
            label: en.title_label,
            requiredErrorMsg: en.title_error_msg,
        },
        name_de: {
            name: 'name_de',
            label: de.title_label,
            requiredErrorMsg: de.title_error_msg,
        },
        picture: {
            name: 'picture',
            label: en.picture_label,
        },
    },
};

const formModels = {
    filterFormModel: formModel,
};

export default formModels;
