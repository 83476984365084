import React, { ChangeEvent, PropsWithChildren, ReactElement } from 'react';
import { Button } from '@material-ui/core';
import styles from './index.module.scss';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../../actions/alert.actions';

type Props = {
    uploadFile: (file: File | ArrayBuffer) => Promise<unknown | AxiosError>;
    allowedFileTypes: string[];
    buttonLabel: string;
    callback?: () => void;
};

const ImportCsvFile = (props: PropsWithChildren<Props>): ReactElement => {
    const hiddenFileInput = React.useRef(null);

    const handleImportClick = (): void => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();

        const fileUploaded: File = event.target.files[0];

        const reader: FileReader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const extension = fileUploaded.name.split('.').pop().toLowerCase();
                if (props.allowedFileTypes.indexOf(extension) <= -1) {
                    alertActions.error(`File type ${extension} is not allowed. Use one of [${props.allowedFileTypes}]`);
                    return;
                }
            }
        };
        await reader.readAsDataURL(fileUploaded);
        await props.uploadFile(fileUploaded);
        props.callback && props.callback();
    };

    return (
        <div className={styles.import}>
            <Button className={styles.importRecipe} startIcon={<CloudUploadIcon />} onClick={handleImportClick}>
                {props.buttonLabel}
            </Button>
            <input
                accept="file/"
                id="importFile"
                type="file"
                className={styles.input}
                ref={hiddenFileInput}
                onChange={handleChange}
            />
        </div>
    );
};

export default ImportCsvFile;
