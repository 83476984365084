import { useEffect, useState } from 'react';
import { globalSearchActions } from '../global.search.actions';
import { IGlobalSearchPageItem } from '../global.search.interfaces';

const deleteFromTree = (rootNodes: IGlobalSearchPageItem[], id: number) => {
    const newRootNodes = rootNodes.filter((node) => node.id !== id);
    return newRootNodes.map((node) => {
        const newNode = { ...node };
        if (newNode.children.length > 0) {
            newNode.children = deleteFromTree(newNode.children, id);
        }
        return newNode;
    });
};

const addNewChildToTree = (rootNodes: IGlobalSearchPageItem[], newNode: IGlobalSearchPageItem) => {
    if (!newNode.parentId) {
        rootNodes.push(newNode);
        return rootNodes;
    }

    for (const node of rootNodes) {
        if (node.id === newNode.parentId) {
            node.children.push(newNode);
            return rootNodes;
        }
        if (node.children.length > 0) {
            node.children = addNewChildToTree(node.children, newNode);
        }
    }
    return rootNodes;
};

const updateNodeInTree = (rootNodes: IGlobalSearchPageItem[], newNode: IGlobalSearchPageItem) => {
    for (const [index, node] of rootNodes.entries()) {
        if (node.id === newNode.id) {
            rootNodes[index] = newNode;
            return rootNodes;
        }
        if (node.children.length > 0) {
            node.children = updateNodeInTree(node.children, newNode);
        }
    }
    return rootNodes;
};

export const useTreeData = (
    getGlobalSearchAppPages: () => Promise<IGlobalSearchPageItem[]>,
    updateGlobalSearchAppPages: (data: IGlobalSearchPageItem[]) => Promise<IGlobalSearchPageItem[]>,
) => {
    const [treeData, setTreeData] = useState<IGlobalSearchPageItem[]>([]);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getGlobalSearchAppPages();
            setTreeData(data);
        };
        fetchData();
    }, []);

    const saveTreeData = async () => {
        const result = await updateGlobalSearchAppPages(treeData);
        if (result) {
            setTreeData(result);
        }
        setIsChanged(false);
    };

    const deleteTreeNode = (id: number) => {
        setTreeData((prev) => {
            const newTreeData = deleteFromTree([...prev], id);
            return newTreeData;
        });
        setIsChanged(true);
    };

    const addNewChild = (newNode: IGlobalSearchPageItem) => {
        newNode.id = new Date().getTime();
        newNode.children = [];

        setTreeData((prev) => {
            const newTreeData = addNewChildToTree([...prev], newNode);
            return newTreeData;
        });
        setIsChanged(true);
    };

    const updateNode = (newNode: IGlobalSearchPageItem) => {
        setTreeData((prev) => {
            const newTreeData = updateNodeInTree([...prev], newNode);
            return newTreeData;
        });
        setIsChanged(true);
    };

    return {
        treeData,
        deleteTreeNode,
        addNewChild,
        updateNode,
        isChanged,
        saveTreeData,
    };
};
