import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../../shared/styles/global.module.scss';
import en from '../../../../assets/language/en.json';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, ISort, IRowsDeleted } from '../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../shared/constants/mui.datatables.constants';
import clsx from 'clsx';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../shared/styles/mui.styles';
import { IUnit, IUnitProps } from '../unit.interfaces';
import { unitActions } from '../unit.actions';
import { unitDataTables } from '../../helpers/unit.datatables';
import AddOrEditUnit from './addOrEditUnits';
import formModels from './addOrEditUnits/formModel/units.form.model';
import { unitConstants } from '../unit.constants';

const Units = (props: PropsWithChildren<IUnitProps>): ReactElement<IUnitProps> => {
    const [units, setUnits] = useState<Array<IUnit>>([]);
    const [filters, setFilters] = useState<IFilters | null>();
    const [sort, setSort] = useState<ISort | null>();
    const [pagination, setPagination] = useState<IPagination | null>();
    const componentIsMounted = useRef(true);

    const { formId, formField } = formModels.unitFormModel;

    const tableColumns = [
        ...unitDataTables.unitColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: IUnit = {
                        id: tableMeta.rowData[unitConstants.UNIT_ROW_ELEMENTS.ID],
                        name: tableMeta.rowData[unitConstants.UNIT_ROW_ELEMENTS.NAME],
                        nameDe: tableMeta.rowData[unitConstants.UNIT_ROW_ELEMENTS.NAME_DE],
                        type: tableMeta.rowData[unitConstants.UNIT_ROW_ELEMENTS.TYPE],
                    };
                    return (
                        <AddOrEditUnit
                            formId={formId}
                            formField={formField}
                            unitsData={row}
                            refreshData={_refreshUnits}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        setRowProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        setCellProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const unitsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const unitsToRemoveLength = unitsToRemoveIndex.length;

            unitsToRemoveIndex.map(async (unitIndex, index) => {
                const unitId = units[unitIndex].id;
                const completed = unitsToRemoveLength === index + 1;

                await props.deleteUnit(unitId, completed);
                completed && (await _refreshUnits());
            });
        },
    };

    useEffect(() => {
        props.getUnits(filters, sort, pagination).then((response) => {
            if (componentIsMounted.current) {
                setUnits(response.units);
                componentIsMounted.current = false;
            }
        });
    }, []);

    const _refreshUnits = (): void => {
        props.getUnits(filters, sort, pagination).then((response) => {
            setUnits(response.units);
        });
    };

    return (
        <div className={styles.generalContainer}>
            <AddOrEditUnit formId={formId} formField={formField} refreshData={_refreshUnits} />
            <MUIDataTable title={en.units_label} options={options} columns={tableColumns} data={units} />
        </div>
    );
};

const mapDispatchToProps = {
    getUnits: unitActions.getUnits,
    deleteUnit: unitActions.deleteUnit,
    updateUnit: unitActions.updateUnit,
};

export default compose(withStyles(muiStyles.customStyles), connect(null, mapDispatchToProps))(Units);
