import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { categoryTagsAction } from '../../../../categoryTags/category.tags.actions';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import { ICategoryTagsData } from '../../../../categoryTags/category.tags.interfaces';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import { selectionActions } from '../../../selection.actions';
import en from '../../../../../../assets/language/en.json';
import {
    IRecipeSelectionCategoryTag,
    IRecipeSelectionCategoryTagsForm,
    IRecipeSelectionCategoryTagsFormComponent,
} from '../../../selection.interfaces';
import { selectionConstants } from '../../../selection.constants';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';

type Props = IRecipeSelectionCategoryTagsFormComponent & ReturnType<typeof mapStateToProps>;

const AddCategoryTags = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [categoryTags, setCategoryTags] = useState<Array<ICategoryTagsData>>([]);
    const [recipeSelectionCategoryTags, setRecipeSelectionCategoryTags] = useState<Array<IRecipeSelectionCategoryTag>>(
        [],
    );
    const [changedCategories, setChangedCategories] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const {
        selectionData: { selection },
        categoryTagsTotal,
        history,
    } = props;

    const id = useIdParam();

    useEffect(() => {
        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            categoryTagsTotal,
            categoryTags.length + recipeSelectionCategoryTags.length,
            page,
        );
        const isFirstMount = categoryTags.length + recipeSelectionCategoryTags.length === 0;

        (hasPagination || isFirstMount) &&
            props.getCategoryTags({ ...globalConstants.DEFAULT_PAGINATION, page: nextPage }).then((response) => {
                const categoryTagsResponse = response.categoryTags;

                props.getRecipeSelectionCategoryTags(id ? id : selection.id).then((response) => {
                    const recipeSelectionCategoryTagsResponse: Array<IRecipeSelectionCategoryTag> =
                        response.recipeSelectionCategoryTags;

                    if (Array.isArray(recipeSelectionCategoryTagsResponse)) {
                        const newRecipeSelectionCategoryTagsResponse = recipeSelectionCategoryTagsResponse.filter(
                            (recipeSelectionCategoryTagResponse) =>
                                !recipeSelectionCategoryTags.find((categoryTag) => {
                                    return categoryTag.id === recipeSelectionCategoryTagResponse.id;
                                }),
                        );
                        const updatedSelectionCategoryTags = recipeSelectionCategoryTags.concat(
                            newRecipeSelectionCategoryTagsResponse,
                        );

                        setIsUpdating(true);
                        setRecipeSelectionCategoryTags(updatedSelectionCategoryTags);
                        const categoryTagsToDisplay = categoryTagsResponse.filter(
                            (categoryTag) =>
                                !recipeSelectionCategoryTagsResponse.find((filterCategoryTag) => {
                                    return filterCategoryTag.id === categoryTag.id;
                                }),
                        );

                        const updatedCategoryTags = categoryTags.concat(categoryTagsToDisplay);

                        setCategoryTags(updatedCategoryTags);
                        hasPagination && setPage(nextPage);
                    } else {
                        const updatedCategoryTags = categoryTags.concat(categoryTagsResponse);
                        setCategoryTags(updatedCategoryTags);
                        hasPagination && setPage(nextPage);
                    }
                });
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedCategories) : handleInsert(changedCategories);
    };

    const handleUpdate = (categoryTagIds: Array<number>): void => {
        const categoryTagsToSave: IRecipeSelectionCategoryTagsForm = {
            category_tag_ids: categoryTagIds,
            recipe_selection_id: selection.id,
        };

        props.updateRecipeSelectionCategoryTags(categoryTagsToSave).then((response) => {
            props.handleCompleteStep(response);
        });
    };

    const handleInsert = (categoryTagIds: Array<number>): void => {
        const categoryTagsToSave: IRecipeSelectionCategoryTagsForm = {
            recipe_selection_id: selection.id,
            category_tag_ids: categoryTagIds,
        };

        props.addRecipeSelectionCategoryTags(categoryTagsToSave).then((response) => {
            props.handleCompleteStep(response);
        });
    };

    const handlePrevStep = (): void => {
        props.prevStep(props.selectionData);
    };

    const nextStep = (): void => {
        props.nextStep(props.selectionData);
    };

    return (
        <>
            {Array.isArray(categoryTags) || Array.isArray(recipeSelectionCategoryTags) ? (
                <DragAndDrop
                    destinationColumnName={selectionConstants.DRAG_AND_DROP.CATEGORY_TAGS_DESTINATION}
                    sourceColumnName={selectionConstants.DRAG_AND_DROP.CATEGORY_TAGS_SOURCE}
                    items={recipeSelectionCategoryTags}
                    availableItems={categoryTags}
                    handleItemsChange={setChangedCategories}
                    endScroll={[setEndScroll, endScroll]}
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={nextStep}
                    disabled={!selection}
                >
                    {en.next}
                </Button>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                >
                    {en.save_button_label}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handlePrevStep}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    categoryTagsTotal: state.categoryTags.total,
});

const mapDispatchToProps = {
    getCategoryTags: categoryTagsAction.getCategoryTags,
    addRecipeSelectionCategoryTags: selectionActions.addRecipeSelectionCategoryTags,
    getRecipeSelectionCategoryTags: selectionActions.getRecipeSelectionCategoryTags,
    updateRecipeSelectionCategoryTags: selectionActions.updateRecipeSelectionCategoryTags,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddCategoryTags);
