import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import { ICategoryTagForm, ICategoryTagsActions, ICategoryTagsResponse } from './category.tags.interfaces';
import { categoryTagsService } from './category.tags.service';
import { categoryTagsConstants } from './category.tags.constants';
import en from '../../../assets/language/en.json';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';

function getCategoryTags(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<ICategoryTagsResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return categoryTagsService.getCategoryTags(pagination, searchText, filters).then(
            (response: ICategoryTagsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_REQUEST };
    }

    function success(response: ICategoryTagsResponse): ICategoryTagsActions {
        return {
            type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_SUCCESS,
            categoryTagsData: response,
        };
    }

    function failure(error: string): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.GET_CATEGORY_TAGS_FAILURE, error };
    }
}

const addCategoryTag = (
    categoryTagsData: ICategoryTagForm,
): ((dispatch: Dispatch) => Promise<ICategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagsService.addCategoryTag(categoryTagsData).then(
            (response: ICategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.category_tag_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_REQUEST };
    }

    function success(response: ICategoryTagsResponse): ICategoryTagsActions {
        return {
            type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_SUCCESS,
            categoryTag: response.data,
        };
    }

    function failure(error: string): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.ADD_CATEGORY_TAG_FAILURE, error };
    }
};

const updateCategoryTag = (
    categoryTagData: ICategoryTagForm,
    categoryTagId: number,
): ((dispatch: Dispatch) => Promise<ICategoryTagsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagsService.updateCategoryTag(categoryTagData, categoryTagId).then(
            (response: ICategoryTagsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.category_tag_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_REQUEST };
    }

    function success(response: ICategoryTagsResponse): ICategoryTagsActions {
        return {
            type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_SUCCESS,
            categoryTag: response.data,
        };
    }

    function failure(error: string): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.UPDATE_CATEGORY_TAG_FAILURE, error };
    }
};

const deleteCategoryTag = (
    categoryTagId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagsService.deleteCategoryTag(categoryTagId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.category_tag_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagsActions {
        return { type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.DELETE_CATEGORY_TAG_REQUEST };
    }

    function success(response: AxiosResponse): ICategoryTagsActions {
        return {
            type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.DELETE_CATEGORY_TAG_SUCCESS,
        };
    }

    function failure(error: string): ICategoryTagsActions {
        return {
            type: categoryTagsConstants.CATEGORY_TAGS_ACTION_TYPES.DELETE_CATEGORY_TAG_FAILURE,
            error,
        };
    }
};

export const categoryTagsAction = {
    getCategoryTags,
    addCategoryTag,
    updateCategoryTag,
    deleteCategoryTag,
};
