import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { IInfluencerForm } from '../../../influencer.interfaces';
import { zodUtils } from '../../../../../shared/helpers/zodUtils';
import { z } from 'zod';

const {
    formField: { blog_name_en, blog_name_de, blogger_name },
} = formModels.ambassadorFormModel;

const ambassadorValidationSchema: ObjectSchema<IInfluencerForm> = Yup.object()
    .defined()
    .shape({
        blog_name_en: Yup.string().required(blog_name_en.requiredErrorMsg).defined(),
        blog_name_de: Yup.string().required(blog_name_de.requiredErrorMsg).defined(),
        blogger_name: Yup.string().required(blogger_name.requiredErrorMsg).defined(),
        header_picture: Yup.mixed(),
        userAccountMail: Yup.string().email().optional().nullable(),
    });

const influencerFeedSchema = z.object({
    id: z.number().optional().nullable(),
    titleDe: z.string().nullable(),
    descriptionDe: z.string().nullable(),
    titleEn: z.string().nullable(),
    descriptionEn: z.string().nullable(),
    content: zodUtils.fileSchema,
    publiclyVisible: z.boolean(),
});

const validationSchemas = {
    ambassadorValidationSchema,
    influencerFeedSchema,
};

export default validationSchemas;
