import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta } from 'mui-datatables';
import { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { selectionDataTables } from '../../../helpers/selection.datatables';
import { recipeAction } from '../../../recipe/recipe.actions';
import { selectionActions } from '../../selection.actions';
import { selectionConstants } from '../../selection.constants';
import { ISelectionProps } from '../../selection.interfaces';
import ExportRecipesButton from './ExportRecipesButton';
import styles from './index.module.scss';

type OwnProps = ISelectionProps & ReturnType<typeof mapStateToProps>;

const Selections = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history, selections, total, loading } = props;
    const tableColumns = [
        ...selectionDataTables.selectionColumns,
        {
            name: 'exportAction',
            label: 'Export for Cookbook',
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta): JSX.Element {
                    return <ExportRecipesButton selectionId={selections[tableMeta.rowIndex].id} />;
                },
            },
        },
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta): JSX.Element {
                    return (
                        <Button
                            component={Link}
                            to={`${selectionConstants.SELECTION_ROUTES.EDIT_SELECTION}/${
                                selectionConstants.SELECTION_SUB_ROUTES.SELECTION
                            }/${selections[tableMeta.rowIndex].id}`}
                            className={styles.editSelection}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const selectionToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const selectionToRemoveLength = selectionToRemoveIndex.length;

            selectionToRemoveIndex.map(async (selectionIndex, index) => {
                const selectionId = selections[selectionIndex].id;
                const completed = selectionToRemoveLength === index + 1;

                await props.deleteSelection(selectionId, completed);
                completed && (await _refreshSelections(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    const _refreshSelections = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getSelections(pagination, searchText, filters);
    };

    const addNewSelection = async (): Promise<void> => {
        history.push(selectionConstants.SELECTION_ROUTES.ADD_SELECTION);
    };

    return (
        <div className={clsx(styles.selection, 'mt16')}>
            <Button className={clsx(styles.addSelection, 'mb4')} onClick={addNewSelection}>
                {en.add_new_selection}
            </Button>
            <DynamicTable
                title={en.selections_label}
                options={options}
                columns={tableColumns}
                data={selections}
                total={total}
                loading={loading}
                refreshData={_refreshSelections}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    selections: state.selections.recipeSelections,
    total: state.selections.total,
    loading: state.selections.loading,
});

const mapDispatchToProps = {
    getSelections: selectionActions.getSelections,
    getSelectionById: selectionActions.getSelectionById,
    deleteSelection: selectionActions.deleteSelection,
    getSelectionDisplays: selectionActions.getSelectionDisplays,
    getRecipes: recipeAction.getRecipes,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Selections);
