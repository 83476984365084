import React, { FunctionComponent, PropsWithChildren, ReactElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Step, StepButton, Stepper, WithStyles } from '@material-ui/core';
import styles from './index.module.scss';
import formModels from './formModel/form.model';
import { History } from 'history';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { stepperStyles } from '../../../recipe/pages/addRecipe/styles';
import { ITutorialData, ITutorialForm } from '../../tutorial.interfaces';
import TutorialForm from './Forms/Tutorial';
import TutorialStep from './Forms/TutorialStep';
import en from '../../../../../assets/language/en.json';
import AddProducts from './Forms/TutorialProducts';
import { Link, useParams } from 'react-router-dom';
import { tutorialConstants } from '../../tutorial.constants';
import { withRouter } from 'react-router-dom';

interface IStepper extends WithStyles<typeof stepperStyles> {
    history: History;
    tutorialData: ITutorialData;
}

type Props = ITutorialForm & IStepper;

const { formId, formField } = formModels.tutorialFormModel;
const steps: string[] = [en.tutorial_label, en.tutorial_steps_label, en.tutorial_products_label];

const TutorialStepper = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const { classes, history } = props;
    const { id, step } = useParams() as any;

    const totalSteps: number = steps.length;
    const subRoutes = tutorialConstants.TUTORIALS_SUB_ROUTES;
    const defaultActiveStep = step ? Object.values(subRoutes).indexOf(step) : 0;

    const [activeStep, setActiveStep] = useState<number | null>(defaultActiveStep);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [tutorialToUpdate, setTutorialToUpdate] = useState<ITutorialData | null>(props.tutorialData);
    const isLastStep = () => {
        return activeStep === totalSteps - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps;
    };

    const nextStep = (tutorial?: ITutorialData): void => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setTutorialToUpdate(tutorial);
        setActiveStep(newActiveStep);
        id && pushToHistory(newActiveStep);
    };

    const prevStep = (tutorial?: ITutorialData): void => {
        setTutorialToUpdate(tutorial);
        setActiveStep((prevStep: number) => prevStep - 1);
        id && pushToHistory(activeStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = (tutorial?: ITutorialData, step?: string) => {
        const newCompleted = completed;

        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        nextStep(tutorial);
        id && pushToHistory(activeStep + 1);
    };

    const pushToHistory = (historyStep) => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        history.push(`${tutorialConstants.TUTORIALS_ROUTES.EDIT_TUTORIAL}/${formatSubRoutes[historyStep]}/${id}`);
    };

    const renderSteps = (step: number): JSX.Element => {
        switch (step) {
            case 0:
                return (
                    <TutorialForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        tutorialData={tutorialToUpdate}
                    />
                );
            case 1:
                return (
                    <TutorialStep
                        formId={formModels.tutorialStepFormModel.formId}
                        formField={formModels.tutorialStepFormModel.formField}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        tutorialData={tutorialToUpdate}
                        handleCompleteStep={handleComplete}
                    />
                );
            case 2:
                return (
                    <AddProducts
                        prevStep={prevStep}
                        tutorialData={tutorialToUpdate}
                        handleCompleteStep={handleComplete}
                    />
                );
            default:
                return (
                    <TutorialForm
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                        tutorialData={tutorialToUpdate}
                    />
                );
        }
    };

    return (
        <div className={styles.addTutorialStepper}>
            <Stepper activeStep={activeStep} className={classes.stepper} variant={'outlined'} nonLinear>
                {steps.map((label: string, index: number) => {
                    const subRoute = Object.values(subRoutes)[index];
                    return (
                        <Step key={label}>
                            <StepButton
                                component={Link}
                                to={`${tutorialConstants.TUTORIALS_ROUTES.EDIT_TUTORIAL}/${subRoute}/${id}`}
                                onClick={handleStep(index)}
                                completed={completed[index]}
                                disabled={!tutorialToUpdate}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {renderSteps(activeStep)}
        </div>
    );
};

export default compose(withRouter, withStyles(stepperStyles), connect(null, null))(TutorialStepper);
