import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as globalStyles from '../../../../shared/styles/global.module.scss';
import styles from './index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import clsx from 'clsx';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import AddOrEditBlacklist from './addOrEditBlacklistItem';
import formModels from './addOrEditBlacklistItem/formModel/blacklist.form.model';
import { IBlacklist, IBlacklistProps } from '../../reviews.interfaces';
import { IFilters, IPagination, IRowsDeleted, ISort } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { reviewActions } from '../../reviews.actions';
import { reviewDataTables } from '../../../helpers/reviews.datatables';
import { globalConstants } from '../../../../shared/constants/global.constants';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { Button } from '@material-ui/core';
import { reviewConstants } from '../../reviews.constants';

const EditBlacklist = (props: PropsWithChildren<IBlacklistProps>): ReactElement<IBlacklistProps> => {
    const [blacklist, setBlacklist] = useState<Array<IBlacklist>>([]);
    const [total, setTotal] = useState<number>(0);
    const [filters, setFilters] = useState<IFilters | null>();
    const [searchText, setSearchText] = useState<string>('');

    const { formId, formField } = formModels.blacklistFormModel;

    const tableColumns = [
        ...reviewDataTables.blacklistColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: IBlacklist = blacklist[tableMeta.rowIndex];
                    return (
                        <AddOrEditBlacklist
                            formId={formId}
                            formField={formField}
                            blacklistItem={row}
                            refreshData={_refreshBlacklist}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        filterType: muiDataTablesConstants.FILTER_TYPE.DROPDOWN,
        responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
        setRowProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        setCellProps: (): { className: string } => {
            return {
                className: clsx({ [props.classes.NameCell]: true }),
            };
        },
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const blacklistItemsToRemove = rowsDeleted.data.map(({ index }) => index);
            const blacklistItemsToRemoveLength = blacklistItemsToRemove.length;

            blacklistItemsToRemove.map(async (blacklistItemIndex, index) => {
                const blacklistItemId = blacklist[blacklistItemIndex].id;
                const completed = blacklistItemsToRemoveLength === index + 1;

                await props.deleteBlacklist(blacklistItemId, completed);
                completed && (await _refreshBlacklist(globalConstants.DEFAULT_PAGINATION, searchText, filters));
            });
        },
    };

    useEffect(() => {
        _refreshBlacklist(globalConstants.DEFAULT_PAGINATION);
    }, []);

    const _refreshBlacklist = (pagination?: IPagination, searchText?: string, filters?: IFilters): void => {
        props.getBlacklist(pagination || globalConstants.DEFAULT_PAGINATION, searchText, filters).then((response) => {
            setBlacklist(response.blacklist);
            setTotal(response.count);
        });
    };

    return (
        <div className={globalStyles.default.generalContainer}>
            <div className={styles.buttonRow}>
                <Button
                    className={styles.backButton}
                    onClick={() => props.history.replace(reviewConstants.REVIEW_ROUTES.REVIEWS)}
                >
                    {en.back}
                </Button>
                <AddOrEditBlacklist formId={formId} formField={formField} refreshData={_refreshBlacklist} />
            </div>
            <DynamicTable
                title={en.blacklist_label}
                options={options}
                columns={tableColumns}
                data={blacklist}
                total={total}
                loading={false}
                refreshData={_refreshBlacklist}
            />
        </div>
    );
};

const mapDispatchToProps = {
    getBlacklist: reviewActions.getBlacklist,
    deleteBlacklist: reviewActions.deleteBlacklist,
};

export default compose(withStyles(muiStyles.customStyles), connect(null, mapDispatchToProps))(EditBlacklist);
