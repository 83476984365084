import { userReportConstants } from './user.report.constants';

export const userReportEndpoints = {
    UPDATE_REVIEW_REPORT: {
        url: (ratingId: number) =>
            process.env.REACT_APP_SERVER_URL +
            userReportConstants.USER_REPORT_API_ROUTES.UPDATE_REVIEW_REPORT(ratingId),
    },
    GET_REVIEW_REPORTS: {
        url: (ratingId: number) =>
            process.env.REACT_APP_SERVER_URL + userReportConstants.USER_REPORT_API_ROUTES.GET_REVIEW_REPORTS(ratingId),
    },
    GET_REPORTED_USERS: {
        url: process.env.REACT_APP_SERVER_URL + userReportConstants.USER_REPORT_API_ROUTES.GET_REPORTED_USERS,
    },
    UPDATE_REPORTED_USER: {
        url: (userId: number) =>
            process.env.REACT_APP_SERVER_URL + userReportConstants.USER_REPORT_API_ROUTES.UPDATE_REPORTED_USER(userId),
    },
    GET_REPORTS_FOR_USER: {
        url: (userId: number) =>
            process.env.REACT_APP_SERVER_URL + userReportConstants.USER_REPORT_API_ROUTES.GET_REPORTS_FOR_USER(userId),
    },
};
