import React, { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import styles from './index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { tutorialActions } from '../../tutorial.actions';
import { ITutorialProps } from '../../tutorial.interfaces';
import { tutorialDataTables } from '../../../helpers/tutorial.datatables';
import { Button } from '@material-ui/core';
import { tutorialConstants } from '../../tutorial.constants';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type OwnProps = ITutorialProps & ReturnType<typeof mapStateToProps>;

const Tutorial = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history, tutorials, total, loading } = props;
    const tableColumns = [
        ...tutorialDataTables.tutorialColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            component={Link}
                            to={`${tutorialConstants.TUTORIALS_ROUTES.EDIT_TUTORIAL}/${
                                tutorialConstants.TUTORIALS_SUB_ROUTES.TUTORIAL
                            }/${tutorials[tableMeta.rowIndex].id}`}
                            className={styles.editTutorial}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const tutorialsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const tutorialsToRemoveLength = tutorialsToRemoveIndex.length;

            tutorialsToRemoveIndex.map(async (tutorialIndex, index) => {
                const tutorialId = tutorials[tutorialIndex].id;
                const completed = tutorialsToRemoveLength === index + 1;

                await props.deleteTutorial(tutorialId, completed);
                completed && _refreshTutorials(globalConstants.DEFAULT_PAGINATION);
            });
        },
    };

    const _refreshTutorials = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getTutorials(pagination, searchText, filters);
    };

    const addNewTutorial = (): void => {
        history.push(tutorialConstants.TUTORIALS_ROUTES.ADD_TUTORIAL);
    };

    return (
        <div className={clsx(styles.tutorial, 'mt16')}>
            <Button className={clsx(styles.addTutorial, 'mb4')} onClick={addNewTutorial}>
                {en.add_new_tutorial}
            </Button>
            <DynamicTable
                title={en.tutorials_label}
                options={options}
                columns={tableColumns}
                data={tutorials}
                total={total}
                loading={loading}
                refreshData={_refreshTutorials}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    tutorials: state.tutorials.tutorials,
    total: state.tutorials.total,
    loading: state.tutorials.loading,
});

const mapDispatchToProps = {
    getTutorials: tutorialActions.getTutorials,
    deleteTutorial: tutorialActions.deleteTutorial,
    getTutorialById: tutorialActions.getTutorialById,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Tutorial);
