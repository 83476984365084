import { Button } from '@material-ui/core';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';
import { recipeService } from '../../../recipe/recipe.service';
import { alertActions } from '../../../../shared/actions/alert.actions';

type ExportRecipesButtonProps = {
    selectionId: number;
    className?: string;
};

export default function ExportRecipesButton({ className, selectionId }: ExportRecipesButtonProps) {
    const fileMutation = useMutation({
        mutationFn: recipeService.exportRecipesForCookbook,
        onSuccess: (data) => {
            const anchor = anchorRef.current;
            if (anchor) {
                anchor.href = URL.createObjectURL(data.blob);
                anchor.download = data.filename;
                anchor.click();
            }
        },
        onError: (error) => {
            console.error(error);
            // CHeck if error has a toString method
            if (error && typeof error.toString === 'function') {
                alertActions.error(error.toString());
                return;
            }
            alertActions.error('An unknown error occurred while exporting recipes.');
        },
        onMutate: () => {
            alertActions.info('Exporting recipes for cookbook... This may take a while.');
        },
    });
    const anchorRef = useRef<HTMLAnchorElement>(null);

    return (
        <>
            <Button
                className={clsx(styles.editSelection, 'mr4', className)}
                onClick={() => {
                    fileMutation.mutate(selectionId);
                }}
                disabled={fileMutation.isLoading}
            >
                {'Cookbook export'}
            </Button>
            <a
                ref={anchorRef}
                style={{
                    display: 'none',
                }}
            />
        </>
    );
}
