import moment from 'moment';
import { IQuizQuestion, IQuizRound } from '../../../quiz.questions.interfaces';

const quizQuestion: IQuizQuestion = {
    id: null,
    questionEn: '',
    questionDe: '',
    correctAnswerEn: '',
    correctAnswerDe: '',
    wrongAnswerEn: '',
    wrongAnswerDe: '',
    answerExplanationEn: '',
    answerExplanationDe: '',
    questionImage: '',
    rewardPoints: 0,
};

export const ONE_WEEK_IN_HOURS = 168;
const quizRound: IQuizRound = {
    id: null,
    quizName: '',
    startDate: moment().utc().add(1, 'day').startOf('day').toDate(),
    hoursBetweenQuizQuestions: ONE_WEEK_IN_HOURS,
    quizQuestions: [],
};

export const formInitialValues = {
    quizQuestion,
    quizRound,
};
