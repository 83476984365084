export const unitConstants = {
    UNIT_ACTION_TYPES: {
        GET_UNITS_REQUEST: 'GET_UNITS_REQUEST',
        GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
        GET_UNITS_FAILURE: 'GET_UNITS_FAILURE',

        ADD_UNIT_REQUEST: 'ADD_UNITS_REQUEST',
        ADD_UNIT_SUCCESS: 'ADD_UNITS_SUCCESS',
        ADD_UNIT_FAILURE: 'ADD_UNITS_FAILURE',

        UPDATE_UNIT_REQUEST: 'UPDATE_UNIT_REQUEST',
        UPDATE_UNIT_SUCCESS: 'UPDATE_UNIT_SUCCESS',
        UPDATE_UNIT_FAILURE: 'UPDATE_UNIT_FAILURE',

        DELETE_UNIT_REQUEST: 'DELETE_UNIT_REQUEST',
        DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
        DELETE_UNIT_FAILURE: 'DELETE_UNIT_FAILURE',

        GET_CONVERSION_RATES_REQUEST: 'GET_CONVERSION_RATES_REQUEST',
        GET_CONVERSION_RATES_SUCCESS: 'GET_CONVERSION_RATES_SUCCESS',
        GET_CONVERSION_RATES_FAILURE: 'GET_CONVERSION_RATES_FAILURE',
    },
    UNIT_ROW_ELEMENTS: {
        ID: 1,
        NAME: 2,
        NAME_DE: 3,
        TYPE: 4,
    },
    UNIT_ROUTES: {
        UNITS: '/units',
        UNITS_WITH_PARAMS: '/units/',
        FIXED_CONVERSION_RATES: '/unit-conversion-rates',
    },
    TYPE: {
        IMPERIAL: {
            value: 'IMPERIAL',
            label: 'Imperial',
        },
        METRIC: {
            value: 'METRIC',
            label: 'Metric',
        },
        NONE: {
            value: 'NONE',
            label: 'None',
        },
    },
};
