import { authConstants } from '../constants/auth.constants';
import { authService } from '../services/auth.service';

export const authActions = {
    logout,
    invalidateToken,
};

function logout() {
    authService.logout();
    return { type: authConstants.LOGOUT };
}

function invalidateToken() {
    return { type: authConstants.token.INVALID_TOKEN };
}
