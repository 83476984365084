import { AxiosError, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { History } from 'history';
import { ICategoryTagsResponse } from '../categoryTags/category.tags.interfaces';
import { IngredientData, IngredientsResponse } from '../ingredients/ingredients.interfaces';
import { ICookingMethodResponse } from '../cookingMethods/cooking.methods.interfaces';
import { IInfluencersResponse } from '../influencers/influencer.interfaces';
import { IProductResponse } from '../upselling/upselling.interfaces';
import { ITutorialResponse } from '../tutorials/tutorial.interfaces';
import { IEquipmentResponse } from '../equipment/equipment.interfaces';
import { IFilterResponse } from '../filters/filter.interfaces';

export enum RecipeDifficulty {
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    EXPERT = 'EXPERT',
}

export interface IRecipe {
    cooking_time: number;
    deleted: boolean;
    difficulty_en: RecipeDifficulty;
    difficulty_de: RecipeDifficulty;
    full_picture: string;
    id: number;
    name_en: string;
    name_de: string;
    preparation_time: number;
    resting_time: number;
    overnightTime: boolean;
    serving: number;
    minimumServing: number;
    servingSteps: number;
    thumbnail_picture: string;
    ts_created: Date;
    ts_last_modified: Date;
    ambassador_id: number;
    shouldTimeScale: boolean;
    proRecipe: boolean;
    adminRecipe: boolean;
    hintTitleDe: string | null;
    hintTitleEn: string | null;
    hintDescriptionDe: string | null;
    hintDescriptionEn: string | null;
    linkedRecipes: LinkedRecipe[];
}

export interface LinkedRecipe {
    id: number;
    nameEn: string;
    quantityScalingFactor: number;
}

export interface IRecipeCookingMethod {
    id: number;
    recipe_id: number;
    cooking_method_id: number;
}

export interface IRecipeCookingMethodResponse {
    recipeCookingMethods: Array<IRecipeCookingMethod>;
}

export interface IRecipeData {
    totalCookingTime: number;
    id: number;
    isFavorite: boolean;
    nameEn: string;
    preparationTime: number;
    cookingTime: number;
    restingTime: number;
    overnightTime: boolean;
    serving: number;
    createdAt: Date;
    modifiedAt: Date;
}

export interface IRecipeState {
    recipes?: Array<IRecipeData>;
    recipeById?: IRecipe;
    recipeId?: number;
    recipeCategoryTags?: Array<IRecipeCategoryTags>;
    recipeIngredients?: Array<IRecipeIngredients>;
    recipeProducts?: Array<IRecipeProducts>;
    recipeEquipments?: Array<IRecipeEquipment>;
    total?: number;
    loading: boolean;
}

export interface IRecipeProps {
    recipes: IRecipeData;
    loggedIn: boolean;
    getRecipes: (paging: IPagination, searchText?: string, filters?: IFilters[]) => Promise<IRecipeResponse>;
    deleteRecipe: (recipeId: number, completed: boolean) => Promise<AxiosResponse>;
    getRecipeCookingMethods: (recipeId: number) => Promise<IRecipeCookingMethodResponse>;
    getRecipeGrillModels: (recipeId: number) => Promise<IRecipeGrillModelResponse>;
    getRecipeTutorials: (recipeId: number) => Promise<IRecipeTutorialResponse>;
    getRecipeById: (recipeId: number) => Promise<IRecipe>;
    getGrills: () => Promise<IGrillResponse>;
    getCategoryTags: (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ) => Promise<ICategoryTagsResponse>;
    getIngredients: (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ) => Promise<IRecipeIngredientsResponse>;
    getCookingMethods: (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ) => Promise<ICookingMethodResponse | AxiosError>;
    classes: {
        NameCell: string;
    };
    getFilters: (pagination: IPagination, searchText?: string, filters?: IFilters) => Promise<IFilterResponse>;
    history: History;
}

export interface IRecipeResponse extends AxiosResponse {
    recipes: Array<IRecipeData>;
    total: number;
}

export interface RecipeActions {
    type: string;
    recipeData?: {
        recipes: Array<IRecipeData>;
        total?: number;
    };
    recipeById?: IRecipe;
    error?: string;
    recipeCategoryTagsData?: {
        recipeCategoryTags: Array<IRecipeCategoryTags>;
    };
    recipeIngredientsData?: {
        recipeIngredients: Array<IRecipeIngredients>;
    };
    recipeCookingStepsData?: Array<IRecipeCookingStepsData>;
    recipeCookingMethodsData?: {
        recipeCookingMethods: Array<IRecipeCookingMethod>;
    };
    recipeProductsData?: {
        recipeProducts: Array<IRecipeProducts>;
    };
    recipeGrillModelsData?: {
        recipeGrillModels: Array<IRecipeGrillModel>;
    };
    grillModelsData?: {
        grillModels: Array<IGrillModel>;
    };
    recipeTutorialsData?: {
        recipeTutorials: Array<IRecipeTutorial>;
    };
    recipeEquipmentsData?: {
        recipeEquipments: Array<IRecipeEquipment>;
    };
}

export interface IRecipeForm {
    id?: number;
    name_en: string;
    name_de: string;
    cooking_method_ids: Array<number>;
    ambassador_id: number;
    difficulty_en: string;
    difficulty_de?: string;
    resting_time: number;
    preparation_time: number;
    cooking_time: number;
    overnightTime: boolean;
    serving: number;
    minimumServing: number;
    servingSteps: number;
    thumbnail_picture: ArrayBuffer | string;
    full_picture: ArrayBuffer | string;
    grillModelIds: Array<number>;
    shouldTimeScale: boolean;
    proRecipe: boolean;
    adminRecipe: boolean;
    hintTitleDe: string | null;
    hintTitleEn: string | null;
    hintDescriptionDe: string | null;
    hintDescriptionEn: string | null;
    tutorialIds: Array<number>;
    linkedRecipes: LinkedRecipe[];
    getRecipeCookingMethods?: (recipeId: number) => Promise<IRecipeCookingMethodResponse>;
    getRecipeGrillModels?: (recipeId: number) => Promise<IRecipeGrillModelResponse>;
    getRecipeTutorials?: (recipeId: number) => Promise<IRecipeTutorialResponse>;
    getRecipeById?: (recipeId: number) => Promise<IRecipe>;
}

export interface IActionName {
    id: number;
    nameEn: string;
    nameDe: string;
}
export interface IActionNamesActions {
    type: string;
    actionNamesData?: {
        actionNames: Array<IActionName>;
        total?: number;
    };
    actionName?: IActionName;
    error?: string;
}

export interface IActionNamesResponse extends AxiosResponse {
    actionNames: Array<IActionName>;
    total: number;
}

export interface ICookingStepsForm {
    id?: number;
    step_number?: string | number;
    duration: number;
    relevant_for_dashboard: boolean;
    short_description_en?: string;
    short_description_de?: string;
    long_description_en?: string;
    long_description_de?: string;
    first_zone: number;
    second_zone: number;
    third_zone: number;
    fourth_zone: number;
    master_probe: number;
    actions: ICookingStepAction[];
    cooking_step_method?: RecipeCookingStepMethod;
    is_interval?: boolean;
}

export interface ICookingStepAction {
    id?: number;
    actionId: number;
    actionTimer: number;
}

export enum RecipeCookingStepMethod {
    DIRECT = 'DIRECT',
    INDIRECT = 'INDIRECT',
}

export interface IRecipeCookingSteps {
    recipe_id: number;
    cooking_steps: Array<ICookingStepsForm>;
}

export interface IRecipeCategoryTagsForm {
    recipe_id: number;
    category_tag_ids: Array<number>;
}

export interface IRecipeEquipmentForm {
    recipe_id: number;
    equipment_ids: Array<number>;
}

export interface IRecipeIngredientsForm {
    id?: number;
    recipeId?: number;
    ingredient: IngredientData;
    metricQuantity: number;
    imperialQuantity: number;
    metricUnitId?: number;
    imperialUnitId?: number;
    isMeasurementUnitsConversionOn: boolean;
}

export interface IngredientsToSave {
    recipe_id: number;
    ingredients_data: Array<IRecipeIngredients>;
}

export interface IRecipeCategoryTags {
    id: number;
    name_en: string;
    name_de: string;
}

export interface IRecipeEquipment {
    id: number;
    nameEn: string;
    nameDe: string;
}

export interface IRecipeCategoryTagsResponse extends AxiosResponse {
    recipeCategoryTags: Array<IRecipeCategoryTags>;
}

export interface IRecipeEquipmentResponse extends AxiosResponse {
    recipeEquipments: Array<IRecipeEquipment>;
}

export interface IRecipeIngredients {
    id: number;
    ingredientId: number;
    metricUnitId: number;
    imperialUnitId: number;
    name_en: string;
    metricQuantity: number;
    metricUnit: string;
    imperialQuantity: number;
    imperialUnit: string;
    isMeasurementUnitsConversionOn: boolean;
    orderNumber: number;
}

export interface IRecipeIngredientsResponse extends AxiosResponse {
    recipeIngredients: Array<IRecipeIngredients>;
}

export interface IRecipeCookingStepsData extends ICookingStepsForm {
    id: number;
}

export interface IRecipeCookingStepsResponse {
    recipeCookingSteps: Array<IRecipeCookingStepsData>;
}

export interface IRecipeCookingStepsToSave {
    recipe_id?: number;
    cooking_steps: Array<ICookingStepsForm>;
}

export interface IRecipeProducts {
    id: number;
    titleEn: string;
    titleDe: string;
    randomProduct?: boolean;
}

export interface IRecipeProductsForm {
    recipeId: number;
    productIds: Array<number>;
}

export interface IRecipeProductsResponse extends AxiosResponse {
    recipeProducts: Array<IRecipeProducts>;
}

export interface IRecipeGrillModel {
    id: number;
    recipeId: number;
    grillModelId: number;
}

export interface IRecipeGrillModelResponse {
    recipeGrillModels: Array<IRecipeGrillModel>;
    total: number;
}

export interface IGrillModel {
    id: number;
    name: string;
}

export interface IGrillResponse {
    grillModels: Array<IGrillModel>;
    total: number;
}

export interface IRecipeTutorial {
    id: number;
    recipeId: number;
    tutorialId: number;
}

export interface IRecipeTutorialResponse {
    recipeTutorials: Array<IRecipeTutorial>;
    total: number;
}

export interface ICookingStepsComponentProps {
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    history: History;
    recipeToUpdate: IRecipe;
    addRecipeCookingSteps: (recipeCookingSteps: IRecipeCookingStepsToSave) => Promise<AxiosResponse>;
    getRecipeCookingSteps: (recipeId: number) => Promise<Array<IRecipeCookingStepsData>>;
    getActionNames: () => Promise<IActionNamesResponse>;
    updateRecipeCookingSteps: (
        recipeCookingSteps: IRecipeCookingStepsToSave,
        recipeId: number,
    ) => Promise<IRecipeCookingStepsResponse>;
    nextStep: (recipe: IRecipeForm) => void;
    handleCompleteStep: (recipe: IRecipeForm) => void;
}

export interface IRecipeCategoryTagsProps {
    nextStep: (recipe: IRecipeForm) => void;
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    getCategoryTags: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<ICategoryTagsResponse>;
    addRecipeCategoryTags: (recipeCategoryTags: IRecipeCategoryTagsForm) => Promise<AxiosResponse>;
    getRecipeCategoryTags: (recipeId: number) => Promise<IRecipeCategoryTagsResponse>;
    updateRecipeCategoryTags: (
        categoryTagsToUpdateData: IRecipeCategoryTagsForm,
    ) => Promise<IRecipeCategoryTagsResponse>;
}

export interface IRecipeEquipmentProps {
    nextStep: (recipe: IRecipeForm) => void;
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    getEquipment: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<IEquipmentResponse>;
    addRecipeEquipment: (recipeEquipment: IRecipeEquipmentForm) => Promise<AxiosResponse>;
    getRecipeEquipment: (recipeId: number) => Promise<IRecipeEquipmentResponse>;
    updateRecipeEquipment: (
        recipeId: number,
        recipeEquipment: IRecipeEquipmentForm,
    ) => Promise<IRecipeEquipmentResponse>;
}

export interface IRecipeIngredientsProps {
    nextStep: (recipe: IRecipeForm) => void;
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    getIngredients: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<IngredientsResponse>;
    getRecipeIngredients: (recipeId: number) => Promise<IRecipeIngredientsResponse>;
    updateRecipeIngredients: (
        recipeId: number,
        recipeIngredient: IRecipeIngredients[],
    ) => Promise<IRecipeIngredientsResponse>;
    getIngredientById: (ingredientId: number) => Promise<IngredientData>;
}

export interface IAddLinkedRecipeProps {
    nextStep: (recipe: IRecipeForm) => void;
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    getRecipes: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<IRecipeResponse>;
    updateLinkedRecipes: (linkedRecipes: LinkedRecipe[], recipeId: number) => Promise<AxiosResponse>;
    getFilters: (paging: IPagination, searchText?: string, filters?: IFilters) => Promise<IFilterResponse>;
}

export interface IRecipeFormProps {
    getRecipeById: (recipeId: number) => Promise<IRecipe>;
    addRecipe: (values: IRecipeForm) => Promise<IRecipeResponse>;
    updateRecipe: (values: IRecipeForm, recipeId: number) => Promise<IRecipeResponse>;
    getCookingMethods: (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ) => Promise<ICookingMethodResponse | AxiosError>;
    getRecipeCookingMethods: (recipeId: number) => Promise<IRecipeCookingMethodResponse>;
    getTutorials: (pagination: IPagination, searchText?: string, filters?: IFilters) => Promise<ITutorialResponse>;
    getGrills: () => Promise<IGrillResponse>;
    nextStep: (recipe: IRecipeForm) => void;
    recipeToUpdate: IRecipeForm;
    handleCompleteStep: (recipe: IRecipeForm) => void;
}

export interface IRecipeProductsFormProps {
    prevStep: (recipe: IRecipeForm) => void;
    recipe: IRecipeForm;
    history: History;
    recipeToUpdate: IRecipe;
    getProducts: (pagination: IPagination, searchText?: string, filters?: IFilters) => Promise<IProductResponse>;
    addRecipeProducts: (recipeProducts: IRecipeProductsForm) => Promise<AxiosResponse>;
    getRecipeProducts: (recipeId: number) => Promise<IRecipeProductsResponse>;
    updateRecipeProducts: (recipeProducts: IRecipeProductsForm, recipeId: number) => Promise<IRecipeProductsResponse>;
}

export enum CookingStepTypes {
    NORMAL,
    DASHBOARD_RELEVANT,
}
