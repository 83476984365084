import axios, { AxiosRequestConfig } from 'axios';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { analyticsEndpoints } from './analytics.endpoints';
import { FilterOptions, GetQueryOptionsResponse, QueryRequest, QueryResult } from './analytics.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getQueryOptions = async (): Promise<GetQueryOptionsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: analyticsEndpoints.QUERY_OPTIONS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const makeQuery = async (queryKey: string, queryRequest: QueryRequest): Promise<QueryResult> => {
    const requestOptions: AxiosRequestConfig = {
        url: analyticsEndpoints.QUERY.url(queryKey),
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: queryRequest,
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

export const analyticsService = {
    getQueryOptions,
    makeQuery,
};
