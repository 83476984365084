import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { recipeAction } from '../../../recipe.actions';
import { IRecipeProducts, IRecipeProductsForm, IRecipeProductsFormProps } from '../../../recipe.interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import { upsellingActions } from '../../../../upselling/upselling.actions';
import { IProduct } from '../../../../upselling/upselling.interfaces';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import { withRouter } from 'react-router-dom';
import { recipeConstants } from '../../../recipe.constants';
import product from '../../../../upselling/pages/product';
import clsx from 'clsx';
import { styleConstants } from '../../../../../shared/constants/style.constants';

type Props = IRecipeProductsFormProps & ReturnType<typeof mapStateToProps>;

const AddProducts = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [recipeProducts, setRecipeProducts] = useState<Array<IRecipeProducts>>([]);
    const [changedProducts, setChangedProducts] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const { recipe, productsTotal } = props;

    useEffect(() => {
        const isMounted = true;

        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            productsTotal,
            products.length + recipeProducts.length,
            page,
        );
        const isFirstMount = products.length + recipeProducts.length === 0;

        (hasPagination || isFirstMount) &&
            props.getProducts({ ...globalConstants.PAGINATION_NO_LIMIT, page: nextPage }).then((response) => {
                const productsResponse = response.products.map((item) => {
                    return {
                        ...item,
                        nameEn: item.titleEn,
                        nameDe: item.titleDe,
                    };
                });

                if (isMounted) {
                    props.getRecipeProducts(recipe.id).then((response) => {
                        const recipeProductsResponse: Array<IRecipeProducts> = response.recipeProducts;
                        if (Array.isArray(recipeProductsResponse)) {
                            const newRecipeProducts = recipeProductsResponse.filter(
                                (newRecipeProductResponse) =>
                                    !recipeProducts.find((recipeProduct) => {
                                        return recipeProduct.id === newRecipeProductResponse.id;
                                    }),
                            );
                            const updatedRecipeProducts = recipeProducts.concat(newRecipeProducts);

                            setIsUpdating(true);
                            setRecipeProducts(updatedRecipeProducts);

                            const productsToDisplay = productsResponse.filter(
                                (product) =>
                                    !recipeProductsResponse.find((recipeProduct) => {
                                        return recipeProduct.id === product.id;
                                    }),
                            );
                            const updatedProducts = products.concat(productsToDisplay);

                            setProducts(updatedProducts.map((p) => ({ ...p, randomProduct: false })));
                            hasPagination && setPage(nextPage);
                        } else {
                            const updatedProducts = products.concat(productsResponse);
                            setProducts(updatedProducts.map((p) => ({ ...p, randomProduct: false })));
                            hasPagination && setPage(nextPage);
                        }
                    });
                }
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedProducts) : handleInsert(changedProducts);
    };

    const handleUpdate = (productIds: Array<number>): void => {
        const productsToSave: IRecipeProductsForm = { recipeId: recipe.id, productIds };

        props.updateRecipeProducts(productsToSave, recipe.id).then(() => {
            props.history.push(recipeConstants.RECIPE_ROUTES.RECIPES);
        });
    };

    const handleInsert = (productIds: Array<number>): void => {
        const productsToSave: IRecipeProductsForm = {
            recipeId: recipe.id,
            productIds,
        };

        props.addRecipeProducts(productsToSave).then(() => {
            props.history.push(recipeConstants.RECIPE_ROUTES.RECIPES);
        });
    };

    return (
        <>
            {products.length || recipeProducts.length ? (
                <DragAndDrop
                    destinationColumnName={recipeConstants.DRAG_AND_DROP.PRODUCTS_DESTINATION}
                    sourceColumnName={recipeConstants.DRAG_AND_DROP.PRODUCTS_SOURCE}
                    items={recipeProducts}
                    availableItems={products}
                    handleItemsChange={setChangedProducts}
                    endScroll={[setEndScroll, endScroll]}
                    getItemBackgroundColor={(item) =>
                        item['randomProduct'] ? styleConstants.COLORS.LIGHT_GREY : styleConstants.COLORS.WHITE
                    }
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handleSubmit}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_FINISH}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.prevStep(recipe)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    productsTotal: state.products.total,
});

const mapDispatchToProps = {
    getProducts: upsellingActions.getProducts,
    addRecipeProducts: recipeAction.addRecipeProducts,
    getRecipeProducts: recipeAction.getRecipeProducts,
    updateRecipeProducts: recipeAction.updateRecipeProducts,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddProducts);
