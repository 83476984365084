import React, { ChangeEvent, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Chip, FormControl, FormGroup, Grid, TextField, useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Cancel } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import SubmitDialog from '../../../../../shared/components/generics/dialog/SubmitDialog';
import { IPagination } from '../../../../../shared/components/interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { recipeAction } from '../../../../recipe/recipe.actions';
import { selectionActions } from '../../../selection.actions';
import { selectionConstants } from '../../../selection.constants';
import { IAddRecipeForm, IAddRecipeFormComponent, IPinnedSelectionRecipe } from '../../../selection.interfaces';
import { formInitialValues } from '../formModel/form.initial.values';
import formModels from '../formModel/form.model';
import validationSchemas from '../formModel/validation.schema';
import styles from '../index.module.scss';

type Props = IAddRecipeFormComponent & typeof formModels.recipeFormModel;

const AddRecipe = (props: PropsWithChildren<Props>): ReactElement => {
    const {
        formField: { recipe },
        selectionData: { selection },
        history,
    } = props;

    const [open, setOpen] = useState(false);
    const [formInitials, setFormInitials] = useState<{ pinnedRecipes: IPinnedSelectionRecipe[] }>(
        formInitialValues.recipes,
    );
    const [searchText, setSearchText] = useState<string>('');
    const [options, setOptions] = useState<IPinnedSelectionRecipe[]>([]);
    const [noRecipesFound, setNoRecipesFound] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(open);
    const [pagination, setPagination] = useState<IPagination | null>(globalConstants.DEFAULT_PAGINATION);
    const [submitOpen, setSubmitOpen] = useState(false);
    const [submitFunction, setSubmitFunction] = useState(null);
    const [recipeIdsToBlackList, setRecipeIdsToBlackList] = useState<number[]>(selection.blacklistedRecipeIds || []);

    const componentIsMounted = useRef(true);

    useEffect(() => {
        let isActive = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const { recipes } = await props.getRecipesForSelection(pagination, searchText, undefined);
            await genericHelpers.sleep(1e3);

            if (isActive) {
                setOptions(recipes.map((recipe) => ({ recipe_id: recipe.id, name: recipe.nameEn, isPinned: true })));
                setLoading(false);
            }
        })();

        return () => {
            isActive = false;
        };
    }, [loading, searchText]);

    useEffect(() => {
        if (componentIsMounted) {
            (async () => {
                const recipesByTags = (await props.getRecipesByAllTags(selection.id)).recipes.map((value) => {
                    return {
                        recipe_id: value.id,
                        name: value.nameEn,
                    };
                });
                setNoRecipesFound(recipesByTags.length);
                let newPinnedRecipes = selection.pinnedRecipes
                    ? recipesByTags.concat(
                          selection.pinnedRecipes.map((pinned) => ({
                              ...pinned,
                              isPinned: true,
                          })),
                      )
                    : recipesByTags;

                newPinnedRecipes = genericHelpers.toDistinctArrByKey(newPinnedRecipes, 'recipe_id');
                setFormInitials({
                    ...formInitials,
                    pinnedRecipes: newPinnedRecipes,
                });
            })();
            componentIsMounted.current = false;
        }
    }, []);

    const handleSubmit = async (values: IAddRecipeForm): Promise<void> => {
        values.pinnedRecipes = values.pinnedRecipes.filter((recipe) => recipe.isPinned);
        await props.updatePinnedSelectionRecipes(values, recipeIdsToBlackList).then((response) => {
            props.handleCompleteStep(response);
            history.push(selectionConstants.SELECTION_ROUTES.SELECTIONS);
        });
    };

    const handleAutoCompleteChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = event.target.value;

        if (inputValue !== searchText) {
            setSearchText(inputValue);
            setLoading(true);
        }
    };

    const handlePrevStep = (): void => {
        props.prevStep(props.selectionData);
    };

    const handleFinish = (): void => {
        history.push(selectionConstants.SELECTION_ROUTES.SELECTIONS);
    };

    return (
        <div className={clsx(styles.selection, 'mt8')}>
            <Formik
                initialValues={formInitials}
                onSubmit={async (values: IAddRecipeForm, { setSubmitting }) => {
                    values.id = selection.id;
                    await handleSubmit(values);
                    setSubmitting(false);
                }}
                validationSchema={validationSchemas.recipeValidationSchema}
                enableReinitialize={true}
            >
                {(props: FormikProps<IAddRecipeForm>) => {
                    function openDeleteDialog(index: number, isPinned: boolean) {
                        const deleteRecipeFromList = () => {
                            props.values.pinnedRecipes.splice(index, 1);
                        };

                        if (isPinned) {
                            setSubmitFunction(() => () => {
                                deleteRecipeFromList();
                            });
                        } else {
                            setSubmitFunction(() => () => {
                                setRecipeIdsToBlackList((prev) =>
                                    Array.from(new Set([...prev, props.values.pinnedRecipes[index].recipe_id])),
                                );
                                deleteRecipeFromList();
                            });
                        }

                        setSubmitOpen(true);
                    }

                    return (
                        <Form className={styles.addSelectionForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <Autocomplete
                                                multiple
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                    setSearchText('');
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.recipe_id === value.recipe_id
                                                }
                                                getOptionLabel={(option: any) => {
                                                    return option.name;
                                                }}
                                                onInputChange={handleAutoCompleteChange}
                                                onChange={(_, data: IPinnedSelectionRecipe[]) => {
                                                    props.setFieldValue(recipe.name, data);
                                                    setRecipeIdsToBlackList((prev) =>
                                                        prev.filter((id) => id !== data[data.length - 1].recipe_id),
                                                    );
                                                }}
                                                options={options}
                                                loading={loading}
                                                value={props.values.pinnedRecipes}
                                                renderTags={(pinnedRecipes: IPinnedSelectionRecipe[]) => {
                                                    const styleManualTags: React.CSSProperties = {
                                                        backgroundColor: useTheme().palette.grey[800],
                                                        color: useTheme().palette.getContrastText(
                                                            useTheme().palette.grey[800],
                                                        ),
                                                        fill: useTheme().palette.getContrastText(
                                                            useTheme().palette.grey[200],
                                                        ),
                                                    };
                                                    const styleQueriedTags: React.CSSProperties = {
                                                        backgroundColor: useTheme().palette.grey[200],
                                                        color: useTheme().palette.getContrastText(
                                                            useTheme().palette.grey[200],
                                                        ),
                                                    };
                                                    return (
                                                        <>
                                                            <p
                                                                className={clsx(styles.noRecipesLabel)}
                                                            >{`${noRecipesFound} recipes found.`}</p>
                                                            {pinnedRecipes.map((recipe, index) => {
                                                                const bgStyle: React.CSSProperties = recipe.isPinned
                                                                    ? styleManualTags
                                                                    : styleQueriedTags;
                                                                return (
                                                                    <Chip
                                                                        style={bgStyle}
                                                                        className={clsx(styles.pinnedRecipes)}
                                                                        key={index}
                                                                        label={recipe.name}
                                                                        onDelete={() => {
                                                                            openDeleteDialog(index, recipe.isPinned);
                                                                        }}
                                                                        deleteIcon={
                                                                            <Cancel className={styles.cancelChipIcon} />
                                                                        }
                                                                    />
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={recipe.label}
                                                        name={recipe.name}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        <SubmitDialog
                                            open={submitOpen}
                                            onClose={() => setSubmitOpen(false)}
                                            title="Do you really want to remove this recipe from the selection?"
                                            cancelLabel="BACK"
                                            submitLabel="REMOVE"
                                            handleSubmit={submitFunction}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <div className={styles.bottomNavigation}>
                                <Button
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    onClick={handleFinish}
                                    variant={'outlined'}
                                >
                                    {globalConstants.NAVIGATION_BUTTONS_LABELS.FINISH}
                                </Button>
                                <Button
                                    type="submit"
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    variant={'outlined'}
                                    disabled={props.isSubmitting || !props.isValid}
                                >
                                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                                </Button>
                                <Button
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    onClick={handlePrevStep}
                                    variant={'outlined'}
                                >
                                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapDispatchToProps = {
    getRecipesForSelection: recipeAction.getRecipes,
    updatePinnedSelectionRecipes: selectionActions.updatePinnedSelectionRecipes,
    getRecipesByAllTags: selectionActions.getRecipesByAllTags,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(AddRecipe);
