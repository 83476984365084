export const filterConstants = {
    FILTER_ACTION_TYPES: {
        GET_FILTERS_REQUEST: 'GET_FILTERS_REQUEST',
        GET_FILTERS_SUCCESS: 'GET_FILTERS_SUCCESS',
        GET_FILTERS_FAILURE: 'GET_FILTERS_FAILURE',
        GET_FILTER_BY_ID_REQUEST: 'GET_FILTER_BY_ID_REQUEST',
        GET_FILTER_BY_ID_SUCCESS: 'GET_FILTER_BY_ID_SUCCESS',
        GET_FILTER_BY_ID_FAILURE: 'GET_FILTER_BY_ID_FAILURE',
        ADD_FILTER_REQUEST: 'ADD_FILTER_REQUEST',
        ADD_FILTER_SUCCESS: 'ADD_FILTER_SUCCESS',
        ADD_FILTER_FAILURE: 'ADD_FILTER_FAILURE',
        DELETE_FILTER_REQUEST: 'DELETE_FILTER_REQUEST',
        DELETE_FILTER_SUCCESS: 'DELETE_FILTER_SUCCESS',
        DELETE_FILTER_FAILURE: 'DELETE_FILTER_FAILURE',
        UPDATE_FILTER_REQUEST: 'UPDATE_FILTER_REQUEST',
        UPDATE_FILTER_SUCCESS: 'UPDATE_FILTER_SUCCESS',
        UPDATE_FILTER_FAILURE: 'UPDATE_FILTER_FAILURE',
        ADD_FILTER_CATEGORY_TAGS_REQUEST: 'ADD_FILTER_CATEGORY_TAGS_REQUEST',
        ADD_FILTER_CATEGORY_TAGS_SUCCESS: 'ADD_FILTER_CATEGORY_TAGS_SUCCESS',
        ADD_FILTER_CATEGORY_TAGS_FAILURE: 'ADD_FILTER_CATEGORY_TAGS_FAILURE',
        GET_FILTER_CATEGORY_TAGS_REQUEST: 'GET_FILTER_CATEGORY_TAGS_REQUEST',
        GET_FILTER_CATEGORY_TAGS_SUCCESS: 'GET_FILTER_CATEGORY_TAGS_SUCCESS',
        GET_FILTER_CATEGORY_TAGS_FAILURE: 'GET_FILTER_CATEGORY_TAGS_FAILURE',
        UPDATE_FILTER_CATEGORY_TAGS_REQUEST: 'UPDATE_FILTER_CATEGORY_TAGS_REQUEST',
        UPDATE_FILTER_CATEGORY_TAGS_SUCCESS: 'UPDATE_FILTER_CATEGORY_TAGS_SUCCESS',
        UPDATE_FILTER_CATEGORY_TAGS_FAILURE: 'UPDATE_FILTER_CATEGORY_TAGS_FAILURE',
    },
    FILTER_ROW_ELEMENTS: {
        ID: 1,
        NAME: 2,
        PICTURE: 3,
    },
    FILTERS_ROUTES: {
        FILTERS: '/filters',
        FILTERS_WITH_PARAMS: '/filters/',
        ADD_FILTER: '/filters/add',
        EDIT_FILTER: '/filters/edit',
        CATEGORY_TAGS: '/category-tags',
        FILTER_CATEGORY_TAGS: '/filter-category-tags',
    },

    FILTERS_SUB_ROUTES: {
        FILTER: 'filter',
        FILTER_CATEGORY_TAGS: 'category-tags',
    },

    DRAG_AND_DROP: {
        CATEGORY_TAGS_SOURCE: 'Available category tags',
        CATEGORY_TAGS_DESTINATION: 'Filter category tags',
    },
};
