import React from 'react';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import ImageModal from '../../shared/components/generics/imageModal/ImageModal';
import RoundedImage from '../../shared/components/generics/roundedImage/RoundedImage';
import { ReactComponent as UserPlaceholder } from '../../../assets/icons/user_placeholder.svg';

const reviewColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'userId',
        label: en.userId,
        options: {
            display: true,
            sort: true,
            filter: false,
            customHeadRender(columnMeta) {
                return (
                    <div
                        style={{
                            minWidth: '96px',
                        }}
                        className="MuiTableCell-head MuiTableCell-root"
                    >
                        {columnMeta.label}
                    </div>
                );
            },
        },
    },
    {
        name: 'profilePicture',
        label: en.profile_picture,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string, meta: MUIDataTableMeta) {
                return (
                    <div
                        style={{
                            minWidth: '128px',
                        }}
                    >
                        <RoundedImage
                            pictureUrl={pictureUrl}
                            imageSize="80px"
                            fallback={<UserPlaceholder width={'80px'} height={'80px'} />}
                        />
                    </div>
                );
            },
        },
    },
    {
        name: 'description',
        label: en.message,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value, tableMeta, updateValue) {
                return (
                    <span
                        style={{
                            overflowWrap: 'anywhere',
                            minWidth: '16rem',
                            display: 'block',
                        }}
                    >
                        {value}
                    </span>
                );
            },
        },
    },
    {
        name: 'reviewPicture',
        label: en.picture_label,
        options: {
            filter: false,
            sort: false,
            customBodyRender(pictureUrl: string) {
                if (pictureUrl) {
                    return (
                        <ImageModal
                            baseStyle={{
                                width: '96px',
                                height: '96px',
                                borderRadius: '8px',
                            }}
                            title={en.reviews_picture_label}
                            image={pictureUrl}
                            alt={en.reviews_picture_label}
                        />
                    );
                }
                return <></>;
            },
        },
    },
];

const blacklistColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'wordEn',
        label: en.name_en,
        options: {
            sort: true,
            filter: false,
        },
    },
    {
        name: 'wordDe',
        label: en.name_de,
        options: {
            sort: true,
            filter: false,
        },
    },
];

export const reviewDataTables = {
    reviewColumns,
    blacklistColumns,
};
