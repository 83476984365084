import React from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import CustomDatePicker from '../../../../shared/components/generics/datePicker/CustomDatePicker';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType, genericHelpers } from '../../../../shared/helpers/generics';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { AppState } from '../../../../shared/store';
import { quizQuestionTables } from '../../../helpers/quiz.question.datatables';
import { quizQuestionActions } from '../../quiz.questions.actions';
import { quizQuestionEndpoints } from '../../quiz.questions.endpoints';
import { IQuizQuestion, IQuizRound } from '../../quiz.questions.interfaces';
import { ONE_WEEK_IN_HOURS, formInitialValues } from './formModel/form.initial.values';
import formModels from './formModel/quiz.question.form.model';
import validationSchemas from './formModel/validation.schema';
import styles from './index.module.scss';
import DragDropTable from '../../../../shared/components/generics/dragDropTable/DragDropTable';

type OwnProps = DispatchPropsReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: AppState) => ({
    quizQuestions: state.quizQuestions.quizQuestions,
    total: state.quizQuestions.total,
    loading: state.quizQuestions.loading,
});

const mapDispatchToProps = {
    getQuizRound: quizQuestionActions.getQuizRound,
    addQuizRound: quizQuestionActions.addQuizRound,
    updateQuizRound: quizQuestionActions.updateQuizRound,
    getQuizQuestions: quizQuestionActions.getQuizQuestions,
};

export const AddOrEditQuizRound = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)((props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { addQuizRound, updateQuizRound, getQuizRound, getQuizQuestions, quizQuestions, total, loading } = props;

    const id = useIdParam();

    const [updatingQuizRound, setUpdatingQuizRound] = useState<IQuizRound>(null);
    const [allQuestionRows, setAllQuestionRows] = useState<IQuizQuestion[]>(quizQuestions);

    const formInitials = updatingQuizRound || formInitialValues.quizRound;
    const form = formModels.quizRoundFormModel.formField;
    useEffect(() => {
        if (id) {
            getQuizRound(+id).then((quizRound) => {
                setUpdatingQuizRound(quizRound);
            });
        }
        getQuizQuestions(globalConstants.PAGINATION_NO_LIMIT);
    }, []);

    const history = useHistory();
    const handleSubmit = async (values: IQuizRound): Promise<void> => {
        const saveResult = await (updatingQuizRound ? updateQuizRound(values, +id) : addQuizRound(values));
        if (saveResult instanceof Error) {
            return;
        }
        history.push(quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS);
    };

    const tableColumns = [null, ...quizQuestionTables.quizQuestionColumns];

    return (
        <>
            <Paper className={clsx(styles.productPaper, 'mt16')} variant={'outlined'}>
                <div className={clsx(styles.product, 'p4')}>
                    <Formik
                        initialValues={formInitials}
                        onSubmit={async (values: IQuizRound, { setSubmitting }) => {
                            await handleSubmit(values);
                            setSubmitting(false);
                        }}
                        validate={zodUtils.zodFormikValidator(validationSchemas.quizRoundSchema)}
                        enableReinitialize={true}
                    >
                        {(formProps: FormikProps<IQuizRound>) => {
                            const canEdit = moment(formProps.values.startDate).isAfter(moment());

                            useMemo(() => {
                                const selectedQuestionsMap = new Map();
                                for (const item of formProps.values.quizQuestions) {
                                    selectedQuestionsMap.set(item.id, item);
                                }

                                // Sort all questions first put all selected questions first (in order) then put all unselected questions
                                const newRows = [...quizQuestions].sort((a, b) => {
                                    const aSelected = selectedQuestionsMap.get(a.id);
                                    const bSelected = selectedQuestionsMap.get(b.id);
                                    if (aSelected && bSelected) {
                                        return aSelected.orderNumber - bSelected.orderNumber;
                                    } else if (aSelected) {
                                        return -1;
                                    } else if (bSelected) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                });

                                setAllQuestionRows(newRows);
                            }, [quizQuestions, formProps.values.quizQuestions]);

                            const options: MUIDataTableOptions = {
                                responsive: muiDataTablesConstants.RESPONSIVE.VERTICAL,
                                selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.MULTIPLE,
                                serverSide: true,
                                pagination: false,
                                selectableRowsHideCheckboxes: true,
                                rowsSelected: Array.from(Array(formProps.values.quizQuestions.length).keys()),
                                customToolbarSelect() {
                                    return null;
                                },
                                print: false,
                                download: false,
                                filter: false,
                                viewColumns: false,
                                search: false,
                            };

                            tableColumns[0] = {
                                name: 'custom-checkbox',
                                label: ' ',
                                options: {
                                    filter: false,
                                    sort: false,
                                    display: true,
                                    customBodyRenderLite(dataIndex, rowIndex) {
                                        return (
                                            <Checkbox
                                                checked={formProps.values.quizQuestions.some(
                                                    (item) => item.id === allQuestionRows[rowIndex].id,
                                                )}
                                                onChange={(event) => {
                                                    if (!canEdit) {
                                                        event.preventDefault();
                                                        return;
                                                    }

                                                    const clickedQuestionId = allQuestionRows[rowIndex].id;
                                                    if (!event.target.checked) {
                                                        // Remove the question from the list
                                                        const newState = [];
                                                        let idx = 0;
                                                        formProps.values.quizQuestions.forEach((item) => {
                                                            if (item.id !== clickedQuestionId) {
                                                                newState.push({ ...item, orderNumber: idx++ });
                                                            }
                                                        });
                                                        formProps.setFieldValue(form.quizQuestions.name, newState);
                                                    } else {
                                                        // Add the question to the list
                                                        formProps.setFieldValue(form.quizQuestions.name, [
                                                            ...formProps.values.quizQuestions,
                                                            {
                                                                id: clickedQuestionId,
                                                                orderNumber: formProps.values.quizQuestions.length,
                                                            },
                                                        ]);
                                                    }
                                                }}
                                                color="primary"
                                            />
                                        );
                                    },
                                },
                            };

                            return (
                                <Form className={styles.addProductForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                gutterBottom
                                                className={clsx(styles.typography, 'mt8')}
                                            >
                                                {`${id ? 'Edit' : 'Add'} Quiz Question Round`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.quizName.label}
                                                    name={form.quizName.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.quizName}
                                                    variant="outlined"
                                                    disabled={!canEdit}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.quizName && formProps.touched.quizName
                                                        ? formProps.errors.quizName
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    key={form.hoursBetweenQuizQuestions.name}
                                                    className={styles.formInput}
                                                    label={form.hoursBetweenQuizQuestions.label}
                                                    name={form.hoursBetweenQuizQuestions.name}
                                                    type="number"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.hoursBetweenQuizQuestions}
                                                    variant="outlined"
                                                    disabled={!canEdit}
                                                />
                                                <FormLabel>{`${genericHelpers.roundNumber(
                                                    formProps.values.hoursBetweenQuizQuestions / 24,
                                                    2,
                                                )} Days (${genericHelpers.roundNumber(
                                                    formProps.values.hoursBetweenQuizQuestions / ONE_WEEK_IN_HOURS,
                                                    2,
                                                )} weeks)`}</FormLabel>
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.hoursBetweenQuizQuestions &&
                                                    formProps.touched.hoursBetweenQuizQuestions
                                                        ? formProps.errors.hoursBetweenQuizQuestions
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <CustomDatePicker
                                                    label={form.startDate.label}
                                                    name={form.startDate.name}
                                                    value={formProps.values.startDate}
                                                    disabled={!canEdit}
                                                    onChange={(newDate) => {
                                                        formProps.setFieldValue(form.startDate.name, newDate);
                                                    }}
                                                    placeholder="DD/MM/YY"
                                                    dateFormat="dd/MM/yy"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.startDate && formProps.touched.startDate
                                                        ? (formProps.errors.startDate as React.ReactNode)
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <CustomDatePicker
                                                    label={'End Date'}
                                                    name={'endDate'}
                                                    value={moment(formProps.values.startDate)
                                                        .add(
                                                            formProps.values.hoursBetweenQuizQuestions *
                                                                formProps.values.quizQuestions.length,
                                                            'hours',
                                                        )
                                                        .toDate()}
                                                    disabled
                                                    onChange={() => null}
                                                    placeholder="DD/MM/YY"
                                                    dateFormat="dd/MM/yy"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.startDate && formProps.touched.startDate
                                                        ? (formProps.errors.startDate as React.ReactNode)
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        {/* Navigation bar */}
                                        <Grid item xs={12}>
                                            <div className={clsx(styles.bottomNavigation, 'mb4')}>
                                                {(() => {
                                                    const errorKeys = Object.keys(formProps.errors);
                                                    if (errorKeys?.length > 0 && errorKeys?.length <= 3) {
                                                        return (
                                                            <FormHelperText
                                                                className={clsx(styles.error_span, 'mt2', 'mr4')}
                                                            >
                                                                {errorKeys.map((key) => {
                                                                    return (
                                                                        <p
                                                                            key={form[`${key}.label`] || key}
                                                                        >{`${key}: ${formProps.errors[key]}`}</p>
                                                                    );
                                                                })}
                                                            </FormHelperText>
                                                        );
                                                    }
                                                    return null;
                                                })()}
                                                <Button
                                                    type="submit"
                                                    className={clsx(styles.saveProduct, 'mr2', 'mt4')}
                                                    disabled={formProps.isSubmitting || !formProps.isValid || !canEdit}
                                                >
                                                    {en.save_button_label}
                                                </Button>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className={styles.flexStartWrapper}>
                                            <Button
                                                className={clsx(styles.saveProduct, styles.shuffleButton)}
                                                onClick={() => {
                                                    const shuffledQuestions = shuffleArray(allQuestionRows);
                                                    setAllQuestionRows(shuffledQuestions);
                                                    formProps.setFieldValue(
                                                        form.quizQuestions.name,
                                                        shuffledQuestions
                                                            .slice(0, formProps.values.quizQuestions.length)
                                                            .map((item, idx) => ({ id: item.id, orderNumber: idx })),
                                                    );
                                                }}
                                                disabled={formProps.isSubmitting || !canEdit}
                                            >
                                                {'Shuffle Questions'}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MUIDataTable
                                                title={'Add Quiz Questions'}
                                                options={options}
                                                columns={tableColumns}
                                                data={allQuestionRows}
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Paper>
        </>
    );
});

function shuffleArray<T>(array: T[]) {
    const copy = [...array];
    for (let i = copy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = copy[i];
        copy[i] = copy[j];
        copy[j] = temp;
    }
    return copy;
}
