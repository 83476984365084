import { sendmailConstants } from './sendmail.constants';
import { ISendmailAction, ISendmailState } from './sendmail.interfaces';

const initialState: ISendmailState = {
    isLoading: false,
};

export const sendmailReducer = (state = initialState, action: ISendmailAction): ISendmailState => {
    switch (action.type) {
        case sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case sendmailConstants.SENDMAIL_ACTION_TYPES.SEND_MAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};
