import { equipmentConstants } from './equipment.constants';

export const equipmentEndpoints = {
    GET_EQUIPMENT: {
        url: process.env.REACT_APP_SERVER_URL + equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT,
    },
    ADD_EQUIPMENT: {
        url: process.env.REACT_APP_SERVER_URL + equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT + '/',
    },
    UPDATE_EQUIPMENT: {
        url: process.env.REACT_APP_SERVER_URL + equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT + '/',
    },
    DELETE_EQUIPMENT: {
        url: process.env.REACT_APP_SERVER_URL + equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT + '/',
    },
};
