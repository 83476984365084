import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { Button, IconButton, useTheme, withStyles } from '@material-ui/core';
import styles from './index.module.scss';
import { ReactComponent as DuplicateIcon } from '../../../../../assets/svg/duplicate.svg';
import { compose } from 'redux';
import { muiStyles } from '../../../styles/mui.styles';

type ToolBarProps = {
    applyDuplicatesFilter: () => void;
};

const CustomTableToolbar = (props: PropsWithChildren<ToolBarProps>): ReactElement<FunctionComponent<ToolBarProps>> => {
    return (
        <IconButton
            aria-label="Filter Duplicates"
            className={styles.duplicateButton}
            onClick={props.applyDuplicatesFilter}
        >
            <DuplicateIcon
                className={styles.duplicateIcon}
                style={{
                    fill: useTheme().palette.text.secondary,
                }}
            />
        </IconButton>
    );
};

export default compose(withStyles(muiStyles.customStyles))(CustomTableToolbar);
