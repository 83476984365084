import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../shared/store';
import styles from '../../../ingredients/pages/ingredient/index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { ICategoryTagProps, ICategoryTagForm } from '../../category.tags.interfaces';
import { categoryTagsAction } from '../../category.tags.actions';
import { compose } from 'recompose';
import { categoryTagDataTables } from '../../../helpers/category.tag.datatables';
import { withStyles } from '@material-ui/core/styles';
import { categoryTagsConstants } from '../../category.tags.constants';
import formModels from '../addOrEditCategoryTag/formModel/category.tag.form.model';
import AddOrEditCategoryTag from '../addOrEditCategoryTag';
import { ICategoryTagTypeData } from '../../../categoryTagTypes/category.tag.types.interfaces';
import { categoryTagTypesActions } from '../../../categoryTagTypes/category.tag.types.actions';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';

const CategoryTag = (props: PropsWithChildren<ICategoryTagProps>): ReactElement<ICategoryTagProps> => {
    const [categoryTagTypes, setCategoryTagTypes] = useState<Array<ICategoryTagTypeData>>([]);
    const { categoryTags, total, loading } = props;
    const componentIsMounted = useRef(true);
    const { formId, formField } = formModels.categoryTagFormModel;
    const tableColumns = [
        ...categoryTagDataTables.categoryTagColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: ICategoryTagForm = {
                        id: tableMeta.rowData[categoryTagsConstants.CATEGORY_TAG_ROW_ELEMENTS.ID],
                        name_en: tableMeta.rowData[categoryTagsConstants.CATEGORY_TAG_ROW_ELEMENTS.NAME_EN],
                        name_de: tableMeta.rowData[categoryTagsConstants.CATEGORY_TAG_ROW_ELEMENTS.NAME_DE],
                        tag_type_id: tableMeta.rowData[categoryTagsConstants.CATEGORY_TAG_ROW_ELEMENTS.TYPE_ID],
                    };

                    return (
                        <AddOrEditCategoryTag
                            formId={formId}
                            formField={formField}
                            categoryTagsData={row}
                            refreshData={_refreshCategoryTags}
                            categoryTagTypes={categoryTagTypes}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const categoryTagsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const categoryTagsToRemoveLength = categoryTagsToRemoveIndex.length;

            categoryTagsToRemoveIndex.map(async (categoryTagIndex, index) => {
                const categoryTagId = categoryTags[categoryTagIndex].id;
                const completed = categoryTagsToRemoveLength === index + 1;
                await props.deleteCategoryTag(categoryTagId, completed);
                completed && (await _refreshCategoryTags(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    useEffect(() => {
        props.getCategoryTags(globalConstants.DEFAULT_PAGINATION).then(() => {
            if (componentIsMounted.current) {
                props.getCategoryTagTypes(null, null, null).then((categoryTagTypesResponse) => {
                    setCategoryTagTypes(categoryTagTypesResponse.categoryTagTypes);
                });

                componentIsMounted.current = false;
            }
        });
    }, []);

    const _refreshCategoryTags = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getCategoryTags(pagination, searchText, filters);
    };

    return (
        <div className={clsx(styles.ingredient, 'mt16')}>
            <AddOrEditCategoryTag
                formId={formId}
                formField={formField}
                refreshData={_refreshCategoryTags}
                categoryTagTypes={categoryTagTypes}
            />
            <DynamicTable
                title={en.category_tags_label}
                options={options}
                columns={tableColumns}
                data={categoryTags}
                total={total}
                loading={loading}
                refreshData={_refreshCategoryTags}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    categoryTags: state.categoryTags.categoryTags,
    total: state.categoryTags.total,
    loading: state.categoryTags.loading,
});

const mapDispatchToProps = {
    getCategoryTags: categoryTagsAction.getCategoryTags,
    deleteCategoryTag: categoryTagsAction.deleteCategoryTag,
    getCategoryTagTypes: categoryTagTypesActions.getCategoryTagTypes,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(CategoryTag);
