import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';
import { quizQuestionConstants } from './quiz.questions.constants';
import {
    IQuizQuestion,
    IQuizQuestionActions,
    IQuizQuestionResponse,
    IQuizRound,
    IQuizRoundResponse,
} from './quiz.questions.interfaces';
import { quizQuestionService } from './quiz.questions.service';

function getQuizQuestions(
    paging?: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IQuizQuestionResponse> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return quizQuestionService.getQuizQuestions(pagination, searchText, filters).then(
            (response: IQuizQuestionResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return [];
            },
        ) as Promise<IQuizQuestionResponse>;
    };

    function request(): IQuizQuestionActions {
        return { type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_REQUEST };
    }

    function success(response: IQuizQuestionResponse): IQuizQuestionActions {
        return {
            type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_SUCCESS,
            quizQuestionsData: response,
        };
    }

    function failure(error: string): IQuizQuestionActions {
        return {
            type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_QUESTIONS_FAILURE,
            error,
        };
    }
}

function getQuizQuestion(entityId: number): (dispatch: Dispatch) => Promise<IQuizQuestion> {
    return (dispatch) => {
        return quizQuestionService.getQuizQuestion(entityId).then(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        ) as Promise<IQuizQuestion>;
    };
}

const addQuizQuestion = (data: IQuizQuestion): ((dispatch: Dispatch) => Promise<IQuizQuestion | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.addQuizQuestion(data).then(
            (response: IQuizQuestion) => {
                dispatch(alertActions.success('Quiz Question added successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const updateQuizQuestion = (
    data: IQuizQuestion,
    entityId: number,
): ((dispatch: Dispatch) => Promise<IQuizQuestion | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.updateQuizQuestion(data, entityId).then(
            (response: IQuizQuestion) => {
                dispatch(alertActions.success('Quiz Question updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const deleteQuizQuestion = (
    entityId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.deleteQuizQuestion(entityId).then(
            (response: AxiosResponse) => {
                completed && dispatch(alertActions.success('Quiz Question deleted successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };
};

function getQuizRounds(
    paging?: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IQuizRoundResponse> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return quizQuestionService.getQuizRounds(pagination, searchText, filters).then(
            (response: IQuizRoundResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return [];
            },
        ) as Promise<IQuizRoundResponse>;
    };

    function request(): IQuizQuestionActions {
        return { type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_REQUEST };
    }

    function success(response: IQuizRoundResponse): IQuizQuestionActions {
        return {
            type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_SUCCESS,
            quizRoundData: response,
        };
    }

    function failure(error: string): IQuizQuestionActions {
        return {
            type: quizQuestionConstants.QUIZ_QUESTION_ACTION_TYPES.GET_QUIZ_ROUND_FAILURE,
            error,
        };
    }
}

function getQuizRound(entityId: number): (dispatch: Dispatch) => Promise<IQuizRound> {
    return (dispatch) => {
        return quizQuestionService.getQuizRound(entityId).then(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        ) as Promise<IQuizRound>;
    };
}

const addQuizRound = (data: IQuizRound): ((dispatch: Dispatch) => Promise<IQuizRound | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.addQuizRound(data).then(
            (response: IQuizRound) => {
                dispatch(alertActions.success('Quiz Round added successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const updateQuizRound = (
    data: IQuizRound,
    entityId: number,
): ((dispatch: Dispatch) => Promise<IQuizRound | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.updateQuizRound(data, entityId).then(
            (response: IQuizRound) => {
                dispatch(alertActions.success('Quiz Round updated successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };
};

const deleteQuizRound = (
    entityId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        return quizQuestionService.deleteQuizRound(entityId).then(
            (response: AxiosResponse) => {
                completed && dispatch(alertActions.success('Quiz Round deleted successfully'));
                return response;
            },
            (error: AxiosError) => {
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };
};

export const quizQuestionActions = {
    getQuizQuestions,
    getQuizQuestion,
    addQuizQuestion,
    updateQuizQuestion,
    deleteQuizQuestion,
    getQuizRounds,
    getQuizRound,
    addQuizRound,
    updateQuizRound,
    deleteQuizRound,
};
