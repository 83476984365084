import { analyticsConstants } from './analytics.constants';

export const analyticsEndpoints = {
    QUERY_OPTIONS: {
        url: process.env.REACT_APP_SERVER_URL + analyticsConstants.ANALYTICS_API_ROUTES.QUERY_OPTIONS,
    },
    FILTER_OPTIONS: {
        url: (queryKey: string): string =>
            process.env.REACT_APP_SERVER_URL + analyticsConstants.ANALYTICS_API_ROUTES.FILTER_OPTIONS(queryKey),
    },
    QUERY: {
        url: (queryKey: string): string =>
            process.env.REACT_APP_SERVER_URL + analyticsConstants.ANALYTICS_API_ROUTES.QUERY(queryKey),
    },
};
