import { AxiosResponse } from 'axios';
import { IFilters, IPagination, ISort } from '../../shared/components/interfaces';
import { History } from 'history';
import { WithStyles } from '@material-ui/styles';
import { muiStyles } from '../../shared/styles/mui.styles';
import { IFilterResponse } from '../filters/filter.interfaces';

export interface IReview {
    id: number;
    userId: number;
    recipeId: number;
    rating: number;
    description: string;
    filename: string;
    userReviews: number;
    recipeReviews: number;
    read: boolean;
    profilePicture: string;
    fullName: string;
    userBlocked: boolean;
    recipeName: string;
    reviewPicture: string;
    totalReports: number;
    internalDecision: InternalRatingReportDecision;
}

export enum InternalRatingReportDecision {
    BLOCKED = 'Blocked',
    NOT_BLOCKED = 'Not blocked',
}

export interface IReviewResponse extends AxiosResponse {
    reviews: Array<IReview>;
    count: number;
}

export interface IBlockUserResponse extends AxiosResponse {
    id: number;
    name: string;
    surname: string;
    username: string;
    blocked: boolean;
}

export interface ICountUnreadReviewsResponse extends AxiosResponse {
    count: number;
}

export interface IReviewActions {
    type: string;
    reviewData?: IReviewResponse;
    noUnreadReviews?: number;
    error?: string;
}

export interface IReviewState {
    reviews?: Array<IReview>;
    total?: number;
    loading: boolean;
    noUnreadReviews?: number;
}

export interface IReviewProps {
    reviews: Array<IReview>;
    loggedIn: boolean;
    getReviews: (pagination: IPagination, searchText?: string, filters?: IFilters[]) => Promise<IReviewResponse>;
    deleteReview: (reviewId: number, completed: boolean) => Promise<AxiosResponse>;
    blockUser: (userId: number, blocked: boolean) => Promise<IBlockUserResponse>;
    getFilters: (pagination: IPagination, searchText?: string, filters?: IFilters) => Promise<IFilterResponse>;
    updateReviewStatus: (
        reviewId: number,
        read: boolean,
        internalDecision: InternalRatingReportDecision,
    ) => Promise<AxiosResponse>;
    history: History;
}

export interface IBlacklist {
    id?: number;
    wordEn: string;
    wordDe: string;
}

export interface IBlacklistList {
    blacklist: Array<IBlacklist>;
    count: number;
}

export interface IBlacklistProps {
    blacklistList: Array<IBlacklist>;
    loggedIn: boolean;
    getBlacklist: (pagination: IPagination, searchText?: string, filters?: IFilters) => Promise<IBlacklistResponse>;
    deleteBlacklist: (id: number, completed: boolean) => Promise<AxiosResponse>;
    classes: {
        NameCell: string;
    };
    history: History;
}

export interface IBlacklistFormComponent {
    blacklistItem?: IBlacklist | null;
    addBlacklistItem: (values: IBlacklist) => Promise<IBlacklistResponse>;
    updateBlacklistItem: (values: IBlacklist, id: number) => Promise<IBlacklistResponse>;
    refreshData: (pagination?: IPagination, searchText?: string, filters?: IFilters) => void;
    history: History;
}

export interface IAddOrEditBlacklist extends WithStyles<typeof muiStyles.addOrEditDialog> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export interface IBlacklistResponse extends AxiosResponse, IBlacklistList {}
