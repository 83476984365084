import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import { IEquipmentResponse, IEquipment } from './equipment.interfaces';
import { equipmentEndpoints } from './equipment.endpoints';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getEquipment = async (): Promise<IEquipmentResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: equipmentEndpoints.GET_EQUIPMENT.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };
    const response = await axios(requestOptions);

    return response.data.data;
};

const addEquipment = async (equipment: IEquipment): Promise<IEquipmentResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: equipmentEndpoints.ADD_EQUIPMENT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: equipment,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateEquipment = async (equipment: IEquipment, id: number): Promise<IEquipmentResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: equipmentEndpoints.UPDATE_EQUIPMENT.url + id,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: equipment,
    };
    const response = await axios(requestOptions);

    return response.data;
};

const deleteEquipment = async (id: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: equipmentEndpoints.DELETE_EQUIPMENT.url + id,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const equipmentService = {
    getEquipment,
    addEquipment,
    updateEquipment,
    deleteEquipment,
};

export default equipmentService;
