import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { MUIDataTableMeta } from 'mui-datatables';
import { ingredientDataTables } from '../../../helpers/ingredient.datatables';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { compose } from 'recompose';
import { IngredientForm, IngredientProps } from '../../ingredients.interfaces';
import { ingredientsAction } from '../../ingredients.actions';
import AddIngredient from '../addIngredient';
import formModels from '../addIngredient/formModel/ingredient.form.model';
import { ingredientsConstants } from '../../ingredients.constants';
import en from '../../../../../assets/language/en.json';
import styles from './index.module.scss';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { AppState } from '../../../../shared/store';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import clsx from 'clsx';

type OwnProps = IngredientProps & ReturnType<typeof mapStateToProps>;

const Ingredient = (props: PropsWithChildren<OwnProps>): ReactElement<FunctionComponent<OwnProps>> => {
    const { formId, formField } = formModels.ingredientFormModel;
    const { ingredients, total, loading } = props;
    const tableColumns = [
        ...ingredientDataTables.ingredientColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    const row: IngredientForm = {
                        id: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.ID],
                        name_en: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.NAME_EN],
                        name_de: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.NAME_DE],
                        imperialUnitId: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.IMPERIAL_UNIT],
                        metricUnitId: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.METRIC_UNIT],
                        conversion: tableMeta.rowData[ingredientsConstants.INGREDIENT_ROW_ELEMENTS.CONVERSION],
                    };

                    return (
                        <AddIngredient
                            formId={formId}
                            formField={formField}
                            ingredientData={row}
                            refreshData={_refreshIngredients}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const ingredientsToRemoveIndex = rowsDeleted.data.map((item) => item.index);
            const ingredientToRemoveLength = ingredientsToRemoveIndex.length;

            ingredientsToRemoveIndex.map(async (ingredientIndex, index) => {
                const ingredientId = ingredients[ingredientIndex].id;
                const completed = ingredientToRemoveLength === index + 1;
                await props.deleteIngredient(ingredientId, completed);
                completed && _refreshIngredients(globalConstants.DEFAULT_PAGINATION);
            });
        },
    };

    const _refreshIngredients = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getIngredients(pagination, searchText, filters);
    };

    return (
        <div className={clsx(styles.ingredient, 'mt16')}>
            <AddIngredient formId={formId} formField={formField} refreshData={_refreshIngredients} />
            <DynamicTable
                title={en.ingredients_label}
                options={options}
                columns={tableColumns}
                data={ingredients}
                total={total}
                loading={loading}
                refreshData={_refreshIngredients}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    ingredients: state.ingredients.ingredients,
    total: state.ingredients.total,
    loading: state.ingredients.loading,
});

const mapDispatchToProps = {
    getIngredients: ingredientsAction.getIngredients,
    deleteIngredient: ingredientsAction.deleteIngredient,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Ingredient);
