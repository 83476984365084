export const analyticsConstants = {
    ANALYTICS_API_ROUTES: {
        QUERY_OPTIONS: '/analytics/query-options',
        FILTER_OPTIONS: (queryKey: string): string => `/analytics/filter-options/${queryKey}`,
        QUERY: (queryKey: string): string => `/analytics/query/${queryKey}`,
    },
    ANALYTICS_ROUTES: {
        ANALYTICS_OVERVIEW: `/analytics`,
    },
} as const;
