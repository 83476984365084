import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { quizQuestionEndpoints } from './quiz.questions.endpoints';
import { IQuizQuestion, IQuizQuestionResponse, IQuizRound, IQuizRoundResponse } from './quiz.questions.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getQuizQuestions = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IQuizQuestionResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.GET_QUIZ_QUESTIONS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getQuizQuestion = async (quizQuestionId: number): Promise<IQuizQuestion | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.GET_QUIZ_QUESTION.url(quizQuestionId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const quizQuestionToFormData = (quizQuestion: IQuizQuestion) => {
    const formData = new FormData();

    for (const key in quizQuestion) {
        if (!quizQuestion.hasOwnProperty(key)) {
            continue;
        }
        formData.append(`${key}`, quizQuestion[key]);
    }
    return formData;
};

const addQuizQuestion = async (data: IQuizQuestion): Promise<IQuizQuestion | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.ADD_QUIZ_QUESTION.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: quizQuestionToFormData(data),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateQuizQuestion = async (data: IQuizQuestion, quizQuestionID: number): Promise<IQuizQuestion | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.UPDATE_QUIZ_QUESTION.url(quizQuestionID),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: quizQuestionToFormData(data),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteQuizQuestion = async (entityId: number): Promise<AxiosResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.DELETE_QUIZ_QUESTION.url(entityId),
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getQuizRounds = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IQuizRoundResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.GET_QUIZ_ROUNDS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getQuizRound = async (quizRoundId: number): Promise<IQuizRound | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.GET_QUIZ_ROUND.url(quizRoundId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addQuizRound = async (data: IQuizRound): Promise<IQuizRound | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.ADD_QUIZ_ROUND.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: data,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateQuizRound = async (data: IQuizRound, quizRoundID: number): Promise<IQuizRound | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.UPDATE_QUIZ_ROUND.url(quizRoundID),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: data,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteQuizRound = async (entityId: number): Promise<AxiosResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: quizQuestionEndpoints.DELETE_QUIZ_ROUND.url(entityId),
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const quizQuestionService = {
    getQuizQuestions,
    getQuizQuestion,
    addQuizQuestion,
    updateQuizQuestion,
    deleteQuizQuestion,
    getQuizRounds,
    getQuizRound,
    addQuizRound,
    updateQuizRound,
    deleteQuizRound,
};
