export const categoryTagTypeConstants = {
    CATEGORY_TAG_TYPES_ACTION_TYPES: {
        GET_CATEGORY_TAG_TYPES_REQUEST: 'GET_CATEGORY_TAG_TYPES_REQUEST',
        GET_CATEGORY_TAG_TYPES_SUCCESS: 'GET_CATEGORY_TAG_TYPES_SUCCESS',
        GET_CATEGORY_TAG_TYPES_FAILURE: 'GET_CATEGORY_TAG_TYPES_FAILURE',
        GET_CATEGORY_TAG_TYPE_BY_ID: 'GET_CATEGORY_TAG_TYPE_BY_ID',
        ADD_CATEGORY_TAG_TYPE_REQUEST: 'ADD_CATEGORY_TAG_TYPE_REQUEST',
        ADD_CATEGORY_TAG_TYPE_SUCCESS: 'ADD_CATEGORY_TAG_TYPE_SUCCESS',
        ADD_CATEGORY_TAG_TYPE_FAILURE: 'ADD_CATEGORY_TAG_TYPE_FAILURE',
        UPDATE_CATEGORY_TAG_TYPE_REQUEST: 'UPDATE_CATEGORY_TAG_TYPE_REQUEST',
        UPDATE_CATEGORY_TAG_TYPE_SUCCESS: 'UPDATE_CATEGORY_TAG_TYPE_SUCCESS',
        UPDATE_CATEGORY_TAG_TYPE_FAILURE: 'UPDATE_CATEGORY_TAG_TYPE_FAILURE',
        DELETE_CATEGORY_TAG_TYPE: 'DELETE_CATEGORY_TAG_TYPE',
        UPDATE_CATEGORY_TAG_TYPE: 'UPDATE_CATEGORY_TAG_TYPE',
        DELETE_CATEGORY_TAG_TYPE_REQUEST: 'DELETE_CATEGORY_TAG_TYPE_REQUEST',
        DELETE_CATEGORY_TAG_TYPE_SUCCESS: 'DELETE_CATEGORY_TAG_TYPE_SUCCESS',
        DELETE_CATEGORY_TAG_TYPE_FAILURE: 'DELETE_CATEGORY_TAG_TYPE_FAILURE',
    },
    CATEGORY_TAG_TYPES_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
    },
};
