import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import en from '../../../assets/language/en.json';
import { reviewService } from './reviews.service';
import {
    IBlacklist,
    IBlacklistResponse,
    IBlockUserResponse,
    ICountUnreadReviewsResponse,
    InternalRatingReportDecision,
    IReview,
    IReviewActions,
    IReviewResponse,
} from './reviews.interfaces';
import { reviewConstants } from './reviews.constants';
import { IFilters, IPagination } from '../../shared/components/interfaces';

function getReviews(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters[],
): (dispatch: Dispatch) => Promise<IReviewResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return reviewService.getReviews(pagination, searchText, filters).then(
            (response: IReviewResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_REQUEST };
    }

    function success(response: IReviewResponse): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_SUCCESS,
            reviewData: response,
        };
    }

    function failure(error: string): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.GET_REVIEWS_FAILURE, error };
    }
}

const deleteReview = (reviewId: number, completed: boolean): ((dispatch: Dispatch) => Promise<AxiosResponse>) => {
    return (dispatch) => {
        dispatch(request());

        return reviewService.deleteReview(reviewId).then(
            (response) => {
                dispatch(success());
                completed && dispatch(alertActions.success(en.review_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data?.message?.toString() || 'Error deleting review'));
                dispatch(alertActions.error(error.response.data?.message?.toString() || 'Error deleting review'));
                return Promise.reject(error);
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_REQUEST };
    }

    function success(): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_REVIEW_FAILURE,
            error,
        };
    }
};

const blockUser = (
    userId: number,
    blocked: boolean,
): ((dispatch: Dispatch) => Promise<IBlockUserResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return reviewService.blockUser(userId, blocked).then(
            (response) => {
                dispatch(success());
                dispatch(alertActions.success(en.user_blocked_success));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_REQUEST };
    }

    function success(): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.BLOCK_USER_FAILURE,
            error,
        };
    }
};

const countUnreadReviews = () => {
    return reviewService.unreadReviewsCount().then(
        (response) => {
            return success((response as ICountUnreadReviewsResponse).count);
        },
        (error: AxiosError) => {
            return failure(error.toString());
        },
    );

    function success(noUnreadReviews: number): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.COUNT_UNREAD_SUCCESS,
            noUnreadReviews,
        };
    }

    function failure(error: string): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.COUNT_UNREAD_FAILURE,
            error,
        };
    }
};

function getBlacklist(
    pagination: IPagination,
    searchText?: string,
    filters?: IFilters[],
): (dispatch: Dispatch) => Promise<IBlacklistResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return reviewService.getBlacklist(pagination, searchText, filters).then(
            (response: IBlacklistResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.GET_BLACKLIST_REQUEST };
    }

    function success(response: IBlacklistResponse): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.GET_BLACKLIST_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.GET_BLACKLIST_FAILURE, error };
    }
}

const addBlacklist = (blacklist: IBlacklist): ((dispatch: Dispatch) => Promise<IBlacklistResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return reviewService.addBlacklist(blacklist).then(
            (response: IBlacklistResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.blacklist_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.ADD_BLACKLIST_REQUEST };
    }

    function success(response: IBlacklistResponse): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.ADD_BLACKLIST_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.ADD_BLACKLIST_FAILURE, error };
    }
};

const updateBlacklist = (
    blacklist: IBlacklist,
    id: number,
): ((dispatch: Dispatch) => Promise<IBlacklistResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return reviewService.updateBlacklist(blacklist, id).then(
            (response: IBlacklistResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.blacklist_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.UPDATE_BLACKLIST_REQUEST };
    }

    function success(response: IBlacklistResponse): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.UPDATE_BLACKLIST_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.UPDATE_BLACKLIST_FAILURE, error };
    }
};

const deleteBlacklist = (
    id: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return reviewService.deleteBlacklist(id).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.blacklist_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IReviewActions {
        return { type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_BLACKLIST_REQUEST };
    }

    function success(response: AxiosResponse): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_BLACKLIST_SUCCESS,
        };
    }

    function failure(error: string): IReviewActions {
        return {
            type: reviewConstants.REVIEW_ACTION_TYPES.DELETE_BLACKLIST_FAILURE,
            error,
        };
    }
};

export const reviewActions = {
    getReviews,
    deleteReview,
    blockUser,
    countUnreadReviews,
    getBlacklist,
    addBlacklist,
    updateBlacklist,
    deleteBlacklist,
};
