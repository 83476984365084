import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IAddOrEditActionName, IActionNameForm, IActionNameFormComponent } from '../../action.names.interfaces';
import formModels from './formModel/action.name.form.model';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import { FormControl, FormGroup, TextField, InputAdornment } from '@material-ui/core';
import styles from './index.module.scss';
import { compose } from 'recompose';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import clsx from 'clsx';
import { actionNamesAction } from '../../action.names.actions';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { actionNamesConstants } from '../../../../modules/actionNames/action.names.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type ActionNameProps = IActionNameFormComponent & typeof formModels.actionNameFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditActionName) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditActionName = (props: PropsWithChildren<ActionNameProps>): JSX.Element => {
    const id = useIdParam();
    const [open, setOpen] = useState<boolean>(!!(id && id == props.actionNamesData?.id));

    const {
        formField: { nameEn, nameDe },
        actionNamesData,
        history,
    } = props;

    const formValues = actionNamesData ? actionNamesData : formInitialValues.actionName;
    const handleClickOpen = () => {
        setOpen(true);
        actionNamesData &&
            history.push(`${actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES}/${actionNamesData.id}`);
    };
    const handleClose = () => {
        setOpen(false);
        history.push(`${actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES}`);
    };

    const handleSubmit = async (values: IActionNameForm): Promise<void> => {
        actionNamesData ? await handleUpdate(values) : await handleInsert(values);
    };

    const handleInsert = async (values: IActionNameForm): Promise<void> => {
        await props.addActionName(values);

        props.refreshData();
        handleClose();
    };

    const handleUpdate = async (values: IActionNameForm): Promise<void> => {
        const actionNameToUpdate: IActionNameForm = {
            nameEn: values.nameEn,
            nameDe: values.nameDe,
        };

        await props.updateActionName(actionNameToUpdate, values.id);

        props.refreshData();
        handleClose();
    };

    return (
        <div className={styles.categoryTag}>
            {actionNamesData ? (
                <Button className={styles.editCategoryTag} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addCategoryTag, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_action_name}
                </Button>
            )}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {actionNamesData ? en.edit_action_name : en.add_new_action_name}
                </DialogTitle>
                <Formik
                    initialValues={formValues}
                    onSubmit={async (values: IActionNameForm, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.actionNameValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IActionNameForm>) => (
                        <Form className={styles.addCategoryTagForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameEn.name}
                                            type="text"
                                            label={nameEn.label}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.nameEn}
                                            helperText={
                                                props.errors.nameEn && props.touched.nameEn ? props.errors.nameEn : ''
                                            }
                                            variant={'outlined'}
                                            FormHelperTextProps={{
                                                className: clsx(styles.error_span, 'mt2'),
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={nameDe.name}
                                            type="text"
                                            label={nameDe.label}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.nameDe}
                                            helperText={
                                                props.errors.nameDe && props.touched.nameDe ? props.errors.nameDe : ''
                                            }
                                            variant={'outlined'}
                                            FormHelperTextProps={{
                                                className: clsx(styles.error_span, 'mt2'),
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveCategoryTag, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = {
    addActionName: actionNamesAction.addActionName,
    updateActionName: actionNamesAction.updateActionName,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(AddOrEditActionName);
