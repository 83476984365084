import React, { Dispatch, PropsWithChildren, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { recipeConstants } from '../recipe/recipe.constants';
import styles from './index.module.scss';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { styleConstants } from '../../shared/constants/style.constants';
import { globalConstants } from '../../shared/constants/global.constants';

import clsx from 'clsx';

interface OwnProps {
    destinationColumnName: string;
    sourceColumnName: string;
    items: unknown[];
    availableItems: unknown[];
    handleItemsChange: Dispatch<SetStateAction<number[]>>;
    endScroll: [Dispatch<SetStateAction<boolean>>, boolean];
    nameKey?: string;
    getItemBackgroundColor?: (itemContent: Item) => string;
}

type Item = {
    id: number;
};

type Props = OwnProps;

const DragAndDrop = (props: PropsWithChildren<Props>): ReactElement => {
    const { items, availableItems, destinationColumnName, sourceColumnName } = props;
    const nameKey = props.nameKey || 'nameEn';
    const columnsFromBackend = {
        [recipeConstants.DRAG_AND_DROP.KEYS.DESTINATION_COLUMN]: {
            name: destinationColumnName,
            items: [],
        },
        [recipeConstants.DRAG_AND_DROP.KEYS.SOURCE_COLUMN]: {
            name: sourceColumnName,
            items: [],
        },
    };
    const [columns, setColumns] = useState(columnsFromBackend);

    const filterDestinationItems = columns[recipeConstants.DRAG_AND_DROP.KEYS.DESTINATION_COLUMN].items.filter(
        (item) =>
            !items.find(({ id }) => {
                return item.id == id;
            }),
    );

    const filterAvaibleItems = availableItems.filter(
        ({ id }) =>
            !columns[recipeConstants.DRAG_AND_DROP.KEYS.DESTINATION_COLUMN].items.find((item) => {
                return item.id == id;
            }),
    );
    useEffect(() => {
        setColumns({
            ...columns,
            [recipeConstants.DRAG_AND_DROP.KEYS.SOURCE_COLUMN]: {
                name: sourceColumnName,
                items: filterAvaibleItems,
            },
            [recipeConstants.DRAG_AND_DROP.KEYS.DESTINATION_COLUMN]: {
                name: destinationColumnName,
                items: [...items, ...filterDestinationItems],
            },
        });
    }, [items, availableItems]);

    useEffect(() => {
        const isMounted = true;

        handleColumnChange(columns);

        const avaibleProducts = columns[recipeConstants.DRAG_AND_DROP.KEYS.SOURCE_COLUMN].items.length;

        if (isMounted && avaibleProducts > 0 && avaibleProducts < globalConstants.DEFAULT_PAGINATION.size) {
            props.endScroll[0](true);
        }
    }, [columns]);

    const handleOnDragEnd = (result: DropResult): void => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destinationColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destinationItems = [...destinationColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);

            destinationItems.splice(destination.index, 0, removed);

            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destinationColumn,
                    items: destinationItems,
                },
            });
        } else {
            const column = columns[source.droppableId];

            const items = Array.from(column.items);
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items,
                },
            });
        }
    };

    const handleColumnChange = (columns): void => {
        const ids: Array<number> = columns[recipeConstants.DRAG_AND_DROP.KEYS.DESTINATION_COLUMN].items.map(
            (item) => item.id,
        );

        props.handleItemsChange(ids);
    };

    const getStyle = (style, snapshot, defaultBackgroundColor = styleConstants.COLORS.WHITE) => {
        const background = snapshot.isDragging ? styleConstants.COLORS.RED : defaultBackgroundColor;
        const color = snapshot.isDragging ? styleConstants.COLORS.WHITE : styleConstants.COLORS.BLACK_DEEP;
        const rotate = 'rotate(3deg)';

        if (!snapshot.isDropAnimating) {
            return {
                ...style,
                backgroundColor: `${background}`,
                color: `${color}`,
            };
        }

        const { moveTo } = snapshot.dropAnimation;
        const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

        return {
            ...style,
            transform: snapshot.isDragging ? `${translate} ${rotate}` : null,
            transition: `0.3s`,
            backgroundColor: `${background}`,
            color: `${color}`,
            boxShadow: '5px 10px 30px 0px rgba(9, 30, 66, 0.15)',
        };
    };

    const handleScroll = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.stopPropagation();
        const endScrollReached = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        endScrollReached != props.endScroll[1] && props.endScroll[0](endScrollReached);
    };

    return (
        <div>
            <div className={clsx(styles.dragAndDropForm, 'mt8')}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    {Object.entries(columns).map(([id, column]) => (
                        <Card key={id} className={clsx(styles.dragAndDropCard, 'm4')} variant={'outlined'}>
                            <CardHeader title={column.name} className={styles.dragAndDropHeader}>
                                <Typography variant={'h5'}> {column.name} </Typography>
                            </CardHeader>
                            <CardContent>
                                <div className={clsx(styles.dragAndDropContainer, 'm4')} key={id}>
                                    <Droppable droppableId={id} key={id}>
                                        {(provided, snapshot) => (
                                            <div
                                                className={styles.dragAndDropBody}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    backgroundColor: snapshot.isDraggingOver
                                                        ? styleConstants.COLORS.LIGHT_GREY
                                                        : styleConstants.COLORS.WHITE,
                                                }}
                                                onScroll={handleScroll}
                                            >
                                                {column.items.map((item: Item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={clsx(styles.item, 'm2')}
                                                                key={item.id}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getStyle(
                                                                    provided.draggableProps.style,
                                                                    snapshot,
                                                                    props.getItemBackgroundColor
                                                                        ? props.getItemBackgroundColor(item)
                                                                        : null,
                                                                )}
                                                            >
                                                                {item[nameKey]}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </DragDropContext>
            </div>
        </div>
    );
};

export default DragAndDrop;
