import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const cookingStepsFormModel = {
    cookingStepsFormId: uniqueId('form-'),
    cookingStepsFormField: {
        step_number: {
            name: 'step_number',
            label: en.step_number,
            requiredErrorMsg: en.step_number_required_error_msg,
        },
        countdown: {
            name: 'duration',
            label: en.countdown,
            requiredErrorMsg: en.countdown_required_error_msg,
        },
        relevant_for_dashboard: {
            name: 'relevant_for_dashboard',
            label: en.relevant_for_dashboard,
        },
        is_interval: {
            name: 'is_interval',
            label: en.interval,
        },
        short_description_en: {
            name: 'short_description_en',
            label: en.short_description,
            requiredErrorMsg: en.short_description_required_error_msg,
        },
        short_description_de: {
            name: 'short_description_de',
            label: de.short_description,
            requiredErrorMsg: de.short_description_required_error_msg,
        },
        long_description_en: {
            name: 'long_description_en',
            label: en.long_description,
            requiredErrorMsg: en.long_description_required_error_msg,
        },
        long_description_de: {
            name: 'long_description_de',
            label: de.long_description,
            requiredErrorMsg: de.long_description_required_error_msg,
        },
        actions: {
            name: 'actions',
            timerLabel: en.action_timer,
            nameLabel: en.action_name,
            timerRequiredErrorMsg: en.action_timer_required_error_msg,
            nameRequiredErrorMsg: en.action_name_required_error_msg,
        },
        first_zone: {
            name: 'first_zone',
            label: en.first_zone,
        },
        second_zone: {
            name: 'second_zone',
            label: en.second_zone,
        },
        third_zone: {
            name: 'third_zone',
            label: en.third_zone,
        },
        fourth_zone: {
            name: 'fourth_zone',
            label: en.fourth_zone,
        },
        master_probe: {
            name: 'master_probe',
            label: en.master_probe,
        },
        cooking_step_method: {
            name: 'cooking_step_method',
            label: en.cooking_step_method_label,
        },
    },
};

const recipeFormModel = {
    formId: uniqueId('form-'),
    formField: {
        name_en: {
            name: 'name_en',
            label: en.title_label,
            requiredErrorMsg: en.title_error_msg,
        },
        name_de: {
            name: 'name_de',
            label: de.title_label,
            requiredErrorMsg: de.title_error_msg,
        },
        thumbnailPicture: {
            name: 'thumbnail_picture',
            label: en.thumbnail_picture_label,
        },
        fullPicture: {
            name: 'full_picture',
            label: en.full_picture_label,
        },
        cookingMethod: {
            name_en: 'cooking_method_ids',
            label: en.cooking_method_label,
            requiredErrorMsg: en.cooking_method_required_error_msg,
        },
        difficulty: {
            name_en: 'difficulty_en',
            label: en.difficulty_label,
            requiredErrorMsg: en.difficulty_required_error_msg,
        },
        restingTime: {
            name: 'resting_time',
            label: en.resting_time_label,
            requiredErrorMsg: en.resting_time_required_error_msg,
        },
        preparationTime: {
            name: 'preparation_time',
            label: en.preparation_time_label,
            requiredErrorMsg: en.preparation_time_required_error_msg,
        },
        cookingTime: {
            name: 'cooking_time',
            label: en.cooking_time_label,
            requiredErrorMsg: en.cooking_time_required_error_msg,
        },
        overnightTime: {
            name: 'overnightTime',
            label: en.overnight_time,
            requiredErrorMsg: en.cooking_time_required_error_msg,
        },
        servings: {
            name: 'serving',
            label: en.servings_label,
            requiredErrorMsg: en.servings_required_error_msg,
        },
        minimumServing: {
            name: 'minimumServing',
            label: en.minimum_serving_label,
        },
        servingSteps: {
            name: 'servingSteps',
            label: en.serving_steps_label,
        },
        ambassador: {
            name: 'ambassador_id',
            label: en.ambassador_label,
        },
        grillModels: {
            name: 'grillModelIds',
            label: en.grill_models_label,
        },
        shouldTimeScale: {
            name: 'shouldTimeScale',
            label: en.should_time_scale_label,
            requiredErrorMsg: en.should_time_scale_required_error_msg,
        },
        proRecipe: {
            name: 'proRecipe',
            label: en.pro_recipe,
            requiredErrorMsg: en.pro_recipe_required_error_msg,
        },
        adminRecipe: {
            name: 'adminRecipe',
            label: en.admin_recipe,
            requiredErrorMsg: en.admin_recipe_required_error_msg,
        },
        hintTitleDe: {
            name: 'hintTitleDe',
            label: 'Tipp Titel',
        },
        hintTitleEn: {
            name: 'hintTitleEn',
            label: 'Hint Title',
        },
        hintDescriptionDe: {
            name: 'hintDescriptionDe',
            label: 'Tipp',
        },
        hintDescriptionEn: {
            name: 'hintDescriptionEn',
            label: 'Hint',
        },
        recipeTutorials: {
            name: 'tutorialIds',
            label: en.tutorials_label,
        },
    },
};

const ingredientFormModel = {
    ingredientFormId: uniqueId('form-'),
    ingredientFormField: {
        recipeIngredient: {
            name: 'ingredient',
            label: en.ingredient_label,
            requiredErrorMsg: en.ingredient_required_error_msg,
        },
        metricQuantity: {
            name: 'metricQuantity',
            label: en.quantity,
            requiredErrorMsg: en.quantity_required_error_msg,
        },

        imperialQuantity: {
            name: 'imperialQuantity',
            label: en.quantity,
            requiredErrorMsg: en.quantity_required_error_msg,
        },
        isMeasurementUnitsConversionOn: {
            name: 'isMeasurementUnitsConversionOn',
            label: en.measurement_units_conversion_on,
        },
    },
};

const formModels = {
    recipeFormModel,
    cookingStepsFormModel,
    ingredientFormModel,
};

export default formModels;
