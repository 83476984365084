import { TargetUser } from './promotion.campaign.interfaces';

export const promotionCampaignConstants = {
    PROMOTION_CAMPAIGN_ACTION_TYPES: {
        GET_PROMOTION_CAMPAIGNS_REQUEST: 'GET_PROMOTION_CAMPAIGNS_REQUEST',
        GET_PROMOTION_CAMPAIGNS_SUCCESS: 'GET_PROMOTION_CAMPAIGNS_SUCCESS',
        GET_PROMOTION_CAMPAIGNS_FAILURE: 'GET_PROMOTION_CAMPAIGNS_FAILURE',
        GET_PROMOTION_CAMPAIGN_BY_ID_REQUEST: 'GET_PROMOTION_CAMPAIGN_REQUEST',
        GET_PROMOTION_CAMPAIGN_BY_ID_SUCCESS: 'GET_PROMOTION_CAMPAIGN_SUCCESS',
        GET_PROMOTION_CAMPAIGN_BY_ID_FAILURE: 'GET_PROMOTION_CAMPAIGN_FAILURE',
        GET_AVAILABLE_COUPON_PRODUCTS_REQUEST: 'GET_AVAILABLE_COUPON_PRODUCTS_REQUEST',
        GET_AVAILABLE_COUPON_PRODUCTS_SUCCESS: 'GET_AVAILABLE_COUPON_PRODUCTS_SUCCESS',
        GET_AVAILABLE_COUPON_PRODUCTS_FAILURE: 'GET_AVAILABLE_COUPON_PRODUCTS_FAILURE',
        GET_SEGMENT_AUDIENCES_REQUEST: 'GET_SEGMENT_AUDIENCES_REQUEST',
        GET_SEGMENT_AUDIENCES_SUCCESS: 'GET_SEGMENT_AUDIENCES_SUCCESS',
        GET_SEGMENT_AUDIENCES_FAILURE: 'GET_SEGMENT_AUDIENCES_FAILURE',
        ADD_PROMOTION_CAMPAIGN_REQUEST: 'ADD_PROMOTION_CAMPAIGN_REQUEST',
        ADD_PROMOTION_CAMPAIGN_SUCCESS: 'ADD_PROMOTION_CAMPAIGN_SUCCESS',
        ADD_PROMOTION_CAMPAIGN_FAILURE: 'ADD_PROMOTION_CAMPAIGN_FAILURE',
        DELETE_PROMOTION_CAMPAIGN_REQUEST: 'DELETE_PROMOTION_CAMPAIGN_REQUEST',
        DELETE_PROMOTION_CAMPAIGN_SUCCESS: 'DELETE_PROMOTION_CAMPAIGN_SUCCESS',
        DELETE_PROMOTION_CAMPAIGN_FAILURE: 'DELETE_PROMOTION_CAMPAIGN_FAILURE',
        UPDATE_PROMOTION_CAMPAIGN_REQUEST: 'UPDATE_PROMOTION_CAMPAIGN_REQUEST',
        UPDATE_PROMOTION_CAMPAIGN_SUCCESS: 'UPDATE_PROMOTION_CAMPAIGN_SUCCESS',
        UPDATE_PROMOTION_CAMPAIGN_FAILURE: 'UPDATE_PROMOTION_CAMPAIGN_FAILURE',
    },
};

export const targetUserLabels: { [key in keyof typeof TargetUser]: string } = {
    REGISTERED_WITH_GRILL: 'Registered with grill',
    REGISTERED_WITHOUT_GRILL: 'Registered without grill',
    GUEST_USERS: 'Guest users',
};
