import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MUIDataTableMeta } from 'mui-datatables';
import { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { quizQuestionTables } from '../../../helpers/quiz.question.datatables';
import { quizQuestionActions } from '../../quiz.questions.actions';
import { quizQuestionEndpoints } from '../../quiz.questions.endpoints';
import styles from './index.module.scss';

const mapStateToPropsQuestionOverview = (state: AppState) => ({
    quizQuestions: state.quizQuestions.quizQuestions,
    total: state.quizQuestions.total,
    loading: state.quizQuestions.loading,
});

const mapDispatchToPropsQuestionOverview = {
    getQuizQuestions: quizQuestionActions.getQuizQuestions,
    deleteQuizQuestion: quizQuestionActions.deleteQuizQuestion,
};

type OwnPropsQuestionOverview = ReturnType<typeof mapStateToPropsQuestionOverview> &
    DispatchPropsReturnType<typeof mapDispatchToPropsQuestionOverview>;

export const QuestionOverview = compose(
    withStyles(muiStyles.customStyles),
    connect(mapStateToPropsQuestionOverview, mapDispatchToPropsQuestionOverview),
)((props: PropsWithChildren<OwnPropsQuestionOverview>) => {
    const { deleteQuizQuestion, getQuizQuestions, loading, quizQuestions, total } = props;
    const tableColumns = [...quizQuestionTables.quizQuestionColumns];

    tableColumns.push({
        name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                return (
                    <Button
                        component={Link}
                        to={`${quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_QUESTION}/${
                            quizQuestions[tableMeta.rowIndex].id
                        }`}
                        className={styles.editCampaign}
                    >
                        {en.edit_button_label}
                    </Button>
                );
            },
        },
    });

    const options = {
        onRowsDelete: (rowsDeleted: IRowsDeleted): void => {
            const itemToRemove = rowsDeleted.data.map(({ index }) => index);
            const itemsToRemoveLength = itemToRemove.length;

            itemToRemove.map(async (itemIndex, index) => {
                const itemId = quizQuestions[itemIndex].id;
                const completed = itemsToRemoveLength === index + 1;

                await deleteQuizQuestion(itemId, completed);
                completed && (await _refreshQuizQuestions(globalConstants.DEFAULT_PAGINATION, null));
            });
        },
    };

    useEffect(() => {
        _refreshQuizQuestions();
    }, []);

    const _refreshQuizQuestions = async (
        pagination?: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await getQuizQuestions(pagination, searchText, filters);
    };

    return (
        <>
            <Button
                className={clsx(styles.addCampaign, 'mb4')}
                component={Link}
                to={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_QUESTION}
            >
                {'Add Quiz Question'}
            </Button>
            <DynamicTable
                title={'Quiz Questions'}
                options={options}
                columns={tableColumns}
                data={quizQuestions}
                total={total}
                loading={loading}
                refreshData={_refreshQuizQuestions}
            />
        </>
    );
});
