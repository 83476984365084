import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { ingredientsEndpoints } from './ingredients.endpoints';
import { authHeader } from '../../shared/helpers/authHeader';
import { IngredientData, IngredientForm, IngredientsResponse } from './ingredients.interfaces';
import { IFilters, IPagination, ISort } from '../../shared/components/interfaces';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getIngredients = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): Promise<IngredientsResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: ingredientsEndpoints.GET_INGREDIENTS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: camelToSnakeCase(paging.orderBy),
            orderDirection: paging.orderDirection,
            searchText,
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getIngredientById = async (ingredientId: number): Promise<IngredientData | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: ingredientsEndpoints.GET_INGREDIENTS.url + `/${ingredientId}`,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addIngredient = async (ingredientData: IngredientForm): Promise<IngredientsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: ingredientsEndpoints.ADD_INGREDIENT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: ingredientData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteIngredient = async (ingredientId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: ingredientsEndpoints.DELETE_INGREDIENT.url + ingredientId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateIngredient = async (ingredientData: IngredientForm, ingredientId: number): Promise<IngredientsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: ingredientsEndpoints.UPDATE_INGREDIENT.url + ingredientId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: ingredientData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

export const ingredientsService = {
    getIngredients,
    getIngredientById,
    addIngredient,
    deleteIngredient,
    updateIngredient,
};
