import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { IActionNameForm, IActionNamesActions, IActionNamesResponse } from './action.names.interfaces';
import { actionNamesService } from './action.names.service';
import { actionNamesConstants } from './action.names.constants';
import en from '../../../assets/language/en.json';
import { globalConstants } from '../../shared/constants/global.constants';

function getActionNames(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IActionNamesResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;

        return actionNamesService.getActionNames(pagination, searchText, filters).then(
            (response: IActionNamesResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_REQUEST };
    }

    function success(response: IActionNamesResponse): IActionNamesActions {
        return {
            type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_SUCCESS,
            actionNamesData: response,
        };
    }

    function failure(error: string): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.GET_ACTION_NAMES_FAILURE, error };
    }
}

const addActionName = (
    actionNamesData: IActionNameForm,
): ((dispatch: Dispatch) => Promise<IActionNamesResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return actionNamesService.addActionName(actionNamesData).then(
            (response: IActionNamesResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.action_name_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_REQUEST };
    }

    function success(response: IActionNamesResponse): IActionNamesActions {
        return {
            type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_SUCCESS,
            actionName: response.data,
        };
    }

    function failure(error: string): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.ADD_ACTION_NAME_FAILURE, error };
    }
};

const updateActionName = (
    actionNameData: IActionNameForm,
    actionNameId: number,
): ((dispatch: Dispatch) => Promise<IActionNamesResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return actionNamesService.updateActionName(actionNameData, actionNameId).then(
            (response: IActionNamesResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.action_name_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_REQUEST };
    }

    function success(response: IActionNamesResponse): IActionNamesActions {
        return {
            type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_SUCCESS,
            actionName: response.data,
        };
    }

    function failure(error: string): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.UPDATE_ACTION_NAME_FAILURE, error };
    }
};

const deleteActionName = (
    actionNameId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return actionNamesService.deleteActionName(actionNameId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.action_name_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IActionNamesActions {
        return { type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.DELETE_ACTION_NAME_REQUEST };
    }

    function success(response: AxiosResponse): IActionNamesActions {
        return {
            type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.DELETE_ACTION_NAME_SUCCESS,
        };
    }

    function failure(error: string): IActionNamesActions {
        return {
            type: actionNamesConstants.ACTION_NAMES_ACTION_TYPES.DELETE_ACTION_NAME_FAILURE,
            error,
        };
    }
};

export const actionNamesAction = {
    getActionNames,
    addActionName,
    updateActionName,
    deleteActionName,
};
