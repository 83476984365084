import { ingredientsConstants } from './ingredients.constants';
import { IngredientsState, IngredientActions } from './ingredients.interfaces';

const initialState: IngredientsState = { ingredients: [], ingredient: null, loading: false };

const ingredientsReducer = (state = initialState, action: IngredientActions): IngredientsState => {
    switch (action.type) {
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_REQUEST:
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_SUCCESS:
            return {
                ...state,
                ingredients: action.ingredientsData.ingredients,
                total: action.ingredientsData.total,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_FAILURE:
            return {
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_SUCCESS:
            return {
                ...state,
                ingredients: action.ingredientsData.ingredients,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_SUCCESS:
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_SUCCESS:
            return {
                ...state,
                ingredient: action.ingredient,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_FAILURE:
        case ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { ingredientsReducer };
