import { Button, Grid, IconButton } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import clsx from 'clsx';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../../assets/language/en.json';
import DragDropTable from '../../../../../shared/components/generics/dragDropTable/DragDropTable';
import { IRowsDeleted } from '../../../../../shared/components/interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { muiDataTablesConstants } from '../../../../../shared/constants/mui.datatables.constants';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { AppState } from '../../../../../shared/store';
import { recipeCookingStepsDataTable } from '../../../../helpers/recipe.datatables';
import { recipeHelpers } from '../../../../helpers/recipe.helpers';
import { recipeAction } from '../../../recipe.actions';
import { recipeConstants } from '../../../recipe.constants';
import {
    CookingStepTypes as CookingStepType,
    ICookingStepsComponentProps,
    ICookingStepsForm,
} from '../../../recipe.interfaces';
import recipeFormModel from '../FormModel/RecipeFormModel';
import styles from '../index.module.scss';
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/add_icon.svg';
import { StepDialog } from './CookingSteps/StepDialog';

type Props = typeof recipeFormModel.cookingStepsFormModel & ICookingStepsComponentProps;

const AddCookingSteps = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [cookingSteps, setCookingSteps] = useState<ICookingStepsForm[]>([]);
    const [cookingStepToEdit, setCookingStepToEdit] = useState<ICookingStepsForm>(null);
    const [cookingStepsTable, setCookingStepsTable] = useState<ICookingStepsForm[]>([]);
    const [addStepOpen, setAddStepOpen] = useState<boolean>(false);
    const [selectedStepType, setSelectedStepType] = useState<CookingStepType>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const { recipe } = props;

    // Initial page load
    useEffect(() => {
        // load existing cooking steps
        if (recipe) {
            props.getRecipeCookingSteps(recipe.id).then((response) => {
                if (response) {
                    if (response.length > 0) {
                        setIsUpdating(true);
                    }
                    setCookingSteps(response);
                }
            });
        }
    }, []);

    const tableColumns: MUIDataTableColumnDef[] = [
        ...recipeCookingStepsDataTable.recipeCookingStepsColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={clsx(styles.standardButton, 'mr2')}
                            onClick={() => {
                                const cookingStep = cookingSteps[tableMeta.rowIndex];
                                setCookingStepToEdit(cookingStep);
                                if (cookingStep.relevant_for_dashboard) {
                                    setSelectedStepType(CookingStepType.DASHBOARD_RELEVANT);
                                } else {
                                    setSelectedStepType(CookingStepType.NORMAL);
                                }
                                setAddStepOpen(true);
                            }}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    tableColumns.splice(0, 0, {
        name: 'step_number',
        label: en.step_number,
        options: {
            filter: true,
            sort: true,
            customBodyRender: function stepNumberRenderer(value: string | number, meta) {
                const cookingStep = cookingSteps[meta.rowIndex];
                let complete = true;
                if (!cookingStep) {
                    return null;
                }
                if (cookingStep.relevant_for_dashboard) {
                    complete =
                        recipeHelpers.checkDescriptionsFilled(
                            cookingStep.long_description_en,
                            cookingStep.long_description_de,
                        ) &&
                        recipeHelpers.checkDescriptionsFilled(
                            cookingStep.short_description_en,
                            cookingStep.short_description_de,
                        );
                } else {
                    complete = recipeHelpers.checkDescriptionsFilled(
                        cookingStep.long_description_en,
                        cookingStep.long_description_de,
                    );
                }

                return (
                    <div className={styles.incompleteWarningContainer}>
                        <span>{+value + 1}</span> {complete ? null : <div className={styles.incompleteWarning}>!</div>}
                    </div>
                );
            },
        },
    });

    const tableOptions: MUIDataTableOptions = {
        filter: false,
        responsive: muiDataTablesConstants.RESPONSIVE.STANDARD,
        pagination: false,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.SINGLE,
        onRowsDelete: (rowsDeleted: IRowsDeleted) => {
            const stepsToRemoveIndex = rowsDeleted.data.map((row) => row.index);
            setCookingSteps((prev) => {
                prev = prev
                    // Filter out the deleted steps
                    .filter((cookingStep, index) => !stepsToRemoveIndex.includes(index))
                    // Map the new index to correct step_number
                    .map((step, index) => ({
                        ...step,
                        step_number: index,
                    }));
                return prev;
            });
            setIsChanged(true);
        },
    };

    const toggleAddStepOpen = () => {
        setSelectedStepType(null);
        setAddStepOpen((prev) => {
            if (prev === true) {
                setCookingStepToEdit(null);
            }
            return !prev;
        });
    };

    useEffect(() => {
        if (cookingSteps) {
            cookingSteps.forEach((cookingStep) => {
                if (cookingStep?.master_probe && cookingStep.duration === 0) cookingStep.duration = null;
                if (cookingStep?.duration && cookingStep.master_probe === 0) cookingStep.master_probe = null;
            });
        }

        cookingSteps.sort((a, b) => {
            return +a.step_number - +b.step_number;
        });
        setCookingStepsTable(cookingSteps);
    }, [cookingSteps]);

    const handleNewCookingStep = (values: ICookingStepsForm) => {
        setIsChanged(true);
        values = recipeHelpers.cleanStepData(values, selectedStepType);
        genericHelpers.isNullOrUndefined(values.step_number)
            ? handleInsertingFormSubmit(values)
            : handleUpdatingFormSubmit(values);
    };

    const handleInsertingFormSubmit = (values: ICookingStepsForm): void => {
        values.step_number = cookingSteps.length;
        setCookingSteps((prev) => [...prev, values]);
    };

    const handleUpdatingFormSubmit = (values: ICookingStepsForm): void => {
        setCookingSteps((prev) => {
            prev.splice(+values.step_number, 1, values);
            return prev;
        });
    };

    const submitToBackend = () => {
        if (isUpdating) {
            // Update cooking steps..
            props
                .updateRecipeCookingSteps(
                    {
                        recipe_id: props.recipe.id,
                        cooking_steps: cookingSteps,
                    },
                    props.recipe.id,
                )
                .then(() => {
                    props.handleCompleteStep(recipe);
                });
            return;
        }
        // Add cooking steps
        props
            .addRecipeCookingSteps({
                recipe_id: props.recipe.id,
                cooking_steps: cookingSteps,
            })
            .then(() => {
                props.handleCompleteStep(recipe);
            });
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = genericHelpers
            .reorder(cookingSteps, result.source.index, result.destination.index)
            .map((cookingStep: any, index) => ({
                ...cookingStep,
                step_number: index,
            }));

        setIsChanged(true);
        setCookingSteps(items);
    };

    return (
        <div className={clsx(styles.cookingSteps)}>
            {addStepOpen ? (
                <StepDialog
                    toggleOpen={toggleAddStepOpen}
                    handleNewCookingStep={handleNewCookingStep}
                    selectedStepType={selectedStepType}
                    setSelectedStepType={setSelectedStepType}
                    cookingStepToEdit={cookingStepToEdit}
                    recipeId={recipe.id}
                />
            ) : null}
            {cookingSteps.length === 0 ? (
                <div className={styles.addFirstStepContainer}>
                    <IconButton onClick={toggleAddStepOpen} className={styles.addFirstStepIconButton}>
                        <AddIcon className={styles.addFirstStepIcon} />
                    </IconButton>
                    ADD THE FIRST STEP
                </div>
            ) : (
                <>
                    <Grid item xs={12} className={styles.addButtonRow}>
                        <Button
                            className={clsx(styles.standardButton, 'mr2', 'mt4')}
                            variant={'outlined'}
                            onClick={() => {
                                setSelectedStepType(null);
                                toggleAddStepOpen();
                            }}
                        >
                            ADD NEW STEP
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={clsx(styles.cookingStepsTable, 'mt4')}>
                            <DragDropTable
                                title={recipeConstants.RECIPE_COOKING_STEPS_PANELS.TABLE_LABEL}
                                data={cookingStepsTable}
                                columns={tableColumns}
                                options={tableOptions}
                                onDragEnd={onDragEnd}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.bottomNavigation}>
                            <Button
                                className={clsx(styles.standardButton, 'mr2', 'mt4')}
                                type="submit"
                                variant={'outlined'}
                                onClick={() => {
                                    props.nextStep(props.recipe);
                                }}
                            >
                                {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                            </Button>
                            <Button
                                className={clsx(styles.standardButton, 'mr2', 'mt4')}
                                variant={'outlined'}
                                onClick={() => {
                                    submitToBackend();
                                }}
                                disabled={!isChanged}
                            >
                                {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                            </Button>
                        </div>
                    </Grid>
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    addRecipeCookingSteps: recipeAction.addRecipeCookingSteps,
    getRecipeCookingSteps: recipeAction.getRecipeCookingSteps,
    updateRecipeCookingSteps: recipeAction.updateRecipeCookingSteps,
    getActionNames: recipeAction.getActionNames,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(AddCookingSteps);
