import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Step, StepButton, Stepper, WithStyles } from '@material-ui/core';
import styles from './index.module.scss';
import { IRecipeForm } from '../../recipe.interfaces';
import AddNewRecipe from './Forms/AddNewRecipe';
import recipeFormModel from './FormModel/RecipeFormModel';
import AddIngredients from './Forms/AddIngredients';
import AddCategoryTags from './Forms/AddCategoryTags';
import AddCookingSteps from './Forms/AddCookingSteps';
import { History } from 'history';
import en from '../../../../../assets/language/en.json';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { stepperStyles } from './styles';
import AddProducts from './Forms/AddProducts';
import clsx from 'clsx';
import { useParams, Link } from 'react-router-dom';
import { recipeAction } from '../../recipe.actions';
import { recipeHelpers } from '../../../helpers/recipe.helpers';
import { recipeConstants } from '../../recipe.constants';
import AddEquipment from './Forms/AddEquipment';
import AddLinkedRecipe from './Forms/AddLinkedRecipe';

interface IStepper extends WithStyles<typeof stepperStyles> {
    history: History;
}

type Props = IRecipeForm & IStepper;

const { formId, formField } = recipeFormModel.recipeFormModel;
const { cookingStepsFormId, cookingStepsFormField } = recipeFormModel.cookingStepsFormModel;
const { ingredientFormId, ingredientFormField } = recipeFormModel.ingredientFormModel;
const steps: string[] = [
    en.add_recipe_label,
    en.add_category_tags_label,
    en.linked_recipe,
    en.add_ingredients,
    en.add_cooking_steps,
    en.addEquipmentLabel,
    en.add_products_label,
];

const RecipeForm = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const { classes, history } = props;
    const { id, step } = useParams() as any;
    const totalSteps: number = steps.length;
    const subRoutes = recipeConstants.RECIPE_SUB_ROUTES;
    const defaultActiveStep = step ? Object.values(subRoutes).indexOf(step) : 0;

    const [activeStep, setActiveStep] = useState<number | null>(defaultActiveStep);
    const [recipeToUpdate, setRecipeToUpdate] = useState<IRecipeForm | null>(null);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});

    useEffect(() => {
        async function getData() {
            const recipe = await props.getRecipeById(id);
            const recipeCookingMethods = await props.getRecipeCookingMethods(recipe.id);
            const recipeGrillModelsResponse = await props.getRecipeGrillModels(recipe.id);
            const recipeTutorials = await props.getRecipeTutorials(recipe.id);

            setRecipeToUpdate(
                recipeHelpers.recipeMapper(
                    recipe,
                    recipeCookingMethods.recipeCookingMethods,
                    recipeGrillModelsResponse.recipeGrillModels,
                    recipeTutorials.recipeTutorials,
                ),
            );
        }
        id && getData();
    }, []);

    const isLastStep = () => {
        return activeStep === totalSteps - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps;
    };

    const nextStep = (recipe: IRecipeForm): void => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setRecipeToUpdate(recipe);
        setActiveStep(newActiveStep);
        id && pushToHistory(newActiveStep);
    };

    const prevStep = (recipe: IRecipeForm): void => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        setRecipeToUpdate(recipe);
        setActiveStep((prevStep: number) => prevStep - 1);
        id && pushToHistory(activeStep - 1);
    };

    const handleStep = (step: number) => () => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        setActiveStep(step);
    };

    const handleComplete = (recipe: IRecipeForm) => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        nextStep(recipe);
    };

    const pushToHistory = (historyStep) => {
        const formatSubRoutes = Object.entries(subRoutes).map((item) => item[1]);
        history.push(`${recipeConstants.RECIPE_ROUTES.EDIT_RECIPE}/${formatSubRoutes[historyStep]}/${id}`);
    };

    const renderSteps = (step: number): JSX.Element => {
        switch (step) {
            case 0:
                return (
                    <AddNewRecipe
                        recipeToUpdate={recipeToUpdate}
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                    />
                );

            case 1:
                return <AddCategoryTags nextStep={nextStep} prevStep={prevStep} recipe={recipeToUpdate} />;
            case 2:
                return <AddLinkedRecipe nextStep={nextStep} prevStep={prevStep} recipe={recipeToUpdate} />;
            case 3:
                return (
                    <AddIngredients
                        formId={ingredientFormId}
                        ingredientFormField={ingredientFormField}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        recipe={recipeToUpdate}
                    />
                );
            case 4:
                return (
                    <AddCookingSteps
                        cookingStepsFormId={cookingStepsFormId}
                        cookingStepsFormField={cookingStepsFormField}
                        recipe={recipeToUpdate}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                    />
                );
            case 5:
                return <AddEquipment nextStep={nextStep} prevStep={prevStep} recipe={recipeToUpdate} />;
            case 6:
                return <AddProducts nextStep={nextStep} prevStep={prevStep} recipe={recipeToUpdate} />;
            default:
                return (
                    <AddNewRecipe
                        recipeToUpdate={recipeToUpdate}
                        formId={formId}
                        formField={formField}
                        nextStep={nextStep}
                        handleCompleteStep={handleComplete}
                    />
                );
        }
    };

    return (
        <div className={clsx(styles.addRecipe, 'mr4')}>
            <Stepper activeStep={activeStep} className={classes.stepper} variant={'outlined'} nonLinear>
                {steps.map((label: string, index: number) => {
                    const subRoute = Object.values(subRoutes)[index];
                    return (
                        <Step key={label}>
                            <StepButton
                                component={Link}
                                onClick={handleStep(index)}
                                to={`${recipeConstants.RECIPE_ROUTES.EDIT_RECIPE}/${subRoute}/${id}`}
                                disabled={!step}
                                completed={completed[index]}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {step ? recipeToUpdate && renderSteps(activeStep) : renderSteps(activeStep)}
        </div>
    );
};

const mapDispatchToProps = {
    getRecipeById: recipeAction.getRecipeById,
    getRecipeCookingMethods: recipeAction.getRecipeCookingMethods,
    getRecipeGrillModels: recipeAction.getRecipeGrillModels,
    getRecipeTutorials: recipeAction.getRecipeTutorials,
};
export default compose(withStyles(stepperStyles), connect(null, mapDispatchToProps))(RecipeForm);
