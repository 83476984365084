import { AxiosError, AxiosResponse } from 'axios';
import { History } from 'history';
import { IPagination } from '../../shared/components/interfaces';
import { IBlockUserResponse } from '../reviews/reviews.interfaces';

export interface IUserReportActions {
    type: string;
    noUnreadReviews?: number;
    error?: string;
}

export interface IUserReportState {
    loading: boolean;
}

export interface IUserReportReview {
    id: number;
    profilePicture: string;
    name: string;
    reason: string;
    dateOfReport: Date;
}

export interface IUserReportReviewResponse extends AxiosResponse {
    reportedRating: {
        id: number;
    };
    reports: IUserReportReview[];
}

export interface IReportedUser {
    userId: number;
    profilePicture: string;
    blocked: boolean;
    reportsCount: number;
    fullName: string;
    read: boolean;
    internalDecision: InternalUserReportDecision;
}

export interface IReportedUserResponse extends AxiosResponse {
    reportedUsers: IReportedUser[];
    count: number;
}

export interface IReportsForUserResponse extends AxiosResponse {
    reportedUser: {
        id: number;
        blocked: boolean;
        internalDecision: InternalUserReportDecision;
        name: string;
    };
    reports: {
        id: number;
        read: boolean;
        profilePicture: string;
        name: string;
        reason: string;
        dateOfReport: Date;
    }[];
}

export enum InternalUserReportDecision {
    BLOCKED = 'Blocked',
    NOT_BLOCKED = 'Not blocked',
    OBSERVATION = 'Observation',
}

export interface IReviewReportListProps {
    getReviewReports: (reviewId: number) => Promise<IUserReportReviewResponse>;
    deleteReview: (reviewId: number) => Promise<AxiosResponse>;
    history: History;
}

export interface IUserReportsProps {
    getReportedUsers: (pagination: IPagination, searchText?: string) => Promise<IReportedUserResponse>;
    updateReportedUser: (
        userId: number,
        read: boolean,
        internalDecision: InternalUserReportDecision,
    ) => Promise<AxiosResponse>;
    blockUser: (userId: number, blocked: boolean) => Promise<IBlockUserResponse>;
    history: History;
}

export interface IReportsFourUserProps {
    getReportsForUser: (userId: number) => Promise<IReportsForUserResponse>;
    blockUser: (userId: number, blocked: boolean) => Promise<IBlockUserResponse>;
    updateReportedUser: (
        userId: number,
        read: boolean,
        internalDecision: InternalUserReportDecision,
    ) => Promise<AxiosResponse>;
    history: History;
}
