import React, { PropsWithChildren, ReactElement } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../styles/mui.styles';
import clsx from 'clsx';

type OwnProps = {
    isOpen: boolean;
    progressUpload: number;
    classes: {
        backdrop: string;
        circularLabel: string;
    };
};

const BackDrop = (props: PropsWithChildren<OwnProps>): ReactElement => {
    const { isOpen, classes, progressUpload } = props;

    return (
        <Backdrop className={classes.backdrop} open={isOpen}>
            <CircularProgress color="inherit" size={'10rem'} thickness={1} />
            <Box className={classes.circularLabel}>
                <Typography variant="h5" component="div">
                    {`${Math.round(progressUpload)}%`}
                </Typography>
            </Box>
        </Backdrop>
    );
};

export default withStyles(muiStyles.backDropStyles)(BackDrop);
