import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formInitialValues } from './formModel/blacklist.form.initial.values';
import Button from '@material-ui/core/Button';
import en from '../../../../../../assets/language/en.json';
import Dialog from '@material-ui/core/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from './formModel/validation.schema';
import { FormControl, FormGroup, FormHelperText, TextField, InputAdornment } from '@material-ui/core';
import styles from './index.module.scss';
import { compose } from 'recompose';
import { muiStyles } from '../../../../../shared/styles/mui.styles';
import clsx from 'clsx';
import LanguageFlag from '../../../../../shared/components/generics/languageFlag/LanguageFlag';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { IAddOrEditBlacklist, IBlacklist, IBlacklistFormComponent, IReview } from '../../../reviews.interfaces';
import formModels from './formModel/blacklist.form.model';
import { reviewActions } from '../../../reviews.actions';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';

type BlacklistProps = IBlacklistFormComponent & typeof formModels.blacklistFormModel;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditBlacklist) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddOrEditBlacklist = (props: PropsWithChildren<BlacklistProps>): JSX.Element => {
    const {
        formField: { wordEn, wordDe },
        blacklistItem,
        history,
    } = props;
    const id = useIdParam();

    const [open, setOpen] = useState<boolean>(!!(id && id == blacklistItem?.id));

    const formValues = blacklistItem ? blacklistItem : formInitialValues.blacklist;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (values: IBlacklist): void => {
        blacklistItem ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: IBlacklist): void => {
        props.addBlacklistItem(values).then(() => {
            props.refreshData();
            handleClose();
        });
    };

    const handleUpdate = (values: IBlacklist): void => {
        const reviewToUpdate: IBlacklist = {
            wordEn: values.wordEn,
            wordDe: values.wordDe,
        };
        props.updateBlacklistItem(reviewToUpdate, values.id).then(() => {
            props.refreshData();
            handleClose();
        });
    };

    return (
        <div className={styles.blacklist}>
            {blacklistItem ? (
                <Button className={styles.editBlacklist} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addBlacklist, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_blacklist}
                </Button>
            )}

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {blacklistItem ? en.edit_blacklist : en.add_new_blacklist}
                </DialogTitle>
                <Formik
                    initialValues={formValues}
                    onSubmit={(values: IBlacklist, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.blacklistValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IBlacklist>) => (
                        <Form className={styles.addBlacklistForm}>
                            <DialogContent dividers>
                                <FormGroup className={styles.inputGroup}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={wordEn.name}
                                            label={wordEn.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.wordEn}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.wordEn && props.touched.wordEn ? props.errors.wordEn : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            name={wordDe.name}
                                            label={wordDe.label}
                                            type="text"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.wordDe}
                                            variant={'outlined'}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.wordDe && props.touched.wordDe ? props.errors.wordDe : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    className={clsx(styles.saveBlacklist, 'mb4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = {
    addBlacklistItem: reviewActions.addBlacklist,
    updateBlacklistItem: reviewActions.updateBlacklist,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(AddOrEditBlacklist);
