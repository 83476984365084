import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import en from '../../../../../assets/language/en.json';
import de from '../../../../../assets/language/de.json';
import MUIRichTextEditor from 'mui-rte';
import { alertActions } from '../../../../shared/actions/alert.actions';
import styles from './index.module.scss';

interface OwnProps {
    value: string;
    type: {
        name: string;
        label: string;
        requiredErrorMsg: string;
    };
    alert: string;
    openDialog: boolean;
    handleDialogClick: (type?: string) => void;
    onSave: (typeName: string, data: string) => void;
}

interface DialogTitleProps extends WithStyles<typeof muiStyles.addOrEditDialog> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    type: {
        name: string;
        label: string;
        requiredErrorMsg: string;
    };
}

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: DialogTitleProps) => {
    const { children, classes, onClose, type, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={styles.dialogTitle}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogTextEditor = (props: OwnProps): ReactElement => {
    const { onSave, value, type, openDialog, handleDialogClick, alert } = props;
    const editorControls = [
        'title',
        'italic',
        'highlight',
        'undo',
        'redo',
        'link',
        'media',
        'numberList',
        'bulletList',
        'code',
        'save',
    ];

    return (
        openDialog && (
            <Dialog
                onClose={handleDialogClick}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleDialogClick} type={type}>
                    <span>{type.label}</span>
                    <span className={styles.warningDialog}>{en.dialog_warning}</span>
                </DialogTitle>

                <DialogContent>
                    <MUIRichTextEditor
                        label={type.label}
                        onSave={(data) => {
                            onSave(type.name, data);
                            alertActions.info(alert);
                        }}
                        inlineToolbar={true}
                        value={value}
                        controls={editorControls}
                    />
                </DialogContent>
            </Dialog>
        )
    );
};

export default DialogTextEditor;
