import {
    CampaignDisplay,
    ContentOrientation,
    DiscountType,
    IPromotionCampaignData,
} from '../../../promotion.campaign.interfaces';

const promotionCampaign: IPromotionCampaignData = {
    id: null,
    titleEn: '',
    titleDe: '',
    campaignName: '',
    startDate: new Date(),
    endDate: new Date(),
    pictureEn: null,
    pictureDe: null,
    videoEn: null,
    videoDe: null,
    regions: [],
    contentOrientation: ContentOrientation.LEFT,
    display: CampaignDisplay.TOP,
    textColorHex: '',
    buttonColorHex: '',
    closeIconColorHex: '',
    descriptionColorHex: '',
    buttonTitleEn: '',
    buttonTitleDe: '',
    descriptionEn: '',
    descriptionDe: '',
    targetUsers: [],
    segmentAudiences: [],
    coupon: null,
};

const promotionCampaignCoupon: IPromotionCampaignData['coupon'] = {
    productId: null,
    discount: {
        type: DiscountType.PERCENTAGE,
        value: 0,
    },
    expirationDate: new Date(),
};

export const formInitialValues = {
    promotionCampaign,
    promotionCampaignCoupon,
};
