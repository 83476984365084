import { recipeConstants } from './recipe.constants';

export const recipeEndpoints = {
    HOME_ROUTE: '/',
    GET_RECIPES: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES,
    },
    GET_RECIPE_BY_ID: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES_WITH_PARAMS,
    },
    ADD_RECIPE: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES,
    },
    UPDATE_RECIPE: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES_WITH_PARAMS,
    },
    DELETE_RECIPE: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES_WITH_PARAMS,
    },
    GET_RECIPE_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-category-tags/',
    },
    ADD_RECIPE_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-category-tags/add',
    },
    UPDATE_RECIPE_CATEGORY_TAGS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-category-tags/',
    },
    GET_RECIPE_INGREDIENTS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-ingredients/',
    },
    UPDATE_RECIPE_INGREDIENTS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-ingredients/',
    },
    DELETE_RECIPE_INGREDIENT: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-ingredients/',
    },
    ADD_RECIPE_COOKING_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-steps/add',
    },
    GET_RECIPE_COOKING_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-steps/',
    },
    UPDATE_RECIPE_COOKING_STEPS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-steps/',
    },
    ADD_RECIPE_COOKING_METHODS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods/add',
    },
    GET_RECIPE_COOKING_METHODS: {
        url: '/cooking-methods',
    },
    UPDATE_RECIPE_COOKING_METHODS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods/',
    },
    GET_RECIPE_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES_WITH_PARAMS,
    },
    ADD_RECIPE_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + '/recipe-products/add',
    },
    UPDATE_RECIPE_PRODUCTS: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.RECIPES_WITH_PARAMS,
    },
    GET_GRILLS: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.GRILL_ROUTES.GRILLS,
    },
    GET_ACTION_NAMES: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.ACTION_NAMES_ROUTES.ACTION_NAMES,
    },
    RECIPE_EQUIPMENT: {
        url: process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_EQUIPMENT_ROUTES.RECIPE_EQUIPMENT,
    },
    UPDATE_LINKED_RECIPES: {
        url: (recipeId: number) =>
            process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.UPDATE_LINKED_RECIPES(recipeId),
    },
    EXPORT_RECIPES_FOR_COOKBOOK: {
        url: (selectionId: number) =>
            process.env.REACT_APP_SERVER_URL + recipeConstants.RECIPE_ROUTES.EXPORT_RECIPES_FOR_COOKBOOK(selectionId),
    },
};
