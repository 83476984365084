import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import { IngredientActions, IngredientData, IngredientForm, IngredientsResponse } from './ingredients.interfaces';
import { ingredientsService } from './ingredients.service';
import { ingredientsConstants } from './ingredients.constants';
import en from '../../../assets/language/en.json';
import { IFilters, IPagination } from '../../shared/components/interfaces';

function getIngredients(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IngredientsResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return ingredientsService.getIngredients(paging, searchText, filters).then(
            (response: IngredientsResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_REQUEST };
    }

    function success(response: IngredientsResponse): IngredientActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_SUCCESS,
            ingredientsData: response,
        };
    }

    function failure(error: string): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENTS_FAILURE, error };
    }
}

function getIngredientById(ingredientId: number): (dispatch: Dispatch) => Promise<IngredientData | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return ingredientsService.getIngredientById(ingredientId).then(
            (response: IngredientData) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_REQUEST };
    }

    function success(response: IngredientData): IngredientActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_SUCCESS,
            ingredient: response,
        };
    }

    function failure(error: string): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.GET_INGREDIENT_BY_ID_FAILURE, error };
    }
}

const addIngredient = (
    ingredientData: IngredientForm,
): ((dispatch: Dispatch) => Promise<IngredientsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return ingredientsService.addIngredient(ingredientData).then(
            (response: IngredientsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.ingredient_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_REQUEST };
    }

    function success(response: IngredientsResponse): IngredientActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_SUCCESS,
            ingredientsData: response,
        };
    }

    function failure(error: string): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.ADD_INGREDIENT_FAILURE, error };
    }
};

const deleteIngredient = (
    ingredientId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return ingredientsService.deleteIngredient(ingredientId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.ingredient_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_REQUEST };
    }

    function success(response: AxiosResponse): IngredientActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_SUCCESS,
        };
    }

    function failure(error: string): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.DELETE_INGREDIENT_FAILURE, error };
    }
};

const updateIngredient = (
    ingredientData: IngredientForm,
    ingredientId: number,
): ((dispatch: Dispatch) => Promise<IngredientsResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return ingredientsService.updateIngredient(ingredientData, ingredientId).then(
            (response: IngredientsResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.ingredient_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_REQUEST };
    }

    function success(response: IngredientsResponse): IngredientActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_SUCCESS,
            ingredientsData: response,
        };
    }

    function failure(error: string): IngredientActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_FAILURE, error };
    }
};

export const ingredientsAction = {
    getIngredients,
    getIngredientById,
    addIngredient,
    deleteIngredient,
    updateIngredient,
};
