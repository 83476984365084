import React from 'react';
import { alertConstants } from '../constants/alert.constants';
import { ToastContent, toast } from 'react-toastify';
import { IAlert } from './interfaces';
import { styleConstants } from '../constants/style.constants';
import { AxiosError } from 'axios';

const success = (message: string, content?: ToastContent): IAlert => {
    toast.success(content || message, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.SUCCESS, message };
};

const info = (message: string): IAlert => {
    toast.info(message, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.SUCCESS, message };
};

const warn = (message: string): IAlert => {
    toast.warn(message, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.SUCCESS, message };
};

const error = (message: string): IAlert => {
    toast.error(message, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.ERROR, message };
};

const axiosError = (error: AxiosError): IAlert => {
    const message = error.response?.data?.message || error.toString();
    toast.error(message, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.ERROR, message };
};

const zodError = (error: any): IAlert => {
    const message = error.response?.data?.message || error.toString();

    const zodErrors = error.response?.data?.errors as any[];
    const toastContent = (
        <>
            {zodErrors?.map((zodError) => {
                return (
                    <p key={zodError.key}>
                        <b>{zodError.key}: </b> {zodError.message}
                    </p>
                );
            })}
        </>
    );

    const finalCOntent = zodErrors && zodErrors.length > 0 ? toastContent : message;
    toast.error(finalCOntent, styleConstants.TOAST_OPTIONS);
    return { type: alertConstants.State.ERROR, message };
};

const clear = (): IAlert => {
    return { type: alertConstants.State.CLEAR };
};
export const alertActions = {
    success,
    error,
    axiosError,
    clear,
    warn,
    info,
    zodError,
};
