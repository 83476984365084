import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { formInitialValues } from '../formModel/form.initial.values';
import { Form, Formik, FormikProps } from 'formik';
import validationSchemas from '../formModel/validation.schema';
import {
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    InputAdornment,
    ThemeProvider,
    useTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import styles from '../index.module.scss';
import formModels from '../formModel/form.model';
import en from '../../../../../../assets/language/en.json';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { IPinnedSelectionRecipe, ISelectionForm, ISelectionFormComponent } from '../../../selection.interfaces';
import { selectionActions } from '../../../selection.actions';
import { selectionConstants } from '../../../selection.constants';
import { recipeAction } from '../../../../recipe/recipe.actions';
import { IPagination } from '../../../../../shared/components/interfaces';
import { selectionHelpers } from '../../../../helpers/selection.helpers';
import clsx from 'clsx';
import LanguageFlag from '../../../../../shared/components/generics/languageFlag/LanguageFlag';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import DateFnsUtils from '@date-io/date-fns';
import { muiStyles } from '../../../../../shared/styles/mui.styles';
import CustomDatePicker from '../../../../../shared/components/generics/datePicker/CustomDatePicker';

type Props = ISelectionFormComponent & typeof formModels.selectionFormModel;

const SelectionForm = (props: PropsWithChildren<Props>): ReactElement => {
    const componentIsMounted = useRef(true);
    const {
        formField: { name_en, name_de, displays, seasonalStart, seasonalEnd },
        selectionData: { selection },
        history,
    } = props;

    let formInitials = selection ? selection : formInitialValues.selection;

    const [updatingSelection, setUpdatingSelection] = useState<ISelectionForm | null>(selection);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<IPinnedSelectionRecipe[]>([]);

    useEffect(() => {
        if (componentIsMounted) {
            getSelectionData();
            componentIsMounted.current = false;
        }
    }, []);

    useEffect(() => {
        formInitials = updatingSelection;
    }, [updatingSelection]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleSubmit = async (values: ISelectionForm): Promise<void> => {
        selection ? await handleUpdate(values) : await handleInsert(values);
    };

    const handleInsert = async (values: ISelectionForm): Promise<void> => {
        const selectionResponse = await props.addSelection(values);

        values.id = selectionResponse.id;

        const selectionDisplaysResponse = await props.addSelectionDisplays(values);

        const fullSelection = selectionHelpers.createFullSelectionObject(
            selectionResponse,
            selectionDisplaysResponse,
            props.selectionData.cookingMethods,
            props.selectionData.grillModels,
            props.selectionData.ingredients,
            props.selectionData.ambassadors,
            props.selectionData.valueTags,
        );

        props.handleCompleteStep(fullSelection);
    };

    const handleUpdate = async (values: ISelectionForm): Promise<void> => {
        const selectionResponse = await props.updateSelection(values, values.id);
        const selectionDisplaysResponse = await props.updateSelectionDisplays(values);

        const fullSelection = selectionHelpers.createFullSelectionObject(
            selectionResponse,
            selectionDisplaysResponse,
            props.selectionData.cookingMethods,
            props.selectionData.grillModels,
            props.selectionData.ingredients,
            props.selectionData.ambassadors,
            props.selectionData.valueTags,
        );

        props.handleCompleteStep(fullSelection);
    };

    const nextStep = (): void => {
        props.nextStep(props.selectionData);
    };

    const getSelectionData = (): void => {
        if (!genericHelpers.isNullOrUndefined(updatingSelection)) {
            props.getSelectionById(updatingSelection.id).then((response) => {
                setUpdatingSelection(response);
            });
        }
    };

    return (
        <div className={clsx(styles.selection, 'mt8')}>
            <Formik
                initialValues={formInitials}
                onSubmit={async (values: ISelectionForm, { setSubmitting }) => {
                    await handleSubmit(values);
                    setSubmitting(false);
                }}
                validationSchema={validationSchemas.selectionValidationSchema}
                enableReinitialize={true}
            >
                {(props: FormikProps<ISelectionForm>) => {
                    return (
                        <Form className={styles.addSelectionForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={name_en.label}
                                                name={name_en.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_en}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_en && props.touched.name_en
                                                    ? props.errors.name_en
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={name_de.label}
                                                name={name_de.name}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_de}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_de && props.touched.name_de
                                                    ? props.errors.name_de
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <p className={clsx('mb4', 'text-left')}>{en.seasonal_display_label}</p>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <CustomDatePicker
                                                label={seasonalStart.label}
                                                name={seasonalStart.name}
                                                value={props.values.seasonalStart}
                                                onChange={(newDate) => {
                                                    props.setValues(
                                                        {
                                                            ...props.values,
                                                            seasonalStart: newDate,
                                                        },
                                                        true,
                                                    );
                                                }}
                                                dateFormat="dd/MM"
                                                placeholder="DD/MM"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <CustomDatePicker
                                                label={seasonalEnd.label}
                                                name={seasonalEnd.name}
                                                value={props.values.seasonalEnd}
                                                onChange={(newDate) => {
                                                    props.setValues(
                                                        {
                                                            ...props.values,
                                                            seasonalEnd: newDate,
                                                        },
                                                        true,
                                                    );
                                                }}
                                                dateFormat="dd/MM"
                                                placeholder="DD/MM"
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={displays.label}
                                                name={displays.name}
                                                select
                                                type={'text'}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.displays}
                                                variant={'outlined'}
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                required
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{displays.label}</em>
                                                </MenuItem>
                                                {selectionConstants.DISPLAY.map((selectionDisplay, index) => (
                                                    <MenuItem key={index} value={selectionDisplay}>
                                                        {selectionDisplay}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.displays && props.touched.displays
                                                    ? props.errors.displays
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <div className={styles.bottomNavigation}>
                                <Button
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    onClick={nextStep}
                                    disabled={!selection}
                                >
                                    {en.next}
                                </Button>
                                <Button
                                    type="submit"
                                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    {en.save_button_label}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapDispatchToProps = {
    addSelection: selectionActions.addSelection,
    updateSelection: selectionActions.updateSelection,
    getSelectionById: selectionActions.getSelectionById,
    getRecipes: recipeAction.getRecipes,
    addSelectionDisplays: selectionActions.addRecipeSelectionDisplays,
    updateSelectionDisplays: selectionActions.updateRecipeSelectionDisplays,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(SelectionForm);
