export const userReportConstants = {
    USER_REPORT_ACTION_TYPES: {
        UPDATE_REVIEW_REPORT_REQUEST: 'UPDATE_REVIEW_REPORT_REQUEST',
        UPDATE_REVIEW_REPORT_SUCCESS: 'UPDATE_REVIEW_REPORT_SUCCESS',
        UPDATE_REVIEW_REPORT_FAILURE: 'UPDATE_REVIEW_REPORT_FAILURE',
        GET_REVIEW_REPORTS_REQUEST: 'GET_REVIEW_REPORTS_REQUEST',
        GET_REVIEW_REPORTS_SUCCESS: 'GET_REVIEW_REPORTS_SUCCESS',
        GET_REVIEW_REPORTS_FAILURE: 'GET_REVIEW_REPORTS_FAILURE',
        GET_REPORTED_USERS_REQUEST: 'GET_REPORTED_USERS_REQUEST',
        GET_REPORTED_USERS_SUCCESS: 'GET_REPORTED_USERS_SUCCESS',
        GET_REPORTED_USERS_FAILURE: 'GET_REPORTED_USERS_FAILURE',
        UPDATE_REPORTED_USER_REQUEST: 'UPDATE_REPORTED_USER_REQUEST',
        UPDATE_REPORTED_USER_SUCCESS: 'UPDATE_REPORTED_USER_SUCCESS',
        UPDATE_REPORTED_USER_FAILURE: 'UPDATE_REPORTED_USER_FAILURE',
        GET_REPORTS_FOR_USER_REQUEST: 'GET_REPORTS_FOR_USER_REQUEST',
        GET_REPORTS_FOR_USER_SUCCESS: 'GET_REPORTS_FOR_USER_SUCCESS',
        GET_REPORTS_FOR_USER_FAILURE: 'GET_REPORTS_FOR_USER_FAILURE',
    },
    USER_REPORT_API_ROUTES: {
        UPDATE_REVIEW_REPORT: (ratingId: number) => `/user-reporting/reported-rating/${ratingId}`,
        GET_REVIEW_REPORTS: (ratingId: number) => `/user-reporting/rating-reports/${ratingId}`,
        GET_REPORTED_USERS: '/user-reporting/reported-users',
        UPDATE_REPORTED_USER: (userId: number) => `/user-reporting/reported-user/${userId}`,
        GET_REPORTS_FOR_USER: (userId: number) => `/user-reporting/user-reports/${userId}`,
    },
    USER_REPORT_ROUTES: {
        REVIEW_REPORTS: `/user-reporting/reported-rating`,
        REPORTED_USERS: `/user-reporting/reported-users`,
        REPORTS_FOR_USER: `/user-reporting/reports-for-user`,
    },
};
