import React, { PropsWithChildren, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './index.module.scss';

type Props = {
    open: boolean;
    onClose: () => any;
    title: string;
    cancelLabel: string;
    submitLabel: string;
    width?: string;
    handleSubmit: () => any;
};

export default function SubmitDialog(props: PropsWithChildren<Props>) {
    const { open, onClose, title, cancelLabel, submitLabel, width, handleSubmit } = props;

    return (
        <>
            <Dialog
                style={{
                    margin: 'auto',
                    width: width || '600px',
                }}
                open={open}
                onClose={onClose}
                aria-labelledby="submit-dialog-title"
                aria-describedby="submit-dialog-description"
            >
                <div
                    style={{
                        padding: '32px',
                    }}
                >
                    <DialogTitle className={styles.dialogTitle} id="submit-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogActions className={styles.dialogActions}>
                        <Button className={styles.dialogButton} onClick={onClose}>
                            {cancelLabel}
                        </Button>
                        <Button
                            className={styles.dialogButton}
                            onClick={() => {
                                handleSubmit && handleSubmit();
                                onClose();
                            }}
                            autoFocus
                        >
                            {submitLabel}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
}
