import { IProductActions, IProductState } from './upselling.interfaces';
import { productConstants } from './upselling.constants';

const initialState: IProductState = {
    products: [],
    product: null,
    total: 0,
    loading: false,
};

const upsellingReducer = (state = initialState, action: IProductActions): IProductState => {
    switch (action.type) {
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_REQUEST:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_REQUEST:
        case productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_REQUEST:
        case productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_REQUEST:
        case productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_REQUEST:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.productsData.products,
                total: action.productsData.total,
                loading: false,
            };
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCTS_FAILURE:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_FAILURE:
            return {
                loading: false,
            };
        case productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_SUCCESS:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_BY_ID_SUCCESS:
        case productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.product,
                loading: false,
            };
        case productConstants.PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_FAILURE:
        case productConstants.PRODUCT_ACTION_TYPES.ADD_PRODUCT_FAILURE:
        case productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_FAILURE:
        case productConstants.PRODUCT_ACTION_TYPES.DELETE_PRODUCT_SUCCESS:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_FAILURE:
        case productConstants.PRODUCT_ACTION_TYPES.GET_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export { upsellingReducer };
