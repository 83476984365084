import * as Yup from 'yup';
import formModels from './ingredient.form.model';
import { ObjectSchema } from 'yup';
import { IngredientForm } from '../../../ingredients.interfaces';

const {
    formField: { nameEn, nameDe, imperialUnit, metricUnit },
} = formModels.ingredientFormModel;

const ingredientValidationSchema: ObjectSchema<IngredientForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${nameEn.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${nameDe.requiredErrorMsg}`).defined(),
        imperialUnitId: Yup.number().nullable(true),
        metricUnitId: Yup.number().nullable(true),
        conversion: Yup.number().nullable(true),
    });

const validationSchemas = {
    ingredientValidationSchema,
};

export default validationSchemas;
