import { ToggleButton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

export const StyledToggleButton = withStyles({
    root: {
        width: '100%',
        color: 'black !important',
    },
    selected: {
        backgroundColor: '#CC2D1D !important',
        color: 'white !important',
    },
})(ToggleButton);
