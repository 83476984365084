import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styles from './index.module.scss';
import { Form, Formik, FormikProps } from 'formik';
import { formInitialValues } from './formModel/form.initial.values';
import { IAddOrEditIngredient, IIngredientProps, IngredientForm } from '../../ingredients.interfaces';
import validationSchemas from './formModel/validation.schema';
import { FormControl, FormGroup, FormHelperText, Grid, MenuItem, TextField, InputAdornment } from '@material-ui/core';
import formModels from './formModel/ingredient.form.model';
import { ingredientsAction } from '../../ingredients.actions';
import en from '../../../../../assets/language/en.json';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { unitActions } from '../../../unitManagement/unit.actions';
import { IUnit } from '../../../unitManagement/unit.interfaces';
import { unitConstants } from '../../../unitManagement/unit.constants';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import HeightIcon from '@material-ui/icons/Height';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ingredientsEndpoints } from '../../../../modules/ingredients/ingredients.endpoints';
import { useOneTimeFetch } from '../../../../shared/helpers/fetchHook';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';

type IngredientProps = typeof formModels.ingredientFormModel & IIngredientProps;

const DialogTitle = withStyles(muiStyles.addOrEditDialog)((props: IAddOrEditIngredient) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(muiStyles.dialogContent)(MuiDialogContent);

const DialogActions = withStyles(muiStyles.dialogActions)(MuiDialogActions);

const AddIngredient = (props: PropsWithChildren<IngredientProps>): JSX.Element => {
    const {
        formField: { nameEn, nameDe, imperialUnit, metricUnit, conversion },
        ingredientData,
        history,
    } = props;

    const id = useIdParam();

    const [open, setOpen] = useState<boolean>(!!(id && id == ingredientData?.id));
    const [imperialUnits, setImperialUnits] = useState<Array<IUnit>>([]);
    const [metricUnits, setMetricUnits] = useState<Array<IUnit>>([]);
    const componentIsMounted = useRef<boolean>(true);

    const formInitials = ingredientData ? ingredientData : formInitialValues.ingredient;

    const fixedConversionRates = useOneTimeFetch(props.getFixedConversionRates);

    const getUnits = (): void => {
        if (componentIsMounted) {
            props.getUnits().then((response) => {
                const units = response.units;

                setUnitState(units);

                componentIsMounted.current = false;
            });
        }
    };

    useEffect(() => {
        if (open && componentIsMounted) {
            props.getUnits().then((response) => {
                const units = response.units;

                setUnitState(units);

                componentIsMounted.current = false;
            });
        }
    }, []);

    function setUnitState(units: IUnit[]) {
        units.forEach((unit) => {
            switch (unit.type) {
                case unitConstants.TYPE.METRIC.value:
                    setMetricUnits((prevUnits) => [...prevUnits, unit]);
                    break;
                case unitConstants.TYPE.IMPERIAL.value:
                    setImperialUnits((prevUnits) => [...prevUnits, unit]);
                    break;
                default:
                    setMetricUnits((prevUnits) => [...prevUnits, unit]);
                    setImperialUnits((prevUnits) => [...prevUnits, unit]);
                    break;
            }
        });
    }

    const cleanUnits = (): void => {
        setMetricUnits([]);
        setImperialUnits([]);
    };

    const handleClickOpen = () => {
        getUnits();
        setOpen(true);
        ingredientData && history.push(`${ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS}/${ingredientData.id}`);
    };

    const handleClose = () => {
        cleanUnits();
        setOpen(false);
        history.push(`${ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS}`);
    };

    const handleSubmit = async (values: IngredientForm): Promise<void> => {
        ingredientData ? await handleUpdate(values) : await handleInsert(values);
    };

    const handleInsert = async (values: IngredientForm): Promise<void> => {
        await props.addIngredient(values);
        await props.refreshData(globalConstants.DEFAULT_PAGINATION);

        handleClose();
    };

    const handleUpdate = async (values: IngredientForm): Promise<void> => {
        const ingredientToUpdate: IngredientForm = {
            name_en: values.name_en,
            name_de: values.name_de,
            imperialUnitId: values.imperialUnitId,
            metricUnitId: values.metricUnitId,
            conversion: values.conversion,
        };
        await props.updateIngredient(ingredientToUpdate, values.id);
        await props.refreshData(globalConstants.DEFAULT_PAGINATION);

        handleClose();
    };

    function resetConversionRate(props: FormikProps<IngredientForm>) {
        props.setFieldValue(conversion.name, '');
    }

    return (
        <div className={styles.ingredient}>
            {ingredientData ? (
                <Button className={styles.editIngredient} onClick={handleClickOpen}>
                    {en.edit_button_label}
                </Button>
            ) : (
                <Button className={clsx(styles.addIngredient, 'mb4')} onClick={handleClickOpen}>
                    {en.add_new_ingredient}
                </Button>
            )}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xs'}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {ingredientData ? en.edit_ingredient_label : en.add_new_ingredient}
                </DialogTitle>
                <Formik
                    initialValues={formInitials}
                    onSubmit={async (values: IngredientForm, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.ingredientValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IngredientForm>) => {
                        if (props.values.imperialUnitId && props.values.metricUnitId && !props.values.conversion) {
                            const metricUnit = metricUnits.find((unit) => unit.id === props.values.metricUnitId);
                            const imperialUnit = imperialUnits.find((unit) => unit.id === props.values.imperialUnitId);
                            const fixedConversionRate = fixedConversionRates?.find(
                                (rate) =>
                                    rate.metricUnit.toLocaleLowerCase() === metricUnit?.name.toLocaleLowerCase() &&
                                    rate.imperialUnit.toLocaleLowerCase() === imperialUnit?.name.toLocaleLowerCase(),
                            );
                            if (fixedConversionRate) {
                                props.setFieldValue(conversion.name, fixedConversionRate.rate);
                                props.setFieldTouched(conversion.name, true);
                            }
                        }
                        return (
                            <Form className={styles.addIngredientForm}>
                                <DialogContent dividers>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'p2', 'mb4')}>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_en && props.touched.name_en
                                                    ? props.errors.name_en
                                                    : ''}
                                            </FormHelperText>
                                            <TextField
                                                className={styles.formInput}
                                                name={nameEn.name}
                                                label={nameEn.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_en}
                                                variant={'outlined'}
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'p2', 'mb4')}>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {props.errors.name_de && props.touched.name_de
                                                    ? props.errors.name_de
                                                    : ''}
                                            </FormHelperText>
                                            <TextField
                                                className={styles.formInput}
                                                name={nameDe.name}
                                                label={nameDe.label}
                                                type="text"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name_de}
                                                variant={'outlined'}
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="de" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControl className={clsx(styles.formControl, 'p2', 'mb4')} required>
                                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                        {props.errors.metricUnitId && props.touched.metricUnitId
                                                            ? props.errors.metricUnitId
                                                            : ''}
                                                    </FormHelperText>
                                                    <TextField
                                                        label={metricUnit.label}
                                                        select
                                                        name={metricUnit.name}
                                                        value={props.values.metricUnitId}
                                                        onChange={(e) => {
                                                            resetConversionRate(props);
                                                            return props.handleChange(e);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        variant={'outlined'}
                                                        required={Boolean(props.values.imperialUnitId)}
                                                    >
                                                        <MenuItem disabled value={''}>
                                                            {metricUnit.label}
                                                        </MenuItem>
                                                        {metricUnits
                                                            ? metricUnits.map((unit) => (
                                                                  <MenuItem key={unit.id} value={unit.id}>
                                                                      {unit.name}
                                                                  </MenuItem>
                                                              ))
                                                            : null}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl className={clsx(styles.formControl, 'p2', 'mb4')}>
                                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                        {props.errors.imperialUnitId && props.touched.imperialUnitId
                                                            ? props.errors.imperialUnitId
                                                            : ''}
                                                    </FormHelperText>
                                                    <TextField
                                                        label={imperialUnit.label}
                                                        select
                                                        name={imperialUnit.name}
                                                        value={props.values.imperialUnitId}
                                                        onChange={(e) => {
                                                            resetConversionRate(props);
                                                            return props.handleChange(e);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        variant={'outlined'}
                                                        required={Boolean(props.values.metricUnitId)}
                                                    >
                                                        <MenuItem disabled value={''}>
                                                            {imperialUnit.label}
                                                        </MenuItem>
                                                        {imperialUnits
                                                            ? imperialUnits.map((unit) => (
                                                                  <MenuItem key={unit.id} value={unit.id}>
                                                                      {unit.name}
                                                                  </MenuItem>
                                                              ))
                                                            : null}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid alignItems="center" direction="column" style={{ display: 'flex' }}>
                                            <Grid item xs={4}>
                                                <div className={clsx(styles.formControl, 'p2', 'mb4')}>
                                                    <TextField
                                                        disabled
                                                        value={`1 Unit ${metricUnit.label} `}
                                                        variant={'outlined'}
                                                    />
                                                </div>
                                            </Grid>
                                            <div className={clsx(styles.formControl, styles.arrowContainer, 'mb4')}>
                                                <HeightIcon fontSize="large" />
                                            </div>

                                            <Grid item xs={10}>
                                                <div className={clsx(styles.formControl, 'p2', 'mb4')}>
                                                    <TextField
                                                        name={conversion.name}
                                                        type="number"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.conversion}
                                                        variant={'outlined'}
                                                        required={Boolean(
                                                            props.values.imperialUnitId && props.values.metricUnitId,
                                                        )}
                                                        disabled={
                                                            !(props.values.imperialUnitId && props.values.metricUnitId)
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    / Unit {imperialUnit.label}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        className={clsx(styles.saveIngredient, 'mb4')}
                                        disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                    >
                                        {en.save_button_label}
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = {
    addIngredient: ingredientsAction.addIngredient,
    updateIngredient: ingredientsAction.updateIngredient,
    getUnits: unitActions.getUnits,
    getFixedConversionRates: unitActions.getFixedConversionRates,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(AddIngredient);
