import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';

const equipmentColumns = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
        },
    },
    {
        name: 'nameEn',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'nameDe',
        label: en.name,
        options: {
            filter: true,
            sort: true,
        },
    },
];

export const equipmentDataTables = {
    equipmentColumns,
};
