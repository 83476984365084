export class SendmailConfig {
    static allowedFileExtensions = ['csv'];

    static defaultSubject = 'Lieferung';

    static emailTemplate = {
        blocks: [
            {
                key: '1',
                text: 'Hallo [Vorname],',
                type: 'unstyled',
            },
            {
                key: '2',
                text: 'Auf unseren Grills bruzzelt gerade mehr als sonst. Daher können sich einige Bestellungen leider verzögern. Deine Bestellung [Bestell_ID] mit den Produkten',
                type: 'unstyled',
            },
            {
                key: '3',
                text: '',
                type: 'unstyled',
            },
            {
                key: '4',
                text: '[verzögertes Produkt 1]',
                type: 'unstyled',
            },
            {
                key: '5',
                text: '[verzögertes Produkt 2]',
                type: 'unstyled',
            },
            {
                key: '6',
                text: 'wird sich voraussichtlich bis [ungefähres Lieferdatum] auf dem Warmhalterost ruhen dürfen.',
                type: 'unstyled',
            },
            {
                key: '7',
                text: 'Entschuldige bitte mögliche Unannehmlichkeiten. Solltes Du Fragen oder Anliegen haben, kannst Du einfach auf diese E-Mail an Service@OttoWilde.de antworten.',
                type: 'unstyled',
            },
            {
                key: '8',
                text: '',
                type: 'unstyled',
            },
            {
                key: '9',
                text: 'Wilde Grüße aus Düsseldorf,',
                type: 'unstyled',
            },
            {
                key: '10',
                text: 'Dein Otto Wilde Team',
                type: 'unstyled',
            },
        ],
        entityMap: {},
    };
}
