import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { authService } from '../../shared/services/auth.service';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { IAuthCode } from './login.interfaces';
import { routeConstants } from '../../shared/routes/route.constants';
import { LOGIN_ENDPOINTS } from './login.endpoints';
import { headers } from '../../shared/helpers/headers';

export const loginService = {
    login,
    register,
    forgotPassword,
    changePassword,
};

axios.interceptors.response.use(undefined, Interceptor(axios));

async function login(authCode: IAuthCode): Promise<AxiosResponse> {
    const requestOptions: AxiosRequestConfig = {
        url: LOGIN_ENDPOINTS.LOGIN.url,
        method: routeConstants.METHODS.POST,
        headers: headers.routeHeaders(),
        data: JSON.stringify(authCode),
    };

    const response: AxiosResponse = await axios(requestOptions);
    if (response.status === routeConstants.HTTP_STATUS_CODE.OK) {
        return response.data.data;
    } else {
        authService.logout();
    }
}

async function register(authCode: IAuthCode): Promise<AxiosResponse> {
    const requestOptions: AxiosRequestConfig = {
        url: LOGIN_ENDPOINTS.REGISTER.url,
        method: routeConstants.METHODS.POST,
        headers: headers.routeHeaders(),
        data: JSON.stringify(authCode),
    };

    const response: AxiosResponse = await axios(requestOptions);
    if (response.status === routeConstants.HTTP_STATUS_CODE.CREATED) {
        return response.data;
    } else {
        authService.logout();
    }
}

async function forgotPassword(data: { email: string }): Promise<AxiosResponse> {
    const requestOptions: AxiosRequestConfig = {
        url: LOGIN_ENDPOINTS.FORGOT_PASSWORD.url,
        method: routeConstants.METHODS.POST,
        headers: headers.routeHeaders(),
        data: JSON.stringify(data),
    };

    const response: AxiosResponse = await axios(requestOptions);
    return response.data;
}

async function changePassword(data: { newPassword: string; forgotPasswordToken: string }): Promise<boolean> {
    const requestOptions: AxiosRequestConfig = {
        url: LOGIN_ENDPOINTS.CHANGE_PASSWORD.url,
        method: routeConstants.METHODS.POST,
        headers: headers.routeHeaders(),
        data: JSON.stringify({
            password: data.newPassword,
            token: data.forgotPasswordToken,
        }),
    };

    const response: AxiosResponse = await axios(requestOptions);
    if (response.status === routeConstants.HTTP_STATUS_CODE.OK) {
        return true;
    }
    return false;
}
