import { richTextEditorHelpers } from '../../../../../shared/helpers/rich.text.editor.helpers';
import { IInfluencerFeed } from '../../../influencer.interfaces';

const ambassador = {
    blog_name_en: '',
    blog_name_de: '',
    blogger_name: '',
    header_picture: null,
    description_en: richTextEditorHelpers.getEmptyEditorState(),
    description_de: richTextEditorHelpers.getEmptyEditorState(),
    userAccountMail: '',
};

const influencerFeed: IInfluencerFeed = {
    content: null,
    titleDe: '',
    descriptionDe: '',
    titleEn: '',
    descriptionEn: '',
    publiclyVisible: false,
};

export const formInitialValues = {
    ambassador,
    influencerFeed,
};
