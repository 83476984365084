import { tokenConstants } from '../constants/token.constants';
import { ITokenAction } from '../actions/interfaces';

const initialState = { token: null, refreshToken: null };

export function tokenReducer(state = initialState, action: ITokenAction) {
    switch (action.type) {
        case tokenConstants.INVALID_TOKEN:
            return {
                ...state,
            };

        case tokenConstants.TOKEN_REFRESHED:
            return {
                ...state,
                token: action.tokenInfo.data.accessToken,
                refreshToken: action.tokenInfo.data.refreshToken,
            };
        default:
            return state;
    }
}
