import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { routeConstants } from '../../shared/routes/route.constants';
import { sendmailEndpoints } from './sendmail.endpoints';
import { headers } from '../../shared/helpers/headers';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const requestSendingEmails = async (csvFile: File, subject: string, template: string): Promise<AxiosResponse> => {
    const formData = new FormData();

    formData.append('files', csvFile);
    formData.append('template', template);
    formData.append('subject', subject);

    const requestOptions: AxiosRequestConfig = {
        url: sendmailEndpoints.BACKEND_SENDMAIL_ROUTES.SENDMAIL,
        method: routeConstants.METHODS.POST,
        headers: headers.formDataHeaders(),
        data: formData,
    };

    return axios(requestOptions);
};

export const sendmailService = {
    requestSendingEmails,
};
