import React from 'react';
import moment from 'moment/moment';
import { MUIDataTableColumn } from 'mui-datatables';
import { IReferralOrderData } from '../referralOrders/referral.order.interfaces';
import { commonDatatableHelpers, commonOptions } from './common.datatable.options';

const referralOrderColumns: MUIDataTableColumn[] = [
    ...commonOptions.indexColumn,
    {
        name: 'referralCodeUser',
        label: 'User name',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: IReferralOrderData['referralCodeUser']): string {
                return value.name || '';
            },
            customHeadLabelRender: commonDatatableHelpers.labelWithSubLabel('User name', 'Referrer (OWG User)'),
        },
    },
    {
        name: 'referralCode',
        label: 'Referral code',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'shopifyCustomerId',
        label: 'Customer ID',
        options: {
            filter: false,
            sort: false,
            customHeadLabelRender: commonDatatableHelpers.labelWithSubLabel('Customer ID', 'Shopify'),
        },
    },
    {
        name: 'shopifyPaymentId',
        label: 'Transaction ID',
        options: {
            filter: false,
            sort: false,
            customHeadLabelRender: commonDatatableHelpers.labelWithSubLabel('Transaction ID', 'Shopify'),
        },
    },
    {
        name: 'orderCreatedAt',
        label: 'Order created at',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
            customHeadLabelRender: commonDatatableHelpers.labelWithSubLabel('Order created at', 'Shopify'),
        },
    },
    {
        name: 'email',
        label: 'Customer email',
        options: {
            filter: false,
            sort: false,
            customHeadLabelRender: commonDatatableHelpers.labelWithSubLabel('Customer email', 'Referee (Shopify)'),
        },
    },
    {
        name: 'albersVoucherReceived',
        label: 'Albers voucher received',
        options: {
            filter: false,
            sort: false,
            customBodyRender(value, _tableMeta, _updateValue) {
                return value ? 'Yes' : 'No';
            },
        },
    },
];

export const referralOrderDataTables = {
    referralOrderColumns,
};
