export const equipmentConstants = {
    EQUIPMENT_ACTION_TYPES: {
        GET_EQUIPMENT_REQUEST: 'GET_EQUIPMENT_REQUEST',
        GET_EQUIPMENT_SUCCESS: 'GET_EQUIPMENT_SUCCESS',
        GET_EQUIPMENT_FAILURE: 'GET_EQUIPMENT_FAILURE',

        ADD_EQUIPMENT_REQUEST: 'ADD_EQUIPMENT_REQUEST',
        ADD_EQUIPMENT_SUCCESS: 'ADD_EQUIPMENT_SUCCESS',
        ADD_EQUIPMENT_FAILURE: 'ADD_EQUIPMENT_FAILURE',

        UPDATE_EQUIPMENT_REQUEST: 'UPDATE_EQUIPMENT_REQUEST',
        UPDATE_EQUIPMENT_SUCCESS: 'UPDATE_EQUIPMENT_SUCCESS',
        UPDATE_EQUIPMENT_FAILURE: 'UPDATE_EQUIPMENT_FAILURE',

        DELETE_EQUIPMENT_REQUEST: 'DELETE_EQUIPMENT_REQUEST',
        DELETE_EQUIPMENT_SUCCESS: 'DELETE_EQUIPMENT_SUCCESS',
        DELETE_EQUIPMENT_FAILURE: 'DELETE_EQUIPMENT_FAILURE',
    },
    EQUIPMENT_ROW_ELEMENTS: {
        ID: 1,
        NAME_EN: 2,
        NAME_DE: 3,
    },
    EQUIPMENT_ROUTES: {
        EQUIPMENT: '/equipment',
    },
};
