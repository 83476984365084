import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { IProductForm } from '../../../upselling.interfaces';

const {
    formField: { titleEn, titleDe },
} = formModels.productFormModel;

const productValidationSchema: ObjectSchema<IProductForm> = Yup.object()
    .defined()
    .shape({
        titleEn: Yup.string().required(titleEn.requiredErrorMsg).defined(),
        titleDe: Yup.string().required(titleDe.requiredErrorMsg).defined(),
        picture: Yup.mixed(),
        milieuPicture: Yup.mixed(),
        randomProduct: Yup.boolean().default(false),
        shopifyDeAtProductId: Yup.number().optional().nullable(),
        shopifyUsProductId: Yup.number().optional().nullable(),
        shopifyDeAtVariantId: Yup.number().optional().nullable(),
        shopifyUsVariantId: Yup.number().optional().nullable(),
        categories: Yup.array(),
    });

const validationSchemas = {
    productValidationSchema,
};

export default validationSchemas;
