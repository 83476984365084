export const productConstants = {
    PRODUCT_ACTION_TYPES: {
        GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
        GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
        GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
        GET_PRODUCT_BY_ID_REQUEST: 'GET_PRODUCT_BY_ID_REQUEST',
        GET_PRODUCT_BY_ID_SUCCESS: 'GET_PRODUCT_BY_ID_SUCCESS',
        GET_PRODUCT_BY_ID_FAILURE: 'GET_PRODUCT_BY_ID_FAILURE',
        ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
        ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
        ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
        DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
        DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
        DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
        UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
        UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
        UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',
        GET_PRODUCT_CATEGORIES_REQUEST: 'GET_PRODUCT_CATEGORIES_REQUEST',
        GET_PRODUCT_CATEGORIES_SUCCESS: 'GET_PRODUCT_CATEGORIES_SUCCESS',
        GET_PRODUCT_CATEGORIES_FAILURE: 'GET_PRODUCT_CATEGORIES_FAILURE',
    },
    PRODUCT_ROW_ELEMENTS: {
        ID: 1,
        TITLE_EN: 2,
        TITLE_DE: 3,
        LINK_US_SHOP: 4,
        LINK_DE_SHOP: 5,
        LINK_OTHER_SHOP: 6,
    },
    PRODUCT_ROUTES: {
        PRODUCTS: '/products',
        PRODUCTS_WITH_PARAMS: '/products/',
        ADD_PRODUCT: '/products/add',
        EDIT_PRODUCT: '/products/edit',
        PRODUCT_CATEGORIES: '/product-categories',
    },
};
