import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import CustomCheckbox from '../../../../shared/components/generics/checkbox/Checkbox';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import ImageUpload from '../../../../shared/components/imageUpload';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { IInfluencerFeed } from '../../influencer.interfaces';
import { formInitialValues } from './formModel/form.initial.values';
import formModels from './formModel/form.model';
import validationSchemas from './formModel/validation.schema';
import { useInfluencerFeedData } from './hooks';
import styles from './index.module.scss';

type AddEditInfluencerFeedEntryProps = {
    editingEntry: IInfluencerFeed | null;
    isOpen: boolean;
    closeDialog: () => void;
    influencerId: number;
};

export default function AddEditInfluencerFeedEntry({
    closeDialog,
    editingEntry,
    isOpen,
    influencerId,
}: AddEditInfluencerFeedEntryProps) {
    const { addFeedEntryMutation, updateFeedEntryMutation } = useInfluencerFeedData(influencerId);

    async function handleSubmit(values: IInfluencerFeed) {
        if (editingEntry) {
            updateFeedEntryMutation.mutate(values, {
                onSuccess: () => {
                    closeDialog();
                },
            });
        } else {
            addFeedEntryMutation.mutate(values, {
                onSuccess: () => {
                    closeDialog();
                },
            });
        }
    }

    const form = formModels.influencerFeedFormModel.formField;
    return (
        <>
            <Dialog
                key={editingEntry?.id ?? 'adding'}
                open={isOpen}
                maxWidth="md"
                onClose={() => closeDialog()}
                className={styles.dialog}
            >
                <Formik
                    initialValues={editingEntry ?? formInitialValues.influencerFeed}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validate={zodUtils.zodFormikValidator(validationSchemas.influencerFeedSchema)}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<IInfluencerFeed>) => (
                        <Form className={styles.addForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.titleDe.label}
                                            name={form.titleDe.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.titleDe}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.titleDe && formProps.touched.titleDe
                                                ? formProps.errors.titleDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.descriptionDe.label}
                                            name={form.descriptionDe.name}
                                            type="text"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.descriptionDe}
                                            variant="outlined"
                                            multiline
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="de" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.descriptionDe && formProps.touched.descriptionDe
                                                ? formProps.errors.descriptionDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.titleEn.label}
                                            name={form.titleEn.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.titleEn}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.titleEn && formProps.touched.titleEn
                                                ? formProps.errors.titleEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.descriptionEn.label}
                                            name={form.descriptionEn.name}
                                            type="text"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.descriptionEn}
                                            variant="outlined"
                                            multiline
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.descriptionEn && formProps.touched.descriptionEn
                                                ? formProps.errors.descriptionEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControlLabel
                                        className={clsx(styles.formControl, 'mb4')}
                                        control={
                                            <CustomCheckbox
                                                checked={formProps.values.publiclyVisible}
                                                onChange={(event) => {
                                                    formProps.setFieldValue(
                                                        form.publiclyVisible.name,
                                                        event.target.checked,
                                                    );
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={form.publiclyVisible.label}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <ImageUpload
                                            fileValue={formProps.values.content as string}
                                            label={form.content.label}
                                            onChange={(file) => {
                                                formProps.setFieldValue(form.content.name, file);
                                            }}
                                            inputFieldName={form.content.name}
                                            key={form.content.name}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt4')}>
                                            {formProps.errors.content && formProps.touched.content
                                                ? formProps.errors.content
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <div className={styles.dialogSaveButton}>
                                        <Button
                                            className={clsx(styles.redButton)}
                                            type="submit"
                                            disabled={!formProps.isValid}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
