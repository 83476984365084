import * as Yup from 'yup';

export const loginConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    ROUTES: {
        LOGIN: '/app/login',
        REGISTER: '/app/register',
        CHANGE_PASSWORD: '/app/change-password',
    },
    validationSchema: Yup.object()
        .defined()
        .shape({
            email: Yup.string().email('Enter a valid email').required('Email is required').defined(),
            password: Yup.string()
                .min(6, 'A minimum of 6 characters password is required')
                .max(256, 'A maximum of 256 characters are allowed for the password')
                .required('Password is required')
                .defined(),
        }),
    changePasswordValidationSchema: Yup.object()
        .defined()
        .shape({
            newPassword: Yup.string()
                .min(6, 'A minimum of 6 characters password is required')
                .max(256, 'A maximum of 256 characters are allowed for the password')
                .required('A password is required')
                .defined(),
        }),
};
