import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../../shared/store';
import { QueryRequest } from './analytics.interfaces';
import { analyticsService } from './analytics.service';

export function useAnalyticsQueryOptions() {
    const query = useQuery({
        queryKey: ['analyticsQueryOptions'] as const,
        queryFn: analyticsService.getQueryOptions,
    });

    return query;
}

export function useAnalyticsMakeQuery(queryKey: string) {
    const globalSelectedFilters = useAppSelector((state) => state.analytics.globalSelectedFilters);
    const querySpecificSelectedFilters = useAppSelector((state) => state.analytics.querySpecificFilters[queryKey]);

    const query = useQuery({
        queryKey: ['analyticsMakeQuery', queryKey, querySpecificSelectedFilters, globalSelectedFilters] as const,
        queryFn: ({ queryKey: [, queryKey, specificFilter, globalSelectedFilter] }) =>
            analyticsService.makeQuery(queryKey, { ...(specificFilter ?? {}), ...globalSelectedFilter }),
    });

    return query;
}
