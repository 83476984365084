import { troubleshootConstants } from './troubleshoot.constants';
import { ITroubleshootActions, ITroubleshootState } from './troubleshoot.interfaces';

const initialState: ITroubleshootState = {
    troubleshoots: [],
    loading: false,
    total: 0,
};

const troubleshootReducer = (state = initialState, action: ITroubleshootActions): ITroubleshootState => {
    switch (action.type) {
        case troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                troubleshoots: action.troubleshootData.page,
                total: action.troubleshootData.total,
            };
        case troubleshootConstants.TROUBLESHOOT_ACTION_TYPES.GET_TROUBLESHOOTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export { troubleshootReducer };
