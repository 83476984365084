import React from 'react';
import { Box } from '@material-ui/core';
import styles from './index.module.scss';
import MUIRichTextEditor from 'mui-rte';

type Props = {
    handleDialogClick: () => void;
    values: string;
    label: string;
};

const TextEditorValueRender = ({ handleDialogClick, values, label }: Props) => {
    const parsedValues = JSON.parse(values);
    const hasValue = parsedValues.blocks[0].text != '';
    return (
        <Box onClick={handleDialogClick}>
            {!hasValue && <span className={styles.label}>{label}</span>}
            <MUIRichTextEditor readOnly value={values} inlineToolbar={false} controls={[]} />
        </Box>
    );
};

export default TextEditorValueRender;
