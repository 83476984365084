import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { categoryTagsAction } from '../../../../categoryTags/category.tags.actions';
import { recipeAction } from '../../../recipe.actions';
import { IRecipeCategoryTags, IRecipeCategoryTagsForm, IRecipeCategoryTagsProps } from '../../../recipe.interfaces';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import { genericHelpers } from '../../../../../shared/helpers/generics';
import { ICategoryTagsData } from '../../../../categoryTags/category.tags.interfaces';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import { recipeConstants } from '../../../recipe.constants';
import clsx from 'clsx';

type Props = IRecipeCategoryTagsProps & ReturnType<typeof mapStateToProps>;

const AddCategoryTags = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [categoryTags, setCategoryTags] = useState<Array<ICategoryTagsData>>([]);
    const [recipeCategoryTags, setRecipeCategoryTags] = useState<Array<IRecipeCategoryTags>>([]);
    const [changedCategories, setChangedCategories] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const { recipe, categoryTagsTotal } = props;

    useEffect(() => {
        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            categoryTagsTotal,
            categoryTags.length + recipeCategoryTags.length,
            page,
        );

        const isMounted = true;
        const isFirstMount = categoryTags.length + recipeCategoryTags.length === 0;

        (hasPagination || isFirstMount) &&
            props.getCategoryTags({ ...globalConstants.DEFAULT_PAGINATION, page: nextPage }).then((response) => {
                const categoryTagsResponse = response.categoryTags;

                if (isMounted) {
                    props.getRecipeCategoryTags(props.recipe.id).then((response) => {
                        const recipesCategoryTagsResponse: Array<IRecipeCategoryTags> = response.recipeCategoryTags;

                        if (recipesCategoryTagsResponse.length) {
                            const newRecipesCategoryTagsResponse = recipesCategoryTagsResponse.filter(
                                (recipeCategoryTagResponse) =>
                                    !recipeCategoryTags.find((categoryTag) => {
                                        return categoryTag.id === recipeCategoryTagResponse.id;
                                    }),
                            );
                            const updatedRecipeCategoryTags = recipeCategoryTags.concat(newRecipesCategoryTagsResponse);

                            setIsUpdating(true);
                            setRecipeCategoryTags(updatedRecipeCategoryTags);

                            const categoryTagsToDisplay = categoryTagsResponse.filter(
                                (categoryTag) =>
                                    !recipesCategoryTagsResponse.find((recipeCategoryTag) => {
                                        return recipeCategoryTag.id === categoryTag.id;
                                    }),
                            );
                            const updatedCategoryTags = categoryTags.concat(categoryTagsToDisplay);

                            setCategoryTags(updatedCategoryTags);
                            hasPagination && setPage(nextPage);
                        } else {
                            const updatedCategoryTags = categoryTags.concat(categoryTagsResponse);
                            setCategoryTags(updatedCategoryTags);
                            hasPagination && setPage(nextPage);
                        }
                    });
                }
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        isUpdating ? handleUpdate(changedCategories) : handleInsert(changedCategories);
    };

    const handleUpdate = (categoryTagIds: Array<number>): void => {
        const categoryTagsToSave = { category_tag_ids: categoryTagIds, recipe_id: recipe.id };

        props.updateRecipeCategoryTags(categoryTagsToSave).then(() => {
            props.nextStep(recipe);
        });
    };

    const handleInsert = (category_tag_ids: Array<number>): void => {
        const categoryTagsToSave: IRecipeCategoryTagsForm = {
            recipe_id: recipe.id,
            category_tag_ids,
        };

        props.addRecipeCategoryTags(categoryTagsToSave).then(() => {
            props.nextStep(recipe);
        });
    };

    return (
        <>
            {categoryTags.length || recipeCategoryTags.length ? (
                <DragAndDrop
                    destinationColumnName={recipeConstants.DRAG_AND_DROP.CATEGORY_TAGS_DESTINATION}
                    sourceColumnName={recipeConstants.DRAG_AND_DROP.CATEGORY_TAGS_SOURCE}
                    items={recipeCategoryTags}
                    availableItems={categoryTags}
                    handleItemsChange={setChangedCategories}
                    endScroll={[setEndScroll, endScroll]}
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.nextStep(props.recipe)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.NEXT}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={() => props.prevStep(props.recipe)}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handleSubmit}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.SUBMIT_AND_CONTINUE}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    categoryTagsTotal: state.categoryTags.total,
});

const mapDispatchToProps = {
    getCategoryTags: categoryTagsAction.getCategoryTags,
    addRecipeCategoryTags: recipeAction.addRecipeCategoryTags,
    getRecipeCategoryTags: recipeAction.getRecipeCategoryTags,
    updateRecipeCategoryTags: recipeAction.updateRecipeCategoryTags,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCategoryTags);
