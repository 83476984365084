import React, { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../shared/store';
import { compose } from 'recompose';
import styles from '../index.module.scss';
import { Button } from '@material-ui/core';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { paginationHelper } from '../../../../../shared/helpers/paginationHelper';
import DragAndDrop from '../../../../dndComponent/DragNDrop';
import { selectionActions } from '../../../selection.actions';
import en from '../../../../../../assets/language/en.json';
import {
    IRecipeSelectionProduct,
    IRecipeSelectionProductsForm,
    IRecipeSelectionProductsFormComponent,
} from '../../../selection.interfaces';
import { selectionConstants } from '../../../selection.constants';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { IProduct } from '../../../../upselling/upselling.interfaces';
import { upsellingActions } from '../../../../upselling/upselling.actions';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';

type Props = IRecipeSelectionProductsFormComponent & ReturnType<typeof mapStateToProps>;

const AddProducts = (props: PropsWithChildren<Props>): ReactElement<FunctionComponent<Props>> => {
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [recipeSelectionProducts, setRecipeSelectionProducts] = useState<Array<IRecipeSelectionProduct>>([]);
    const [changedProducts, setChangedProducts] = useState<Array<number>>([]);
    const [endScroll, setEndScroll] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const {
        selectionData: { selection },
        productsTotal,
    } = props;

    const id = useIdParam();

    useEffect(() => {
        const { hasPagination, nextPage } = paginationHelper.paginate(
            endScroll,
            productsTotal,
            products.length + recipeSelectionProducts.length,
            page,
        );
        const isFirstMount = products.length + recipeSelectionProducts.length === 0;

        (hasPagination || isFirstMount) &&
            props.getProducts({ ...globalConstants.DEFAULT_PAGINATION, page: nextPage }).then((response) => {
                const productsResponse = response.products;

                props.getRecipeSelectionProducts(id ? id : selection.id).then((response) => {
                    const recipeSelectionProductsResponse = response.recipeSelectionProducts;

                    if (Array.isArray(recipeSelectionProductsResponse)) {
                        const newRecipeSelectionProductsResponse = recipeSelectionProductsResponse.filter(
                            (recipeSelectionProductsResponse) =>
                                !recipeSelectionProducts.find((product) => {
                                    return product.id === recipeSelectionProductsResponse.id;
                                }),
                        );
                        const updatedSelectionProducts = recipeSelectionProducts.concat(
                            newRecipeSelectionProductsResponse,
                        );

                        setIsUpdating(true);
                        setRecipeSelectionProducts(updatedSelectionProducts);
                        const productsToDisplay = productsResponse.filter(
                            (product) =>
                                !recipeSelectionProductsResponse.find((filterProduct) => {
                                    return filterProduct.id === product.id;
                                }),
                        );

                        const updatedProducts = products.concat(productsToDisplay);

                        setProducts(updatedProducts);
                        hasPagination && setPage(nextPage);
                    } else {
                        const updatedProducts = products.concat(productsResponse);
                        setProducts(updatedProducts);
                        hasPagination && setPage(nextPage);
                    }
                });
            });
    }, [endScroll]);

    const handleSubmit = (): void => {
        selection ? handleUpdate(changedProducts) : handleInsert(changedProducts);
    };

    const handleUpdate = (productIds: Array<number>): void => {
        const productsToSave: IRecipeSelectionProductsForm = {
            productIds: productIds,
            recipeSelectionId: selection.id,
        };

        props.updateRecipeSelectionProducts(productsToSave).then((response) => {
            props.handleCompleteStep(props.selectionData);
        });
    };

    const handleInsert = (productIds: Array<number>): void => {
        const productsToSave: IRecipeSelectionProductsForm = {
            productIds: productIds,
            recipeSelectionId: selection.id,
        };

        props.addRecipeSelectionProducts(productsToSave).then((response) => {
            props.handleCompleteStep(props.selectionData);
        });
    };

    const handlePrevStep = (): void => {
        props.prevStep(props.selectionData);
    };

    const nextStep = (): void => {
        props.nextStep(props.selectionData);
    };
    return (
        <>
            {Array.isArray(products) || Array.isArray(recipeSelectionProducts) ? (
                <DragAndDrop
                    destinationColumnName={selectionConstants.DRAG_AND_DROP.PRODUCTS_DESTINATION}
                    sourceColumnName={selectionConstants.DRAG_AND_DROP.PRODUCTS_SOURCE}
                    items={recipeSelectionProducts}
                    availableItems={products}
                    handleItemsChange={setChangedProducts}
                    endScroll={[setEndScroll, endScroll]}
                    nameKey="titleEn"
                />
            ) : null}
            <div className={styles.bottomNavigation}>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={nextStep}
                    disabled={!selection}
                >
                    {en.next}
                </Button>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                >
                    {en.save_button_label}
                </Button>
                <Button
                    className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                    onClick={handlePrevStep}
                    variant={'outlined'}
                >
                    {globalConstants.NAVIGATION_BUTTONS_LABELS.BACK}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    productsTotal: state.products.total,
});

const mapDispatchToProps = {
    getProducts: upsellingActions.getProducts,
    addRecipeSelectionProducts: selectionActions.addRecipeSelectionProducts,
    getRecipeSelectionProducts: selectionActions.getRecipeSelectionProducts,
    updateRecipeSelectionProducts: selectionActions.updateRecipeSelectionProducts,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddProducts);
