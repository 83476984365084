import React, { PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import en from '../../../../../assets/language/en.json';
import { MUIDataTableMeta } from 'mui-datatables';
import { IFilters, IPagination, IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { muiStyles } from '../../../../shared/styles/mui.styles';
import { Button } from '@material-ui/core';
import { IProductProps } from '../../upselling.interfaces';
import { upsellingActions } from '../../upselling.actions';
import { upsellingDataTables } from '../../../helpers/upselling.datatables';
import { productConstants } from '../../upselling.constants';
import { AppState } from '../../../../shared/store';
import DynamicTable from '../../../../shared/components/generics/table/DynamicTable';
import { globalConstants } from '../../../../shared/constants/global.constants';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type OwnProps = IProductProps & ReturnType<typeof mapStateToProps>;

const Product = (props: PropsWithChildren<OwnProps>): ReactElement<OwnProps> => {
    const { history, products, total, loading } = props;

    const tableColumns = [
        ...upsellingDataTables.productColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            component={Link}
                            to={`${productConstants.PRODUCT_ROUTES.EDIT_PRODUCT}/${products[tableMeta.rowIndex].id}`}
                            className={styles.editProduct}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        onRowsDelete: async (rowsDeleted: IRowsDeleted): Promise<void> => {
            const productsToRemoveIndex = rowsDeleted.data.map(({ index }) => index);
            const productsToRemoveLength = productsToRemoveIndex.length;

            productsToRemoveIndex.map(async (productIndex, index) => {
                const productId = products[productIndex].id;
                const completed = productsToRemoveLength === index + 1;

                await props.deleteProduct(productId, completed);
                completed && (await _refreshProducts(globalConstants.DEFAULT_PAGINATION));
            });
        },
    };

    const _refreshProducts = async (
        pagination: IPagination,
        searchText?: string,
        filters?: IFilters,
    ): Promise<void> => {
        await props.getProducts(pagination, searchText, filters);
    };

    const addNewProduct = (): void => {
        history.push(productConstants.PRODUCT_ROUTES.ADD_PRODUCT);
    };

    return (
        <div className={clsx(styles.product, 'mt16')}>
            <Button className={clsx(styles.addProduct, 'mb4')} onClick={addNewProduct}>
                {en.add_new_product_label}
            </Button>
            <DynamicTable
                title={en.products_label}
                options={options}
                columns={tableColumns}
                data={products}
                total={total}
                loading={loading}
                refreshData={_refreshProducts}
            />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    products: state.products.products,
    total: state.products.total,
    loading: state.products.loading,
});

const mapDispatchToProps = {
    getProducts: upsellingActions.getProducts,
    getProductById: upsellingActions.getProductById,
    deleteProduct: upsellingActions.deleteProduct,
};

export default compose(withStyles(muiStyles.customStyles), connect(mapStateToProps, mapDispatchToProps))(Product);
