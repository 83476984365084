import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Login from '../../../modules/login/pages/login';
import Register from '../../../modules/login/pages/register';
import ChangePassword from '../../../modules/login/pages/changePassword';
import { AppState } from '../../store';
import Footer from '../partials/Footer';
import { ILayoutProps } from './layout.interfaces';
import { loginConstants } from '../../../modules/login/login.constants';

const PublicLayout: React.FC<ILayoutProps> = (props: PropsWithChildren<ILayoutProps>) => {
    if (!props.loggedIn) {
        return (
            <div>
                <Switch>
                    <Route exact path={loginConstants.ROUTES.LOGIN} component={Login} />
                    <Route exact path={loginConstants.ROUTES.REGISTER} component={Register} />
                    <Route path={`${loginConstants.ROUTES.CHANGE_PASSWORD}/:token`} component={ChangePassword} />
                </Switch>
                <Footer />
            </div>
        );
    } else {
        return <Redirect to="/" />;
    }
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
});

export default compose(withRouter, connect(mapStateToProps, null))(PublicLayout);
