import { z } from 'zod';
import { zodUtils } from '../../../../../shared/helpers/zodUtils';

const zodValidationSchema = z.object({
    id: zodUtils.numberOrNullSchema.optional().nullable(),
    internalName: z.string().min(1).max(256),
    thumbnailDe: zodUtils.fileSchema,
    thumbnailEn: zodUtils.fileSchema,
    videoDe: zodUtils.fileSchema.optional().nullable(),
    videoEn: zodUtils.fileSchema.optional().nullable(),
});

const troubleshootPrimaryStepSchema = z.object({
    id: z.number().optional().nullable(),
    stepNumber: z.number().min(0).optional().nullable(),
    titleEn: z.string().min(1),
    titleDe: z.string().min(1),
    thumbnailEn: z
        .union([z.string(), z.instanceof(File)])
        .optional()
        .nullable(),
    thumbnailDe: z
        .union([z.string(), z.instanceof(File)])
        .optional()
        .nullable(),
});

const troubleshootStepSchema = z
    .object({
        id: z.number().optional().nullable(),
        stepNumber: z.number().min(0).optional().nullable(),
        defaultDescription: zodUtils.localizationSchema(z.string().nonempty()).optional().nullable(),
        oldFirmwareIOS: zodUtils.localizationSchema(z.string().nonempty()).optional().nullable(),
        oldFirmwareAndroid: zodUtils.localizationSchema(z.string().nonempty()).optional().nullable(),
        semanticFirmwareIOS: zodUtils.localizationSchema(z.string().nonempty()).optional().nullable(),
        semanticFirmwareAndroid: zodUtils.localizationSchema(z.string().nonempty()).optional().nullable(),
        isResolvingStep: z.boolean().optional().nullable().default(false),
    })
    .superRefine((data, ctx) => {
        if (data.defaultDescription) {
            if (
                data.oldFirmwareIOS ||
                data.oldFirmwareAndroid ||
                data.semanticFirmwareIOS ||
                data.semanticFirmwareAndroid
            ) {
                return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'If defaultDescription is provided, none of the other descriptions should be provided',
                });
            }
            return;
        }

        if (
            data.oldFirmwareIOS ||
            data.oldFirmwareAndroid ||
            data.semanticFirmwareIOS ||
            data.semanticFirmwareAndroid
        ) {
            if (data.defaultDescription) {
                return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message:
                        'If all of the platform specific descriptions are provided, defaultDescription should not be provided',
                });
            }
            return;
        }

        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Either defaultDescription or (at least) one platform specific descriptions should be provided',
        });
    });

const troubleshootWithStepsSchema = z.object({
    troubleshootingPrimarySteps: z.array(troubleshootPrimaryStepSchema),
});

const troubleshootStepsSchema = z.array(troubleshootStepSchema, {
    errorMap: (error) => {
        const stepErrors = error.path.map((path) => +path + 1);
        return {
            message: `Step ${stepErrors.join(', ')}: ${error.message}`,
        };
    },
});

const validationSchemas = {
    zodValidationSchema,
    troubleshootStepSchema,
    troubleshootWithStepsSchema,
    troubleshootPrimaryStepSchema,
    troubleshootStepsSchema,
};

export default validationSchemas;
