import { MUIDataTableMeta } from 'mui-datatables';
import en from '../../../assets/language/en.json';
import de from '../../../assets/language/de.json';
import moment from 'moment';

const productColumns = [
    {
        name: '#',
        options: {
            sort: false,
            filter: false,
            customBodyRender(value: unknown, meta: MUIDataTableMeta): number {
                return meta.rowIndex + 1;
            },
        },
    },
    {
        name: 'id',
        label: en.id,
        options: {
            display: false,
            sort: false,
            filter: false,
        },
    },
    {
        name: 'titleEn',
        label: en.title_label,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'titleDe',
        label: de.title_label,
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'hasUsProduct',
        label: 'Has US Shopify Product',
        options: {
            filter: true,
            sort: true,
            customBodyRender(value: unknown): string {
                return value ? 'Yes' : 'No';
            },
        },
    },
    {
        name: 'hasDeAtProduct',
        label: 'Has DE/AT Shopify Product',
        options: {
            filter: true,
            sort: true,
            customBodyRender(value: unknown): string {
                return value ? 'Yes' : 'No';
            },
        },
    },
    {
        name: 'createdAt',
        label: en.create_date_label,
        options: {
            filter: true,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
    {
        name: 'modifiedAt',
        label: en.modify_date_label,
        options: {
            filter: true,
            sort: false,
            customBodyRender(value: unknown): string {
                return moment(value).calendar();
            },
        },
    },
];

export const upsellingDataTables = {
    productColumns,
};
