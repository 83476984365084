import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import styles from './index.module.scss';
import clsx from 'clsx';

interface Color {
    colorHex: string;
    name: string;
}

type OwnProps = {
    colors: Color[];
    value: unknown;
    handleColorChange: (colorHex: string) => unknown;
    fieldVariant?: 'textfield' | 'onlyCircles';
} & TextFieldProps;

const ColorInputField = (props: OwnProps) => {
    const { colors, handleColorChange, value, fieldVariant, style, ...otherProps } = props;
    const colorName = colors.find((c) => c.colorHex === value)?.name;
    return fieldVariant === 'onlyCircles' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: `${37 * colors.length}px`,
                ...style,
            }}
        >
            {colors.map((color, index) => (
                <span
                    key={index}
                    className={clsx(styles.circle, value === color.colorHex ? styles.selected : null)}
                    style={{ backgroundColor: color.colorHex }}
                    onClick={() => handleColorChange(color.colorHex)}
                ></span>
            ))}
        </div>
    ) : (
        <TextField
            {...(otherProps as TextFieldProps)}
            disabled
            style={style}
            value={colorName ? `${value} (${colorName})` : value}
            key={otherProps.key || (value as string)}
            InputProps={{
                ...otherProps.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        {colors.map((color, index) => (
                            <span
                                key={index}
                                className={clsx(styles.circle, value === color.colorHex ? styles.selected : null)}
                                style={{ backgroundColor: color.colorHex }}
                                onClick={() => handleColorChange(color.colorHex)}
                            ></span>
                        ))}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default ColorInputField;
