import React, { useState } from 'react';
import { Button, FormControl, FormGroup, Input, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ObjectSchema } from 'yup';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import { loginActions } from '../../login.actions';
import { loginConstants } from '../../login.constants';
import { IFormValues, IValidationSchema } from '../../login.interfaces';
import styles from './index.module.scss';

type OwnProps = DispatchPropsReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Login = (props: PropsWithChildren<OwnProps>) => {
    const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false);

    // Get the query params from the url
    const queryParams = new URLSearchParams(window.location.search);
    const justVerified = queryParams.get('verified')?.toLowerCase() === 'true';
    const email = queryParams.get('email');

    const initialValues: IFormValues = { email: email || '', password: '' };

    const validationSchema: ObjectSchema<IValidationSchema> = loginConstants.validationSchema;

    const handleSubmit = (values: IFormValues) => {
        props.login(values);
    };

    const handleForgotPassword = async (email: string) => {
        if (forgotPasswordSubmitted) {
            return;
        }
        setForgotPasswordSubmitted(true);
        await props.forgotPassword({ email });
    };

    return (
        <div className={styles.login}>
            <div className={styles.loginContainer}>
                <h1 className={styles.title}>Login to Otto Wilde CMS</h1>
                {justVerified && <h3 className={styles.verifiedTitle}>Successfully verified your email.</h3>}
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchema}
                >
                    {(props: FormikProps<IFormValues>) => (
                        <Form className={styles.loginForm}>
                            <FormGroup>
                                <FormControl className={clsx(styles.formControl, 'mb8')}>
                                    <InputLabel id="emailLabel" className={styles.formLabel}>
                                        Email
                                    </InputLabel>
                                    <Input
                                        className={styles.formInput}
                                        name="email"
                                        type="text"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                    />
                                    <span className={clsx(styles.error_span, 'mt2')}>
                                        {props.errors.email && props.touched.email ? props.errors.email : ''}
                                    </span>
                                </FormControl>
                                <FormControl className={clsx(styles.formControl, 'mb8')}>
                                    <InputLabel id="passwordLabel" className={styles.formLabel}>
                                        Password
                                    </InputLabel>
                                    <Input
                                        className={styles.formInput}
                                        name="password"
                                        type="password"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                    />
                                    <span className={clsx(styles.error_span, 'mt2')}>
                                        {props.errors.password && props.touched.password ? props.errors.password : ''}
                                    </span>
                                </FormControl>
                                <Button
                                    className={styles.submitBtn}
                                    type="submit"
                                    variant={'outlined'}
                                    color={'inherit'}
                                    disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                >
                                    Submit
                                </Button>
                                <a
                                    className="mt-1"
                                    onClick={() => handleForgotPassword(props.values.email)}
                                    style={{
                                        color: forgotPasswordSubmitted ? 'grey' : undefined,
                                    }}
                                >
                                    Forgot your password? Click here
                                </a>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
                <Link className="mt-2" to={loginConstants.ROUTES.REGISTER}>
                    Don&lsquo;t have an account? Register here
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
});

const mapDispatchToProps = {
    login: loginActions.login,
    forgotPassword: loginActions.forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
