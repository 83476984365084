export const cookingMethodsEndpoints = {
    COOKING_METHOD_ROUTES: {
        COOKING_METHOD: '/cooking-methods',
    },
    GET_COOKING_METHODS: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods',
    },
    ADD_COOKING_METHOD: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods',
    },
    DELETE_COOKING_METHOD: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods/',
    },
    UPDATE_COOKING_METHOD: {
        url: process.env.REACT_APP_SERVER_URL + '/cooking-methods/',
    },
};
