const isOneAtLeastValidator = (a: Array<number | null | undefined>, b?: number): boolean =>
    !!(a.filter((v) => v).length >= 1 || b);

// Check if theres at least one element in the array or the number is defined
const isOneAtMostValidator = (a: Array<number | null | undefined>, b: number): boolean => {
    const result = isOneAtLeastValidator(a);

    return !!(result && b);
};

const validatorHelpers = {
    isOneAtLeastValidator,
    isOneAtMostValidator,
};

export default validatorHelpers;
