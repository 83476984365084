import { promotionCampaignConstants } from './promotion.campaign.constants';
import { IPromotionCampaignActions, IPromotionCampaignState } from './promotion.campaign.interfaces';

const initialState: IPromotionCampaignState = {
    promotionCampaigns: [],
    promotionCampaign: null,
    loading: false,
    total: 0,
    availableProducts: [],
};

const promotionCampaignReducer = (state = initialState, action: IPromotionCampaignActions): IPromotionCampaignState => {
    switch (action.type) {
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_REQUEST:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_REQUEST:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.UPDATE_PROMOTION_CAMPAIGN_REQUEST:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.DELETE_PROMOTION_CAMPAIGN_REQUEST:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_REQUEST:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_FAILURE:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_FAILURE:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_FAILURE:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                promotionCampaigns: action.promotionCampaignsData.promotionCampaigns,
                total: action.promotionCampaignsData.total,
                loading: false,
            };
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_SUCCESS:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.UPDATE_PROMOTION_CAMPAIGN_SUCCESS:
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_SUCCESS:
            return {
                ...state,
                promotionCampaign: action.promotionCampaign,
                loading: false,
            };
        case promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_SUCCESS:
            return {
                ...state,
                availableProducts: action.availableProducts,
                loading: false,
            };
        default:
            return state;
    }
};

export { promotionCampaignReducer };
