const camelToSnakeCase = (inputString) => {
    return inputString
        .split('')
        .map((character) => {
            if (character == character.toUpperCase()) {
                return '_' + character.toLowerCase();
            } else {
                return character;
            }
        })
        .join('');
};

export { camelToSnakeCase };
