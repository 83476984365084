import { useAnalyticsMakeQuery } from '../analytics.hooks';
import { QueryResult } from '../analytics.interfaces';

type AnalyticsQueryResultProps = {
    queryKey: string;
    displayValueSum?: boolean;
};

export default function AnalyticsQueryResult({ queryKey, displayValueSum }: AnalyticsQueryResultProps) {
    const queryResult = useAnalyticsMakeQuery(queryKey);

    if (queryResult.isLoading) {
        return <div>Loading...</div>;
    }

    if (queryResult.isError) {
        return <div>Error: {queryResult.error}</div>;
    }

    return (
        <table>
            {!!displayValueSum && <TableTotalSumHead queryResult={queryResult.data} />}
            <tbody>
                {queryResult.data.map((result, index) => (
                    <tr key={result.resultKey + index}>
                        <td>{result.resultKey}</td>
                        <td>{result.resultValue}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function TableTotalSumHead({ queryResult }: { queryResult: QueryResult }) {
    const totalSum = queryResult.reduce((sum, result) => sum + Number(result.resultValue), 0);
    return (
        <thead>
            <tr>
                <th>Sum</th>
                <th>{totalSum}</th>
            </tr>
        </thead>
    );
}
