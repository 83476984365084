import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { alertActions } from '../../shared/actions/alert.actions';
import { authActions } from '../../shared/actions/auth.actions';
import { ingredientsConstants } from '../ingredients/ingredients.constants';
import {
    ICategoryTagTypeActions,
    ICategoryTagTypeForm,
    ICategoryTagTypeResponse,
} from './category.tag.types.interfaces';
import { categoryTagTypesService } from './category.tag.types.service';
import { categoryTagTypeConstants } from './category.tag.types.constants';
import en from '../../../assets/language/en.json';

function getCategoryTagTypes(): (dispatch: Dispatch) => Promise<ICategoryTagTypeResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());

        return categoryTagTypesService.getCategoryTagTypes().then(
            (response: ICategoryTagTypeResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): ICategoryTagTypeActions {
        return { type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.GET_CATEGORY_TAG_TYPES_REQUEST };
    }

    function success(response: ICategoryTagTypeResponse): ICategoryTagTypeActions {
        return {
            type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.GET_CATEGORY_TAG_TYPES_SUCCESS,
            categoryTagTypeData: response,
        };
    }

    function failure(error: string): ICategoryTagTypeActions {
        return { type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_FAILURE, error };
    }
}

const addCategoryTagType = (
    categoryTagTypeData: ICategoryTagTypeForm,
): ((dispatch: Dispatch) => Promise<ICategoryTagTypeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagTypesService.addCategoryTagType(categoryTagTypeData).then(
            (response: ICategoryTagTypeResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.category_tag_type_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagTypeActions {
        return { type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_REQUEST };
    }

    function success(response: ICategoryTagTypeResponse): ICategoryTagTypeActions {
        return {
            type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_SUCCESS,
            categoryTagType: response.data,
        };
    }

    function failure(error: string): ICategoryTagTypeActions {
        return { type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.ADD_CATEGORY_TAG_TYPE_FAILURE, error };
    }
};

const deleteCategoryTagType = (
    categoryTagTypeId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagTypesService.deleteCategoryTagType(categoryTagTypeId).then(
            (response: AxiosResponse) => {
                dispatch(success(response));
                completed && dispatch(alertActions.success(en.category_tag_type_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagTypeActions {
        return { type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_REQUEST };
    }

    function success(response: AxiosResponse): ICategoryTagTypeActions {
        return {
            type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_SUCCESS,
        };
    }

    function failure(error: string): ICategoryTagTypeActions {
        return {
            type: categoryTagTypeConstants.CATEGORY_TAG_TYPES_ACTION_TYPES.DELETE_CATEGORY_TAG_TYPE_FAILURE,
            error,
        };
    }
};

const updateCategoryTagType = (
    categoryTagTypeData: ICategoryTagTypeForm,
    categoryTagTypeId: number,
): ((dispatch: Dispatch) => Promise<ICategoryTagTypeResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return categoryTagTypesService.updateCategoryTagType(categoryTagTypeData, categoryTagTypeId).then(
            (response: ICategoryTagTypeResponse) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.category_tag_type_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): ICategoryTagTypeActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_REQUEST };
    }

    function success(response: ICategoryTagTypeResponse): ICategoryTagTypeActions {
        return {
            type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_SUCCESS,
            categoryTagType: response.data,
        };
    }

    function failure(error: string): ICategoryTagTypeActions {
        return { type: ingredientsConstants.INGREDIENTS_ACTION_TYPES.UPDATE_INGREDIENT_FAILURE, error };
    }
};

export const categoryTagTypesActions = {
    getCategoryTagTypes,
    addCategoryTagType,
    deleteCategoryTagType,
    updateCategoryTagType,
};
